import { cloneDeep } from "lodash";
import * as types from "../../const/redux/user";

let initialState = {
  profile: {},
  verifyInfo: {},
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USER_PROFILE_SUCCESS: {
      let newState = cloneDeep(state);
      newState.profile = cloneDeep(action.payload.data);
      return newState;
    }
    case types.GET_USER_VERIFY_SUCCESS: {
      let newState = cloneDeep(state);
      newState.verifyInfo = cloneDeep(action.payload.data);
      return newState;
    }
    case types.DESTROY_USER_PROFILE: {
      let newState = cloneDeep(initialState);
      return newState;
    }
    default:
      return state;
  }
};

export default userReducer;
