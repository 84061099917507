import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import Bell from "../../assets/img/Bell.png";
import CheckReadallActive from "../../assets/img/CheckReadallActive.png";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
  iconButton: {
    fontSize: "16px",
  },
  paperPopover: { backgroundColor: `${theme.body.backgroundColor}` },
  rootCard: {
    minWidth: 400,
    backgroundColor: `${theme.body.backgroundColor}`,
  },
  cardHeader: { backgroundColor: "hsla(0, 0%, 100%, 0.05)" },
  cardContent: { padding: 0 },
  actionHeader: {
    marginTop: 0,
    alignSelf: "center",
    marginRight: 5,
  },
  rootTitle: {
    display: "flex",
    alignItems: "center",
  },
  buttonTitle: {
    padding: "5px 10px",
    minWidth: 0,
    marginLeft: 10,
    borderRadius: 2,
  },
  typographyPrimary: {
    color: `${theme.text.primary}`,
  },
  typographySecondary: { color: `${theme.text.secondary}` },
  typographyLink: {
    color: `${theme.text.link}`,
    marginRight: 5,
  },
}));

function PopOverNoti(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <IconButton
        color="inherit"
        className={classes.iconButton}
        onClick={handleClick}
      >
        <img src={`${Bell}`} alt="Bell" />
      </IconButton>
      <Popover
        classes={{ paper: classes.paperPopover }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Card classes={{ root: classes.rootCard }}>
          <CardHeader
            classes={{ root: classes.cardHeader, action: classes.actionHeader }}
            title={
              <div className={classes.rootTitle}>
                <Typography
                  variant="body2"
                  classes={{ root: classes.typographyLink }}
                >
                  34
                </Typography>
                <Typography
                  variant="body2"
                  classes={{ root: classes.typographyPrimary }}
                >
                  {t("notice unopened")}
                </Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  classes={{ root: classes.buttonTitle }}
                >
                  <img src={CheckReadallActive} alt="CheckReadallActive" />
                </Button>
              </div>
            }
            action={
              <Typography
                variant="body2"
                classes={{ root: classes.typographyLink }}
              >
                {t("All")}
              </Typography>
            }
          />
          <CardContent classes={{ root: classes.cardContent }}>
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      variant="body2"
                      classes={{ root: classes.typographyPrimary }}
                    >
                      Notification title
                    </Typography>
                  }
                  secondary={
                    <Typography
                      variant="caption"
                      classes={{ root: classes.typographySecondary }}
                    >
                      Notification title
                    </Typography>
                  }
                />
                <ListItemSecondaryAction>
                  <Typography
                    variant="caption"
                    classes={{ root: classes.typographySecondary }}
                  >
                    Notification title
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Popover>
    </>
  );
}

PopOverNoti.propTypes = {};

export default PopOverNoti;
