import {
  AppBar,
  Button,
  createStyles,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import logo from "../../assets/img/LogoLarge.png";
import { PATH } from "../../const/paths";
import SelectLanguage from "../Select/SelectLanguage";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        paddingBottom: 8,
      },
      appBar: {
        backgroundColor: "transparent",
        borderBottom: `1px solid rgba(255, 255, 255, 0.25)`,
        padding: "20px 30px",
        boxShadow: "none",
        [theme.breakpoints.down("xs")]: {
          padding: "20px 0px",
        },
      },
      toolBar: {
        color: "#888888",
        justifyContent: "space-between",
        [theme.breakpoints.down("xs")]: {
          flexDirection: "column",
        },
      },
      menuButton: {
        marginRight: 0,
      },
      imageLogo: { width: 56, height: 45 },
      title: {
        flexGrow: 1,
        fontWeight: "bold",
        // fontFamily: "Montserrat",
        fontStyle: "normal",
        fontSize: 32,
        color: theme.text.primary,
        cursor: "pointer",
        marginRight: 10,
      },
      buttonLogin: {
        color: theme.text.primary,
        borderColor: theme.text.primary,
        borderRadius: 1,
        textTransform: "none",
        minWidth: 160,
        minHeight: 40,
        margin: "0 7px",
        fontSize: 18,
      },
      buttonRegister: {
        backgroundColor: theme.text.demo,
        borderColor: theme.text.demo,
        borderRadius: 1,
        textTransform: "none",
        minWidth: 160,
        minHeight: 40,
        margin: "0 7px",
        fontSize: 18,
        "&:hover": {
          color: theme.text.primary,
        },
      },
      rootLogo: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        [theme.breakpoints.down("xs")]: {
          marginBottom: 10,
        },
      },
      rootAction: {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
          marginLeft: 10,
        },
      },
    }),
  { index: 1 }
);

function TopBarHome(props) {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const history = useHistory();

  const handleLink = (path) => {
    let jwt_token = localStorage.getItem("jwt_token");
    if (path === PATH.HOME && jwt_token) {
      history.push(PATH.TRADING_VIEW);
    } else history.push(path);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar variant="dense" className={classes.toolBar}>
          <div className={classes.rootLogo}>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={() => {
                handleLink(PATH.HOME);
              }}
            >
              <img src={`${logo}`} alt="logo" className={classes.imageLogo} />
            </IconButton>
            <div>
              <Typography
                variant="h6"
                className={classes.title}
                onClick={() => {
                  handleLink(PATH.HOME);
                }}
              >
                {t("Alltrade")}
              </Typography>
            </div>
            <SelectLanguage />
          </div>
          <div className={classes.rootAction}>
            <Button
              variant="outlined"
              className={classes.buttonLogin}
              onClick={() => {
                handleLink(PATH.LOGIN);
              }}
            >
              {t("Log in")}
            </Button>
            <Button
              variant="outlined"
              className={classes.buttonRegister}
              onClick={() => {
                handleLink(PATH.REGISTER);
              }}
            >
              {t("Register")}
            </Button>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

TopBarHome.propTypes = {};

export default TopBarHome;
