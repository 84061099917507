import React from "react";

export default function BotIcon() {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5_830)">
        <path
          d="M24 14.5693V11.1402C23.8305 9.32961 22.5397 9.34536 20.5714 9.4257V16.2838C23.7313 16.6122 23.8919 15.3695 24 14.5693ZM3.42858 16.2838V9.42575C1.4603 9.3454 0.1695 9.32965 0 11.1402V14.5693C0.108094 15.3695 0.268687 16.6122 3.42858 16.2838V16.2838ZM0.857156 20.5701H23.1428V23.9992H0.857156V20.5701ZM15.39 3.42481C14.8497 -1.01214 9.25514 -1.2715 8.61 3.42481H15.39ZM1.00697 1.5395C1.46878 0.667998 2.70712 0.617185 3.23062 1.44823C3.68194 2.16458 3.3128 3.07925 2.57119 3.34339L2.57142 8.5684H1.71427V3.34578C1.01053 3.10147 0.624422 2.26137 1.00697 1.5395ZM16.2857 10.7115C16.2857 11.6978 15.2109 12.3175 14.3557 11.8243C13.5005 11.3313 13.5005 10.0918 14.3557 9.59872C15.2109 9.10559 16.2857 9.72528 16.2857 10.7115V10.7115ZM8.3557 11.8243C9.21089 12.3175 10.2857 11.6978 10.2857 10.7115C10.2857 9.72528 9.21089 9.10559 8.3557 9.59872C7.50047 10.0918 7.50047 11.3313 8.3557 11.8243V11.8243ZM19.7143 7.71111V19.7129H4.28578V7.71115C4.28578 5.81745 5.82094 4.28211 7.71431 4.28211H16.2858C18.1792 4.28211 19.7143 5.81745 19.7143 7.71115L19.7143 7.71111ZM7.92614 12.5664C9.35152 13.3882 11.1428 12.3553 11.1428 10.7116C11.1428 9.06795 9.35152 8.03506 7.92614 8.85692C6.50077 9.67878 6.50081 11.7445 7.92614 12.5664ZM16.2857 15.4266H7.71422V17.1411H16.2858V15.4266H16.2857ZM17.1428 10.7116C17.1428 9.06795 15.3515 8.03506 13.9261 8.85692C12.5008 9.67878 12.5008 11.7445 13.9261 12.5664C15.3515 13.3882 17.1428 12.3553 17.1428 10.7116Z"
          fill="#848E9C"
        />
      </g>
      <defs>
        <clipPath id="clip0_5_830">
          <rect width={24} height={24} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
