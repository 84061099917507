import {
  Button,
  Card,
  CardContent,
  CardHeader,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import convertNumberToCurrency from "../../common/convertNumberToCurrency";
import convertNumberToMoney from "../../common/convertNumberToMoney";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      dialogHeader: {
        color: "#fff",
        padding: "10px 20px",
      },
      closeButton: {
        position: "absolute",
        right: 2,
        top: 0,
        color: theme.text.primary,
      },
      dialogContent: {
        padding: "10px 80px 20px 80px",
      },
      dividers: { borderTopColor: "#272C3A" },
      textField: {
        marginBottom: 15,
        backgroundColor: "#2A2D35",
      },
      cssOutlinedInput: {
        "&$cssFocused $notchedOutline": {
          borderColor: `${theme.text.primary} !important`,
        },
      },
      notchedOutline: {
        borderWidth: "1px",
        borderColor: "#2A2D35 !important",
        borderRadius: "2px !important",
      },
      multilineColor: {
        color: theme.text.primary,
        fontSize: 13,
      },
      input: {
        "&::placeholder": {
          opacity: 0.2,
        },
      },
      typographySecondary: {
        color: theme.text.secondary,
      },
      typographyPrimary: {
        color: theme.text.primary,
      },
      typographyName: {
        color: theme.text.primary,
        fontWeight: 600,
        fontSize: 18,
        textAlign: "center",
      },
      typographyValue: { paddingLeft: 20 },
      buttonSubmit: {
        width: "100%",
        color: theme.text.primary,
        backgroundColor: theme.color.primary,
        textTransform: "none",
        borderRadius: 2,
        marginTop: 10,
      },
      paperDialog: {
        width: 456,
        // minHeight: 573,
        // maxHeight: 700,
        backgroundColor: "#191D23",
        boxShadow: "none ",
      },
      imageBot: {
        width: 146,
        height: 146,
      },
      dialogActions: {
        padding: "0 15px 25px 15px",
      },
      buttonSelect: {
        backgroundColor: "#2A2D35",
        width: "100%",
        textTransform: "none",
        // minWidth: 250,
        borderRadius: 3,
        color: theme.text.primary,
        padding: "8px 8px",
        margin: "10px 0 15px 0",
        "&:hover": {
          backgroundColor: "rgba(0,0,0,.05)",
        },
      },
      labelButton: {
        display: "flex",
        justifyContent: "space-between",
      },
      coverCurrency: {
        display: "flex",
        alignItems: "center",
      },
      typographyBtnPrimary: {
        margin: "0 5px",
      },
      typographyBtnSecondary: {
        margin: "0 5px",
        color: theme.text.secondary,
      },
      rootDrawer: {
        backgroundColor: theme.color.background,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
      },
      rootCard: { backgroundColor: theme.color.background },
      paperCard: { boxShadow: "none" },
      cardHeader: {
        borderBottom: `1px solid ${theme.color.border}`,
        marginBottom: 5,
      },
      iconButton: { color: theme.text.primary },
    }),
  { index: 1 }
);

export default function DialogBuyAgentRights(props) {
  const classes = useStyles(props);
  const { open, handleClose, walletInfo, referralInfo, handlePay } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  const renderContent = () => {
    return (
      <>
        <div>
          <Button
            className={classes.buttonSelect}
            classes={{ label: classes.labelButton }}
            variant="contained"
            color="primary"
          >
            <div className={classes.coverCurrency}>
              <img
                src={`${walletInfo?.c?.icon_url}`}
                alt="icon"
                width={25}
                height={25}
              ></img>
              <Typography
                variant="body1"
                classes={{ root: classes.typographyBtnPrimary }}
              >
                {walletInfo?.c?.symbol}
              </Typography>
              <Typography
                variant="caption"
                classes={{ root: classes.typographyBtnSecondary }}
              >
                {walletInfo?.c?.name}
              </Typography>
            </div>
          </Button>
        </div>
        <Grid container>
          {/* <Grid item xs={5}>
            <Typography
              variant="caption"
              className={classes.typographySecondary}
            >
              {t("Total balance")}:
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="caption" className={classes.typographyPrimary}>
              61,000.00 USDT
            </Typography>
          </Grid> */}
          <Grid item xs={5}>
            <Typography
              variant="caption"
              className={classes.typographySecondary}
            >
              {t("Available balances")}:
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="caption" className={classes.typographyPrimary}>
              {convertNumberToMoney(parseFloat(walletInfo?.balance))}{" "}
              {walletInfo?.c?.symbol}
            </Typography>
          </Grid>
        </Grid>
        <div style={{ textAlign: "center", paddingTop: 30 }}>
          <Typography variant="body2" className={classes.typographySecondary}>
            {t("Payment amount")}
          </Typography>
          <Typography variant="h6" className={classes.typographyPrimary}>
            {convertNumberToCurrency(100)}
          </Typography>
        </div>
      </>
    );
  };

  return (
    <>
      {matches ? (
        <Drawer
          anchor={"bottom"}
          open={open}
          onClose={handleClose}
          classes={{ paper: classes.rootDrawer }}
        >
          <Card classes={{ root: classes.rootCard, paper: classes.paperCard }}>
            <CardHeader
              title={
                <Typography
                  classes={{ root: classes.typographyPrimary }}
                  variant="h6"
                >
                  {t("Buy agency license ")}
                </Typography>
              }
              action={
                <IconButton
                  classes={{ root: classes.iconButton }}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>
              }
              classes={{ root: classes.cardHeader }}
            />
            <CardContent>
              {renderContent()}{" "}
              <Button
                className={classes.buttonSubmit}
                onClick={() => {
                  handlePay(referralInfo?.id);
                }}
              >
                {t("Pay")}
              </Button>
            </CardContent>
          </Card>
        </Drawer>
      ) : (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={false}
          classes={{
            paper: classes.paperDialog,
          }}
        >
          <DialogTitle id="alert-dialog-title" className={classes.dialogHeader}>
            <Typography variant="body1">{t("Buy agency license ")}</Typography>
            <IconButton className={classes.closeButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            className={classes.dialogContent}
            dividers={true}
            classes={{ dividers: classes.dividers }}
          >
            {renderContent()}
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              className={classes.buttonSubmit}
              onClick={() => {
                handlePay(referralInfo?.id);
              }}
            >
              {t("Pay")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

DialogBuyAgentRights.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};
