import {
  Button,
  createStyles,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import lv0 from "../../../assets/img/0-03.png";
import lv1 from "../../../assets/img/1-03.png";
import lv2 from "../../../assets/img/2-03.png";
import lv3 from "../../../assets/img/3-03.png";
import lv4 from "../../../assets/img/4-03.png";
import lv5 from "../../../assets/img/5-03.png";
import lv6 from "../../../assets/img/6-03.png";
import lv7 from "../../../assets/img/7-03.png";
import lv8 from "../../../assets/img/8-03.png";
import lv9 from "../../../assets/img/9-03.png";
import lv10 from "../../../assets/img/10-03.png";
import convertNumberToCurrency from "../../../common/convertNumberToCurrency";
import convertNumberToMoney from "../../../common/convertNumberToMoney";
import convertNumberToUSDT from "../../../common/convertNumberToUSDT";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        width: "100%",
        // backgroundColor: theme.color.background,
        // margin: "10px 0 0 10px",
        // padding: "20px 0",
        padding: "40px 10px",
        [theme.breakpoints.down("xs")]: {
          padding: 10,
          width: "95%",
          margin: "20px auto",
        },
      },
      typographySecondary: {
        color: theme.text.secondary,
      },
      typographyPrimary: {
        color: theme.text.primary,
      },
      typographyInviter: { color: theme.text.primary, fontStyle: "italic" },
      coverBoxRight: {
        // marginBottom: "20px",
        padding: 10,
        borderRadius: 10,
        border: `1px solid ${theme.color.border}`,
        backgroundColor: `${theme.body.backgroundColor}`,
        // height: "100%",
        [theme.breakpoints.down("xs")]: {
          marginBottom: 0,
        },
      },
      coverLevel: {
        margin: "10px 0",
        backgroundColor: `hsla(0, 0%, 100%, 0.05)`,
        borderRadius: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      },
      coverStatistic: {
        margin: "10px 0",
        padding: 10,
        backgroundColor: `hsla(0, 0%, 100%, 0.05)`,
        borderRadius: 10,
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        width: "100%",
      },
      coverConditionLevel: {
        margin: "10px 0",
      },
      avatarLarge: {
        width: theme.spacing(6),
        height: theme.spacing(6),
      },
      listItem: {
        padding: "0px 15px ",
        alignItems: "flex-start",
      },
      inviterRoot: {
        display: "flex",
        alignItems: "center",
        borderBottom: `0.5px solid ${theme.color.border}`,
        marginBottom: 10,
      },
    }),
  { index: 1 }
);

function PaperAgencySummary({ referralInfo }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  const renderSrcImageLevel = (bo_level) => {
    switch (bo_level) {
      case 0:
        return lv0;
      case 1:
        return lv1;
      case 2:
        return lv2;
      case 3:
        return lv3;
      case 4:
        return lv4;
      case 5:
        return lv5;
      case 6:
        return lv6;
      case 7:
        return lv7;
      case 8:
        return lv8;
      case 9:
        return lv9;
      case 10:
        return lv10;
      default:
        return lv0;
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={7}>
          <Grid
            container
            justify="center"
            spacing={2}
            classes={{ root: classes.coverBoxRight }}
          >
            <Grid item xs={12} sm={12}>
              <Grid
                container
                justify="center"
                spacing={2}
                className={classes.coverLevel}
              >
                <Grid item xs={12} sm={12}>
                  <div>
                    <List>
                      <ListItem classes={{ root: classes.listItem }}>
                        <ListItemAvatar
                          style={{ position: "relative", marginRight: 10 }}
                        >
                          <div style={{ textAlign: "center" }}>
                            <img
                              src={renderSrcImageLevel(
                                parseInt(referralInfo?.level)
                              )}
                              alt="lv"
                              width={60}
                              height="auto"
                            ></img>
                          </div>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              variant="h5"
                              style={{ color: "#FFA031" }}
                            >
                              {referralInfo?.inviter?.username}
                            </Typography>
                          }
                          secondary={
                            <>
                              <Typography
                                variant="body1"
                                className={classes.typographyInviter}
                              >
                                {t("Inviter")}:{" "}
                                <b style={{ color: "#FFA031" }}>
                                  {referralInfo?.inviter?.username || ""}
                                </b>
                              </Typography>
                            </>
                          }
                        />
                      </ListItem>
                    </List>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {matches && (
          <Grid item xs={12} sm={12}>
            <Grid
              container
              justify="center"
              spacing={2}
              classes={{ root: classes.coverBoxRight }}
            >
              <Grid item xs={6} sm={6}>
                <Typography
                  variant="body1"
                  className={classes.typographySecondary}
                >
                  {t("F1 VIP")}
                </Typography>
                <div style={{ paddingLeft: 15 }}>
                  <Typography
                    variant="body2"
                    className={classes.typographyPrimary}
                  >
                    {referralInfo?.total_f1_active}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Typography
                  variant="body1"
                  className={classes.typographySecondary}
                >
                  {t("VIP traders")}
                </Typography>
                <div style={{ paddingLeft: 15 }}>
                  <Typography
                    variant="body2"
                    className={classes.typographyPrimary}
                  >
                    {referralInfo?.total_network_active}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Typography
                  variant="body1"
                  className={classes.typographySecondary}
                >
                  {t("Total commission")}
                </Typography>
                <div style={{ paddingLeft: 15 }}>
                  <Typography
                    variant="body2"
                    className={classes.typographyPrimary}
                  >
                    {convertNumberToUSDT(referralInfo?.total_commission)}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Typography
                  variant="body1"
                  className={classes.typographySecondary}
                >
                  {t("Network Invest")}
                </Typography>
                <div style={{ paddingLeft: 15 }}>
                  <Typography
                    variant="body2"
                    className={classes.typographyPrimary}
                  >
                    {convertNumberToUSDT(referralInfo?.network_invest)}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} sm={5}>
          {!matches && (
            <Grid
              container
              justify="center"
              spacing={2}
              classes={{ root: classes.coverBoxRight }}
            >
              <Grid item xs={6} sm={6}>
                <Typography
                  variant="body1"
                  className={classes.typographySecondary}
                >
                  {t("F1 VIP")}
                </Typography>
                <div style={{ paddingLeft: 15 }}>
                  <Typography
                    variant="body2"
                    className={classes.typographyPrimary}
                  >
                    {referralInfo?.total_f1_active}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Typography
                  variant="body1"
                  className={classes.typographySecondary}
                >
                  {t("VIP traders")}
                </Typography>
                <div style={{ paddingLeft: 15 }}>
                  <Typography
                    variant="body1"
                    className={classes.typographyPrimary}
                  >
                    {referralInfo?.total_network_active}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body1"
                  className={classes.typographySecondary}
                >
                  {t("Total commission")}
                </Typography>
                <div style={{ paddingLeft: 15 }}>
                  <Typography
                    variant="body2"
                    className={classes.typographyPrimary}
                  >
                    {convertNumberToUSDT(referralInfo?.total_commission)}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body1"
                  className={classes.typographySecondary}
                >
                  {t("Network Invest")}
                </Typography>
                <div style={{ paddingLeft: 15 }}>
                  <Typography
                    variant="body2"
                    className={classes.typographyPrimary}
                  >
                    {convertNumberToUSDT(referralInfo?.network_invest)}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

PaperAgencySummary.propTypes = {};

export default PaperAgencySummary;
