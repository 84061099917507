import { createStyles, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import BackgroundBlockchain from "../../../assets/img/BackgroundBlockchain.png";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        width: "100vw",
        minHeight: 700,
        padding: 40,
        background:
          "linear-gradient(256.19deg, #283D92 3.67%, #46177C 101.31%)",
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        // alignItems: "center",
      },
      image: { float: "right", width: "100%" },
      title: {
        fontSize: 32,
        color: theme.text.primary,
        marginBottom: 30,
      },
      content: { color: theme.text.primary },
      rootLeft: {
        maxWidth: 400,
        // marginTop: 50,
        marginRight: 50,
        [theme.breakpoints.down("xs")]: {
          marginRight: 0,
        },
      },
      rootRight: {
        paddingTop: 40,
        [theme.breakpoints.down("xs")]: {
          paddingTop: 0,
        },
      },
    }),
  { index: 1 }
);

export default function PaperFourthFloor(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <div className={classes.rootLeft}>
        <Typography variant="h5" className={classes.title}>
          {t("Blockchain technology application")}
        </Typography>
        <Typography variant="body2" className={classes.content}>
          {t(
            "Using Smart Contracts on Blockchain, we create a safe and transparent environment for transactions and protection of your assets."
          )}
        </Typography>
      </div>
      <div className={classes.rootRight}>
        <img
          src={`${BackgroundBlockchain}`}
          alt="BackgroundBlockchain"
          className={classes.image}
        ></img>
      </div>
    </div>
  );
}
