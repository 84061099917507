import {
  Button,
  createStyles,
  makeStyles,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {},
      typographyPrimary: { color: theme.text.primary },
      typographySecondary: { color: theme.text.secondary },
      rootButton: { margin: "40px 0 40px 0" },
      buttonBack: {
        float: "left",
        color: theme.text.secondary,
        borderColor: theme.text.secondary,
        borderRadius: 1,
        textTransform: "none",
        width: 100,
        [theme.breakpoints.down("xs")]: {
          width: "100%",
          margin: "10px 0",
        },
      },
      buttonNext: {
        float: "right",
        color: theme.text.primary,
        borderColor: theme.color.primary,
        backgroundColor: theme.color.primary,
        borderRadius: 1,
        textTransform: "none",
        width: 100,
        [theme.breakpoints.down("xs")]: {
          width: "100%",
          margin: "10px 0",
        },
      },
    }),
  { index: 1 }
);

export default function FormIdentityVerification(props) {
  const classes = useStyles(props);
  const { handleNextStep, handleBackStep, children } = props;
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <div>
        <Typography
          variant="body2"
          classes={{ root: classes.typographyPrimary }}
        >
          1.{" "}
          {t(
            "Make sure that the captured image is complete and clear, in JPG, PNG, JPEG format."
          )}
        </Typography>
      </div>
      <div>
        <Typography
          variant="body2"
          classes={{ root: classes.typographyPrimary }}
        >
          2.{" "}
          {t(
            "Take a photo of your ID / Passport. Photos should be bright and clear, without losing corners."
          )}
        </Typography>
      </div>
      {children}
      <div className={classes.rootButton}>
        <Button
          className={classes.buttonNext}
          variant="outlined"
          onClick={() => {
            handleNextStep();
          }}
        >
          {t("Next")}
        </Button>
        <Button
          className={classes.buttonBack}
          variant="outlined"
          onClick={() => {
            handleBackStep();
          }}
        >
          {t("Back")}
        </Button>
      </div>
    </div>
  );
}

FormIdentityVerification.propTypes = {
  handleNextStep: PropTypes.func,
  handleBackStep: PropTypes.func,
};
