/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getBotTradeTransaction,
  getBotTransaction,
  getTradeHistory,
} from "../../apis/bot.api";
import PaperGeneralDataTradingBot from "../../components/Body/BodyTradingBot/PaperGeneralDataTradingBot";
import PaperTradingBotInfo from "../../components/Body/BodyTradingBot/PaperTradingBotInfo";
import TableBotRecentOrder from "../../components/Table/TableBotRecentOrder";
import TableBotTransaction from "../../components/Table/TableBotTransaction";
import TableTradeHistory from "../../components/Table/TableTradeHistory";

export default function History({ version = 1 }) {
  const [valueTab, setValueTab] = useState(0);
  let [paginationTransaction, setPaginationTransaction] = useState({
    page: 1,
    page_size: 10,
  });
  const [transactionTradingBot, setTransactionTradingBot] = useState({});
  let [paginationProfitBot, setPaginationProfitBot] = useState({
    page: 1,
    page_size: 10,
  });
  const [botProfit, setBotProfit] = useState({});
  let [paginationTradeHistory, setPaginationTradeHistory] = useState({
    page: 1,
    page_size: 10,
  });
  const [tradingHistory, setTradingHistory] = useState({});
  const userPackage = useSelector((state) =>
    version === 1
      ? state.botReducer.userPackage
      : state.tradingBotV2Reducer.userPackage
  );

  const callTradingHistory = () => {
    let paramsTradeHistory = { ...paginationTradeHistory };
    getTradeHistory(paramsTradeHistory)
      .then((res) => {
        if (res.status === 200) {
          let { data } = res;
          setTradingHistory(data);
        }
      })
      .catch();
  };

  const callBotTradeTransaction = () => {
    let paramsTransaction = { ...paginationTransaction };
    getBotTradeTransaction(paramsTransaction)
      .then((res) => {
        if (res.status === 200) {
          let { data } = res;
          setTransactionTradingBot(data);
        }
      })
      .catch();
  };

  const callBotTransaction = () => {
    let paramsBotProfit = { ...paginationProfitBot };
    getBotTransaction({ ...paramsBotProfit })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          let { data } = res;
          setBotProfit(data);
        }
      })
      .catch();
  };

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };
  const handleChangePageRecentOrder = (page) => {
    setPaginationTransaction({ ...paginationTransaction, page });
  };

  const handleChangePageProfitTransaction = (page) => {
    setPaginationProfitBot({ ...paginationProfitBot, page });
  };

  const handleChangePageTradeHistory = (page) => {
    setPaginationTradeHistory({ ...paginationTradeHistory, page });
  };

  const renderTabPanel = () => {
    switch (valueTab) {
      case 0:
        return (
          <TableTradeHistory
            tradeHistory={tradingHistory}
            pagination={paginationTradeHistory}
            handleChangePage={handleChangePageTradeHistory}
          />
        );
      case 1:
        return (
          <TableBotRecentOrder
            transactionTradingBot={transactionTradingBot}
            paginationTransaction={paginationTransaction}
            handleChangePage={handleChangePageRecentOrder}
          />
        );
      case 2:
        return (
          <TableBotTransaction
            botProfit={botProfit}
            paginationProfitBot={paginationProfitBot}
            handleChangePage={handleChangePageProfitTransaction}
          />
        );

      default:
        return null;
    }
  };

  useEffect(() => {
    if (Object.keys(transactionTradingBot).length > 0)
      callBotTradeTransaction();
  }, [paginationTransaction]);

  useEffect(() => {
    if (Object.keys(botProfit).length > 0) {
      callBotTransaction();
    }
  }, [paginationProfitBot]);

  useEffect(() => {
    if (Object.keys(tradingHistory).length > 0) callTradingHistory();
  }, [paginationTradeHistory]);

  useEffect(() => {
    switch (valueTab) {
      case 0: {
        callTradingHistory();
        break;
      }
      case 1: {
        callBotTradeTransaction();
        break;
      }
      case 2: {
        callBotTransaction();
        break;
      }
      default:
        break;
    }
  }, [valueTab]);

  return (
    <div style={{ width: "100%" }}>
      <Grid container>
        <Grid item xs={12}>
          <PaperGeneralDataTradingBot userPackage={userPackage} />
          <PaperTradingBotInfo
            valueTab={valueTab}
            handleChangeTab={handleChangeTab}
          >
            {renderTabPanel()}
          </PaperTradingBotInfo>
        </Grid>
      </Grid>
    </div>
  );
}
