import React from "react";
import Login from "../containers/Login/Login";

export default function LoginPage() {
  return (
    <div>
      <Login />
    </div>
  );
}
