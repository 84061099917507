import { SCHEME, DOMAIN } from "../const/api";
import callApi from "../utils/apiCaller";

const headers = {
  //prettier-ignore
  "Content-Type": "application/json",
};

export const getCandleStickData = (params) => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "get",
    url: `${SCHEME}://${DOMAIN}/v1/wallet/candlestick/`,
    headers: {
      ...headers,
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
    params,
  };
  return callApi(config);
};

export const getOrderResult = (time) => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "get",
    url: `${SCHEME}://${DOMAIN}/v1/trade/trade-list/order_result/${time}/`,
    headers: {
      ...headers,
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
  };
  return callApi(config);
};

export const getListOrder = (params) => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "get",
    url: `${SCHEME}://${DOMAIN}/v1/trade/trade-list/`,
    headers: {
      ...headers,
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
    params,
  };
  return callApi(config);
};

export const createTradeOrder = (data) => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "post",
    url: `${SCHEME}://${DOMAIN}/v1/trade/trade-list/order/`,
    headers: {
      ...headers,
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
    data,
  };
  return callApi(config);
};

export const getSystemSecond = () => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "get",
    url: `${SCHEME}://${DOMAIN}/v1/trade/trade-list/get_system_sec/`,
    headers: {
      ...headers,
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
  };
  return callApi(config);
};

export const getTradeStatistic = () => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "get",
    url: `${SCHEME}://${DOMAIN}/v1/trade/trade-stats/`,
    headers: {
      ...headers,
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
  };
  return callApi(config);
};

export const createDemoTradeOrder = (data) => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "post",
    url: `${SCHEME}://${DOMAIN}/v1/trade/demo-trade/`,
    headers: {
      ...headers,
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
    data,
  };
  return callApi(config);
};

export const getDemoListOrder = (params) => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "get",
    url: `${SCHEME}://${DOMAIN}/v1/trade/demo-trade/`,
    headers: {
      ...headers,
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
    params,
  };
  return callApi(config);
};

export const getDemoOrderResult = (time) => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "get",
    url: `${SCHEME}://${DOMAIN}/v1/trade/demo-trade/order_result/${time}/`,
    headers: {
      ...headers,
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
  };
  return callApi(config);
};
