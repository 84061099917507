import { call, put, takeEvery } from "redux-saga/effects";
import {
  buyReferralCode,
  getReferralDetail,
  getReferralTree,
  getUnderReferral,
  getReferralBoDetail,
  getBoTree,
} from "../../apis/referral.api";
import { TOAST_NOTI } from "../../const/common";
import * as types from "../../const/redux/referral";
import {
  buyReferralCodeSuccess,
  getReferralDetailBoSuccess,
  getReferralDetailSuccess,
  getReferralTreelSuccess,
  getUnderReferrallSuccess,
} from "../actions/referralAction";
import { callToastNotiSuccess } from "../actions/toastNotiAction";

function* getReferralDetailSagas({ payload }) {
  try {
    let responseReferral = yield call(getReferralDetail);
    if (responseReferral.status === 200) {
      let { data } = responseReferral;
      yield put(getReferralDetailSuccess(data));
    }
  } catch (err) {
    let { data } = err.response;
    yield put(
      callToastNotiSuccess({
        noti: data.message,
        status: TOAST_NOTI.ERROR,
        code: data.code,
      })
    );
  }
}

function* getReferralBoDetailSagas({ payload }) {
  try {
    let responseReferral = yield call(getReferralBoDetail);
    if (responseReferral.status === 200) {
      let { data } = responseReferral;
      yield put(getReferralDetailBoSuccess(data));
    }
  } catch (err) {
    let { data } = err.response;
    yield put(
      callToastNotiSuccess({
        noti: data.message,
        status: TOAST_NOTI.ERROR,
        code: data.code,
      })
    );
  }
}

function* buyReferralCodeSagas({ payload }) {
  try {
    const { refId } = payload;
    let responseReferralCode = yield call(buyReferralCode, refId);
    if (responseReferralCode.status === 200) {
      let { data } = responseReferralCode;
      yield put(buyReferralCodeSuccess(data));
    }
  } catch (err) {
    let { data } = err.response;
    yield put(
      callToastNotiSuccess({
        noti: data.message,
        status: TOAST_NOTI.ERROR,
        code: data.code,
      })
    );
  }
}

function* getReferralTreeSagas({ payload }) {
  try {
    const { refId } = payload;
    let responseReferral = yield call(getReferralTree, refId);
    if (responseReferral.status === 200) {
      let { data } = responseReferral;
      yield put(getReferralTreelSuccess(data));
    }
  } catch (err) {
    let { data } = err.response;
    yield put(
      callToastNotiSuccess({
        noti: data.message,
        status: TOAST_NOTI.ERROR,
        code: data.code,
      })
    );
  }
}

function* getBoTreeSagas({ payload }) {
  try {
    const { refId } = payload;
    let responseReferral = yield call(getBoTree, refId);
    if (responseReferral.status === 200) {
      let { data } = responseReferral;
      yield put(getReferralTreelSuccess(data));
    }
  } catch (err) {
    let { data } = err.response;
    yield put(
      callToastNotiSuccess({
        noti: data.message,
        status: TOAST_NOTI.ERROR,
        code: data.code,
      })
    );
  }
}

function* getUnderReferralSagas({ payload }) {
  try {
    const { refId, params } = payload;
    let responseUnderReferral = yield call(getUnderReferral, refId, params);
    if (responseUnderReferral.status === 200) {
      let { data } = responseUnderReferral;
      yield put(getUnderReferrallSuccess(data));
    }
  } catch (err) {
    let { data } = err.response;
    yield put(
      callToastNotiSuccess({
        noti: data.message,
        status: TOAST_NOTI.ERROR,
        code: data.code,
      })
    );
  }
}

export function* watchReferralAsync() {
  yield takeEvery(types.GET_REFERRAL_DETAIL_REQ, getReferralDetailSagas);
  yield takeEvery(types.GET_REFERRAL_BO_DETAIL_REQ, getReferralBoDetailSagas);
  yield takeEvery(types.BUY_REFERRAL_CODE_REQ, buyReferralCodeSagas);
  yield takeEvery(types.GET_REFERRAL_TREE_REQ, getReferralTreeSagas);
  yield takeEvery(types.GET_BO_TREE_REQ, getBoTreeSagas);
  yield takeEvery(types.GET_UNDER_REFERRAL_REQ, getUnderReferralSagas);
}
