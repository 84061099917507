import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReferralBoDetailReq } from "../../redux/actions/referralAction";
import AgencyTradeInfo from "./AgencyTradeInfo";
import AgencyTradeRights from "./AgencyTradeRights";

export default function Agency() {
  const dispatch = useDispatch();
  const referralBo = useSelector((state) => state.referralReducer.bo);

  useEffect(() => {
    dispatch(getReferralBoDetailReq());

    // return () => {
    //   dispatch(destroyReferral());
    //   dispatch(destroyWallet());
    // };
  }, []);

  return (
    <>
      {Object.keys(referralBo).length > 0 && (
        <>
          {!!referralBo.ref_code ? <AgencyTradeInfo /> : <AgencyTradeRights />}
        </>
      )}
    </>
  );
}
