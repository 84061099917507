import {
  Breadcrumbs,
  createStyles,
  Link,
  makeStyles,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        width: "100%",
        marginLeft: theme.marginLayout,
        backgroundColor: theme.color.background,
        padding: "5px 0 0 15px",
        [theme.breakpoints.down("xs")]: {
          marginLeft: 0,
          padding: 0,
          backgroundColor: "transparent",
        },
      },
      breadcrumb: {
        color: theme.text.primary,
      },
      tabs: { marginTop: 10 },
      tab: {
        padding: 0,
        marginRight: 10,
        textTransform: "none",
        fontWeight: "bold",
        // color: "#848E9C",
        // fontFamily: [
        //   "-apple-system",
        //   "BlinkMacSystemFont",
        //   '"Segoe UI"',
        //   "Roboto",
        //   '"Helvetica Neue"',
        //   "Arial",
        //   "sans-serif",
        //   '"Apple Color Emoji"',
        //   '"Segoe UI Emoji"',
        //   '"Segoe UI Symbol"',
        // ].join(","),
        minWidth: 70,
        "&:hover": {
          color: "#fff",
          opacity: 1,
        },
        "&:focus": {
          color: "#fff",
        },
      },
      labelTab: {
        fontSize: 16,
        [theme.breakpoints.down("xs")]: {
          fontSize: 14,
        },
      },
    }),
  { index: 1 }
);

export default function HeaderCurrencyTransaction(props) {
  const classes = useStyles(props);
  const {
    valueTab,
    handleChangeTab,
    handleBackToWallet,
    walletSelected,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <div className={classes.root}>
      {!matches && (
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={">"}
          className={classes.breadcrumb}
        >
          <Link
            color="inherit"
            onClick={() => {
              handleBackToWallet();
            }}
          >
            <Typography variant="caption">{t("Wallet")}</Typography>
          </Link>
          <Link color="inherit">
            <Typography variant="caption">{valueTab}</Typography>
          </Link>
        </Breadcrumbs>
      )}

      <Tabs
        className={classes.tabs}
        value={valueTab}
        onChange={handleChangeTab}
        textColor="inherit"
      >
        {walletSelected?.c?.can_deposit && (
          <Tab
            value={t("Deposit")}
            label={
              <Typography variant="body2" classes={{ root: classes.labelTab }}>
                {t("Deposit")}
              </Typography>
            }
            className={classes.tab}
            disableRipple={true}
          />
        )}
        {walletSelected?.c?.can_withdraw && (
          <Tab
            value={t("Withdraw")}
            label={
              <Typography variant="body2" classes={{ root: classes.labelTab }}>
                {t("Withdraw")}
              </Typography>
            }
            className={classes.tab}
            disableRipple={true}
          />
        )}
      </Tabs>
    </div>
  );
}
