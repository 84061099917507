import { cloneDeep } from "lodash";
import * as types from "../../const/redux/toastNoti";

let initialState = {
  noti: "",
  status: "",
};

const toastNoti = (state = initialState, action) => {
  switch (action.type) {
    case types.CALL_TOAST_NOTI_SUCCESS: {
      let newState = cloneDeep(action.payload.data);
      return newState;
    }
    default:
      return state;
  }
};

export default toastNoti;
