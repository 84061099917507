import { Button, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(
  (theme) => ({
    root: { margin: 10, maxWidth: 370 },
    coverTitle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 10,
    },
    name: { fontWeight: 700 },
    btnPlay: {
      borderRadius: theme.radius.secondary,
      width: 140,
      height: 30,
      background: "#2F86FA",
      textTransform: "none",
      fontWeight: 700,
      color: theme.text.primary,
    },
  }),
  { index: 1 }
);

export default function BoxBingoStar(props) {
  const classes = useStyles();
  const { img, name, intro } = props;
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <img src={img} alt="game" width={370} height="auto" />
      <div className={classes.coverTitle}>
        <Typography variant="body1" classes={{ root: classes.name }}>
          {t("Game")} {name}
        </Typography>
        <Button variant="contained" classes={{ root: classes.btnPlay }}>
          {t("Play")}
        </Button>
      </div>
      <Typography variant="body2">{intro}</Typography>
    </div>
  );
}
