import { Grid, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import StepperComponent from "../../components/Body/BodyProfile/StepperComponent";
import DrawerAccountVerification from "../../components/Drawer/DrawerAccountVerification";
import HeaderAccountVerification from "../../components/Header/HeaderAccountVerification";
import StepBrowseProfile from "./StepAccountVerification/StepBrowseProfile";
import StepIdVerify from "./StepAccountVerification/StepIdVerify";
import StepUserVerify from "./StepAccountVerification/StepUserVerify";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  rootBody: {
    backgroundColor: theme.color.background,
    margin: 15,
    height: "90vh",
  },
}));

export default function AccountVerification(props) {
  const classes = useStyles(props);
  const { handleCloseAccountVerification, showAccountVerification } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [valueVerify, setValueVerify] = useState({
    first_name: "",
    last_name: "",
    id_number: "",
    country_code: "",
    front_side_photo: null,
    back_side_photo: null,
  });
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const { t } = useTranslation();

  const steps = [
    t("User info"),
    t("Identity verification"),
    t("Browser profiles"),
  ];

  const handleNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBackStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleUpdateValueVerify = (value) => {
    let newValueVerify = { ...valueVerify, ...value };
    setValueVerify(newValueVerify);
  };

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <StepUserVerify
            handleNextStep={handleNextStep}
            handleUpdateValueVerify={handleUpdateValueVerify}
            valueVerify={valueVerify}
          />
        );
      case 1:
        return (
          <StepIdVerify
            valueVerify={valueVerify}
            handleNextStep={handleNextStep}
            handleBackStep={handleBackStep}
          />
        );
      case 2:
        return (
          <StepBrowseProfile backToProfile={handleCloseAccountVerification} />
        );
      default:
        return null;
    }
  };

  //   useEffect(() => {
  //     if (profile.kyc) {
  //       let value = {
  //         first_name: profile.kyc.first_name,
  //         last_name: profile.kyc.last_name,
  //         id_number: profile.kyc.id_number,
  //         country_code: profile.kyc.country_code,
  //         front_side_photo: profile.kyc.front_side_photo,
  //         back_side_photo: profile.kyc.back_side_photo,
  //       };
  //       setValueVerify(value);
  //     }
  //   }, [profile]);

  return (
    <div className={classes.root}>
      {matches ? (
        <DrawerAccountVerification
          openDrawer={showAccountVerification}
          handleCloseDrawer={handleCloseAccountVerification}
        >
          <StepperComponent activeStep={activeStep} steps={steps}>
            {renderStepContent()}
          </StepperComponent>
        </DrawerAccountVerification>
      ) : (
        <>
          {showAccountVerification && (
            <Grid container>
              <HeaderAccountVerification
                backToProfile={handleCloseAccountVerification}
              />
              <Grid item sm={9} xs={12} className={classes.rootBody}>
                <StepperComponent activeStep={activeStep} steps={steps}>
                  {renderStepContent()}
                </StepperComponent>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </div>
  );
}
