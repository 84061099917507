import {
  Button,
  Card,
  CardContent,
  createStyles,
  makeStyles,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import convertNumberToUSDT from "../../common/convertNumberToUSDT";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {},
      card: {
        // width: 304,
        // height: 414,
        margin: 10,
        backgroundColor: theme.color.background,
        border: `1px solid ${theme.color.border}`,
      },
      cardContent: { padding: theme.spacing(4) },
      typographyTitle: {
        fontWeight: "bold",
        color: theme.text.primary,
        marginTop: 10,
      },
      typographyInfoLabel: {
        color: theme.text.secondary,
        fontSize: 14,
      },
      typographyInfoValue: { color: theme.text.primary, fontSize: 14 },
      typographyPrice: { color: theme.text.primary, padding: "15px 0" },
      button: {
        color: theme.text.primary,
        borderColor: theme.color.primary,
        backgroundColor: theme.color.primary,
        borderRadius: 1,
        textTransform: "none",
        minWidth: 170,
        height: 40,
      },
      buttonDisabled: {
        color: `#757575 !important`,
        backgroundColor: "#bdbdbd",
      },
    }),
  { index: 1 }
);

function CardBot(props) {
  const classes = useStyles(props);
  const { handleRentNow, botInfo } = props;
  const { t } = useTranslation();

  return (
    <div>
      <Card className={classes.card}>
        <CardContent classes={{ root: classes.cardContent }}>
          <div>
            {/* <img
              src={`${botInfo.icon || UltraBot}`}
              alt={botInfo.name?.toUpperCase()}
            ></img> */}
            {/* {renderImageBot(botType)} */}
          </div>
          <div>
            <Typography variant="h5" className={classes.typographyTitle}>
              {botInfo.name?.toUpperCase()} {t("BOT")}
            </Typography>
          </div>
          <div>
            <Typography
              variant="caption"
              className={classes.typographyInfoLabel}
            >
              {t("Profit")}:{" "}
            </Typography>
            <Typography
              variant="caption"
              className={classes.typographyInfoValue}
            >
              {Math.round(parseFloat(botInfo.min_profit) * 100)}% ~{" "}
              {Math.round(parseFloat(botInfo.max_profit) * 100)}% / {t("year")}
            </Typography>
          </div>
          <div>
            <Typography variant="h5" className={classes.typographyPrice}>
              {convertNumberToUSDT(parseFloat(botInfo.first_invest))}
            </Typography>
          </div>
          {/* <div>
            <Button
              className={classes.button}
              classes={{ disabled: classes.buttonDisabled }}
              variant="outlined"
              onClick={() => {
                handleRentNow(botInfo);
              }}
            >
              {t("Invest")}
            </Button>
          </div> */}
        </CardContent>
      </Card>
    </div>
  );
}

CardBot.propTypes = {
  botType: PropTypes.string,
  handleRentNow: PropTypes.func,
};

export default CardBot;
