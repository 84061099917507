import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CloseCircle from "../../assets/img/CloseCircle.png";
import TrendingDownReverse from "../../assets/img/TrendingDownReverse.png";
import TrendingUpReverse from "../../assets/img/TrendingUpReverse.png";
import convertNumberToCurrency from "../../common/convertNumberToCurrency";
import PopoverAccountContainer from "../../containers/common/PopoverAccountContainer";
import InputCurrency from "../Input/InputCurrency";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        backgroundColor: theme.color.background,
        height: "100%",
        padding: "0 10px",
        [theme.breakpoints.down("sm")]: {
          backgroundColor: "transparent",
        },
      },
      coverShowAmount: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#2A2D35",
        padding: 0,
        borderRadius: 3,
      },
      buttonTransactionWeight: {
        backgroundColor: "#2A2D35",
        textAlign: "center",
        textTransform: "none",
        padding: "6px 0",
        color: theme.text.primary,
        width: "100%",
        minHeight: 45,
        minWidth: 0,
      },
      buttonTrendingUp: {
        backgroundColor: theme.color.trendingUp,
        color: theme.text.primary,
        width: "100%",
        textAlign: "center",
        marginBottom: 10,
        padding: 15,
        borderRadius: 2,
        cursor: "pointer",
        "&:hover": {
          backgroundColor: theme.color.trendingUp,
          opacity: 0.7,
        },
        [theme.breakpoints.down("sm")]: {
          padding: 7,
        },
      },
      coverContentButtonTrade: {
        [theme.breakpoints.down("sm")]: {
          display: "flex",
        },
      },
      buttonTrendingDown: {
        backgroundColor: theme.color.trendingDown,
        color: theme.text.primary,
        width: "100%",
        textAlign: "center",
        padding: 15,
        borderRadius: 2,
        cursor: "pointer",
        "&:hover": {
          backgroundColor: theme.color.trendingDown,
          opacity: 0.7,
        },
        [theme.breakpoints.down("sm")]: {
          padding: 7,
        },
      },
      buttonTradeDisabled: {
        backgroundColor: theme.color.disabled,
        color: theme.color.disabled,
      },
      textButtonTradeDisabled: { fontWeight: 500, color: "#fff" },
      textButtonTrade: { fontWeight: 500 },
      gridProgress: {
        display: "flex",
        justifyContent: "center",
      },
      rootProgress: {
        width: "80%",
        display: "flex",
        justifyContent: "center",
      },
      cssOutlinedInput: {
        "&$cssFocused $notchedOutline": {
          borderColor: `${theme.text.primary} !important`,
        },
      },
      notchedOutline: {
        borderWidth: "1px",
        borderColor: "#2A2D35 !important",
        borderRadius: "2px !important",
      },
      multilineColor: {
        padding: "0 5px",
        color: theme.text.primary,
        fontSize: 13,
      },
      input: {
        "&::placeholder": {
          opacity: 0.2,
        },
      },
      textStartAdornment: {
        color: theme.text.primary,
        marginRight: 5,
      },
      coverLoading: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 70,
        [theme.breakpoints.down("xs")]: {
          height: 30,
        },
      },
    }),
  { index: 1 }
);

export default function BitcoinTrendTab(props) {
  const classes = useStyles(props);
  const {
    walletAmount,
    amount,
    systemSecond,
    handleUpAmount,
    handleDownAmount,
    handleUpdateAmount,
    handleBuy,
    handleSell,
    handleOpenDrawerTransactionVolume,
    renderCircleProgress,
    disableOrder,
    loadingOrder,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const renderButtonSell = () => {
    return (
      <Button
        classes={{
          root: classes.buttonTrendingDown,
          disabled: classes.buttonTradeDisabled,
        }}
        onClick={handleSell}
        disabled={disableOrder ? disableOrder : systemSecond > 30}
      >
        {loadingOrder ? (
          <div className={classes.coverLoading}>
            <CircularProgress style={{ color: "#fff" }} size={25} />
          </div>
        ) : (
          <div className={classes.coverContentButtonTrade}>
            <img src={`${TrendingDownReverse}`} alt="TrendingDownReverse"></img>
            <Typography
              variant="h6"
              classes={{
                root:
                  systemSecond > 30
                    ? classes.textButtonTradeDisabled
                    : classes.textButtonTrade,
              }}
            >
              {t("Sell")}
            </Typography>
          </div>
        )}
      </Button>
    );
  };

  const renderButtonBuy = () => {
    return (
      <Button
        classes={{
          root: classes.buttonTrendingUp,
          disabled: classes.buttonTradeDisabled,
        }}
        onClick={handleBuy}
        disabled={disableOrder ? disableOrder : systemSecond > 30}
      >
        {loadingOrder ? (
          <div className={classes.coverLoading}>
            <CircularProgress style={{ color: "#fff" }} size={25} />
          </div>
        ) : (
          <div className={classes.coverContentButtonTrade}>
            <img src={`${TrendingUpReverse}`} alt="TrendingUpReverse"></img>
            <Typography
              variant="h6"
              classes={{
                root:
                  systemSecond > 30
                    ? classes.textButtonTradeDisabled
                    : classes.textButtonTrade,
              }}
            >
              {t("Buy")}
            </Typography>
          </div>
        )}
      </Button>
    );
  };

  const renderUpMedium = () => {
    return (
      <>
        <div style={{ height: 20 }}></div>
        <PopoverAccountContainer />
        <div style={{ height: 20 }}></div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {renderCircleProgress()}
        </div>
        <div style={{ height: 20 }}></div>
        <Grid container spacing={1}>
          <Grid item sm={12}>
            <div className={classes.coverShowAmount}>
              <TextField
                className={classes.textField}
                fullWidth
                variant="outlined"
                size="small"
                value={amount}
                id="groupName"
                name="otp_token"
                onChange={(e) => {
                  handleUpdateAmount(parseFloat(e.target.value));
                }}
                InputLabelProps={{ shrink: false }}
                InputProps={{
                  inputComponent: InputCurrency,
                  startAdornment: (
                    <Typography
                      variant="body2"
                      classes={{ root: classes.textStartAdornment }}
                    >
                      $
                    </Typography>
                  ),
                  endAdornment: (
                    <img
                      src={`${CloseCircle}`}
                      alt="CloseCircle"
                      style={{ marginLeft: 10, cursor: "pointer" }}
                      onClick={() => {
                        handleUpdateAmount(0);
                      }}
                    ></img>
                  ),
                  classes: {
                    root: classes.cssOutlinedInput,
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                  className: classes.multilineColor,
                }}
              />
            </div>
          </Grid>
          <Grid item sm={6}>
            <Button
              classes={{ root: classes.buttonTransactionWeight }}
              onClick={() => {
                handleUpAmount(5);
              }}
            >
              <Typography variant="body2">+5</Typography>
            </Button>
          </Grid>
          <Grid item sm={6}>
            <Button
              classes={{ root: classes.buttonTransactionWeight }}
              onClick={() => {
                handleUpAmount(10);
              }}
            >
              <Typography variant="body2">+10</Typography>
            </Button>
          </Grid>
          <Grid item sm={6}>
            <Button
              classes={{ root: classes.buttonTransactionWeight }}
              onClick={() => {
                handleUpAmount(20);
              }}
            >
              <Typography variant="body2">+20</Typography>
            </Button>
          </Grid>
          <Grid item sm={6}>
            <Button
              classes={{ root: classes.buttonTransactionWeight }}
              onClick={() => {
                handleUpAmount(50);
              }}
            >
              <Typography variant="body2">+50</Typography>
            </Button>
          </Grid>
          <Grid item sm={6}>
            <Button
              classes={{ root: classes.buttonTransactionWeight }}
              onClick={() => {
                handleUpAmount(100);
              }}
            >
              <Typography variant="body2">+100</Typography>
            </Button>
          </Grid>
          <Grid item sm={6}>
            <Button
              classes={{ root: classes.buttonTransactionWeight }}
              onClick={() => {
                handleUpdateAmount(walletAmount);
              }}
            >
              <Typography variant="body2">{t("All")}</Typography>
            </Button>
          </Grid>
        </Grid>
        <div style={{ height: 20 }}></div>
        <div>
          {renderButtonBuy()}
          {renderButtonSell()}
        </div>
      </>
    );
  };

  const renderDownExtraSmall = () => {
    return (
      <>
        <div style={{ height: 20 }}></div>
        <div>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Button
                classes={{ root: classes.buttonTransactionWeight }}
                onClick={() => {
                  handleDownAmount(5);
                }}
              >
                <Typography variant="h6">-</Typography>
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                classes={{ root: classes.buttonTransactionWeight }}
                onClick={handleOpenDrawerTransactionVolume}
              >
                <Typography variant="body1">
                  {convertNumberToCurrency(amount)}
                </Typography>
                <img
                  src={`${CloseCircle}`}
                  alt="CloseCircle"
                  style={{ marginLeft: 10 }}
                ></img>
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                classes={{ root: classes.buttonTransactionWeight }}
                onClick={() => {
                  handleUpAmount(5);
                }}
              >
                <Typography variant="h6">+</Typography>
              </Button>
            </Grid>
            <Grid item xs={4}>
              {renderButtonBuy()}
            </Grid>
            <Grid item xs={4} classes={{ root: classes.gridProgress }}>
              <div className={classes.rootProgress}>
                {renderCircleProgress()}
              </div>
            </Grid>
            <Grid item xs={4}>
              {renderButtonSell()}
            </Grid>
          </Grid>
        </div>
      </>
    );
  };

  return (
    <div className={classes.root}>
      {matches ? renderDownExtraSmall() : renderUpMedium()}
    </div>
  );
}
