import { useDispatch, useSelector } from "react-redux";
import { resetDemoWallet } from "../../apis/wallet.api";
import PopOverAccount from "../../components/PopOver/PopOverAccount";
import {
  getDemoWalletSuccess,
  setAccount,
} from "../../redux/actions/walletAction";

export default function PopoverAccountContainer() {
  const walletInfo = useSelector((state) => state.walletReducer?.info);
  const walletAccount = useSelector((state) => state.walletReducer?.account);
  const walletDemo = useSelector((state) => state.walletReducer?.demo);
  const dispatch = useDispatch();

  const walletAmount = walletInfo.results?.filter(
    (wallet) => wallet.c?.symbol === "USDT"
  )[0]?.balance;

  const handleSelectAccount = (account) => {
    localStorage.setItem("account", account);
    dispatch(setAccount(account));
  };

  const handleResetDemo = () => {
    resetDemoWallet()
      .then((res) => {
        if (res.status === 200) {
          dispatch(getDemoWalletSuccess(res.data));
        }
      })
      .catch();
  };

  return (
    <>
      <PopOverAccount
        walletAmount={walletAmount}
        account={walletAccount}
        walletDemo={walletDemo}
        handleSelectAccount={handleSelectAccount}
        handleResetDemo={handleResetDemo}
      />
    </>
  );
}
