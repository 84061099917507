import { Redirect } from "react-router";
import { PATH } from "../const/paths";
import BoChartPage from "../pages/BoChartPage";
import ForgotPasswordPage from "../pages/ForgotPasswordPage";
import HomePage from "../pages/HomePage";
import LoginPage from "../pages/LoginPage";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import RegisterPage from "../pages/RegisterPage";
import ResetPasswordPage from "../pages/ResetPasswordPage";
import TermOfUse from "../pages/TermOfUse";
import TradingPage from "../pages/TradingPage";
import VerifyRegistrationPage from "../pages/VerifyRegistrationPage";

const routes = [
  {
    path: PATH.TRADING,
    exact: false,
    component: () => <TradingPage />,
  },
  {
    path: PATH.HOME,
    exact: true,
    component: () => <HomePage />,
  },
  {
    path: PATH.LOGIN,
    exact: true,
    component: () => <LoginPage />,
  },
  {
    path: PATH.REGISTER,
    exact: true,
    component: () => <RegisterPage />,
  },
  {
    path: PATH.FORGOT_PASSWORD,
    exact: true,
    component: () => <ForgotPasswordPage />,
  },
  {
    path: PATH.RESET_PASSWORD,
    exact: true,
    component: () => <ResetPasswordPage />,
  },
  {
    path: PATH.VERIFYREGISTRATION,
    exact: true,
    component: () => <VerifyRegistrationPage />,
  },
  {
    path: "/",
    exact: true,
    component: () => <Redirect to={PATH.HOME} />,
  },
  {
    path: PATH.BO_CHART,
    exact: true,
    component: () => <BoChartPage />,
  },
  {
    path: PATH.PRIVACY_POLICY,
    exact: true,
    component: () => <PrivacyPolicy />,
  },
  {
    path: PATH.TERM_OF_USE,
    exact: true,
    component: () => <TermOfUse />,
  },
];

export default routes;
