import {
  Button,
  createStyles,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import lv0 from "../../../assets/img/0-03.png";
import lv1 from "../../../assets/img/1-03.png";
import lv2 from "../../../assets/img/2-03.png";
import lv3 from "../../../assets/img/3-03.png";
import lv4 from "../../../assets/img/4-03.png";
import lv5 from "../../../assets/img/5-03.png";
import lv6 from "../../../assets/img/6-03.png";
import lv7 from "../../../assets/img/7-03.png";
import lv8 from "../../../assets/img/8-03.png";
import lv9 from "../../../assets/img/9-03.png";
import lv10 from "../../../assets/img/10-03.png";
import convertNumberToCurrency from "../../../common/convertNumberToCurrency";
import convertNumberToMoney from "../../../common/convertNumberToMoney";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        width: "100%",
        // backgroundColor: theme.color.background,
        margin: "10px 0 0 10px",
        padding: "20px 0",
        [theme.breakpoints.down("xs")]: {
          padding: 10,
          width: "95%",
          margin: "20px auto",
        },
      },
      typographySecondary: {
        color: theme.text.secondary,
      },
      typographyPrimary: {
        color: theme.text.primary,
      },
      typographyInviter: { color: theme.text.primary, fontStyle: "italic" },
      coverBoxRight: {
        marginBottom: "20px",
        padding: 10,
        borderRadius: 10,
        border: `1px solid ${theme.color.border}`,
        backgroundColor: `${theme.body.backgroundColor}`,
        // height: "100%",
        [theme.breakpoints.down("xs")]: {
          marginBottom: 0,
        },
      },
      coverLevel: {
        margin: "10px 0",
        backgroundColor: `hsla(0, 0%, 100%, 0.05)`,
        borderRadius: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      },
      coverStatistic: {
        margin: "10px 0",
        padding: 10,
        backgroundColor: `hsla(0, 0%, 100%, 0.05)`,
        borderRadius: 10,
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        width: "100%",
      },
      coverConditionLevel: {
        margin: "10px 0",
      },
      avatarLarge: {
        width: theme.spacing(6),
        height: theme.spacing(6),
      },
      listItem: {
        padding: "0px 15px ",
        alignItems: "flex-start",
      },
      inviterRoot: {
        display: "flex",
        alignItems: "center",
        borderBottom: `0.5px solid ${theme.color.border}`,
        marginBottom: 10,
      },
    }),
  { index: 1 }
);

function PaperAgencyTradeSummary(props) {
  const classes = useStyles(props);
  const {
    referralBo,
    renderTableVolume,
    dataTradingVolume,
    handleOpenUpgradeLevel,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  console.log(referralBo);

  const renderNextLevel = () => {
    let f1_active = referralBo?.total_f1_active;
    let volume_current = referralBo?.volume_current_week;
    let bo_required = referralBo?.bo_commission_required;
    let next_level = referralBo?.bo_level;
    for (let i = 1; i < Object.keys(bo_required).length + 1; i++) {
      if (
        f1_active >= bo_required[`${i}`]?.num_f1 &&
        volume_current >= bo_required[`${i}`]?.volume_week
      ) {
        next_level = i;
      }
    }
    return next_level;
  };

  const renderSrcImageLevel = (bo_level) => {
    switch (bo_level) {
      case 0:
        return lv0;
      case 1:
        return lv1;
      case 2:
        return lv2;
      case 3:
        return lv3;
      case 4:
        return lv4;
      case 5:
        return lv5;
      case 6:
        return lv6;
      case 7:
        return lv7;
      case 8:
        return lv8;
      case 9:
        return lv9;
      case 10:
        return lv10;
      default:
        return lv0;
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={7}>
          <Grid
            container
            justify="center"
            spacing={2}
            classes={{ root: classes.coverBoxRight }}
          >
            <Grid item xs={12} sm={12}>
              <Grid
                container
                justify="center"
                spacing={2}
                className={classes.coverLevel}
              >
                <Grid item xs={12} sm={12}>
                  <div>
                    <List>
                      <ListItem classes={{ root: classes.listItem }}>
                        <ListItemAvatar
                          style={{ position: "relative", marginRight: 10 }}
                        >
                          <div style={{ textAlign: "center" }}>
                            <img
                              src={renderSrcImageLevel(
                                parseInt(referralBo?.bo_level)
                              )}
                              alt="lv"
                              width={60}
                              height="auto"
                            ></img>
                          </div>
                          <Button
                            style={{
                              color: "#fff",
                              textTransform: "none",
                              borderColor: "#fff",
                            }}
                            variant="outlined"
                            // onClick={handleOpenUpgradeLevel}
                          >
                            {t("Upgrade")}
                          </Button>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              variant="h5"
                              style={{ color: "#FFA031" }}
                            >
                              {referralBo?.user?.username}
                            </Typography>
                          }
                          secondary={
                            <>
                              <Typography
                                variant="body1"
                                className={classes.typographyInviter}
                              >
                                {t("Inviter")}:{" "}
                                <b style={{ color: "#FFA031" }}>
                                  {referralBo?.inviter?.username || ""}
                                </b>
                              </Typography>
                            </>
                          }
                        />
                      </ListItem>
                    </List>
                  </div>
                </Grid>
              </Grid>
              <Divider style={{ backgroundColor: "#fff", height: 0.25 }} />
              <div className={classes.coverConditionLevel}>
                {/* <Typography variant="body1">{t("Rank condition")}</Typography> */}
                <Grid container spacing={1} className={classes.coverStatistic}>
                  <Grid item sm={6} xs={6}>
                    <div>
                      <Typography
                        variant="body1"
                        className={classes.typographySecondary}
                      >
                        {t("F1 VIP")}
                      </Typography>
                      {referralBo?.fixed_bo_level ? (
                        <Typography variant="body1" style={{ marginTop: 5 }}>
                          {referralBo?.total_f1_active}
                        </Typography>
                      ) : (
                        <Typography variant="body1" style={{ marginTop: 5 }}>
                          {referralBo?.total_f1_active}/
                          {
                            referralBo?.bo_commission_required[
                              `${referralBo?.bo_level + 1}`
                            ]?.num_f1
                          }
                        </Typography>
                      )}
                    </div>
                  </Grid>
                  <Grid item sm={6} xs={6}>
                    <div>
                      <Typography
                        variant="body1"
                        className={classes.typographySecondary}
                      >
                        {t("F1 volume")}
                      </Typography>
                      {referralBo?.fixed_bo_level ? (
                        <Typography variant="body1" style={{ marginTop: 5 }}>
                          {referralBo?.volume_current_week}
                        </Typography>
                      ) : (
                        <Typography variant="body1" style={{ marginTop: 5 }}>
                          {referralBo?.volume_current_week}/
                          {
                            referralBo?.bo_commission_required[
                              `${referralBo?.bo_level + 1}`
                            ]?.volume_week
                          }
                        </Typography>
                      )}
                    </div>
                  </Grid>
                  {/* <Grid item sm={4} xs={12}>
                    <div>
                      <Typography variant="caption">
                        {referralBo?.fixed_bo_level
                          ? t("Level")
                          : t("Next level")}
                      </Typography>
                      <Typography variant="body1" style={{ marginTop: 5 }}>
                        {referralBo?.fixed_bo_level
                          ? referralBo?.bo_level
                          : renderNextLevel()}
                      </Typography>
                    </div>
                  </Grid> */}
                  <Grid item xs={6} sm={6}>
                    <Typography
                      variant="body1"
                      className={classes.typographySecondary}
                    >
                      {t("Traders")}
                    </Typography>
                    <Typography
                      variant="body1"
                      className={classes.typographyPrimary}
                    >
                      {referralBo?.total_network_count}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Typography
                      variant="body1"
                      className={classes.typographySecondary}
                    >
                      {t("VIP traders")}
                    </Typography>
                    <Typography
                      variant="body1"
                      className={classes.typographyPrimary}
                    >
                      {referralBo?.total_network_active}
                    </Typography>
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <Typography
                      variant="body1"
                      className={classes.typographySecondary}
                    >
                      {t("Investment")}
                    </Typography>
                    <Typography
                      variant="body1"
                      className={classes.typographyPrimary}
                      style={{ color: "#23CA7A" }}
                    >
                      {convertNumberToCurrency(
                        parseFloat(referralBo?.total_investment) || 0
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Typography
                      variant="body1"
                      className={classes.typographySecondary}
                    >
                      {t("Network investment")}
                    </Typography>
                    <Typography
                      variant="body1"
                      className={classes.typographyPrimary}
                      style={{ color: "#23CA7A" }}
                    >
                      {convertNumberToCurrency(
                        parseFloat(referralBo?.total_ref_investment) || 0
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
        {matches && (
          <Grid item xs={12} sm={12}>
            <Grid
              container
              justify="center"
              spacing={2}
              classes={{ root: classes.coverBoxRight }}
            >
              <Grid item xs={6} sm={6}>
                <Typography
                  variant="body1"
                  className={classes.typographySecondary}
                >
                  {t("Trade commission")}
                </Typography>
                <div style={{ paddingLeft: 15 }}>
                  <Typography
                    variant="body2"
                    className={classes.typographyPrimary}
                    style={{ color: "#23CA7A" }}
                  >
                    {convertNumberToCurrency(
                      parseFloat(referralBo?.summary_commission?.bo?.USDT || 0)
                    )}
                  </Typography>{" "}
                  <Typography
                    variant="body2"
                    className={classes.typographyPrimary}
                    style={{ color: "#2F86FA" }}
                  >
                    {convertNumberToMoney(
                      parseFloat(
                        parseFloat(referralBo?.summary_commission?.bo?.ALA || 0)
                      )
                    )}{" "}
                    ALA
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Typography
                  variant="body1"
                  className={classes.typographySecondary}
                >
                  {t("VIP commission")}
                </Typography>
                <div style={{ paddingLeft: 15 }}>
                  <Typography
                    variant="body2"
                    className={classes.typographyPrimary}
                    style={{ color: "#23CA7A" }}
                  >
                    {convertNumberToCurrency(
                      parseFloat(
                        referralBo?.summary_commission?.buy_ref?.USDT || 0
                      )
                    )}
                  </Typography>{" "}
                  <Typography
                    variant="body2"
                    className={classes.typographyPrimary}
                    style={{ color: "#2F86FA" }}
                  >
                    {convertNumberToMoney(
                      parseFloat(
                        referralBo?.summary_commission?.buy_ref?.ALA || 0
                      )
                    )}{" "}
                    ALA
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body1"
                  className={classes.typographySecondary}
                >
                  {t("Transaction commission")}
                </Typography>
                <div style={{ paddingLeft: 15 }}>
                  <Typography
                    variant="body2"
                    className={classes.typographyPrimary}
                    style={{ color: "#23CA7A" }}
                  >
                    {convertNumberToCurrency(
                      parseFloat(
                        referralBo?.summary_commission?.invest?.USDT || 0
                      )
                    )}
                  </Typography>{" "}
                  <Typography
                    variant="body2"
                    className={classes.typographyPrimary}
                    style={{ color: "#2F86FA" }}
                  >
                    {convertNumberToMoney(
                      parseFloat(
                        referralBo?.summary_commission?.invest?.USDT || 0
                      )
                    )}{" "}
                    ALA
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} sm={5}>
          {!matches && (
            <Grid
              container
              justify="center"
              spacing={2}
              classes={{ root: classes.coverBoxRight }}
            >
              <Grid item xs={6} sm={6}>
                <Typography
                  variant="body1"
                  className={classes.typographySecondary}
                >
                  {t("Trade commission")}
                </Typography>
                <div style={{ paddingLeft: 15 }}>
                  <Typography
                    variant="body2"
                    className={classes.typographyPrimary}
                    style={{ color: "#23CA7A" }}
                  >
                    {convertNumberToCurrency(
                      parseFloat(referralBo?.summary_commission?.bo?.USDT || 0)
                    )}
                  </Typography>{" "}
                  <Typography
                    variant="body2"
                    className={classes.typographyPrimary}
                    style={{ color: "#2F86FA" }}
                  >
                    {convertNumberToMoney(
                      parseFloat(
                        parseFloat(referralBo?.summary_commission?.bo?.ALA || 0)
                      )
                    )}{" "}
                    ALA
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Typography
                  variant="body1"
                  className={classes.typographySecondary}
                >
                  {t("VIP commission")}
                </Typography>
                <div style={{ paddingLeft: 15 }}>
                  <Typography
                    variant="body2"
                    className={classes.typographyPrimary}
                    style={{ color: "#23CA7A" }}
                  >
                    {convertNumberToCurrency(
                      parseFloat(
                        referralBo?.summary_commission?.buy_ref?.USDT || 0
                      )
                    )}
                  </Typography>{" "}
                  <Typography
                    variant="body2"
                    className={classes.typographyPrimary}
                    style={{ color: "#2F86FA" }}
                  >
                    {convertNumberToMoney(
                      parseFloat(
                        referralBo?.summary_commission?.buy_ref?.ALA || 0
                      )
                    )}{" "}
                    ALA
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography
                  variant="body1"
                  className={classes.typographySecondary}
                >
                  {t("Invest commission")}
                </Typography>
                <div style={{ paddingLeft: 15 }}>
                  <Typography
                    variant="body2"
                    className={classes.typographyPrimary}
                    style={{ color: "#23CA7A" }}
                  >
                    {convertNumberToCurrency(
                      parseFloat(
                        referralBo?.summary_commission?.invest?.USDT || 0
                      )
                    )}
                  </Typography>{" "}
                  <Typography
                    variant="body2"
                    className={classes.typographyPrimary}
                    style={{ color: "#2F86FA" }}
                  >
                    {convertNumberToMoney(
                      parseFloat(
                        referralBo?.summary_commission?.invest?.ALA || 0
                      )
                    )}{" "}
                    ALA
                  </Typography>
                </div>
              </Grid>
            </Grid>
          )}
          <Grid
            container
            justify="center"
            spacing={2}
            classes={{ root: classes.coverBoxRight }}
          >
            <Typography variant="body1">
              {t("Total trading volume of lower node")}
            </Typography>
            <div
              style={{
                width: "100%",
                overflow: "hidden",
                minHeight: !matches ? 200 : 150,
                backgroundColor: "hsla(0, 0%, 100%, 0.05)",
                borderRadius: 10,
                marginTop: 10,
                position: "relative",
                // marginLeft: "25vw",
                // marginTop: "25vh",
              }}
            >
              {/* {renderTableVolume()} */}
              {/* {dataTradingVolume && (
                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    height: 40,
                    padding: "0 20px",
                    width: "100%",
                    backgroundColor: theme.color.primary,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1">{t("Total")}</Typography>
                  <Typography variant="body1">
                    {convertNumberToCurrency(
                      dataTradingVolume.extra_data?.total_volume__sum || 0
                    )}
                  </Typography>
                  <div></div>
                </div>
              )} */}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

PaperAgencyTradeSummary.propTypes = {};

export default PaperAgencyTradeSummary;
