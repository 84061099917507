const hideString = (params) => {
  let string = params.toString();
  let length = string.length;
  let result = "";
  for (let i = 0; i < length; i++) {
    result += "*";
  }
  return result;
};

export default hideString;
