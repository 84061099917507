import {
  makeStyles,
  Typography,
  Button,
  useMediaQuery,
} from "@material-ui/core";
import React from "react";
// import BoardBingo from "../../../assets/img/BoardBingo.png";
// import FrameBingo from "../../../assets/img/FrameBingo.png";
import BingoItem from "../../../assets/img/BingoItem.png";
import IconTicket from "../../../assets/img/IconTicket.png";
import IconMoney from "../../../assets/img/IconMoney.png";
import IconGuide from "../../../assets/img/IconGuide.png";
import convertNumberToMoney from "../../../common/convertNumberToMoney";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/styles";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      zIndex: 2,
      position: "absolute",
      top: 30,
      left: "20%",
      [theme.breakpoints.down("xs")]: {
        top: 0,
        left: "5%",
      },
    },
    board: {
      position: "relative",
      //   backgroundImage: `url(${BoardBingo})`,
      //   backgroundRepeat: "no-repeat",
      //   backgroundSize: "auto",
      width: 710,
      //   height: 320,
      textAlign: "center",
      [theme.breakpoints.down("xs")]: {
        width: "90vw",
      },
    },
    title: {
      padding: "5px 0",
      fontWeight: "bold",
      fontFamily: "'Chakra Petch', sans-serif",
    },
    money: {
      padding: "5px 0",
      fontWeight: "bold",
      color: theme.text.demo,
      fontFamily: "'Chakra Petch', sans-serif",
    },
    coverResult: {
      display: "flex",
      justifyContent: "center",
    },
    itemNumber: {
      //   backgroundImage: `url(${BGNumber1})`,
      //   backgroundRepeat: "no-repeat",
      //   backgroundSize: "auto",
      backgroundColor: "#150026",
      borderRadius: "50%",
      border: "2px solid #009688",

      width: 45,
      height: 45,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        width: 30,
        height: 30,
      },
    },
    coverButtonPlay: {
      display: "flex",
      justifyContent: "center",
      margin: "30px 0",
    },
    buttonPlay: {
      color: theme.text.primary,
      //   textTransform: "none",
      fontSize: 20,
      fontWeight: 700,
      padding: "5px 20px",
      fontFamily: "'Chakra Petch', sans-serif",
      background:
        "linear-gradient(225deg, #009680 11.88%, #009688 34.76%, #009695 82.9%, #009699 85.62%)",
    },
    coverNextDraw: {
      display: "flex",
      justifyContent: "center",
      margin: "30px 0",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        alignItems: "center",
      },
    },
    textFont: { fontFamily: "'Gemunu Libre', sans-serif" },
    textNumberTickets: {
      color: theme.text.primary,
      fontWeight: 700,
    },
    numberTickets: {
      color: theme.text.link,
      fontWeight: 700,
      marginLeft: 10,
    },
    textButton: { fontWeight: 700, fontFamily: "'Gemunu Libre', sans-serif" },
    coverButton: {
      display: "flex",
      justifyContent: "center",
      marginTop: 20,
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        alignItems: "center",
      },
    },
    btnAction: {
      width: 195,
      height: 43,
      marginRight: 10,
      cursor: "pointer",
      // backgroundRepeat: "no-repeat",
      // backgroundSize: "auto",
      // backgroundImage: `url(${BingoItem})`,
      border: "1px solid #009688",
      borderRadius: "5px",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        margin: "5px 0",
      },
    },
    iconContainer: {
      width: 45,
      height: 43,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background:
        "linear-gradient(225deg, #009680 11.88%, #009688 34.76%, #009695 82.9%, #009699 85.62%)",
      borderRadius: "5px",
    },

    coverRound1Number: {
      padding: 10,
      borderRadius: "50%",
      backgroundColor: "#150026",
      border: "2px solid #303f9f",
      [theme.breakpoints.down("xs")]: {
        padding: 3,
      },
    },
    coverRound2Number: {
      padding: 10,
      background:
        "radial-gradient(49.99% 49.99% at 50.01% 49.99%, #E7FFFF 2.6%, rgba(206, 251, 250, 0.9427) 8.17%, rgba(152, 243, 238, 0.8045) 21.64%, rgba(106, 236, 228, 0.6649) 35.24%, rgba(68, 230, 220, 0.5267) 48.7%, rgba(38, 226, 213, 0.3901) 62%, rgba(17, 223, 209, 0.2557) 75.09%, rgba(4, 221, 206, 0.1245) 87.87%, rgba(0, 220, 205, 0) 100%)",
      margin: 5,
      borderRadius: "50%",
      [theme.breakpoints.down("xs")]: {
        padding: 10,
        margin: 0,
      },
    },
  }),
  { index: 1 }
);

export default function ResultBox(props) {
  const classes = useStyles();
  const { resultBingo, handleOpenDialog, handlePlay, tickets } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  const renderNumber = () => {
    let numbers = resultBingo.split("");
    let result = null;
    result = numbers.map((item, index) => {
      return (
        <div className={classes.coverRound2Number}>
          <div className={classes.coverRound1Number}>
            <div
              className={classes.itemNumber}
              // style={{
              //   marginRight:
              //     index % 2 !== 0 && index < numbers.length - 1 ? 20 : 0,
              // }}
            >
              <Typography
                variant={matches ? "h6" : "h4"}
                classes={{ root: classes.textFont }}
              >
                {item}
              </Typography>
            </div>
          </div>
        </div>
      );
    });
    return result;
  };

  const listButton = [
    { id: 0, text: t("Buy ticket"), icon: IconTicket, action: "buyticket" },
    { id: 1, text: t("History"), icon: IconMoney, action: "history" },
    { id: 2, text: t("Guide"), icon: IconGuide, action: "guide" },
    // { id: 3, text: t("Goodluck"), icon: IconGuide, action: "goodluck" },
    // { id: 4, text: t("Win"), icon: IconGuide, action: "win" },
  ];

  return (
    <div className={classes.root}>
      <div className={classes.board}>
        <Typography variant="h5" classes={{ root: classes.title }}>
          {t("TRY YOUR LUCK AND WIN NOW")}
        </Typography>
        <Typography variant="h4" classes={{ root: classes.money }}>
          {convertNumberToMoney(1000)} ALA
        </Typography>
        <div className={classes.coverResult}>{renderNumber()}</div>
      </div>
      <div className={classes.coverButtonPlay}>
        <Button
          classes={{ root: classes.buttonPlay }}
          variant="contained"
          onClick={handlePlay}
          disabled={tickets === 0}
        >
          {t("Play now")}
        </Button>
      </div>
      <div className={classes.coverNextDraw}>
        <Typography variant="h6" classes={{ root: classes.textNumberTickets }}>
          {t("Number of ticket")}:{" "}
        </Typography>
        <Typography variant="h6" classes={{ root: classes.numberTickets }}>
          {" "}
          {tickets}
        </Typography>
      </div>
      <div className={classes.coverButton}>
        {listButton.map((button) => (
          <div
            className={classes.btnAction}
            onClick={() => {
              handleOpenDialog(button.action);
            }}
          >
            <div className={classes.iconContainer}>
              <img src={button.icon} alt="icon" />
            </div>
            <div
              style={{
                width: 151,
                height: 42,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                classes={{ root: classes.textButton }}
                //   style={{ paddingLeft: 20 }}
              >
                {button.text}
              </Typography>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
