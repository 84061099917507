import {
  Card,
  CardContent,
  CardHeader,
  Drawer,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.color.background,
    },
    rootCard: {
      backgroundColor: theme.color.background,
      boxShadow: "none",
    },
    typography: {
      color: theme.text.primary,
      width: "100%",
      hyphens: "auto",
      //   wordWrap: "break-word",
    },
    iconButton: {
      color: "#C4C4C4",
    },
    cardContentRoot: {
      display: "flex",
      flexDirection: "column-reverse",
    },
  }),
  { index: 1 }
);

function DrawerTradingResultHistory(props) {
  const classes = useStyles();
  const { openDrawer, handleCloseDrawer, children } = props;
  const { t } = useTranslation();

  return (
    <Drawer
      anchor={"bottom"}
      open={openDrawer}
      onClose={handleCloseDrawer}
      classes={{ paper: classes.root }}
    >
      <Card classes={{ root: classes.rootCard }}>
        <CardHeader
          title={
            <Typography classes={{ root: classes.typography }} variant="h6">
              {t("Results history")}
            </Typography>
          }
          action={
            <IconButton
              classes={{ root: classes.iconButton }}
              onClick={handleCloseDrawer}
            >
              <CloseIcon />
            </IconButton>
          }
        />
        <CardContent classes={{ root: classes.cardContentRoot }}>
          {children}
        </CardContent>
      </Card>
    </Drawer>
  );
}

DrawerTradingResultHistory.propTypes = {};

export default DrawerTradingResultHistory;
