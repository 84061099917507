import {
  Button,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { cloneDeep, findIndex } from "lodash";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCandleStickData } from "../../apis/trade.api";
import List from "../../assets/img/List.png";
import CandleChart from "../../components/Chart/BOchart/CandleChart";
import TradingLine from "../../components/Chart/TradingLine";
import TradingPoint from "../../components/Chart/TradingPoint";
import DrawerTradingResultHistory from "../../components/Drawer/DrawerTradingResultHistory";
import DrawerTransactionHistory from "../../components/Drawer/DrawerTransactionHistory";
import BitcoinTrendItem from "../../components/NavBar/BitcoinTrendItem";
import NavBarRight from "../../components/NavBar/NavBarRight";
import { ACCOUNT, TOAST_NOTI } from "../../const/common";
import { callToastNotiSuccess } from "../../redux/actions/toastNotiAction";
import {
  getDemoListOrderReq,
  getListOrderReq,
} from "../../redux/actions/transactionAction";
import { firebase } from "../../utils/initFirebase";
import PlayBoTab from "./PlayBoTab";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: "100%",
      position: "relative",
      minHeight: "92vh",
    },
    gridContainer: {
      //   height: "100%",
    },
    rootCommingSoon: {
      position: "absolute",
      width: "100%",
      height: "100%",
      background: "rgba(0,0,0,0.65)",
      zIndex: 10,
    },
    textComingSoon: {
      textAlign: "center",
      marginTop: "30vh",
      color: theme.text.primary,
    },
    gridTop: { display: "flex" },
    rootChart: {
      width: "88%",
      paddingTop: 70,
      [theme.breakpoints.down("sm")]: {
        paddingTop: 50,
        width: "100%",
      },
    },
    rootTab: { width: "12%" },
    rootPointChart: {
      marginTop: 0,
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        // marginTop: -30,
        // flexWrap: "wrap",
        // display: "flex",
        // flexDirection: "column-reverse",
      },
    },
    rootTradingLine: {
      position: "absolute",
      top: -20,
      left: 15,
    },
    gridBottom: {
      [theme.breakpoints.down("sm")]: {
        borderTop: `1px solid ${theme.color.border}`,
        paddingTop: 10,
        // backgroundColor: theme.color.background,
        // height: "100%",
        // position: "absolute",
        // bottom: 0,
      },
    },
    coverCurrentPrice: {
      textAlign: "right",
      marginRight: 20,
      [theme.breakpoints.down("sm")]: {
        position: "fixed",
        top: 80,
        right: 0,
        zIndex: 100,
      },
    },
    coverLoading: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: 100,
      [theme.breakpoints.down("xs")]: {
        height: 80,
      },
    },
  }),
  { index: 1 }
);

function Transaction(props) {
  const classes = useStyles(props);

  const [openDrawerTransactionHistory, setOpenDrawerTransactionHistory] =
    useState(false);
  const [openDrawerResultHistory, setOpenDrawerResultHistory] = useState(false);
  const [initialBoData, setInitialBoData] = useState([]);
  const [boData, setBoData] = useState([]);
  const [newBo, setNewBo] = useState();
  const [valueTabOrder, setValueTabOrder] = useState(0);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXs = useMediaQuery(theme.breakpoints.down("xs"));
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const listOrder = useSelector(
    (state) => state.transactionReducer.listOrder?.results
  );
  const loadingOrder = useSelector(
    (state) => state.transactionReducer.loadingOrder
  );
  const walletAccount = useSelector((state) => state.walletReducer?.account);
  //   const chartRef = useRef();

  const handleChangeTabOrder = (newValue) => {
    switch (newValue) {
      case 0:
        if (walletAccount === ACCOUNT.REAL)
          dispatch(getListOrderReq({ result: "Open" }));
        else dispatch(getDemoListOrderReq({ result: "Open" }));
        break;
      case 1:
        if (walletAccount === ACCOUNT.REAL)
          dispatch(
            getListOrderReq({ result: "Close", page: 1, page_size: 10 })
          );
        else
          dispatch(
            getDemoListOrderReq({ result: "Close", page: 1, page_size: 10 })
          );

        break;
      default:
        break;
    }
    setValueTabOrder(newValue);
  };

  const handleOpenDrawerTransactionHistory = () => {
    setOpenDrawerTransactionHistory(true);
  };

  const handleCloseDrawerTransactionHistory = () => {
    setOpenDrawerTransactionHistory(false);
  };

  const handleCloseDrawerResultHistory = () => {
    setOpenDrawerResultHistory(false);
  };

  const renderNavBarItem = () => {
    if (listOrder && !loadingOrder) {
      return (
        <>
          {listOrder.map((order) => (
            <BitcoinTrendItem
              key={order.id}
              data={order}
              account={walletAccount}
            />
          ))}
        </>
      );
    } else if (loadingOrder) {
      // TODO add loading
      return (
        <div className={classes.coverLoading}>
          <CircularProgress style={{ color: "#fff" }} size={25} />
        </div>
      );
    }
    return null;
  };

  const renderPointMedium = () => {
    const countPoint = 100;
    let lengthTradingPoint = boData.length;
    let newBoData = cloneDeep(boData);
    if (lengthTradingPoint < countPoint) {
      for (let i = lengthTradingPoint; i < countPoint; i++) {
        newBoData.push({ id: Math.floor(Math.random() * 100001) });
      }
    }
    const numberPoint = 20;
    let count = Math.ceil(newBoData.length / numberPoint);
    let result = [];
    for (let i = 0; i < count; i++) {
      result.push(
        <TradingPoint
          data={newBoData.slice(i * numberPoint, numberPoint * (i + 1))}
        />
      );
    }
    return result;
  };

  const renderPointXS = () => {
    const countPoint = 60;
    let boDataXS = boData.slice(40, boData.length);
    let lengthTradingPoint = boDataXS.length;
    let newBoData = cloneDeep(boDataXS);
    if (lengthTradingPoint < countPoint) {
      for (let i = lengthTradingPoint; i < countPoint; i++) {
        newBoData.push({ id: Math.floor(Math.random() * 100001) });
      }
    }
    const numberPoint = 20;
    let count = Math.ceil(newBoData.length / numberPoint);
    let result = [];
    for (let i = 0; i < count; i++) {
      result.push(
        <TradingPoint
          data={newBoData.slice(i * numberPoint, numberPoint * (i + 1))}
        />
      );
    }
    return result;
  };

  const renderPointExtraSmallDrawer = () => {
    const countPoint = 80;
    let lengthTradingPoint = boData.length;
    let newBoData = cloneDeep(boData);
    if (lengthTradingPoint < countPoint) {
      for (let i = lengthTradingPoint; i < countPoint; i++) {
        newBoData.push({ id: Math.floor(Math.random() * 100001) });
      }
    }
    const numberPoint = 10;
    let count = Math.ceil(newBoData.length / numberPoint);
    let result = [];
    for (let i = 0; i < count; i++) {
      result.push(
        <TradingPoint
          data={newBoData.slice(i * numberPoint, numberPoint * (i + 1))}
        />
      );
    }
    return result;
  };

  const renderChart = useMemo(() => {
    return (
      <CandleChart
        data={
          !matchesXs
            ? initialBoData.slice(
                initialBoData.length - 75,
                initialBoData.length
              )
            : !matches
            ? initialBoData.slice(
                initialBoData.length - 60,
                initialBoData.length
              )
            : initialBoData.slice(
                initialBoData.length - 40,
                initialBoData.length
              )
        }
      ></CandleChart>
    );
  }, [initialBoData, matches, matchesXs]);

  const subcribeFirebase = (dataChart) => {
    const bo = firebase.database().ref(process.env.REACT_APP_FIREBASE_REF);
    // bo.once("value", (snapshot) => {
    // });
    bo.on("value", (snapshot) => {
      const data = snapshot.val();
      if (!data) return;
      setNewBo({
        ...data,
        timestamp: parseFloat(data.timestamp) * 1000,
        now: parseInt(data.now),
      });
    });
  };

  useEffect(() => {
    if (walletAccount === ACCOUNT.REAL)
      dispatch(getListOrderReq({ result: "Open" }));
    else dispatch(getDemoListOrderReq({ result: "Open" }));

    getCandleStickData({ page: 1, page_size: 100 })
      .then((res) => {
        if (res.status === 200) {
          let { data } = res;
          let time = Math.floor(
            (
              ((moment().utc().minutes() % 10) +
                moment().utc().seconds() / 60) *
              2
            ).toFixed(2)
          );
          data?.results?.sort((a, b) => a.id - b.id);
          data?.results?.splice(0, 20 - time);
          let newData = data?.results.map((bo) => ({
            id: bo.id,
            volume: parseFloat(bo.volume),
            high: parseFloat(bo.high),
            low: parseFloat(bo.low),
            open: parseFloat(bo.open),
            close: parseFloat(bo.close),
            timestamp: parseFloat(bo.timestamp) * 1000,
          }));
          //   newData.sort((a, b) => a.id - b.id);
          subcribeFirebase(newData);
          setInitialBoData(newData);
          setBoData(newData);
        }
      })
      .catch((err) => {
        let data = err.response?.data;
        if (data) {
          dispatch(
            callToastNotiSuccess({
              noti: data.message,
              status: TOAST_NOTI.ERROR,
              code: data.code,
            })
          );
        }
      });
  }, []);

  useEffect(() => {
    if (boData.length > 0) {
      const max = 100;
      let newBoData = cloneDeep(boData);
      let indexId = findIndex(newBoData, (o) => {
        return o.id === newBo?.id;
      });
      let indexTimestamp = findIndex(newBoData, (o) => {
        return o.timestamp === newBo?.timestamp;
      });

      if (indexTimestamp !== -1 && indexId !== -1) {
        newBoData.splice(indexTimestamp, 1, newBo);
      } else if (indexId !== -1) {
        newBoData.splice(indexId, 1, newBo);
      } else if (newBo?.id >= boData[0].id) {
        newBoData.push(newBo);
        if (newBoData.length === max) {
          newBoData.splice(0, 20);
        }
      }

      newBoData.sort((a, b) => a.id - b.id);
      setBoData(newBoData);
    }
  }, [newBo]);

  useEffect(() => {
    setValueTabOrder(0);
    if (walletAccount === ACCOUNT.REAL)
      dispatch(getListOrderReq({ result: "Open" }));
    else dispatch(getDemoListOrderReq({ result: "Open" }));
  }, [walletAccount]);

  return (
    <div className={classes.root}>
      {process.env.REACT_APP_ENABLE_TRADE === "false" && (
        <div className={classes.rootCommingSoon}>
          <Typography variant="h3" classes={{ root: classes.textComingSoon }}>
            {t("Maintenance")}
          </Typography>
        </div>
      )}
      <Grid container classes={{ root: classes.gridContainer }}>
        <Grid item md={10} sm={12} xs={12} classes={{ root: classes.gridTop }}>
          <div className={classes.rootChart}>
            {boData.length > 0 && (
              <div style={{ position: "relative" }}>
                {renderChart}
                <div className={classes.rootTradingLine}>
                  {!matches && <TradingLine data={newBo} />}
                </div>
                {!matchesXs ? (
                  <div className={classes.rootPointChart}>
                    {renderPointMedium()}
                  </div>
                ) : (
                  <div className={classes.rootPointChart}>
                    {renderPointXS()}
                  </div>
                )}
              </div>
            )}
          </div>

          {!matches && (
            <div className={classes.rootTab}>
              <PlayBoTab
                time={newBo?.now}
                boData={boData}
                handleChangeTabOrder={handleChangeTabOrder}
              />
            </div>
          )}
        </Grid>
        <Grid
          item
          md={2}
          sm={12}
          xs={12}
          classes={{ root: classes.gridBottom }}
        >
          {!matches ? (
            <NavBarRight
              handleChange={handleChangeTabOrder}
              value={valueTabOrder}
            >
              {renderNavBarItem()}
            </NavBarRight>
          ) : (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <TradingLine data={newBo} />
                <Button onClick={handleOpenDrawerTransactionHistory}>
                  <img src={`${List}`} alt="List"></img>
                </Button>
              </div>
              <div>
                <PlayBoTab
                  time={newBo?.now}
                  boData={boData}
                  handleChangeTabOrder={handleChangeTabOrder}
                />
              </div>
            </div>
          )}
        </Grid>
      </Grid>

      <DrawerTransactionHistory
        handleCloseDrawer={handleCloseDrawerTransactionHistory}
        openDrawer={openDrawerTransactionHistory}
      >
        <NavBarRight handleChange={handleChangeTabOrder} value={valueTabOrder}>
          {renderNavBarItem()}
        </NavBarRight>
      </DrawerTransactionHistory>
      <DrawerTradingResultHistory
        handleCloseDrawer={handleCloseDrawerResultHistory}
        openDrawer={openDrawerResultHistory}
      >
        {renderPointExtraSmallDrawer()}
      </DrawerTradingResultHistory>
    </div>
  );
}

export default Transaction;
