import {
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import convertNumberToCurrency from "../../common/convertNumberToCurrency";
import { ACCOUNT } from "../../const/common";
import CupWinner from "../../assets/img/CupWinner.png";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      paperRoot: {
        backgroundColor: "transparent",
        boxShadow: "none",
      },
      closeButton: {
        position: "absolute",
        right: 0,
        top: 0,
        color: theme.palette.grey[500],
      },
      dialogContent: {
        // backgroundColor: "theme.color.background",
        background:
          "linear-gradient(177.83deg, #C75D00 -50.91%, #000000 138.93%)",
        padding: "24px 48px",
        borderRadius: 10,
      },
      text: {
        color: theme.text.success,
        textAlign: "center",
        fontWeight: 700,
      },
      title: {
        float: "right",
        padding: "0px 8px",
        backgroundColor: "#ff9800",
        color: theme.text.primary,
        borderRadius: 2,
      },
    }),
  { index: 1 }
);

export default function DialogOrderTradeSuccess(props) {
  const classes = useStyles(props);
  const { open, handleClose, value, walletAccount } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.paperRoot }}
    >
      <DialogTitle>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <div>
          {walletAccount !== ACCOUNT.REAL && (
            <Typography variant="body2" classes={{ root: classes.title }}>
              Demo
            </Typography>
          )}
        </div>
        <img
          src={CupWinner}
          alt="CupWinner"
          style={{ mixBlendMode: "screen" }}
        />
        <Typography variant="h4" classes={{ root: classes.text }}>
          +{convertNumberToCurrency(value)}
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
