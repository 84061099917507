import { SCHEME, DOMAIN } from "../const/api";
import callApi from "../utils/apiCaller";

const headers = {
  //prettier-ignore
  "Content-Type": "application/json",
};

export const getUserBotV2 = () => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "get",
    url: `${SCHEME}://${DOMAIN}/v1/bot-v2/user-bot/`,
    headers: {
      ...headers,
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
  };
  return callApi(config);
};

export const buyBotV2 = (data) => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "post",
    url: `${SCHEME}://${DOMAIN}/v1/bot-v2/user-bot/buy/`,
    headers: {
      ...headers,
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
    data,
  };
  return callApi(config);
};

export const depositBotV2 = (data) => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "post",
    url: `${SCHEME}://${DOMAIN}/v1/bot-v2/user-bot/upgrade/`,
    headers: {
      ...headers,
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
    data,
  };
  return callApi(config);
};

export const withdrawBotV2 = (data) => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "post",
    url: `${SCHEME}://${DOMAIN}/v1/bot-v2/user-bot/withdraw/`,
    headers: {
      ...headers,
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
    data,
  };
  return callApi(config);
};

export const getTransactionBotV2 = (params) => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "get",
    url: `${SCHEME}://${DOMAIN}/v1/bot-v2/user-bot-transaction/`,
    headers: {
      ...headers,
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
    params,
  };
  return callApi(config);
};

export const getRecentOrderBotV2 = (params) => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "get",
    url: `${SCHEME}://${DOMAIN}/v1/bot-v2/user-bot-v2-trade/`,
    headers: {
      ...headers,
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
    params,
  };
  return callApi(config);
};
