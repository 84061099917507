import {
  Avatar,
  createStyles,
  makeStyles,
  Popover,
  Typography,
} from "@material-ui/core";
import React, { memo, useState } from "react";
import { Handle } from "react-flow-renderer";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: 80,
      backgroundColor: "rgba(255, 255, 255, 0.03)",
      //   display: "flex",
      //   alignItems: "center",
      //   flexDirection: "column",
      padding: 5,
      borderRadius: 1,
      textAlign: "center",
      //   flexWrap: "wrap",
      //   transform: "rotate(60deg)",
    },
    avatar: { margin: "auto" },
    typography: {
      //   wordWrap: "break-word",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    handle: {
      justifyContent: "center",
      background: "#555",
      visibility: "hidden",
    },
  })
);

export default memo(({ data }) => {
  const classes = useStyles();
  const { info, handlePopoverOpen, handlePopoverClose } = data;

  return (
    <>
      <Handle
        type="target"
        position="top"
        className={classes.handle}
        onConnect={() => {}}
      />

      <div
        className={classes.root}
        onMouseEnter={(event) => {
          handlePopoverOpen(event, info);
        }}
        onMouseLeave={handlePopoverClose}
      >
        <Avatar classes={{ root: classes.avatar }}></Avatar>
        <Typography variant="body2" classes={{ root: classes.typography }}>
          {info.id}
        </Typography>
      </div>

      <Handle
        type="source"
        position="bottom"
        id="a"
        className={classes.handle}
      />
      <Handle
        type="source"
        position="bottom"
        id="b"
        className={classes.handle}
      />
    </>
  );
});
