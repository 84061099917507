import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PaperBuyAgencyTradeRights from "../../components/Body/BodyAgencyTrade/PaperBuyAgencyTradeRights";
import DialogBuyAgentRights from "../../components/Dialog/DialogBuyAgentRights";
import { buyReferralCodeReq } from "../../redux/actions/referralAction";
import { getUserWalletReq } from "../../redux/actions/walletAction";

export default function AgencyRights() {
  const [openDialogBuyAgentRights, setOpenDialogBuyAgentRights] = useState(
    false
  );
  const walletsInfo = useSelector((state) => state.walletReducer.info);
  const referralBo = useSelector((state) => state.referralReducer.bo);
  const dispatch = useDispatch();

  const handleOpenBuyAgentRights = () => {
    setOpenDialogBuyAgentRights(true);
  };

  const handleCloseBuyAgentRights = () => {
    setOpenDialogBuyAgentRights(false);
  };

  const handlePay = (refId) => {
    dispatch(buyReferralCodeReq(refId));
  };

  useEffect(() => {
    dispatch(getUserWalletReq());
  }, []);

  return (
    <>
      <PaperBuyAgencyTradeRights
        handleOpenBuyAgentRights={handleOpenBuyAgentRights}
      />
      <DialogBuyAgentRights
        open={openDialogBuyAgentRights}
        handleClose={handleCloseBuyAgentRights}
        walletInfo={
          walletsInfo.results?.filter((wallet) => wallet.c.symbol === "USDT")[0]
        }
        referralInfo={referralBo}
        handlePay={handlePay}
      />
    </>
  );
}
