import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import GoodLuckBG from "../../../assets/img/GoodLuck.png";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: "10px 0 40px 0",
    },
    text: {
      fontWeight: 700,
      color: theme.text.primary,
      padding: "10px 0",
    },
    img: {
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        height: "auto",
      },
    },
  }),
  { index: 1 }
);

export default function GoodLuck() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Typography variant="h5" classes={{ root: classes.text }}>
        {t("Good luck next time")}
      </Typography>
      <img src={GoodLuckBG} alt="bg" className={classes.img} />
    </div>
  );
}
