import routes from "../routes/routes";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ToastNoti from "../components/ToastNoti/ToastNoti";
import ToastLoading from "../components/ToastNoti/ToastLoading";
import ChatIcon from "../assets/img/chat.png";
import { createStyles, makeStyles } from "@material-ui/core";
import CloseIcon from "../components/Icons/CloseIcon";
import { useState } from "react";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      chatRoot: {
        position: "fixed",
        bottom: 50,
        right: 50,
        [theme.breakpoints.down("xs")]: {
          bottom: 10,
          right: 10,
        },
      },
      chatBtn: {
        position: "relative",
        cursor: "pointer",
        borderRadius: 100,
        background: "#ffffff",
        width: 70,
        height: 70,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow:
          "0px 0px 8px #C9C9C9, -0px 0px 8px #C9C9C9, 0px 0px 8px #C9C9C9, 0px -0px 8px #C9C9C9",
        [theme.breakpoints.down("xs")]: {
          width: 50,
          height: 50,
        },
      },
      closeIcon: {
        position: "absolute",
        top: -13,
        right: -13,
        cursor: "pointer",
      },
      chatIcon: {
        width: 40,
        [theme.breakpoints.down("xs")]: {
          width: 30,
        },
      },
    }),
  { index: 1 }
);

export default function Main() {
  const showBody = (routes) => {
    let result = null;
    if (routes.length > 0) {
      result = routes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        );
      });
    }
    return <Switch>{result}</Switch>;
  };

  return (
    <>
      <Router>
        <ToastNoti />
        <ToastLoading />
        {showBody(routes)}
      </Router>

      <Chat />
    </>
  );
}

const Chat = () => {
  const classes = useStyles();
  const [closeChat, setCloseChat] = useState(true);

  const handleCloseChat = () => {
    setCloseChat(false);
  };

  return (
    <>
      {closeChat && (
        <div className={classes.chatRoot}>
          <div className={classes.chatBtn}>
            <div className={classes.closeIcon} onClick={handleCloseChat}>
              <CloseIcon />
            </div>

            <a
              href={"https://t.me/helenna_alltrade"}
              target="_blank"
              rel="noreferrer"
            >
              <img
                className={classes.chatIcon}
                src={ChatIcon}
                alt="chat-icon"
              />
            </a>
          </div>
        </div>
      )}
    </>
  );
};
