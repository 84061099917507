import {
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@material-ui/core";
import { Pagination, PaginationItem } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import convertNumberToCurrency from "../../common/convertNumberToCurrency";
import CircularProgress from "@material-ui/core/CircularProgress";
import convertNumberToMoney from "../../common/convertNumberToMoney";
import moment from "moment";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        // maxHeight: 400,
      },
      table: {
        border: `1px solid ${theme.color.border}`,
        borderBottom: "none",
      },
      tableCellHeader: {
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        border: "none",
        color: theme.text.secondary,
      },
      labelHeader: { color: `${theme.text.secondary} !important` },
      tableCellBody: {
        borderBottom: "1px solid #424242",
        color: "#fff",
      },
      tableCellFooter: {
        backgroundColor: "rgba(255, 255, 255, 0.2)",
        border: "none",
        color: theme.text.secondary,
      },
      rootPagination: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: 20,
        marginBottom: 50,
      },
      pagination: {
        color: theme.text.primary,
      },
      rootPaginationItem: {
        border: `1px solid ${theme.text.primary}`,
        color: theme.text.primary,
        borderRadius: 2,
      },
      ellipsisPagination: { border: "none" },
      pageSelected: {
        backgroundColor: "hsla(0, 0%, 100%, 0.1) !important",
        border: `1px solid ${theme.color.border}`,
      },
      coverLoading: {
        position: "absolute",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // backgroundColor: "hsla(0, 0%, 100%, 0.05)",
        // [theme.breakpoints.down("xs")]: {
        //   height: 80,
        // },
      },
    }),
  { index: 1 }
);

function createData(date, trade_vol, com_usdt, com_ala) {
  return { date, trade_vol, com_usdt, com_ala };
}

function TableDataUserTradeBo(props) {
  const classes = useStyles(props);
  const {
    dataTable,
    pagination,
    handleChangePage,
    loading,
    handleSort,
  } = props;
  const [rows, setRows] = useState([]);
  const [footer, setFooter] = useState([]);
  const [orderBy, setOrderBy] = useState();
  const [order, setOrder] = useState("asc");
  const { t } = useTranslation();

  const columns = [
    { id: "date", label: t("Date"), minWidth: 100, sort: false },
    {
      id: "trade_vol",
      label: t("Total trade volume"),
      minWidth: 100,
      sort: true,
    },
    {
      id: "com_usdt",
      label: t("Commission USDT"),
      minWidth: 100,
      sort: true,
    },
    {
      id: "com_ala",
      label: t("Commission ALA"),
      minWidth: 100,
      sort: true,
    },
  ];

  useEffect(() => {
    if (dataTable) {
      let rows = dataTable?.results.map((data) => {
        let { trade_volume, com_usdt, com_ala, month } = data;
        month = moment(month).format("MM-YYYY");
        trade_volume = (
          <Typography variant="body2" style={{ color: "#23CA7A" }}>
            {convertNumberToCurrency(trade_volume)}
          </Typography>
        );
        com_usdt = (
          <Typography variant="body2" style={{ color: "#23CA7A" }}>
            {convertNumberToCurrency(com_usdt)}
          </Typography>
        );
        com_ala = (
          <Typography variant="body2" style={{ color: "#2F86FA" }}>
            {convertNumberToMoney(parseFloat(com_ala))}
          </Typography>
        );
        return createData(month, trade_volume, com_usdt, com_ala);
      });
      setRows(rows);

      let footer = [
        { id: "total", label: t("Total") },
        {
          id: "sum_vol",
          label: "",
        },
        {
          id: "sum_commission_usdt",
          label: (
            <Typography variant="body2" style={{ color: "#23CA7A" }}>
              {convertNumberToCurrency(dataTable.extra_data?.com_usdt__sum)}
            </Typography>
          ),
        },
        {
          id: "sum_commission_ala",
          label: (
            <Typography variant="body2" style={{ color: "#2F86FA" }}>
              {convertNumberToMoney(
                parseFloat(dataTable.extra_data?.com_ala__sum)
              )}
            </Typography>
          ),
        },
      ];
      setFooter(footer);
    }
  }, [dataTable]);

  return (
    <>
      <TableContainer className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className={classes.tableCellHeader}
                  sortDirection={orderBy === column.id ? order : false}
                >
                  {column.sort ? (
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : "asc"}
                      classes={{
                        root: classes.labelHeader,
                        icon: classes.labelHeader,
                      }}
                      onClick={() => {
                        setOrderBy(column.id);
                        if (order === "asc") {
                          setOrder("desc");
                          handleSort(column.id, "desc");
                        } else {
                          setOrder("asc");
                          handleSort(column.id, "asc");
                        }
                      }}
                    >
                      {column.label}
                    </TableSortLabel>
                  ) : (
                    column.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody style={{ position: "relative", minHeight: 80 }}>
            {/* TODO add loading */}
            {loading && (
              <div className={classes.coverLoading}>
                <CircularProgress style={{ color: "#fff" }} size={25} />
              </div>
            )}
            <>
              {rows.length === 0 ? (
                <TableRow tabIndex={-1}>
                  <TableCell
                    colSpan={4}
                    className={classes.tableCellBody}
                  ></TableCell>
                </TableRow>
              ) : (
                rows.map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            className={classes.tableCellBody}
                            style={{ opacity: loading ? 0.3 : 1 }}
                          >
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              )}
            </>

            <TableRow>
              {footer.map((column) => (
                <TableCell key={column.id} className={classes.tableCellFooter}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.rootPagination}>
        {dataTable?.count > pagination.page_size && (
          <Pagination
            count={Math.ceil(dataTable?.count / pagination.page_size || 0)}
            page={pagination.page}
            onChange={(_, value) => {
              handleChangePage(value);
            }}
            variant="outlined"
            shape="rounded"
            classes={{ root: classes.pagination }}
            renderItem={(item) => (
              <PaginationItem
                classes={{
                  root: classes.rootPaginationItem,
                  ellipsis: classes.ellipsisPagination,
                  selected: classes.pageSelected,
                }}
                {...item}
                variant="outlined"
              />
            )}
          />
        )}
      </div>
    </>
  );
}

TableDataUserTradeBo.propTypes = {};

export default TableDataUserTradeBo;
