import {
  Button,
  Card,
  CardContent,
  CardHeader,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Drawer,
  IconButton,
  makeStyles,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";
import Eyeopen from "../../assets/img/Eyeopen.png";
import Eyeclose from "../../assets/img/Eyeclose.png";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useState } from "react";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      rootDrawer: {
        backgroundColor: theme.body.backgroundColor,
        height: "100%",
      },
      rootCard: {
        backgroundColor: "rgba(255, 255, 255, 0.03)",
        height: "100%",
        boxShadow: "none",
      },
      rootCardHeader: {
        background: theme.color.background,
      },
      rootPaper: { boxShadow: "none !important" },
      dialogHeader: {
        backgroundColor: "#191D23",
        color: "#fff",
        padding: "10px 20px",
      },
      closeButton: {
        position: "absolute",
        right: 2,
        top: 0,
        color: theme.text.primary,
      },
      dialogContent: {
        backgroundColor: "#191D23",
        padding: "20px 30px 30px 30px",
      },
      dividers: { borderTopColor: "#272C3A" },
      textField: {
        marginBottom: 15,
        backgroundColor: "#2A2D35",
      },
      cssOutlinedInput: {
        "&$cssFocused $notchedOutline": {
          borderColor: `${theme.text.primary} !important`,
        },
      },
      notchedOutline: {
        borderWidth: "1px",
        borderColor: "#2A2D35 !important",
        borderRadius: "2px !important",
      },
      multilineColor: {
        color: theme.text.primary,
        fontSize: 13,
      },
      input: {
        "&::placeholder": {
          opacity: 0.2,
        },
      },
      typographySecondary: {
        color: theme.text.secondary,
      },
      typographyPrimary: {
        color: theme.text.primary,
      },
      typographyValue: { paddingLeft: 20 },
      buttonSubmit: {
        width: "100%",
        color: theme.text.primary,
        backgroundColor: theme.color.primary,
        textTransform: "none",
        borderRadius: 2,
        marginTop: 10,
      },
      iconButtonEye: {
        // paddingRight: 0,
      },
    }),
  { index: 1 }
);

const initialValues = {
  current_password: "",
  password: "",
  confirm_password: "",
};

export default function DialogChangePassword(props) {
  const classes = useStyles(props);
  const { open, handleClose, handleChangePassword } = props;
  const [showCurrentPsw, setShowCurrentPsw] = useState(false);
  const [showNewPsw, setShowNewPsw] = useState(false);
  const [showConfirmPsw, setShowConfirmPsw] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  const validationSchema = Yup.object().shape({
    current_password: Yup.string().required(t("Not be empty")),
    password: Yup.string().required(t("Not be empty")),
    confirm_password: Yup.string()
      .required(t("Not be empty"))
      .oneOf([Yup.ref("password"), null], t("Password did not match")),
  });

  const onSubmit = (values) => {
    handleChangePassword(values);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => onSubmit(values),
  });

  const handleHideCurrentPsw = () => {
    setShowCurrentPsw(false);
  };

  const handleShowCurrentPsw = () => {
    setShowCurrentPsw(true);
  };

  const handleHideNewPsw = () => {
    setShowNewPsw(false);
  };

  const handleShowNewPsw = () => {
    setShowNewPsw(true);
  };

  const handleHideConfirmPsw = () => {
    setShowConfirmPsw(false);
  };

  const handleShowConfirmPsw = () => {
    setShowConfirmPsw(true);
  };

  const renderContent = () => {
    return (
      <form onSubmit={formik.handleSubmit}>
        <div>
          <Typography variant="caption" className={classes.typographySecondary}>
            {t("Current password")}
          </Typography>
          <TextField
            className={classes.textField}
            fullWidth
            variant="outlined"
            id="current_password"
            name="current_password"
            type={!showCurrentPsw && "password"}
            InputLabelProps={{ shrink: false }}
            InputProps={{
              endAdornment: (
                <>
                  {/* <IconButton classes={{ root: classes.iconButtonEye }}>
                    <img src={`${Eyeopen}`} alt="Eyeopen"></img>
                  </IconButton> */}
                  {showCurrentPsw ? (
                    <img
                      src={`${Eyeclose}`}
                      alt="Eyeclose"
                      style={{ cursor: "pointer" }}
                      onClick={handleHideCurrentPsw}
                    ></img>
                  ) : (
                    <img
                      src={`${Eyeopen}`}
                      alt="Eyeopen"
                      style={{ cursor: "pointer" }}
                      onClick={handleShowCurrentPsw}
                    ></img>
                  )}
                </>
              ),
              classes: {
                root: classes.cssOutlinedInput,
                notchedOutline: classes.notchedOutline,
                input: classes.input,
              },
              className: classes.multilineColor,
            }}
            size="small"
            onChange={formik.handleChange}
            //   onBlur={formik.handleBlur}
            value={formik.values.current_password}
            error={
              formik.touched.current_password &&
              Boolean(formik.errors.current_password)
            }
            helperText={
              formik.touched.current_password && formik.errors.current_password
            }
          />
        </div>
        <div>
          <Typography variant="caption" className={classes.typographySecondary}>
            {t("New password")}
          </Typography>
          <TextField
            className={classes.textField}
            fullWidth
            variant="outlined"
            id="pasword"
            name="password"
            type={!showNewPsw && "password"}
            InputLabelProps={{ shrink: false }}
            InputProps={{
              endAdornment: (
                <>
                  {/* <IconButton classes={{ root: classes.iconButtonEye }}>
                    <img src={`${Eyeopen}`} alt="Eyeopen"></img>
                  </IconButton> */}
                  {showNewPsw ? (
                    <img
                      src={`${Eyeclose}`}
                      alt="Eyeclose"
                      style={{ cursor: "pointer" }}
                      onClick={handleHideNewPsw}
                    ></img>
                  ) : (
                    <img
                      src={`${Eyeopen}`}
                      alt="Eyeopen"
                      style={{ cursor: "pointer" }}
                      onClick={handleShowNewPsw}
                    ></img>
                  )}
                </>
              ),
              classes: {
                root: classes.cssOutlinedInput,
                notchedOutline: classes.notchedOutline,
                input: classes.input,
              },
              className: classes.multilineColor,
            }}
            size="small"
            onChange={formik.handleChange}
            //   onBlur={formik.handleBlur}
            value={formik.values.password}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
        </div>
        <div>
          <Typography variant="caption" className={classes.typographySecondary}>
            {t("Confirm new password")}
          </Typography>
          <TextField
            className={classes.textField}
            fullWidth
            variant="outlined"
            id="confirm_password"
            name="confirm_password"
            type={!showConfirmPsw && "password"}
            InputLabelProps={{ shrink: false }}
            InputProps={{
              endAdornment: (
                <>
                  {/* <IconButton classes={{ root: classes.iconButtonEye }}>
                    <img src={`${Eyeopen}`} alt="Eyeopen"></img>
                  </IconButton> */}
                  {showConfirmPsw ? (
                    <img
                      src={`${Eyeclose}`}
                      alt="Eyeclose"
                      style={{ cursor: "pointer" }}
                      onClick={handleHideConfirmPsw}
                    ></img>
                  ) : (
                    <img
                      src={`${Eyeopen}`}
                      alt="Eyeopen"
                      style={{ cursor: "pointer" }}
                      onClick={handleShowConfirmPsw}
                    ></img>
                  )}
                </>
              ),
              classes: {
                root: classes.cssOutlinedInput,
                notchedOutline: classes.notchedOutline,
                input: classes.input,
              },
              className: classes.multilineColor,
            }}
            size="small"
            onChange={formik.handleChange}
            //   onBlur={formik.handleBlur}
            value={formik.values.confirm_password}
            error={
              formik.touched.confirm_password &&
              Boolean(formik.errors.confirm_password)
            }
            helperText={
              formik.touched.confirm_password && formik.errors.confirm_password
            }
          />
        </div>
        {/* <div>
          <Typography variant="caption" className={classes.typographySecondary}>
            {t("Google Authenticator code")}
          </Typography>
          <TextField
            className={classes.textField}
            fullWidth
            variant="outlined"
            id="groupName"
            InputLabelProps={{ shrink: false }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                notchedOutline: classes.notchedOutline,
                input: classes.input,
              },
              className: classes.multilineColor,
            }}
            size="small"
          />
        </div> */}
        <div>
          <Button className={classes.buttonSubmit} type="submit">
            {t("Change password")}
          </Button>
        </div>
      </form>
    );
  };

  return (
    <>
      {matches ? (
        <Drawer
          anchor={"bottom"}
          open={open}
          onClose={handleClose}
          classes={{ paper: classes.rootDrawer }}
        >
          <Card classes={{ root: classes.rootCard }}>
            <CardHeader
              classes={{ root: classes.rootCardHeader }}
              title={
                <>
                  <Typography
                    variant="body1"
                    className={classes.typographyPrimary}
                  >
                    {t("Change password")}
                  </Typography>
                </>
              }
              action={
                <IconButton
                  className={classes.closeButton}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>
              }
            />
            <CardContent classes={{ root: classes.rootCardContent }}>
              {renderContent()}
            </CardContent>
          </Card>
        </Drawer>
      ) : (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          classes={{ paper: classes.rootPaper }}
        >
          <DialogTitle id="alert-dialog-title" className={classes.dialogHeader}>
            <Typography variant="body1">{t("Change password")}</Typography>
            <IconButton className={classes.closeButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            className={classes.dialogContent}
            dividers={true}
            classes={{ dividers: classes.dividers }}
          >
            {renderContent()}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

DialogChangePassword.propTypes = {};
