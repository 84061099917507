import {
  IconButton,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import HighchartsBB from "highcharts/indicators/bollinger-bands";
// import HighchartsEMA from "highcharts/indicators/ema";
import HighchartsIndicators from "highcharts/indicators/indicators";
import HighchartsData from "highcharts/modules/data";
import moment from "moment";
import { useEffect } from "react";
import { useMemo, useRef, useState } from "react";
import BtcLarge from "../../../assets/img/BtcLarge.png";
import Eyeclose from "../../../assets/img/Eyeclose.png";
import Eyeopen from "../../../assets/img/Eyeopen.png";
import { BOCHART } from "../../../const/colorChart";
import useCandleStick from "../../../hooks/useCandleStick";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: "relative",
      //   backgroundImage: `url(${BackgroundChart})`,
      //   maxHeight: "50vh",
      //   overflow: "auto",
      //   backgroundRepeat: "no-repeat",
      //   backgroundPosition: "center bottom",
      [theme.breakpoints.down("xs")]: {
        // backgroundImage: `url(${BackgroundChartXS})`,
      },
    },
    tooltip: {
      color: "green",
    },
    coverBB: {
      display: "flex",
      alignItems: "center",
      marginTop: 10,
      flexDirection: "row-reverse",
    },
    coverNote: {
      position: "absolute",
      right: 20,
      top: 0,
      zIndex: 10,
    },
    textPrimary: {
      color: theme.text.primary,
    },
  }),
  { index: 1 }
);

function volumeBarColor(point) {
  if (point.open < point.close) return BOCHART.BUY;
  else return BOCHART.SELL;
}

export default function CandleChartBundle(props) {
  const classes = useStyles();
  const { data, rootRef } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const viewIP6 = useMediaQuery("(max-height:670px)");
  const viewIP5 = useMediaQuery("(max-height:570px)");
  const [showBB, setShowBB] = useState(true);
  const [showMA, setShowMA] = useState(true);
  const chartRef = useRef();

  var ohlc = [],
    volume = [],
    dataLength = data.length,
    i = 0;

  let currentValue = [
    { x: 0, y: data[dataLength - 1]?.close },
    {
      x: data[dataLength - 1]?.timestamp + 15000,
      y: data[dataLength - 1]?.close,
    },
  ];

  for (i; i < dataLength; i += 1) {
    ohlc.push({
      id: data[i].id,
      x: data[i].timestamp,
      date: data[i].timestamp,
      open: data[i].open,
      close: data[i].close,
      high: data[i].high,
      low: data[i].low,
      color: volumeBarColor(data[i]),
      lineColor: volumeBarColor(data[i]),
    });

    volume.push({
      id: data[i].id,
      x: data[i].timestamp, // the date
      y: data[i].volume, // the volume
      color: volumeBarColor(data[i]),
    });
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const options = {
    rangeSelector: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },

    // title: {
    //   useHTML: true,
    //   text: `<div><img src={${BtcLarge}} alt="logo" /><p>huhuh</p></div>`,
    // },

    chart: {
      style: {
        fontFamily: "'IBM Plex Sans'",
      },
      plotBackgroundColor: "transparent",
      // plotBackgroundImage: `${BackgroundChart}`,
      backgroundColor: "transparent",
      marginRight: matches ? 60 : 70,
      // eslint-disable-next-line no-restricted-globals
      height: rootRef.current?.clientHeight,

      //   height: !matches
      //     ? // eslint-disable-next-line no-restricted-globals
      //       (screen.height * 60) / 100
      //     : viewIP5
      //     ? // eslint-disable-next-line no-restricted-globals
      //       (screen.height * 25) / 100
      //     : viewIP6
      //     ? // eslint-disable-next-line no-restricted-globals
      //       (screen.height * 30) / 100 // eslint-disable-next-line no-restricted-globals
      //     : (screen.height * 40) / 100,
      zoomType: '""',
      pinchType: "x",
      panning: false,
      events: {
        load: function () {
          this.reflow();
        },
      },
      //   marginBottom: 0,
    },

    tooltip: {
      split: true,
      enabled: true,
      crosshairs: false,
      useHTML: true,
      backgroundColor: "transparent",
      borderColor: "none",
      borderWith: 0,
      shadow: false,
      followTouchMove: false,
      className: classes.tooltip,
      style: {
        color: "#fff",
      },
      positioner: function () {
        if (matches) {
          return {
            x: 0,
            y: 50,
          };
        }
        return {
          x: 50,
          y: 50,
        };
      },
      formatter: function () {
        return `<div style='display: flex; justify-content: flex-start; width: ${
          matches ? "250px" : "500px"
        }; flex-wrap: wrap'>
            <p style='font-size: 12px; margin: 0px 5px'>O: ${
              this.points[0]?.point?.open
            }</p>
            <p style='font-size: 12px; margin: 0px 5px'>C: ${
              this.points[0]?.point?.close
            }</p>
            <p style='font-size: 12px; margin: 0px 5px'>V: ${
              this.points[1]?.point?.y
            }</p>
            <p style='font-size: 12px; margin: 0px 5px'>H: ${
              this.points[0]?.point?.high
            }</p>
            <p style='font-size: 12px; margin: 0px 5px'>L: ${
              this.points[0]?.point?.low
            }</p>
            <p style='font-size: 12px; margin: 0px 5px'>Time: ${moment(
              this.points[1]?.point?.x
            ).format("HH:mm")}</p>
          </div>`;
      },
    },

    xAxis: [
      {
        lineColor: "#12161C",
        tickColor: "#12161C",
        labels: {
          formatter: function () {
            return moment(this.value * 1000).format("HH:mm");
          },
          enabled: false,
          style: {
            color: "#B2B8D2",
          },
          x: 0,
          y: 0,
        },
        lineWidth: 0,
        resize: {
          enabled: true,
        },
        visible: true,
        crosshair: {
          color: "#424242",
        },
        min: data[20]?.timestamp,
      },
    ],
    yAxis: [
      {
        labels: {
          align: "right",
          x: 50,
          style: {
            color: "#B2B8D2",
          },
          enabled: true,
        },
        top: "10%",
        height: "80%",
        lineWidth: 0,
        resize: {
          enabled: true,
        },
        visible: true,
        gridLineColor: "#424242",
        gridLineWidth: 0.3,
      },
      {
        labels: {
          align: "right",
          x: 25,
          style: {
            color: "#B2B8D2",
          },
          enabled: false,
        },
        visible: true,
        top: matches ? "95%" : "90%",
        height: matches ? "5%" : "10%",
        offset: 0,
        lineWidth: 0,

        gridLineColor: "#424242",
        gridLineWidth: 0,
      },
      {
        labels: {
          enabled: false,
        },
      },
    ],

    plotOptions: {
      candlestick: {},
      column: {},
      scatter: {
        dataLabels: {
          enabled: true,
          borderRadius: 2,
          overflow: "allow",
          crop: false,
          align: "right",
          x: 63,
          y: 15,
          backgroundColor: "#448aff",
          color: "#fff",
          useHTML: true,
          //   formatter: function () {
          //     return (
          //       <Typography variant="body2">{this.point.y.toFixed(2)}</Typography>
          //     );
          //   },
        },
        enableMouseTracking: false,
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            enabled: false,
          },
        },
      },
    },

    series: [
      {
        type: "candlestick",
        name: "bo",
        id: "bo",
        data: ohlc,
        maxPointWidth: 10,
        lastVisiblePrice: {
          enabled: true,
        },
      },
      {
        type: "column",
        name: "Volume",
        data: volume,
        yAxis: 1,
        maxPointWidth: 10,
      },
      {
        type: "scatter",
        data: currentValue,
        lineWidth: 0.7,
        marker: {
          enabled: false, // hide the markers (to show only the line)
        },
        showInLegend: false, // hide in legend
        enableMouseTracking: false, // prevent user from interacting with this line
        label: {
          enabled: false,
          onArea: false,
        },
        color: "#448aff",
        lastVisiblePrice: {
          enabled: true,
        },
      },
      {
        type: "sma",
        linkedTo: "bo",
        color: "#f44336",
        lineWidth: 1,
        marker: {
          enabled: false,
        },
        zIndex: 1,
        enableMouseTracking: false,
        params: {
          index: showMA ? 2 : -1,
          period: 5,
        },
        allowPointSelect: false,
      },
      {
        type: "sma",
        linkedTo: "bo",
        color: "#43a047",
        lineWidth: 1,
        marker: {
          enabled: false,
        },
        zIndex: 1,
        enableMouseTracking: false,
        params: {
          //   index: showMA ? 2 : -1,
          period: 10,
        },
        allowPointSelect: false,
      },
      {
        type: "bb",
        linkedTo: "bo",
        color: "#26a69a",
        lineWidth: 1,
        marker: {
          enabled: false,
        },
        params: {
          //   index: showBB ? 3 : -1,
          //   period: 15,
          //   standardDeviation: 3,
        },
      },
    ],
  };

  const handleHideShowMa = () => {
    let MA1 = chartRef?.current?.chart.series[3];
    let MA2 = chartRef?.current?.chart.series[4];
    if (MA1 && MA2) {
      if (showMA) {
        MA1.hide();
        MA2.hide();
      } else {
        MA1.show();
        MA2.show();
      }
    }
  };

  const handleHideShowBB = () => {
    let BB = chartRef?.current?.chart.series[5];
    if (BB) {
      if (showBB) {
        BB.hide();
      } else {
        BB.show();
      }
    }
  };

  const updateSize = () => {
    chartRef?.current.chart.setSize(
      rootRef.current?.clientWidth,
      rootRef.current?.clientHeight,
      true
    );
  };

  useEffect(() => {
    window.addEventListener("resize", updateSize);
  }, []);

  if (typeof Highcharts === "object") {
    HighchartsData(Highcharts);
    HighchartsIndicators(Highcharts);
    HighchartsBB(Highcharts);
    // HighchartsEMA(Highcharts);
  }

  useCandleStick(chartRef, data);

  const chart = useMemo(() => {
    return (
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"stockChart"}
        options={options}
        // oneToOne={true}
        ref={chartRef}
        // containerProps={{ style: { height: "calc(100vh - 270px)" } }}
        // containerProps={{ style: { height: "100%" } }}
      />
    );
  }, [options]);

  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          position: "absolute",

          justifyContent: "flex-start",
          top: 10,
          left: matchesSM ? 20 : 80,
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={BtcLarge}
            alt="logo"
            height="auto"
            width={matches ? 25 : 30}
            style={{ marginRight: 10 }}
          />
        </div>
        <Typography
          variant={matches ? "body1" : "h6"}
          classes={{ root: classes.textPrimary }}
        >
          BTC/USD
        </Typography>
      </div>
      <div className={classes.coverNote}>
        <div className={classes.coverBB}>
          <Typography variant="caption" classes={{ root: classes.textPrimary }}>
            BB
          </Typography>
          <div
            style={{
              width: 50,
              height: 2,
              background: "#26a69a",
              marginRight: 5,
              marginLeft: 5,
            }}
          ></div>
          <IconButton
            size="small"
            onClick={() => {
              setShowBB(!showBB);
              handleHideShowBB();
            }}
          >
            <img src={!showBB ? Eyeclose : Eyeopen} alt="eye" />
          </IconButton>
        </div>
        <div className={classes.coverBB}>
          <Typography variant="caption" classes={{ root: classes.textPrimary }}>
            MA
          </Typography>
          <div style={{ marginRight: 5, marginLeft: 5 }}>
            <div
              style={{
                width: 50,
                height: 2,
                background: "#f44336",
                marginBottom: 10,
              }}
            ></div>
            <div
              style={{
                width: 50,
                height: 2,
                background: "#43a047",
              }}
            ></div>
          </div>
          <IconButton
            size="small"
            onClick={() => {
              setShowMA(!showMA);
              handleHideShowMa();
              //   handleResetData();
            }}
          >
            <img src={!showMA ? Eyeclose : Eyeopen} alt="eye" />
          </IconButton>
        </div>
      </div>
      {chart}
    </div>
  );
}
