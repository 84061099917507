import { PATH } from "../const/paths";
import TranSaction from "../containers/Transaction/Transaction";
import Wallet from "../containers/Wallet/Wallet";
import Profile from "../containers/Profile/Profile";
import TradingBot from "../containers/TradingBot/TradingBot";
import BotV2 from "../containers/BotV2/BotV2";
import Agency from "../containers/Agency/Agency";
import AgencyTrade from "../containers/AgencyTrade/AgencyTrade";
import Dashboard from "../containers/Dashboard/DashBoard";
import Notification from "../containers/Notification/Notification";
import Lottery from "../containers/Lottery/Lottery";
import TradingView from "../pages/TradingView";
import TradingBotV2 from "../containers/TradingBotv2";

const routes = [
  {
    path: PATH.TRANSACTION,
    exact: true,
    component: () => <TranSaction />,
  },
  {
    path: PATH.WALLET,
    exact: true,
    component: () => <Wallet />,
  },
  {
    path: PATH.PROFILE,
    exact: true,
    component: () => <Profile />,
  },
  {
    path: PATH.TRADING_VIEW,
    exact: true,
    component: () => <TradingView />,
  },
  {
    path: PATH.TRADINGBOT,
    exact: true,
    component: () => <TradingBot />,
  },
  {
    path: PATH.TRADINGBOTV2,
    exact: true,
    component: () => <TradingBotV2 />,
  },
  // {
  //   path: PATH.BOTV2,
  //   exact: true,
  //   component: () => <BotV2 />,
  // },
  {
    path: PATH.AGENCY,
    exact: true,
    component: () => <Agency />,
  },
  {
    path: PATH.AGENCY_TRADE,
    exact: true,
    component: () => <AgencyTrade />,
  },
  {
    path: PATH.LOTTERY,
    exact: true,
    component: () => <Lottery />,
  },
  {
    path: PATH.DASHBOARD,
    exact: true,
    component: () => <Dashboard />,
  },
  {
    path: PATH.NOTIFICATION,
    exact: true,
    component: () => <Notification />,
  },
];

export default routes;
