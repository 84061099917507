import { makeStyles } from "@material-ui/core";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: "relative",
      overflow: "visible",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center bottom",
      [theme.breakpoints.down("xs")]: {},
    },
  }),
  { index: 1 }
);

function TradingRateChart(props) {
  const classes = useStyles(props);
  const { data } = props;
  const { t } = useTranslation();

  const options = {
    rangeSelector: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },

    chart: {
      plotBorderWidth: null,
      plotShadow: false,
      plotBackgroundColor: "transparent",
      backgroundColor: "transparent",
      type: "pie",
      height: "40%",
    },
    title: {
      text: null,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
          formatter: function () {
            return this.key + ": " + this.y + "%";
          },
        },
        showInLegend: true,
      },
    },
    series: [
      {
        name: "Composition",
        colorByPoint: true,
        innerSize: "80%",
        data: [
          {
            name: "Win",
            color: "#55ad82",
            y: (data.total_win / data.total_trade) * 100,
          },
          {
            name: "Lose",
            color: "#eb6a61",
            y: 100 - (data.total_win / data.total_trade) * 100,
          },
        ],
      },
    ],
  };

  return (
    <div className={classes.root}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}

TradingRateChart.propTypes = {};

export default TradingRateChart;
