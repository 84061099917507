import {
  Card,
  CardContent,
  CardHeader,
  createStyles,
  Drawer,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import TradingBotSuccess from "../../../assets/img/TradingBotSuccess.png";
import convertNumberToMoney from "../../../common/convertNumberToMoney";
import { PATH } from "../../../const/paths";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      rootDrawer: {
        backgroundColor: theme.body.backgroundColor,
        height: "100%",
      },
      rootCard: {
        backgroundColor: "rgba(255, 255, 255, 0.03)",
        height: "100%",
        boxShadow: "none",
      },
      rootCardHeader: {
        background: theme.color.background,
      },
      closeButton: {
        position: "absolute",
        right: 2,
        top: 2,
        color: theme.text.primary,
      },
      root: {
        margin: "10px auto",
        width: "65%",
        height: 606,
        backgroundColor: theme.color.background,
        [theme.breakpoints.down("xs")]: {
          margin: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "transparent",
        },
      },
      typographyTitle: {
        textAlign: "center",
        padding: 10,
        color: "#FFAA3A",
      },
      typographyCenter: {
        textAlign: "center",
        padding: 10,
        color: theme.text.primary,
        [theme.breakpoints.down("xs")]: {
          padding: "10px 0",
        },
      },
      typographySecondary: {
        color: theme.text.secondary,
        padding: 5,
      },
      typographyPrimary: {
        color: theme.text.primary,
        padding: 5,
      },
      rootImage: {
        width: 264,
        height: 265,
        margin: "auto",
        position: "relative",
        backgroundImage: `url(${TradingBotSuccess})`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      rootGridItemLeft: {
        paddingLeft: 120,
        [theme.breakpoints.down("xs")]: {
          paddingLeft: 0,
        },
      },
      gridFooter: {
        display: "flex",
        justifyContent: "center",
      },
    }),
  { index: 1 }
);

function PaperBuyBotSuccess(props) {
  const classes = useStyles(props);
  const { botSelected, buyBotSuccess, handleClose, botBought } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const { t } = useTranslation();

  const renderContent = () => {
    return (
      <div className={classes.root}>
        <Grid container>
          <Grid sm={2}></Grid>
          <Grid sm={8} xs={12}>
            {/* <div className={classes.rootImage}>
              <img src={`${botSelected?.icon}`} alt={botSelected?.name}></img>
            </div> */}

            <Grid container>
              <Grid item sm={12}>
                <Typography variant="h5" className={classes.typographyTitle}>
                  {t("You have successfully purchased a Trading bot")}!
                </Typography>
              </Grid>
              <Grid container>
                <Grid
                  item
                  sm={6}
                  xs={6}
                  classes={{ root: classes.rootGridItemLeft }}
                >
                  <Typography
                    variant="body2"
                    className={classes.typographySecondary}
                  >
                    {t("Bot type")}:
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={6}>
                  <Typography
                    variant="body2"
                    className={classes.typographyPrimary}
                  >
                    {botSelected.name?.toUpperCase()} BOT
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  sm={6}
                  xs={6}
                  classes={{ root: classes.rootGridItemLeft }}
                >
                  <Typography
                    variant="body2"
                    className={classes.typographySecondary}
                  >
                    {t("Profit")}:
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={6}>
                  <Typography
                    variant="body2"
                    className={classes.typographyPrimary}
                  >
                    {Math.round(
                      parseFloat(botBought?.package_data?.min_profit) * 100
                    )}
                    % ~{" "}
                    {Math.round(
                      parseFloat(botBought?.package_data?.max_profit) * 100
                    )}
                    %
                  </Typography>
                </Grid>
              </Grid>
              <Grid item sm={12} xs={12}>
                {/* <Typography
                  variant="body2"
                  className={classes.typographyCenter}
                >
                  {t("get bonus ala", {
                    bonus: convertNumberToMoney(parseFloat(botSelected?.bonus)),
                  })}{" "}
                  {t("validate amount", {
                    min: 0,
                    max: 10000,
                  })}
                  <Link to={PATH.WALLET}>here.</Link>
                </Typography> */}
                {/* <Typography
                  variant="body2"
                  className={classes.typographyCenter}
                >
                  {jsUcfirst(
                    t(
                      "Trading bot will be active and execute the order after 1 hour of successful purchase."
                    )
                  )}
                </Typography> */}
              </Grid>
              {/* <Grid item sm={12} xs={12} classes={{ root: classes.gridFooter }}>
                <Typography
                  variant="body2"
                  className={classes.typographySecondary}
                >
                  {t("Timeout for activation")}:
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.typographyPrimary}
                >
                  00:59:59
                </Typography>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid sm={2}></Grid>
        </Grid>
      </div>
    );
  };

  return (
    <>
      {matches ? (
        <Drawer
          anchor={"bottom"}
          open={buyBotSuccess}
          onClose={handleClose}
          classes={{ paper: classes.rootDrawer }}
        >
          <Card classes={{ root: classes.rootCard }}>
            <CardHeader
              title={
                <Typography
                  classes={{ root: classes.typographyPrimary }}
                  variant="h6"
                >
                  {t("Trading bot")}
                </Typography>
              }
              action={
                <IconButton
                  classes={{ root: classes.closeButton }}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>
              }
              classes={{ root: classes.cardHeader }}
            />
            <CardContent>{renderContent()}</CardContent>
          </Card>
        </Drawer>
      ) : (
        <>{buyBotSuccess && renderContent()}</>
      )}
    </>
  );
}

PaperBuyBotSuccess.propTypes = {
  botType: PropTypes.string,
  buyBotSuccess: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default PaperBuyBotSuccess;
