import {
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Slide,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
// import BGDialogBingo from "../../../assets/img/BGDialogBingo.png";
import BingoItem from "../../../assets/img/BingoItem.png";
// import CloseIconBingo from "../../../assets/img/CloseIconBingo.png";
import CloseIcon from "@material-ui/icons/Close";
import { forwardRef } from "react";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      paperRoot: {
        backgroundColor: "#191D23",
        boxShadow: "none",
        maxWidth: 700,
        // width: 700,
        // [theme.breakpoints.down("xs")]: {
        //   width: 400,
        // },
      },
      dialogTitle: {
        padding: 20,
      },
      title: {
        width: 235,
        height: 50,
        margin: "auto",
        border: `1px solid #FFFFFF`,
        borderRadius: theme.radius.secondary,

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      textBold: {
        fontWeight: 700,
        color: theme.text.primary,
        fontFamily: "'Gemunu Libre', sans-serif",
      },
      closeButton: {
        position: "absolute",
        right: 5,
        top: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",
        [theme.breakpoints.down("xs")]: {
          right: 0,
          top: 10,
          width: 60,
        },
      },
      dialogContent: {
        backgroundColor: "transparent",
        borderRadius: 10,
        textAlign: "center",
        padding: "0px 10px",
      },
      coverContent: {
        // backgroundImage: `url(${BGDialogBingo})`,
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "100% 100%",
      },
    }),
  { index: 1 }
);

export default function DialogAction(props) {
  const classes = useStyles(props);
  const { open, handleClose, action, children } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  const arrActions = [
    {
      type: "buyticket",
      text: t("Buy ticket"),
      image: BingoItem,
    },
    {
      type: "history",
      text: t("History"),
      image: BingoItem,
    },
    {
      type: "guide",
      text: t("Guide"),
      image: BingoItem,
    },
  ];

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.paperRoot }}
      fullScreen={
        !!matches && arrActions?.map((action) => action.type)?.includes(action)
      }
      maxWidth={false}
      TransitionComponent={Transition}
    >
      <DialogTitle classes={{ root: classes.dialogTitle }}>
        {arrActions.filter((item) => item.type === action)[0] && (
          <div className={classes.title}>
            <Typography
              variant="h6"
              classes={{ root: classes.textBold }}
              style={{ paddingLeft: 10 }}
            >
              {arrActions.filter((item) => item.type === action)[0]?.text}
            </Typography>
          </div>
        )}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          {/* <img
            src={CloseIconBingo}
            alt="CloseIconBingo"
            width={"100%"}
            height={"auto"}
          ></img> */}
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <div className={classes.coverContent}>{children}</div>
      </DialogContent>
    </Dialog>
  );
}
