import {
  Button,
  Card,
  CardContent,
  CardHeader,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Drawer,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import convertNumberToMoney from "../../common/convertNumberToMoney";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      rootDrawer: {
        backgroundColor: theme.color.background,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
      },
      rootCard: { backgroundColor: theme.color.background, boxShadow: "none" },
      dialogHeader: {
        color: "#fff",
        padding: "10px 20px",
      },
      closeButton: {
        position: "absolute",
        right: 2,
        top: 0,
        color: theme.text.primary,
      },
      cardHeader: {
        borderBottom: `1px solid ${theme.color.border}`,
        marginBottom: 5,
      },
      dialogContent: {
        padding: "10px 20px 30px 20px",
      },
      dividers: { borderTopColor: "#272C3A" },
      textField: {
        marginBottom: 15,
        backgroundColor: "#2A2D35",
      },
      cssOutlinedInput: {
        "&$cssFocused $notchedOutline": {
          borderColor: `${theme.text.primary} !important`,
        },
      },
      notchedOutline: {
        borderWidth: "1px",
        borderColor: "#2A2D35 !important",
        borderRadius: "2px !important",
      },
      multilineColor: {
        color: theme.text.primary,
        fontSize: 13,
      },
      input: {
        "&::placeholder": {
          opacity: 0.2,
        },
      },
      typographySecondary: {
        color: theme.text.secondary,
      },
      typographyPrimary: {
        color: theme.text.primary,
      },
      typographyName: {
        color: theme.text.primary,
        fontWeight: 600,
        fontSize: 18,
        textAlign: "center",
      },
      typographyValue: { paddingLeft: 20 },
      buttonSubmit: {
        width: "100%",
        color: theme.text.primary,
        backgroundColor: theme.color.primary,
        textTransform: "none",
        borderRadius: 2,
        marginTop: 10,
        "&:hover": {
          backgroundColor: theme.color.primaryHover,
        },
      },
      paper: {
        width: 456,
        // minHeight: 573,
        // maxHeight: 700,
        backgroundColor: "#191D23",
      },
      imageBot: {
        width: 146,
        height: 146,
      },
      dialogActions: {
        padding: "0 15px 25px 15px",
      },
      coverPasteCode: { minWidth: 90, cursor: "pointer", textAlign: "right" },
      endAdornment: {
        color: theme.text.link,
      },
      rootGridItem: { margin: "5px 0" },
      typographyBody2TopPrimary: {
        margin: "0 0 10px 0",
        color: theme.text.primary,
      },
      typographyBody2BottomPrimary: {
        margin: "10px 0",
        color: theme.text.primary,
      },
      iconButton: { color: theme.text.primary },
    }),
  { index: 1 }
);

const initialValues = {
  otp_token: "",
};

export default function DialogConfirmTransfer(props) {
  const classes = useStyles(props);
  const {
    open,
    handleClose,
    handleConfirmTransfer,
    withdrawValue,
    withdrawFee,
    walletSelected,
    network,
    dataTransfer,
    loading,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  const validationSchema = Yup.object().shape({
    otp_token: Yup.string().required(t("Not be empty")),
  });

  const onSubmit = (values) => {
    handleClose();
    handleConfirmTransfer(values);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => onSubmit(values),
  });

  const handlePasteCode = () => {
    navigator.clipboard
      .readText()
      .then((text) => {
        formik.setFieldValue("otp_token", text);
      })
      .catch();
  };

  const renderContent = () => {
    return (
      <>
        <Typography
          variant="body2"
          className={classes.typographyBody2TopPrimary}
        >
          {t("You have initiated the money order")}:
        </Typography>
        <Grid container>
          <Grid item xs={4} classes={{ root: classes.rootGridItem }}>
            <Typography
              variant="caption"
              className={classes.typographySecondary}
            >
              {t("Network")}:
            </Typography>
          </Grid>
          <Grid item xs={8} classes={{ root: classes.rootGridItem }}>
            <Typography variant="caption" className={classes.typographyPrimary}>
              {network}
            </Typography>
          </Grid>
          <Grid item xs={4} classes={{ root: classes.rootGridItem }}>
            <Typography
              variant="caption"
              className={classes.typographySecondary}
            >
              {t("Receiving address")}:
            </Typography>
          </Grid>
          <Grid item xs={8} classes={{ root: classes.rootGridItem }}>
            <Typography variant="caption" className={classes.typographyPrimary}>
              {dataTransfer?.address || ""}
            </Typography>
          </Grid>
          <Grid item xs={4} classes={{ root: classes.rootGridItem }}>
            <Typography
              variant="caption"
              className={classes.typographySecondary}
            >
              {t("Value")}:
            </Typography>
          </Grid>
          <Grid item xs={8} classes={{ root: classes.rootGridItem }}>
            <Typography variant="caption" className={classes.typographyPrimary}>
              {convertNumberToMoney(withdrawValue)} {walletSelected?.c?.symbol}
            </Typography>
          </Grid>
          <Grid item xs={4} classes={{ root: classes.rootGridItem }}>
            <Typography
              variant="caption"
              className={classes.typographySecondary}
            >
              {t("Transaction fees")}:
            </Typography>
          </Grid>
          <Grid item xs={8} classes={{ root: classes.rootGridItem }}>
            <Typography variant="caption" className={classes.typographyPrimary}>
              {convertNumberToMoney(withdrawFee)} {walletSelected?.c?.symbol}
            </Typography>
          </Grid>
          <Grid item xs={4} classes={{ root: classes.rootGridItem }}>
            <Typography
              variant="caption"
              className={classes.typographySecondary}
            >
              {t("You will receive")}:
            </Typography>
          </Grid>
          <Grid item xs={8} classes={{ root: classes.rootGridItem }}>
            <Typography variant="caption" className={classes.typographyPrimary}>
              {convertNumberToMoney(withdrawValue - withdrawFee)}{" "}
              {walletSelected?.c?.symbol}
            </Typography>
          </Grid>
        </Grid>
        <div>
          <Typography
            variant="body2"
            className={classes.typographyBody2BottomPrimary}
          >
            {t("Please enter the 2FA code to confirm the transaction")}
          </Typography>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div>
            <Typography variant="body2" className={classes.typographySecondary}>
              {t("2FA Code")}
            </Typography>
            <TextField
              className={classes.textField}
              fullWidth
              variant="outlined"
              id="otp_token"
              name="otp_token"
              placeholder={t("Enter the 2FA code taken from the application")}
              InputLabelProps={{ shrink: false }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  notchedOutline: classes.notchedOutline,
                  input: classes.input,
                },
                className: classes.multilineColor,
                endAdornment: (
                  <div
                    onClick={handlePasteCode}
                    className={classes.coverPasteCode}
                  >
                    <Typography
                      variant="caption"
                      className={classes.endAdornment}
                    >
                      {t("Paste the code")}
                    </Typography>
                  </div>
                ),
              }}
              size="small"
              onChange={formik.handleChange}
              //   onBlur={formik.handleBlur}
              value={formik.values.otp_token}
              error={
                formik.touched.otp_token && Boolean(formik.errors.otp_token)
              }
              helperText={formik.touched.otp_token && formik.errors.otp_token}
            />
          </div>
          <Button
            className={classes.buttonSubmit}
            type="submit"
            disabled={loading}
          >
            {/* TODO add loading */}
            {loading ? (
              <CircularProgress style={{ color: "#fff" }} size={25} />
            ) : (
              t("Confirm")
            )}
          </Button>
        </form>
      </>
    );
  };

  return (
    <>
      {matches ? (
        <Drawer
          anchor={"bottom"}
          open={open}
          onClose={handleClose}
          classes={{ paper: classes.rootDrawer }}
        >
          <Card classes={{ root: classes.rootCard }}>
            <CardHeader
              title={
                <Typography
                  classes={{ root: classes.typographyPrimary }}
                  variant="h6"
                >
                  {t("Transaction volume")}
                </Typography>
              }
              action={
                <IconButton
                  classes={{ root: classes.iconButton }}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>
              }
              classes={{ root: classes.cardHeader }}
            />
            <CardContent>{renderContent()}</CardContent>
          </Card>
        </Drawer>
      ) : (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={false}
          classes={{ paper: classes.paper }}
        >
          <DialogTitle id="alert-dialog-title" className={classes.dialogHeader}>
            <Typography variant="h6">{t("Confirm transfers")}</Typography>
            <IconButton className={classes.closeButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            className={classes.dialogContent}
            dividers={true}
            classes={{ dividers: classes.dividers }}
          >
            {renderContent()}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

DialogConfirmTransfer.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};
