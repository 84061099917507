import {
  Button,
  createStyles,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import BackgroundTransaction from "../../../assets/img/BackgroundTransaction.png";
import { PATH } from "../../../const/paths";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        width: "100vw",
        minHeight: 700,
        background: "#fff",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        padding: "40px 0 70px 0",
      },
      button: {
        padding: "10px 25px",
        textTransform: "none",
        fontSize: 18,
        backgroundColor: "#FFAA3A",
        borderRadius: 2,
        margin: "30px 0",
        minWidth: 200,
      },
      rootBottom: {
        maxWidth: 470,
        textAlign: "center",
      },
      title: {
        fontSize: 18,
      },
      content: {
        fontSize: 16,
      },
      image: {
        width: "100%",
        [theme.breakpoints.down("xs")]: {
          width: "90%",
          marginLeft: "5%",
        },
      },
    }),
  { index: 1 }
);

export default function PaperFifthFloor(props) {
  const classes = useStyles(props);
  const { handleLink } = props;
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <div>
        <img
          src={`${BackgroundTransaction}`}
          alt="BackgroundTransaction"
          className={classes.image}
        ></img>
      </div>
      <Button
        className={classes.button}
        variant="outlined"
        onClick={() => {
          handleLink(PATH.LOGIN);
        }}
      >
        {t("Start trading")}
      </Button>
      <div className={classes.rootBottom}>
        <Typography variant="body1" className={classes.title}>
          {/* {t("Start trading with unlimited demo accounts")} */}
          {t("Success together")}
        </Typography>
        <Typography variant="body1" className={classes.content}>
          {/* {t(
            "You can always improve your trading skills on a demo account and make money after perfecting your strategy"
          )} */}
          {t(
            "Become our partner you will get so many benefit with our 6 level commission system only for 200$"
          )}
          .
        </Typography>
      </div>
    </div>
  );
}
