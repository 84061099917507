import {
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@material-ui/core";
import { Pagination, PaginationItem } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import convertNumberToCurrency from "../../common/convertNumberToCurrency";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: { margin: "20px 0" },
      table: {
        // border: `1px solid ${theme.color.border}`,
        border: "none",
        borderCollapse: "separate",
      },
      tableCellHeaderFixed: {
        backgroundColor: "#181A20",
        border: `1px solid ${theme.color.border}`,
        // borderLeft: `1px solid ${theme.color.border}`,
        // borderRight: `1px solid ${theme.color.border}`,
        // borderTop: `1px solid ${theme.color.border}`,
        // borderBottom: "none",
        color: theme.text.secondary,
        position: "sticky",
        left: 0,
        // boxShadow: "-1px -1px 5px grey",
        zIndex: "3",
      },
      tableCellHeader: {
        backgroundColor: "#181A20",
        border: `1px solid ${theme.color.border}`,
        color: theme.text.secondary,
      },
      labelHeader: { color: `${theme.text.secondary} !important` },
      tableCellBodyFixed: {
        backgroundColor: "#12161C",
        border: `1px solid ${theme.color.border}`,
        color: theme.text.link,
        position: "sticky",
        left: 0,
        // borderLeft: `1px solid ${theme.color.border}`,
        // borderRight: `1px solid ${theme.color.border}`,
        // borderTop: `1px solid ${theme.color.border}`,
        // boxShadow: "-1px -1px 5px grey",
        zIndex: "3",
      },
      tableCellBody: {
        border: `1px solid ${theme.color.border}`,
        color: "#fff",
      },
      rootPagination: {
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: 40,
      },
      pagination: {
        color: theme.text.primary,
      },
      rootPaginationItem: {
        border: `1px solid ${theme.text.primary}`,
        color: theme.text.primary,
        borderRadius: 2,
      },
      ellipsisPagination: { border: "none" },
      pageSelected: {
        backgroundColor: "hsla(0, 0%, 100%, 0.1) !important",
        border: `1px solid ${theme.color.border}`,
      },
      coverLoading: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
          height: 80,
        },
      },
    }),
  { index: 1 }
);

function createData(
  username,
  level,
  network_level,
  total_volume,
  total_commission
) {
  return { username, level, network_level, total_volume, total_commission };
}

function TableBoUnderRef(props) {
  const classes = useStyles(props);
  const {
    dataTable,
    pagination,
    handleChangePage,
    handleResetPageBoUnderRef,
    loading,
    handleClickUser,
    handleSort,
  } = props;
  const [rows, setRows] = useState([]);
  const [orderBy, setOrderBy] = useState();
  const [order, setOrder] = useState("asc");
  const { t } = useTranslation();

  const columns = [
    { id: "username", label: t("Nickname"), minWidth: 100, fixed: true },
    { id: "level", label: t("Agency level"), minWidth: 130 },
    { id: "network_level", label: t("Network level"), minWidth: 130 },
    {
      id: "total_volume",
      label: t("Total trade volume"),
      minWidth: 120,
      sort: true,
    },
    {
      id: "total_commission",
      label: t("Total commission"),
      minWidth: 130,
      sort: true,
    },
  ];

  useEffect(() => {
    if (dataTable) {
      let rows = dataTable.results?.map((data) => {
        let username = (
          <Typography
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleClickUser(data);
              //   handleClickUser(data.id);
            }}
          >
            {data.user?.username}
          </Typography>
        );
        let level = `Lv${data.bo_level}`;
        let network_level = `F${data.network_level}`;
        let total_volume = (
          <Typography variant="body2" style={{ color: "#23CA7A" }}>
            {convertNumberToCurrency(data.total_volume)}
          </Typography>
        );
        let total_commission = (
          <Typography variant="body2" style={{ color: "#FFA031" }}>
            {convertNumberToCurrency(data.total_commission || 0)}
          </Typography>
        );
        return createData(
          username,
          level,
          network_level,
          total_volume,
          total_commission
        );
      });
      setRows(rows);
    }
  }, [dataTable]);

  useEffect(() => {
    return () => {
      handleResetPageBoUnderRef();
    };
  }, []);

  return (
    <>
      <TableContainer className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className={
                    column.fixed
                      ? classes.tableCellHeaderFixed
                      : classes.tableCellHeader
                  }
                  sortDirection={orderBy === column.id ? order : false}
                >
                  {column.sort ? (
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : "asc"}
                      classes={{
                        root: classes.labelHeader,
                        icon: classes.labelHeader,
                      }}
                      onClick={() => {
                        setOrderBy(column.id);
                        if (order === "asc") {
                          setOrder("desc");
                          handleSort(column.id, "desc");
                        } else {
                          setOrder("asc");
                          handleSort(column.id, "asc");
                        }
                      }}
                    >
                      {column.label}
                    </TableSortLabel>
                  ) : (
                    column.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow tabIndex={-1}>
                <TableCell colSpan={5} className={classes.tableCellBody}>
                  {/* TODO add loading */}
                  <div className={classes.coverLoading}>
                    <CircularProgress style={{ color: "#fff" }} size={25} />
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {rows.length === 0 ? (
                  <TableRow tabIndex={-1}>
                    <TableCell colSpan={5} className={classes.tableCellBody}>
                      <Typography variant="body2">{`${t(
                        "No data"
                      )}`}</Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  rows.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              className={
                                column.fixed
                                  ? classes.tableCellBodyFixed
                                  : classes.tableCellBody
                              }
                            >
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {dataTable?.count > pagination.page_size && (
        <div className={classes.rootPagination}>
          <Pagination
            count={Math.ceil(dataTable?.count / pagination.page_size || 0)}
            page={pagination.page}
            onChange={(_, value) => {
              handleChangePage(value);
            }}
            variant="outlined"
            shape="rounded"
            classes={{ root: classes.pagination }}
            renderItem={(item) => (
              <PaginationItem
                classes={{
                  root: classes.rootPaginationItem,
                  ellipsis: classes.ellipsisPagination,
                  selected: classes.pageSelected,
                }}
                {...item}
                variant="outlined"
              />
            )}
          />
        </div>
      )}
    </>
  );
}

TableBoUnderRef.propTypes = {};

export default TableBoUnderRef;
