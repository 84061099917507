import {
  createStyles,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import moment from "moment";
import { useTranslation } from "react-i18next";
import convertNumberToCurrency from "../../../common/convertNumberToCurrency";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        width: "100%",
        backgroundColor: theme.color.background,
        margin: "10px 0 0 10px",
        padding: "40px 220px 40px 180px",
        [theme.breakpoints.down("xs")]: {
          padding: "15px 0 15px 15px",
          margin: "10px 0 0 5px",
          width: "97%",
        },
      },
      typographySecondary: {
        color: theme.text.secondary,
      },
      typographyPrimary: {
        color: theme.text.primary,
      },
    }),
  { index: 1 }
);

function PaperGeneralDataBotV2(props) {
  const classes = useStyles(props);
  const { botV2Info, insurance } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <>
      {/* {matches && (
        <div className={classes.root}>
          {" "}
          <Typography variant="body2" className={classes.typographySecondary}>
            {t("Insurance fund")}
          </Typography>
          <Typography variant="h6" className={classes.typographyPrimary}>
            {convertNumberToCurrency(parseFloat(insurance)) || 0}
          </Typography>
        </div>
      )} */}
      <div className={classes.root}>
        <Grid container justify="center">
          {/* <Grid item xs={12} sm={12} md={4}>
          <Typography variant="body2" className={classes.typographySecondary}>
            {t("Total profit")}
          </Typography>
          <Typography variant="h6" className={classes.typographyPrimary}>
            $10,284.88
          </Typography>
        </Grid> */}
          <Grid item xs={12} sm={12} md={4}>
            <Typography variant="body2" className={classes.typographySecondary}>
              {t("Profit index")}
            </Typography>
            <Typography variant="h6" className={classes.typographyPrimary}>
              10 - 12% / {t("month")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Typography variant="body2" className={classes.typographySecondary}>
              {t("Profit")}
            </Typography>
            <Typography variant="h6" className={classes.typographyPrimary}>
              {convertNumberToCurrency(parseFloat(botV2Info?.claimed_profit)) ||
                0}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Typography variant="body2" className={classes.typographySecondary}>
              {t("Total running time")}
            </Typography>
            <Typography variant="h6" className={classes.typographyPrimary}>
              {botV2Info?.running_days} {t("Days")}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default PaperGeneralDataBotV2;
