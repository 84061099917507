import { createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import convertNumberToCurrency from "../../../common/convertNumberToCurrency";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        width: "100%",
        backgroundColor: theme.color.background,
        margin: "10px 0 0 10px",
        padding: "40px 220px 40px 180px",
        [theme.breakpoints.down("xs")]: {
          padding: 10,
          width: "85%",
          margin: "20px auto",
        },
      },
      typographySecondary: {
        color: theme.text.secondary,
      },
      typographyPrimary: {
        color: theme.text.primary,
      },
    }),
  { index: 1 }
);

function PaperGeneralDataAgency(props) {
  const classes = useStyles(props);
  const { summaryRef } = props;
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Grid container justify="center">
        <Grid item xs={6} sm={6} md={3}>
          <Typography variant="body2" className={classes.typographySecondary}>
            {t("Total commission")}
          </Typography>
          <Typography variant="h6" className={classes.typographyPrimary}>
            {summaryRef?.total_commissions === "none"
              ? convertNumberToCurrency(0)
              : convertNumberToCurrency(
                  parseFloat(summaryRef?.total_commissions) || 0
                )}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Typography variant="body2" className={classes.typographySecondary}>
            {t("Lower grade numbers")}
          </Typography>
          <Typography variant="h6" className={classes.typographyPrimary}>
            {summaryRef?.total_ref}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="body2" className={classes.typographySecondary}>
            {t("Level")}
          </Typography>
          <Typography variant="h6" className={classes.typographyPrimary}>
            {summaryRef?.level}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="body2" className={classes.typographySecondary}>
            {t("Network investment")}
          </Typography>
          <Typography variant="h6" className={classes.typographyPrimary}>
            {convertNumberToCurrency(
              parseFloat(summaryRef?.total_ref_investment) || 0
            )}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

PaperGeneralDataAgency.propTypes = {};

export default PaperGeneralDataAgency;
