import {
  Button,
  Card,
  CardContent,
  CardHeader,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Drawer,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import convertNumberToMoney from "../../common/convertNumberToMoney";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      rootDrawer: {
        backgroundColor: theme.color.background,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
      },
      rootCard: { backgroundColor: theme.color.background, boxShadow: "none" },
      dialogHeader: {
        color: "#fff",
        padding: "10px 20px",
      },
      closeButton: {
        position: "absolute",
        right: 2,
        top: 0,
        color: theme.text.primary,
      },
      cardHeader: {
        borderBottom: `1px solid ${theme.color.border}`,
        marginBottom: 5,
      },
      dialogContent: {
        padding: "10px 20px 30px 20px",
      },
      dividers: { borderTopColor: "#272C3A" },
      typographySecondary: {
        color: theme.text.secondary,
      },
      typographyPrimary: {
        color: theme.text.primary,
      },
      buttonSubmit: {
        width: "100%",
        color: theme.text.primary,
        backgroundColor: theme.color.primary,
        textTransform: "none",
        borderRadius: 2,
        marginTop: 10,
      },
      paperDialog: {
        width: 456,
        // minHeight: 573,
        // maxHeight: 700,
        backgroundColor: "#191D23",
        boxShadow: "none ",
      },
      rootGridItem: { margin: "5px 0" },
      typographyBody2TopPrimary: {
        margin: "0 0 10px 0",
        color: theme.text.primary,
      },
      iconButton: { color: theme.text.primary },
    }),
  { index: 1 }
);

const initialValues = {
  otp_token: "",
};

export default function DialogConfirmUpgradeBotV2(props) {
  const classes = useStyles(props);
  const {
    open,
    handleClose,
    handleConfirmUpgrade,
    amount,
    loadingConfirm,
    action,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  const validationSchema = Yup.object().shape({
    // otp_token: Yup.string().required(t("Not be empty")),
  });

  const onSubmit = (values) => {
    handleConfirmUpgrade(values);
    handleClose();
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => onSubmit(values),
  });

  const renderContent = () => {
    return (
      <>
        <Typography
          variant="body2"
          className={classes.typographyBody2TopPrimary}
        >
          {t("You have initiated an order to raise capital")}:
        </Typography>
        <Grid container>
          <Grid item xs={4} classes={{ root: classes.rootGridItem }}>
            <Typography variant="body1" className={classes.typographySecondary}>
              {t("Amount")}:
            </Typography>
          </Grid>
          <Grid item xs={8} classes={{ root: classes.rootGridItem }}>
            <Typography variant="body1" className={classes.typographyPrimary}>
              {convertNumberToMoney(
                action !== "deposit" ? amount * 0.7 : amount
              )}{" "}
              {action === "deposit" ? "USDT" : "ALA"}
            </Typography>
          </Grid>
        </Grid>

        <form onSubmit={formik.handleSubmit}>
          <Button
            className={classes.buttonSubmit}
            type="submit"
            disabled={loadingConfirm}
          >
            {loadingConfirm ? (
              <div className={classes.coverLoading}>
                <CircularProgress style={{ color: "#fff" }} size={20} />
              </div>
            ) : (
              t("Confirm")
            )}
          </Button>
        </form>
      </>
    );
  };

  return (
    <>
      {matches ? (
        <Drawer
          anchor={"bottom"}
          open={open}
          onClose={handleClose}
          classes={{ paper: classes.rootDrawer }}
        >
          <Card classes={{ root: classes.rootCard }}>
            <CardHeader
              title={
                <Typography
                  classes={{ root: classes.typographyPrimary }}
                  variant="h6"
                >
                  {action === "deposit"
                    ? t("Confirm Deposit")
                    : t("Confirm Withdraw")}
                </Typography>
              }
              action={
                <IconButton
                  classes={{ root: classes.iconButton }}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>
              }
              classes={{ root: classes.cardHeader }}
            />
            <CardContent>{renderContent()}</CardContent>
          </Card>
        </Drawer>
      ) : (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={false}
          classes={{ paper: classes.paperDialog }}
        >
          <DialogTitle id="alert-dialog-title" className={classes.dialogHeader}>
            <Typography variant="h6">
              {" "}
              {action === "deposit"
                ? t("Confirm Deposit")
                : t("Confirm Withdraw")}
            </Typography>
            <IconButton className={classes.closeButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            className={classes.dialogContent}
            dividers={true}
            classes={{ dividers: classes.dividers }}
          >
            {renderContent()}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
