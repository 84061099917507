import {
  Button,
  createStyles,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import moment from "moment";
import { useTranslation } from "react-i18next";
import convertNumberToCurrency from "../../common/convertNumberToCurrency";
import { USER_PACKAGE } from "../../const/common";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        marginLeft: theme.marginLayout,
        backgroundColor: theme.color.background,
        padding: 15,
        position: "relative",
        // height: 72,
        // flexWrap: "wrap",
        [theme.breakpoints.down("xs")]: {
          flexDirection: "column",
          alignItems: "flex-start",
          marginRight: theme.marginLayout,
        },
      },
      coverValueHeader: {
        margin: "0 30px",
        [theme.breakpoints.down("xs")]: {
          margin: "0",
        },
      },
      button: {
        textTransform: "none",
        borderColor: "hsla(0, 0%, 100%, 0.05)",
        backgroundColor: "hsla(0, 0%, 100%, 0.05)",
        color: theme.text.primary,
        margin: "0 15px",
        padding: "0 10px",
        borderRadius: 2,
        [theme.breakpoints.down("xs")]: {
          flexDirection: "column",
          alignItems: "flex-start",
          margin: "10px 0",
        },
      },
      typographyPrimary: {
        color: theme.text.primary,
        paddingLeft: 5,
      },
      typographySecondary: {
        color: theme.text.secondary,
      },
      buttonIncrease: {
        borderColor: "#FFA031",
        backgroundColor: "#FFA031",
        textTransform: "none",
        color: theme.text.primary,
        margin: "0 5px",
        padding: "0px 10px",
        fontWeight: 700,
        borderRadius: 2,
        [theme.breakpoints.down("xs")]: {
          flexDirection: "column",
          alignItems: "flex-start",
          margin: "10px 10px 0 0",
        },
      },
      buttonWithdraw: {
        borderColor: theme.color.primary,
        backgroundColor: theme.color.primary,
        textTransform: "none",
        color: theme.text.primary,
        margin: "0 5px",
        padding: "0px 10px",
        fontWeight: 700,
        borderRadius: 2,
        [theme.breakpoints.down("xs")]: {
          flexDirection: "column",
          alignItems: "flex-start",
          margin: "10px 0",
        },
      },
    }),
  { index: 1 }
);

export default function HeaderBotV2Statistic(props) {
  const classes = useStyles(props);
  const { handleActionBot, botV2Info, insurance } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <div className={classes.root}>
      <Typography variant="h5">{t("Bot V2")}</Typography>
      <div className={classes.coverValueHeader}>
        <div>
          <Typography variant="caption" className={classes.typographySecondary}>
            {t("Capital value")}:
          </Typography>
          <Typography variant="caption" className={classes.typographyPrimary}>
            {convertNumberToCurrency(parseFloat(botV2Info?.amount))} ALA
          </Typography>
        </div>
        <div>
          <Typography variant="caption" className={classes.typographySecondary}>
            {t("Estimated value")}:
          </Typography>
          <Typography variant="caption" className={classes.typographyPrimary}>
            {convertNumberToCurrency(botV2Info?.usdt_value)} USDT
          </Typography>
        </div>
        {/* <div>
          <Typography variant="caption" className={classes.typographySecondary}>
            {t("Time remaining")}:
          </Typography>
          <Typography variant="caption" className={classes.typographyPrimary}>
            {Math.floor(
              moment
                .duration(
                  new Date(botV2Info?.results[0]?.expired_at).valueOf() -
                    new Date().valueOf()
                )
                .asDays()
            ) > 0
              ? Math.floor(
                  moment
                    .duration(
                      new Date(botV2Info?.results[0]?.expired_at).valueOf() -
                        new Date().valueOf()
                    )
                    .asDays()
                )
              : 0}{" "}
            {t("Days")}
          </Typography>
        </div> */}
      </div>

      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <Button
          className={classes.buttonIncrease}
          variant="outlined"
          onClick={() => {
            handleActionBot("deposit");
          }}
        >
          {t("Deposit")}
        </Button>
        <Button
          className={classes.buttonWithdraw}
          variant="outlined"
          onClick={() => {
            handleActionBot("withdraw");
          }}
        >
          {t("Withdraw")}
        </Button>
      </div>

      {/* {!matches && (
        <div
          style={{
            position: "absolute",
            right: 220,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography variant="body1" className={classes.typographySecondary}>
            {t("Insurance fund")}:
          </Typography>
          <Typography variant="h6" className={classes.typographyPrimary}>
            {convertNumberToCurrency(parseFloat(insurance)) || 0}
          </Typography>
        </div>
      )} */}
    </div>
  );
}
