import {
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import React from "react";
import convertNumberToUSDT from "../../common/convertNumberToUSDT";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: { margin: "30px 0", display: "flex", flex: "auto" },
      tableHeader: {
        width: 300,
        border: `1px solid ${theme.color.border}`,
        borderBottom: "none",
      },
      table: {
        border: `1px solid ${theme.color.border}`,
        borderBottom: "none",
      },
      tableCellHeaderRow: {
        borderBottom: `1px solid ${theme.color.border}`,
        borderRight: `1px solid ${theme.color.border}`,
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        color: theme.text.primary,
      },
      tableCellBody: {
        borderBottom: `1px solid ${theme.color.border}`,
        color: theme.text.primary,
      },
      tableCellBodyLast: {
        borderTop: `1px solid ${theme.color.border}`,
        borderBottom: `1px solid ${theme.color.border}`,
        borderRight: `1px solid ${theme.color.border}`,
        color: theme.text.primary,
      },
      tableCellBodyHeader: {
        borderTop: `1px solid ${theme.color.border}`,
        borderBottom: `1px solid ${theme.color.border}`,
        borderRight: `none`,
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        color: theme.text.primary,
      },
      tableCellBodyHeaderLast: {
        borderTop: `1px solid ${theme.color.border}`,
        borderBottom: `1px solid ${theme.color.border}`,
        borderRight: `1px solid ${theme.color.border}`,
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        color: theme.text.primary,
      },
    }),
  { index: 1 }
);

const headerColumn = [
  { id: "packageBot", label: "Package bot", width: 150 },
  { id: "profit", label: "Profit", width: 150 },
  {
    id: "price",
    label: "Price",
    width: 150,
  },
  // {
  //   id: "initPrice",
  //   label: "Initial Price",
  //   width: 150,
  // },
  //   {
  //     id: "maintainPrice",
  //     label: "Maintain Price",
  //     width: 150,
  //   },
  // {
  //   id: "bonus",
  //   label: "Bonus",
  //   width: 150,
  // },
];

function TableBotPackage({ botsInfo }) {
  const classes = useStyles();

  return (
    <div>
      <TableContainer className={classes.root}>
        <Table className={classes.tableHeader}>
          <TableBody>
            {headerColumn.map((column) => {
              return (
                <TableRow key={column.id} style={{ width: column.width }} hover>
                  <TableCell
                    align={column.align}
                    classes={{ root: classes.tableCellHeaderRow }}
                  >
                    {column.label}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {botsInfo.results?.map((bot, index) => (
          <Table key={bot.id}>
            <TableBody>
              <TableRow hover>
                <TableCell
                  classes={{
                    root:
                      index < botsInfo.results?.length - 1
                        ? classes.tableCellBodyHeader
                        : classes.tableCellBodyHeaderLast,
                  }}
                >
                  {bot.name?.toUpperCase()} BOT
                </TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell
                  classes={{
                    root:
                      index < botsInfo.results?.length - 1
                        ? classes.tableCellBody
                        : classes.tableCellBodyLast,
                  }}
                >
                  {Math.round(parseFloat(bot.min_profit) * 100)}% ~{" "}
                  {Math.round(parseFloat(bot.max_profit) * 100)}%
                </TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell
                  classes={{
                    root:
                      index < botsInfo.results?.length - 1
                        ? classes.tableCellBody
                        : classes.tableCellBodyLast,
                  }}
                >
                  {convertNumberToUSDT(parseFloat(bot.first_invest))}
                </TableCell>
              </TableRow>
              {/* <TableRow hover>
                <TableCell
                  classes={{
                    root:
                      index < botsInfo.results?.length - 1
                        ? classes.tableCellBody
                        : classes.tableCellBodyLast,
                  }}
                >
                  {convertNumberToCurrency(parseFloat(bot.init_price))}
                </TableCell>
              </TableRow> */}
              {/* <TableRow hover>
                <TableCell
                  classes={{
                    root:
                      index < botsInfo.results?.length - 1
                        ? classes.tableCellBody
                        : classes.tableCellBodyLast,
                  }}
                >
                  {convertNumberToCurrency(parseFloat(bot.maintain_price))}
                </TableCell>
              </TableRow> */}
              {/* <TableRow hover>
                <TableCell
                  classes={{
                    root:
                      index < botsInfo.results?.length - 1
                        ? classes.tableCellBody
                        : classes.tableCellBodyLast,
                  }}
                >
                  {convertNumberToCurrency(parseFloat(bot.bonus))}
                </TableCell>
              </TableRow> */}
            </TableBody>
          </Table>
        ))}
      </TableContainer>
    </div>
  );
}

TableBotPackage.propTypes = {};

export default TableBotPackage;
