export const GET_REFERRAL_DETAIL_REQ = "GET_REFERRAL_DETAIL_REQ";
export const GET_REFERRAL_DETAIL_SUCCESS = "GET_REFERRAL_DETAIL_SUCCESS";

export const GET_REFERRAL_BO_DETAIL_REQ = "GET_REFERRAL_BO_DETAIL_REQ";
export const GET_REFERRAL_BO_DETAIL_SUCCESS = "GET_REFERRAL_BO_DETAIL_SUCCESS";

export const BUY_REFERRAL_CODE_REQ = "BUY_REFERRAL_CODE_REQ";
export const BUY_REFERRAL_CODE_SUCCESS = "BUY_REFERRAL_CODE_SUCCESS";

export const GET_REFERRAL_TREE_REQ = "GET_REFERRAL_TREE_REQ";
export const GET_REFERRAL_TREE_SUCCESS = "GET_REFERRAL_TREE_SUCCESS";

export const GET_BO_TREE_REQ = "GET_BO_TREE_REQ";
export const GET_BO_TREE_SUCCESS = "GET_BO_TREE_SUCCESS";

export const GET_UNDER_REFERRAL_REQ = "GET_UNDER_REFERRAL_REQ";
export const GET_UNDER_REFERRAL_SUCCESS = "GET_UNDER_REFERRAL_SUCCESS";

export const DESTROY_REFERRAL = "DESTROY_REFERRAL";

export const DESTROY_TREE = "DESTROY_TREE";
