import React from "react";

export default function TradeIcon() {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 9H8V11H17L12 6V9ZM7 13L12 18V15H16V13H7Z"
        fill="#848E9C"
      />
    </svg>
  );
}
