import {
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Pagination, PaginationItem } from "@material-ui/lab";
import BigNumber from "bignumber.js";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import convertNumberToUSDT from "../../common/convertNumberToUSDT";
import formatCurrency from "../../common/formatCurrency";
import { bch, usdt } from "../../const/currency";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: { margin: "20px 0" },
      table: {
        border: `1px solid ${theme.color.border}`,
        borderBottom: "none",
      },
      tableCellHeader: {
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        border: "none",
        color: theme.text.secondary,
      },
      tableCellBody: {
        borderBottom: "1px solid #424242",
        color: "#fff",
      },
      rootPagination: {
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: 40,
      },
      pagination: {
        color: theme.text.primary,
      },
      rootPaginationItem: {
        border: `1px solid ${theme.text.primary}`,
        color: theme.text.primary,
        borderRadius: 2,
      },
      ellipsisPagination: { border: "none" },
      pageSelected: {
        backgroundColor: "hsla(0, 0%, 100%, 0.1) !important",
        border: `1px solid ${theme.color.border}`,
      },
      typographyUp: {
        color: theme.color.trendingUp,
      },
      typographyDown: {
        color: theme.color.trendingDown,
      },
    }),
  { index: 1 }
);

function createData(time, amount, action, status) {
  return { time, amount, action, status };
}

function TableBotTransaction(props) {
  const classes = useStyles(props);
  const { botProfit, paginationProfitBot, handleChangePage } = props;
  const [rows, setRows] = useState([]);
  const { t } = useTranslation();

  console.log("botProfit", botProfit);

  const columns = [
    { id: "time", label: t("Time"), minWidth: 120 },
    { id: "amount", label: t("Amount"), minWidth: 100 },
    { id: "action", label: t("Action"), minWidth: 100 },
    { id: "status", label: t("Status"), minWidth: 100 },
  ];

  useEffect(() => {
    if (botProfit.results?.length > 0) {
      let rows = botProfit.results?.map((item) => {
        let time = moment(item?.created).format("YYYY-MM-DD hh:mm");
        let amount = (
          <Typography
            variant="body2"
            classes={{
              root:
                item?.action_type === "profit_bot"
                  ? classes.typographyUp
                  : classes.typographyDown,
            }}
          >
            {new BigNumber(
              item.note.symbol && item.note.symbol === bch.symbol
                ? item.note?.bch_amount
                : item?.amount
            ).toFormat(
              item.note.symbol && item.note.symbol === bch.symbol ? 5 : 2
            )}{" "}
            {item.note.symbol ? item.note.symbol : usdt.symbol}
          </Typography>
        );
        let action = item?.action_type?.replace("_", " ")?.toUpperCase();
        let status = item?.status?.toUpperCase();
        return createData(time, amount, action, status);
      });
      setRows(rows);
    }
  }, [botProfit]);

  return (
    <>
      <TableContainer className={classes.root}>
        <Table aria-label="sticky table" className={classes.table}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className={classes.tableCellHeader}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={classes.tableCellBody}
                      >
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.rootPagination}>
        <Pagination
          count={Math.ceil(
            botProfit?.count / paginationProfitBot.page_size || 0
          )}
          page={paginationProfitBot.page}
          onChange={(_, value) => {
            handleChangePage(value);
          }}
          variant="outlined"
          shape="rounded"
          classes={{ root: classes.pagination }}
          renderItem={(item) => (
            <PaginationItem
              classes={{
                root: classes.rootPaginationItem,
                ellipsis: classes.ellipsisPagination,
                selected: classes.pageSelected,
              }}
              {...item}
              variant="outlined"
            />
          )}
        />
      </div>
    </>
  );
}

TableBotTransaction.propTypes = {};

export default TableBotTransaction;
