import { makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(
  (theme) => ({
    title: (props) => {
      let results = {
        textAlign: "center",
        fontWeight: 700,
        marginTop: 150,
      };
      switch (props.status) {
        case "failed":
          results.color = theme.text.danger;
          break;
        case "success":
          results.color = theme.text.success;
          break;
        default:
          break;
      }
      return results;
    },
  }),
  { index: 1 }
);

export default function BodyVerifyRegistration(props) {
  const classes = useStyles(props);
  const { status, message } = props;

  return (
    <>
      <div>
        {/* TODO add loading */}
        <Typography variant="h3" classes={{ root: classes.title }}>
          {/* {t(`Verify account ${status}`)} */}
          {message}
        </Typography>
      </div>
    </>
  );
}
