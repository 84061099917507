import {
  createStyles,
  Divider,
  ListItem,
  makeStyles,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import { useTranslation } from "react-i18next";
import UsdtXS from "../../assets/img/UsdtXS.png";
import DollarDemo from "../../assets/img/DollarDemo.png";
import TrendingUp from "../../assets/img/TrendingUp.png";
import TrendingDown from "../../assets/img/TrendingDown.png";
import convertNumberToCurrency from "../../common/convertNumberToCurrency";
import DollarReal from "../../assets/img/DollarReal.png";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        display: "flex",
        justifyContent: "space-between",
        color: "#848E9C",
      },
      typographyTitle: {},
      rowItem: { paddingBottom: 5 },
      title: {
        display: "flex",
        alignItems: "center",
      },
      trend: { display: "flex", alignItems: "center", paddingBottom: 5 },
      typographyTrendUp: {
        marginLeft: "10px",
        paddingTop: "2px",
        color: theme.color.trendingUp,
      },
      typographyTrendDown: {
        marginLeft: "10px",
        paddingTop: "2px",
        color: theme.color.trendingDown,
      },
      typographyDemo: {
        marginLeft: "10px",
        padding: "0px 5px",
        backgroundColor: "#ff9800",
        color: theme.text.primary,
        borderRadius: 2,
      },
      divider: { backgroundColor: "#424242", width: "88%", marginLeft: 16 },
    }),
  { index: 1 }
);

export default function BitcoinTrendItem(props) {
  const classes = useStyles(props);
  const { data, account } = props;
  const { t } = useTranslation();

  const renderResultAmount = (data) => {
    switch (data.result) {
      case "win":
        return (
          <Typography
            variant="body2"
            classes={{ root: classes.typographyTrendUp }}
          >
            +{convertNumberToCurrency(parseFloat(data.result_amount) || 0)}{" "}
          </Typography>
        );
      case "lose":
        return (
          <Typography
            variant="body2"
            classes={{ root: classes.typographyTrendDown }}
          >
            {convertNumberToCurrency(parseFloat(data.result_amount) || 0)}
          </Typography>
        );
      case "pending":
        return <Typography variant="body2">---</Typography>;
      default:
        return null;
    }
  };

  return (
    <>
      <ListItem className={classes.root}>
        <div>
          <div className={classes.title}>
            <Typography variant="body1" className={classes.typographyTitle}>
              {t("BTC/USD")}
            </Typography>
            {account === "demo" && (
              <Typography
                variant="caption"
                classes={{ root: classes.typographyDemo }}
              >
                {t("Demo")}
              </Typography>
            )}
          </div>
          <div className={classes.trend}>
            {data.action_type === "up" ? (
              <>
                <img src={`${TrendingUp}`} alt="TrendingUp"></img>
                <Typography
                  variant="body2"
                  className={classes.typographyTrendUp}
                >
                  {t("Buy")}
                </Typography>
              </>
            ) : (
              <>
                <img src={`${TrendingDown}`} alt="TrendingDown"></img>
                <Typography
                  variant="body2"
                  className={classes.typographyTrendDown}
                >
                  {t("Sell")}
                </Typography>
              </>
            )}
          </div>
          <Typography variant="body2">
            {moment(data.timestamp).format("MM-DD HH:mm:ss")}
          </Typography>
        </div>
        <div style={{ textAlign: "right" }}>
          <img
            src={account === "real" ? DollarReal : DollarDemo}
            alt={"currency icon"}
            style={{ width: 15, height: "auto", paddingTop: 5 }}
          />
          <Typography variant="body1" className={classes.rowItem}>
            {convertNumberToCurrency(parseFloat(data.amount) || 0)}
          </Typography>
          {renderResultAmount(data)}
        </div>
      </ListItem>
      <Divider className={classes.divider} />
    </>
  );
}
