import {
  Button,
  Card,
  CardContent,
  CardHeader,
  createStyles,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { KYC_STATUS } from "../../const/common";
import FindInPageIcon from "@material-ui/icons/FindInPage";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        width: 464,
        height: 170,
        margin: "15px 0 15px 15px",
        [theme.breakpoints.down("xs")]: { maxWidth: 328, margin: "15px auto" },
      },
      card: {
        backgroundColor: "rgba(255, 255, 255, 0.03)",
        color: theme.text.primary,
      },
      cardHeader: { paddingBottom: 10 },
      cardContent: { paddingTop: 0, minWidth: 0 },
      typographySecondary: { color: theme.text.secondary, display: "block" },
      coverButton: {
        marginTop: 5,
        [theme.breakpoints.down("xs")]: {
          display: "flex",
          justifyContent: "flex-end",
        },
      },
      buttonAction: {
        color: "#C4C4C4",
        borderColor: "#C4C4C4",
        marginRight: 10,
        borderRadius: 2,
        textTransform: "none",
        width: 120,
        [theme.breakpoints.down("xs")]: {
          marginRight: 5,
        },
      },
      typography: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "5px 10px",
        borderRadius: 3,
        backgroundColor: `hsla(0, 0%, 100%, 0.05)`,
      },
      typographyReject: {
        color: "#f44336",
        marginTop: 10,
      },
    }),
  { index: 1 }
);

export default function CardAccountVerification(props) {
  const classes = useStyles(props);
  const { handleActionClick, profile } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  const renderButtonVerify = () => {
    switch (profile.kyc_status) {
      case KYC_STATUS.INACTIVE:
        return (
          <div className={classes.coverButton}>
            <Button
              className={classes.buttonAction}
              variant="outlined"
              onClick={() => {
                handleActionClick("Verify account");
              }}
            >
              {t("Verify")}
            </Button>
          </div>
        );
      case KYC_STATUS.REJECTED:
        return (
          <div className={classes.coverButton}>
            <Button
              className={classes.buttonAction}
              variant="outlined"
              onClick={() => {
                handleActionClick("Verify account");
              }}
            >
              {t("Re-verify")}
            </Button>
          </div>
        );
      case KYC_STATUS.PENDING:
        return (
          <div className={classes.coverButton}>
            <Typography
              variant="caption"
              classes={{ root: classes.typography }}
            >
              <FindInPageIcon style={{ fontSize: 14, marginRight: 3 }} />{" "}
              {t("Pending browse profile")}
            </Typography>
          </div>
        );
      case KYC_STATUS.ACCEPTED:
        return (
          <div className={classes.coverButton}>
            <Typography
              variant="caption"
              classes={{ root: classes.typography }}
            >
              {t("Verified")}
            </Typography>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          title={
            <Typography variant="body1">{t("Account verification")}</Typography>
          }
          action={!matches && renderButtonVerify()}
        />
        <CardContent className={classes.cardContent}>
          <Typography variant="body2" className={classes.typographySecondary}>
            {t(
              "To ensure the safety of your property, we need identity verification."
            )}
          </Typography>
          <Typography variant="body2" className={classes.typographySecondary}>
            {t(
              "Fill in the information accurately, once identity verification is completed, the information will no longer be edited."
            )}
          </Typography>
          {profile.kyc_status === KYC_STATUS.REJECTED && (
            <Typography
              variant="caption"
              classes={{ root: classes.typographyReject }}
            >
              {t("You have been rejected verification")}
            </Typography>
          )}

          {matches && renderButtonVerify()}
        </CardContent>
      </Card>
    </div>
  );
}

CardAccountVerification.propTypes = { handleActionClick: PropTypes.func };
