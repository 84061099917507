import { makeStyles, Typography } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getPendingTicket, playBingo } from "../../apis/bingo.api";
import BGBingoStar from "../../assets/img/BGBingoStar.png";
import DialogAction from "../../components/Body/BodyBingoStar/DialogAction";
import ResultBox from "../../components/Body/BodyBingoStar/ResultBox";
import { getUserWalletReq } from "../../redux/actions/walletAction";
import BingoTicket from "./BingoSTar/BingoTicket";
import GoodLuck from "./BingoSTar/GoodLuck";
import Guide from "./BingoSTar/Guide";
import WinBingo from "./BingoSTar/WinBingo";
import WinPrize from "./BingoSTar/WinPrize";
import PlayBingo from "../../assets/audio/playBingo.mp3";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      marginTop: 10,
      position: "relative",
      width: "80%",
    },
    image: {
      position: "absolute",
      top: 0,
      opacity: 0.5,
      width: "100%",
      height: "auto",
      zIndex: 1,
      [theme.breakpoints.down("xs")]: {
        width: "100vw",
        height: 160,
      },
    },
    commingsoon: {
      width: "100%",
      height: "300px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  }),
  { index: 1 }
);

const playBingoAudio = new Audio(PlayBingo);

function pad(num, size) {
  var s = "000000000" + num;
  return s.substr(s.length - size);
}

export default function BingoStarContainer() {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [resultBingo, setResultBingo] = useState(0);
  const [resultAmount, setResultAmount] = useState(0);
  const [tickets, setTickets] = useState(0);
  const [action, setAction] = useState("");
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const callNumberTickets = () => {
    getPendingTicket()
      .then((res) => {
        if (res.status === 200) {
          setTickets(res.data?.pending);
        }
      })
      .catch();
  };

  const handleOpenDialog = (action) => {
    setOpenDialog(true);
    setAction(action);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handlePlay = () => {
    playBingoAudio.play();
    let curTime = moment().valueOf();
    let result = 0;
    let response = null;
    let random = setInterval(() => {
      setResultBingo(Math.floor(Math.random() * 1000001));
      let now = moment().valueOf();
      if (now >= curTime + 4000) {
        setResultBingo(result);
        clearInterval(random);
        if (response) {
          callNumberTickets();
          if (response.result === "lose") {
            handleOpenDialog("lose");
          } else {
            handleOpenDialog("win");
            setResultAmount(parseFloat(response.result_amount));
            dispatch(getUserWalletReq());
          }
        }
      }
    }, [100]);
    playBingo()
      .then((res) => {
        if (res.status === 200) {
          let data = res.data;
          result = parseInt(data.result_number);
          response = data;
        }
      })
      .catch();
  };

  const renderContent = () => {
    switch (action) {
      case "buyticket": {
        return (
          <BingoTicket
            handleCloseDialog={handleCloseDialog}
            callNumberTickets={callNumberTickets}
          />
        );
      }
      case "history": {
        return <WinPrize />;
      }
      case "guide": {
        return <Guide />;
      }
      case "lose": {
        return <GoodLuck />;
      }
      case "win": {
        return <WinBingo amount={resultAmount} />;
      }
      default:
        return null;
    }
  };

  //   useEffect(() => {
  //     if (play) {
  //       let random = setInterval(() => {
  //         setResultBingo(Math.floor(Math.random() * 1000001));
  //         let now = moment().valueOf();
  //         if (now >= curTime + 5000) {
  //           clearInterval(random);
  //           setPlay(false);
  //         }
  //       }, [100]);
  //     }
  //   }, [play]);

  useEffect(() => {
    callNumberTickets();
  }, []);

  return (
    <div className={classes.root}>
      <img src={BGBingoStar} alt="bingo" className={classes.image} />
      <div className={classes.commingsoon}>
        <Typography variant="h4">{t("Comming soon")}</Typography>
      </div>
      {/* <ResultBox
        resultBingo={pad(resultBingo, 6)}
        handleOpenDialog={handleOpenDialog}
        handlePlay={handlePlay}
        tickets={tickets}
      /> */}
      {/* <DialogAction
        open={openDialog}
        handleClose={handleCloseDialog}
        action={action}
      >
        {renderContent()}
      </DialogAction> */}
    </div>
  );
}
