import React from "react";
import ForgotPassword from "../containers/ForgotPassword/ForgotPassword";

export default function LoginPage() {
  return (
    <div>
      <ForgotPassword />
    </div>
  );
}
