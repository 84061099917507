import React from "react";
import ResetPassword from "../containers/ResetPassword/ResetPassword";

export default function LoginPage() {
  return (
    <div>
      <ResetPassword />
    </div>
  );
}
