/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, makeStyles } from "@material-ui/core";
import { find } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PaperBuyBotSuccess from "../../components/Body/BodyTradingBot/PaperBuyBotSuccess";
import DialogInvestTradingBotV2 from "../../components/Dialog/DialogInvestTradingBotV2";
import { bch, usdt } from "../../const/currency";
import {
  buyBotV2PackageReq,
  getBotV2PackageReq,
  upgradeBotV2PackageReq,
} from "../../redux/actions/tradingBotV2Action";
import Bot from "./Bot";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: "100%",
      maxWidth: "1200px",
      margin: "auto",
    },
    gridContainer: {
      width: "100%",
      margin: 0,
    },
  }),
  { index: 1 }
);

export default function Packgages(props) {
  const classes = useStyles();
  const [botSelected, setBotSelected] = useState({
    info: {},
    open: false,
  });
  const [openPaperBotSuccess, setOpenPaperBotSuccess] = useState(false);
  const dispatch = useDispatch();
  const botsInfo = useSelector((state) => state.tradingBotV2Reducer.info);
  const buySuccess = useSelector(
    (state) => state.tradingBotV2Reducer.buySuccess
  );
  const botBought = useSelector((state) => state.tradingBotV2Reducer.botBought);
  const walletInfo = useSelector((state) => state.walletReducer.info);
  const userPackage = useSelector(
    (state) => state.tradingBotV2Reducer.userPackage
  );
  const wallets = useSelector(
    (state) => state.walletReducer.info?.results || []
  );

  const handleRentNow = (botInfo) => {
    setBotSelected({ info: botInfo, open: true });
  };

  const handleCloseDialogBuyBot = () => {
    setBotSelected({ ...botSelected, open: false });
  };

  const handleCloseDialogBuyBotSuccess = () => {
    setOpenPaperBotSuccess(false);
  };

  const handlePayBot = (amount, symbol) => {
    let userPackageBought = userPackage.results;
    let botSelectedId = botSelected.info?.id;

    if (
      Array.isArray(userPackageBought) &&
      userPackageBought.length > 0 &&
      userPackageBought
        .map((userPackage) => userPackage.package_data?.id)
        .includes(Number(botSelected.info?.id))
    ) {
      let packageData = find(
        userPackageBought,
        (userPackage) =>
          Number(userPackage.package_data?.id) === Number(botSelectedId)
      );
      let dataReq = {
        amount,
        symbol,
      };
      dispatch(upgradeBotV2PackageReq(packageData?.id, dataReq));
      setBotSelected({ ...botSelected, open: false });
    } else {
      let dataReq = {
        bot_package: botSelectedId,
        amount,
        symbol,
      };
      dispatch(buyBotV2PackageReq(dataReq));
      setBotSelected({ ...botSelected, open: false });
    }
  };

  useEffect(() => {
    if (buySuccess) {
      setOpenPaperBotSuccess(true);
    }
  }, [buySuccess]);

  useEffect(() => {
    dispatch(getBotV2PackageReq());
  }, []);

  return (
    <>
      {!openPaperBotSuccess && Object.keys(botsInfo).length > 0 && (
        <div className={classes.root}>
          <Grid
            container
            spacing={2}
            classes={{ container: classes.gridContainer }}
          >
            {botsInfo.results?.map((bot) => (
              <Grid key={bot.id} item md={6} xs={12}>
                <Bot
                  botType="ultra"
                  handleRentNow={handleRentNow}
                  botInfo={bot}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      )}
      <PaperBuyBotSuccess
        botSelected={botSelected.info}
        handleClose={handleCloseDialogBuyBotSuccess}
        buyBotSuccess={openPaperBotSuccess}
        botBought={botBought}
      />

      <DialogInvestTradingBotV2
        botSelected={botSelected}
        handleClose={handleCloseDialogBuyBot}
        handlePayBot={handlePayBot}
        wallets={wallets.filter(
          (wallet) =>
            wallet.c?.symbol === bch.symbol || wallet.c?.symbol === usdt.symbol
        )}
      />
    </>
  );
}
