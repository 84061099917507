import {
  Grid,
  makeStyles,
  Paper,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { convertBCHtoALA, convertCurrency } from "../../apis/wallet.api";
import BodyWallet from "../../components/Body/BodyWallet/BodyWallet";
import ButtonAccount from "../../components/Body/BodyWallet/ButtonAccount";
import FormConvertCurrency from "../../components/Body/BodyWallet/FormConvertCurrency";
import CardAccount from "../../components/Card/CardAccount";
import CardDeposit from "../../components/Card/CardDeposit";
import CardDepositHistory from "../../components/Card/CardDepositHistory";
import CardWithdrawalHistory from "../../components/Card/CardWithdrawalHistory";
import DialogConvertCurrency from "../../components/Dialog/DialogConvertCurrency";
import HeaderMyAssets from "../../components/Header/HeaderMyAssets";
import TableConvertTransaction from "../../components/Table/TableConvertTransaction";
import TableTransactionHistory from "../../components/Table/TableTransactionHistory";
import TabsWalletHistory from "../../components/Tabs/TabsWalletHistory";
import { TOAST_NOTI, USDTCurrency } from "../../const/common";
import { callToastNotiSuccess } from "../../redux/actions/toastNotiAction";
import {
  getConvertTransactionReq,
  getMainTransactionReq,
  getUserWalletReq,
} from "../../redux/actions/walletAction";
import CurrencyTransaction from "./CurrencyTransaction";

const useStyles = makeStyles(
  (theme) => ({
    root: { width: "100%" },
    rootPaper: {
      padding: "30px 0 50px 0",
      margin: "30px 0",
      boxShadow: "none",
      background: theme.color.background,
      [theme.breakpoints.down("xs")]: {
        background: "transparent",
        border: "none",
        boxShadow: "none",
      },
    },
    rootPagination: {
      display: "flex",
      justifyContent: "flex-end",
      marginBottom: 40,
      marginTop: 20,
    },
    pagination: {
      color: theme.text.primary,
    },
    rootPaginationItem: {
      border: `1px solid ${theme.color.light}`,
      color: theme.text.primary,
      borderRadius: 2,
    },
    ellipsisPagination: { border: "none" },
    pageSelected: {
      backgroundColor: "hsla(0, 0%, 100%, 0.1) !important",
      border: `1px solid ${theme.color.border}`,
    },
    coverLoading: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        height: 80,
      },
    },
  }),
  { index: 1 }
);

const accountTypeReal = "real";

export default function Wallet() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [accountType, setAccountType] = useState("real");
  const [openExchange, setOpenExchange] = useState(false);
  const [openConvertCurrency, setOpenConvertCurrency] = useState(false);
  const [transactionInfo, setTransactionInfo] = useState({
    action: "",
  });
  const [showBalance, setShowBalance] = useState(false);
  const [walletSelected, setWalletSelected] = useState(null);
  const [valueTabHistory, setValueTabHistory] = useState(t("Deposit history"));
  const [pagination, setPagination] = useState({
    page: 1,
    page_size: 10,
  });
  const [tx_type, setTx_type] = useState("deposit");
  const [dataConvert, setDataConvert] = useState();
  const [loadingConvert, setLoadingConvert] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const dispatch = useDispatch();
  const walletsInfo = useSelector((state) => state.walletReducer.info);
  const walletTransaction = useSelector(
    (state) => state.walletReducer.transaction
  );
  const loading = useSelector((state) => state.walletReducer.loading);

  const handleUpdateLoadingConvert = (status) => {
    setLoadingConvert(status);
  };

  const handleSetTxType = (newValue) => {
    switch (newValue) {
      case t("Deposit history"):
      case t("Deposit"): {
        return "deposit";
      }
      case t("Withdrawal history"):
      case t("Withdraw"): {
        return "withdraw";
      }
      default:
        return;
    }
  };

  const handleChangeTxType = (tx_type) => {
    setTx_type(tx_type);
  };

  const handleChangePage = (event, value) => {
    setPagination({ ...pagination, page: value });
    // dispatch(getMainTransactionReq({ ...pagination, page: value, tx_type }));
  };

  const handleResetPagination = () => {
    setPagination({
      page: 1,
      page_size: 10,
    });
  };

  const handleChangeTabHistory = (event, newValue) => {
    setValueTabHistory(newValue);
    handleResetPagination();
    if (newValue !== "convert_transaction") {
      let tx_type = handleSetTxType(newValue);
      handleChangeTxType(tx_type);
    } else handleChangeTxType("");

    // dispatch(getMainTransactionReq({ ...pagination, tx_type }));
  };

  const handleSelectAccountType = (accountType) => {
    setAccountType(accountType);
  };

  const handleExchange = (action, wallet) => {
    setTransactionInfo({ action });
    setOpenExchange(true);
    setWalletSelected(wallet);

    let newPagination = { page: 1, page_size: pagination.page_size };
    setPagination(newPagination);

    let tx_type = handleSetTxType(action);
    handleChangeTxType(tx_type);
  };

  const handleBackToWallet = () => {
    setTransactionInfo({ action: "" });
    setValueTabHistory(t("Deposit history"));
    let tx_type = handleSetTxType(t("Deposit"));
    handleChangeTxType(tx_type);

    let newPagination = { page: 1, page_size: pagination.page_size };
    setPagination(newPagination);

    setOpenExchange(false);
  };

  const handleShowBalance = () => {
    setShowBalance(true);
  };

  const handleHideBalance = () => {
    setShowBalance(false);
  };

  const handleSelectWallet = (wallet) => {
    setWalletSelected(wallet);
  };

  const handleOpenConvertCurrency = (dataConvert, wallet) => {
    setWalletSelected(wallet);
    setDataConvert(dataConvert);
    setOpenConvertCurrency(true);
  };

  const handleCloseConvertCurrency = () => {
    setOpenConvertCurrency(false);
  };

  const handleConvert = async (dataReq) => {
    const body = { ...dataReq, amount: dataReq?.amount || 0 };
    handleUpdateLoadingConvert(true);
    // await convertCurrency(walletSelected?.id, dataReq)
    await convertBCHtoALA(body)
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            callToastNotiSuccess({
              noti: t("Convert successfully"),
              status: "success",
            })
          );
          dispatch(getUserWalletReq());
        } else
          dispatch(
            callToastNotiSuccess({
              noti: t("Convert failed"),
              status: "error",
            })
          );
      })
      .catch((err) => {
        let { data } = err.response;
        dispatch(
          callToastNotiSuccess({
            noti: data.message,
            status: TOAST_NOTI.ERROR,
            code: data.code,
          })
        );
      });
    handleCloseConvertCurrency();
    handleUpdateLoadingConvert(false);
  };

  const renderCard = () => {
    switch (accountType) {
      case "real":
        return (
          <>
            {walletsInfo.results?.map((wallet) => (
              <Grid key={wallet.id} item lg={6} md={8} sm={12} xs={12}>
                <CardAccount
                  wallet={wallet}
                  handleExchange={handleExchange}
                  showBalance={showBalance}
                  handleOpenConvertCurrency={handleOpenConvertCurrency}
                />
              </Grid>
            ))}
          </>
        );
      case "demo":
        return (
          <Grid item sm={6} xs={12}>
            <CardDeposit />
          </Grid>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (walletSelected) {
      let newWalletSelected = walletsInfo.results?.filter(
        (result) => result.id === walletSelected.id
      )[0];
      setWalletSelected(newWalletSelected);
    }
  }, [walletsInfo]);

  useEffect(() => {
    dispatch(getUserWalletReq());
    let tx_type;
    handleChangeTxType(tx_type);
    // return () => {
    //   dispatch(destroyWallet());
    // };
  }, []);

  useEffect(() => {
    if (!openExchange && valueTabHistory.length > 0) {
      let tx_type = handleSetTxType(valueTabHistory);
      handleChangeTxType(tx_type);
      //   dispatch(getMainTransactionReq({ ...pagination, tx_type }));
    }
  }, [openExchange]);

  useEffect(() => {
    if (tx_type) {
      dispatch(getMainTransactionReq({ ...pagination, tx_type }));
    } else {
      dispatch(getConvertTransactionReq({ ...pagination }));
    }
  }, [tx_type, pagination]);

  return (
    <div className={classes.root}>
      <CurrencyTransaction
        transactionInfo={transactionInfo}
        handleBackToWallet={handleBackToWallet}
        openExchange={openExchange}
        walletSelected={walletSelected}
        handleSelectWallet={handleSelectWallet}
        pagination={pagination}
        handleChangePage={handleChangePage}
        handleResetPagination={handleResetPagination}
        handleChangeTxType={handleChangeTxType}
        tx_type={tx_type}
      />
      {!openExchange && (
        <Grid container>
          <HeaderMyAssets
            showBalance={showBalance}
            handleShowBalance={handleShowBalance}
            handleHideBalance={handleHideBalance}
          />
          <BodyWallet>
            <Grid item xs={12} sm={6} md={6}>
              <ButtonAccount
                accountType={accountTypeReal}
                handleSelectAccountType={handleSelectAccountType}
                selected={accountType === accountTypeReal}
                showBalance={showBalance}
                walletsInfo={walletsInfo}
              />
            </Grid>
            {Object.keys(walletsInfo).length > 0 && (
              <Grid container>{renderCard()}</Grid>
            )}
            {accountType === accountTypeReal && (
              <Grid item xs={12}>
                <Paper classes={{ root: classes.rootPaper }}>
                  <TabsWalletHistory
                    value={valueTabHistory}
                    handleChange={handleChangeTabHistory}
                  />
                  {/* TODO add loading */}
                  {matches ? (
                    loading ? (
                      <div className={classes.coverLoading}>
                        <CircularProgress style={{ color: "#fff" }} size={25} />
                      </div>
                    ) : (
                      <>
                        {tx_type ? (
                          <div>
                            {walletTransaction.results &&
                              walletTransaction.results?.map((transaction) => {
                                if (transaction.tx_type !== "withdraw")
                                  return (
                                    <CardDepositHistory
                                      key={transaction.id}
                                      typeCurrency={USDTCurrency}
                                      transaction={transaction}
                                      handleExchange={handleExchange}
                                    />
                                  );
                                return (
                                  <CardWithdrawalHistory
                                    key={transaction.id}
                                    typeCurrency={USDTCurrency}
                                    transaction={transaction}
                                    handleExchange={handleExchange}
                                  />
                                );
                              })}
                            <div className={classes.rootPagination}>
                              <Pagination
                                count={Math.ceil(
                                  walletTransaction.count /
                                    pagination.page_size || 0
                                )}
                                page={pagination.page}
                                variant="outlined"
                                shape="rounded"
                                onChange={handleChangePage}
                                classes={{ root: classes.pagination }}
                                renderItem={(item) => (
                                  <PaginationItem
                                    classes={{
                                      root: classes.rootPaginationItem,
                                      ellipsis: classes.ellipsisPagination,
                                      selected: classes.pageSelected,
                                    }}
                                    {...item}
                                    variant="outlined"
                                  />
                                )}
                              />
                            </div>
                          </div>
                        ) : (
                          <div style={{ marginTop: 10 }}>
                            <TableConvertTransaction
                              loading={loading}
                              walletTransaction={walletTransaction}
                              handleChangePage={handleChangePage}
                              pagination={pagination}
                            />
                          </div>
                        )}
                      </>
                    )
                  ) : tx_type ? (
                    <>
                      <TableTransactionHistory
                        loading={loading}
                        walletTransaction={walletTransaction}
                        handleChangePage={handleChangePage}
                        pagination={pagination}
                      />
                    </>
                  ) : (
                    <>
                      <TableConvertTransaction
                        loading={loading}
                        walletTransaction={walletTransaction}
                        handleChangePage={handleChangePage}
                        pagination={pagination}
                      />
                    </>
                  )}
                </Paper>
              </Grid>
            )}
          </BodyWallet>
        </Grid>
      )}
      <DialogConvertCurrency
        open={openConvertCurrency}
        handleClose={handleCloseConvertCurrency}
      >
        {dataConvert && (
          <FormConvertCurrency
            walletCurrent={
              walletsInfo.results?.filter(
                (wallet) => wallet.c.symbol === dataConvert?.base.symbol
              )[0]
            }
            wallets={walletsInfo?.results || []}
            handleConvert={handleConvert}
            dataConvert={dataConvert}
            loading={loadingConvert}
            isCanSelectCurrency={dataConvert?.base.symbol === "BCH"}
            handleOpenConvertCurrency={handleOpenConvertCurrency}
          />
        )}
      </DialogConvertCurrency>
    </div>
  );
}
