import {
  Button,
  Card,
  CardActions,
  CardContent,
  createStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Deposit from "../../assets/img/Deposit.png";
import Withdraw from "../../assets/img/Withdraw.png";
import Convert from "../../assets/img/Convert.png";
import Lock from "../../assets/img/Lock.png";
import convertNumberToCurrency from "../../common/convertNumberToCurrency";
import hideString from "../../common/hideString";
import convertNumberToMoney from "../../common/convertNumberToMoney";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: { margin: "25px 15px 0 0" },
      card: {
        padding: "10px 20px 10px 15px",
        minWidth: "450px",
        minHeight: 230,
        position: "relative",
        background: "linear-gradient(97.09deg, #000428 -2.03%, #004E92 99.57%)",
        [theme.breakpoints.down("xs")]: {
          minWidth: 330,
          minHeight: 150,
        },
      },
      cardContent: {
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down("xs")]: {
          paddingBottom: 30,
        },
      },
      list: { padding: 0 },
      listItem: { padding: 0 },
      listItemText: { margin: 0 },
      logo: { minWidth: 0, marginRight: 15 },
      typographyPrimary: {
        color: theme.text.primary,
      },
      typographySecondary: {
        color: theme.text.secondary,
      },
      coverLock: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        backgroundColor: "rgba(0,0,0,.3)",
        padding: "2px 10px",
        borderRadius: 2,
      },
      buttonActionRecharge: {
        backgroundColor: theme.color.secondary,
        color: theme.text.secondary,
        border: `1px solid ${theme.color.primary}`,
        fontWeight: 700,
        textTransform: "none",
        width: 130,
        justifyContent: "center",
        padding: 10,
        "&:hover": {
          border: `1px solid ${theme.color.light}`,
        },
      },
      buttonDisable: {
        cursor: "not-allowed",
        minHeight: 44,
      },
      buttonActionWithdrawal: {
        backgroundColor: theme.color.primary,
        color: theme.text.primary,
        fontWeight: 700,
        textTransform: "none",
        width: 130,
        justifyContent: "center",
        padding: 10,
        "&:hover": {
          border: `1px solid ${theme.color.primary}`,
        },
      },
      buttonActionConvert: {
        backgroundColor: "#FFA031",
        color: theme.text.primary,
        fontWeight: 700,
        textTransform: "none",
        width: 130,
        justifyContent: "center",
        padding: 10,
        "&:hover": {
          border: "1px solid #FFA031",
        },
        [theme.breakpoints.down("xs")]: {
          marginTop: 10,
        },
      },
      balance: {
        textAlign: "right",
      },
      cardActions: {
        position: "absolute",
        bottom: 10,
        right: 10,
        [theme.breakpoints.down("xs")]: {
          position: "relative",
          flexWrap: "wrap",
          justifyContent: "flex-end",
        },
      },
    }),
  { index: 1 }
);

export default function CardAccount(props) {
  const classes = useStyles(props);
  const { handleExchange, wallet, showBalance, handleOpenConvertCurrency } =
    props;
  const { t } = useTranslation();

  const renderLogoCard = (iconUrl) => {
    return <img src={`${iconUrl}`} alt="LogoLarge" width={50} height={50} />;
  };

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div>
            <List className={classes.list}>
              <ListItem className={classes.listItem}>
                <ListItemIcon className={classes.logo}>
                  {renderLogoCard(wallet.c?.icon_url)}
                </ListItemIcon>
                <ListItemText
                  className={classes.listItemText}
                  primary={
                    <Typography
                      className={classes.typographyPrimary}
                      variant="h6"
                    >
                      {wallet.c?.symbol}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      className={classes.typographySecondary}
                      variant="body2"
                    >
                      {wallet.c?.name}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </div>
          <div className={classes.balance}>
            <Typography className={classes.typographyPrimary} variant="h6">
              {showBalance
                ? convertNumberToMoney(wallet.balance || 0)
                : hideString(convertNumberToMoney(wallet.balance || 0))}
            </Typography>
            <Typography className={classes.typographySecondary} variant="body2">
              {showBalance ? (
                <>~ {convertNumberToCurrency(wallet.estimate_usdt || 0)}</>
              ) : (
                hideString(convertNumberToCurrency(wallet.estimate_usdt || 0))
              )}
            </Typography>
            {parseInt(wallet.lock_balance) !== 0 && (
              <div className={classes.coverLock}>
                <img
                  src={Lock}
                  alt="Lock"
                  width={12}
                  height="auto"
                  style={{ marginRight: 5 }}
                />
                <Typography
                  className={classes.typographySecondary}
                  variant="body2"
                >
                  {showBalance ? (
                    <>
                      {convertNumberToMoney(
                        parseFloat(wallet.lock_balance) || 0
                      )}
                    </>
                  ) : (
                    hideString(
                      convertNumberToMoney(parseFloat(wallet.lock_balance) || 0)
                    )
                  )}
                </Typography>
              </div>
            )}
          </div>
        </CardContent>
        <CardActions classes={{ root: classes.cardActions }}>
          {wallet.c?.can_deposit && (
            <Button
              className={classes.buttonActionRecharge}
              classes={{ disabled: classes.buttonDisable }}
              onClick={() => {
                handleExchange(t("Deposit"), wallet);
              }}
              disabled={!wallet.c?.can_deposit}
              variant="outlined"
            >
              <img
                src={`${Deposit}`}
                alt="Deposit"
                style={{ marginRight: 10 }}
              />
              {t("Deposit")}
            </Button>
          )}
          {wallet.c?.can_withdraw && (
            <Button
              className={classes.buttonActionWithdrawal}
              onClick={() => {
                handleExchange(t("Withdraw"), wallet);
              }}
              disabled={!wallet.c?.can_withdraw}
              variant="outlined"
            >
              <img
                src={`${Withdraw}`}
                alt="Withdraw"
                style={{ marginRight: 10 }}
              />
              {t("Withdraw")}
            </Button>
          )}
          {wallet.available_convert_pair?.length > 0 && (
            <Button
              className={classes.buttonActionConvert}
              variant="outlined"
              onClick={() => {
                let dataConvert = {
                  ...wallet.available_convert_pair[0],
                };
                handleOpenConvertCurrency(dataConvert, wallet);
              }}
            >
              <img
                src={`${Convert}`}
                alt="Convert"
                style={{ marginRight: 10 }}
              />
              {t("Convert")}
            </Button>
          )}
        </CardActions>
      </Card>
    </div>
  );
}

CardAccount.propTypes = {
  typeCurrency: PropTypes.string,
  handleExchange: PropTypes.func,
};
