import "./App.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import { SnackbarProvider } from "notistack";
import Main from "./containers/Main";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import Cookies from "js-cookie";
import AddToHomescreen from "react-add-to-homescreen";

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    let language = Cookies.get("language");
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [i18n]);

  return (
    <div className="app">
      {/* <AddToHomescreen /> */}

      <SnackbarProvider>
        <CssBaseline />
        <Main />
      </SnackbarProvider>
    </div>
  );
}

export default App;
