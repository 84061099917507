import React from "react";

export default function CloseIcon() {
  return (
    <svg
      width={13}
      height={13}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6718 12.0812L9.59053 7.9999L13.6718 3.91865C14.1124 3.47803 14.1124 2.76865 13.6718 2.32803C13.2312 1.8874 12.5218 1.8874 12.0812 2.32803L7.9999 6.40928L3.91865 2.32803C3.47803 1.8874 2.76865 1.8874 2.32803 2.32803C1.8874 2.76865 1.8874 3.47803 2.32803 3.91865L6.40928 7.9999L2.32803 12.0812C1.8874 12.5218 1.8874 13.2312 2.32803 13.6718C2.76865 14.1124 3.47803 14.1124 3.91865 13.6718L7.9999 9.59053L12.0812 13.6718C12.5218 14.1124 13.2312 14.1124 13.6718 13.6718C14.1093 13.2312 14.1093 12.5187 13.6718 12.0812Z"
        fill="white"
      />
    </svg>
  );
}
