import {
  Card,
  CardContent,
  Drawer,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";
import Logo from "../../assets/img/Logo.png";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.color.background,
      width: 250,
    },
    rootCard: {
      backgroundColor: theme.color.background,
      boxShadow: "none",
    },
    typography: {
      color: theme.text.primary,
      width: "100%",
      hyphens: "auto",
      //   wordWrap: "break-word",
    },
    iconButton: {
      color: "#C4C4C4",
    },
    cardContent: {
      padding: "20px 10px 0 20px",
    },
    rootHeader: {
      display: "flex",
      alignItems: "center",
    },
    title: {
      color: theme.text.primary,
    },
  }),
  { index: 1 }
);

function DrawerSideBar(props) {
  const classes = useStyles();
  const { openDrawer, handleCloseDrawer, children } = props;
  const { t } = useTranslation();

  return (
    <Drawer
      anchor={"left"}
      open={openDrawer}
      onClose={handleCloseDrawer}
      classes={{ paper: classes.root }}
    >
      <Card classes={{ root: classes.rootCard }}>
        <CardContent classes={{ root: classes.cardContent }}>
          <div className={classes.rootHeader}>
            <IconButton
              classes={{ root: classes.iconButton }}
              onClick={handleCloseDrawer}
            >
              <CloseIcon />
            </IconButton>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
            >
              <img src={`${Logo}`} alt="Logo" />
            </IconButton>
            <Typography variant="h5" className={classes.title}>
              {t("Alltrade")}
            </Typography>
          </div>
          {children}
        </CardContent>
      </Card>
    </Drawer>
  );
}

DrawerSideBar.propTypes = {};

export default DrawerSideBar;
