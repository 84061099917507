import { Button, useMediaQuery, useTheme } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { PATH } from "../../const/paths";

export default function ToastNoti() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const toastNotiReducer = useSelector((state) => state.toastNotiReducer);
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const { t } = useTranslation();

  const action = (key) => (
    <>
      <Button
        onClick={() => {
          closeSnackbar(key);
        }}
        variant="outlined"
        style={{
          color: "#fff",
          border: "1px Solid #fff",
          textTransform: "none",
        }}
      >
        {t("Close")}
      </Button>
    </>
  );

  useEffect(() => {
    if (toastNotiReducer.noti?.length > 0) {
      if (toastNotiReducer.code === 500) {
        enqueueSnackbar("Internal server error ", {
          anchorOrigin: {
            vertical: matches ? "top" : "bottom",
            horizontal: matches ? "center" : "left",
          },
          variant: toastNotiReducer.status,
          persist: false,
          autoHideDuration: 4000,
          TransitionComponent: Slide,
          disableWindowBlurListener: true,
          action,
        });
      } else
        enqueueSnackbar(toastNotiReducer.noti, {
          anchorOrigin: {
            vertical: matches ? "top" : "bottom",
            horizontal: matches ? "center" : "left",
          },
          variant: toastNotiReducer.status,
          persist: false,
          autoHideDuration: 4000,
          TransitionComponent: Slide,
          disableWindowBlurListener: true,
          action,
        });
      if (toastNotiReducer.code === 401) {
        localStorage.removeItem("jwt_token");
        history.push(PATH.HOME);
      }
    }
  }, [toastNotiReducer]);

  return <div>{/* <ToastContainer /> */}</div>;
}
