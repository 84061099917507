import {
  Button,
  Card,
  CardActions,
  CardContent,
  createStyles,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import EntypoCycle from "../../assets/img/EntypoCycle.png";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: { margin: "25px 15px 0 0" },
      card: {
        background: "linear-gradient(97.09deg, #F12711 -2.03%, #F5AF19 99.57%)",
      },
      cardContent: { padding: "50px 0 10px 0" },
      typographyPrimary: {
        color: theme.text.primary,
        textAlign: "center",
      },
      cardAction: {
        justifyContent: "center",
      },
      buttonActionRedeposit: {
        backgroundColor: theme.color.default,
        color: theme.text.primary,
        textTransform: "none",
        width: 270,
        justifyContent: "center",
        padding: 10,
        marginBottom: 25,
      },
    }),
  { index: 1 }
);

function CardDeposit(props) {
  const classes = useStyles(props);
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div>
            <Typography className={classes.typographyPrimary} variant="h6">
              -$10,000.00
            </Typography>
          </div>
        </CardContent>
        <CardActions className={classes.cardAction}>
          <Button className={classes.buttonActionRedeposit}>
            <img
              src={`${EntypoCycle}`}
              alt="EntypoCycle"
              style={{ marginRight: 10 }}
            />
            {t("redeposit")}
          </Button>
        </CardActions>
      </Card>
    </div>
  );
}

CardDeposit.propTypes = {};

export default CardDeposit;
