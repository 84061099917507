import {
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Typography,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Pagination, PaginationItem } from "@material-ui/lab";
import React from "react";
import { useTranslation } from "react-i18next";

// import "./styles.css";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        border: `none`,
        padding: "0 15px",
        maxHeight: "70vh",
        overflowY: "auto",
      },
      rootTable: {
        backgroundColor: theme.palette.myBackgroundColor?.primary,
      },
      rootCellData: {
        wordBreak: "break-word",
        fontWeight: "bolder",
        fontSize: 14,
        color: theme.text.primary,
        fontFamily: "'Chakra Petch', sans-serif",
      },
      cellHeaderSticky: {
        position: "sticky",
        top: 0,
        padding: "10px 15px 10px 30px",
        border: "none",
        zIndex: 2,
        backgroundColor: "#191D23",
      },
      cellBody: {
        padding: "10px 15px 10px 30px",
        border: "none",
      },
      textHeader: {
        fontWeight: "bolder",
        fontSize: 18,
        color: theme.text.link,
        textTransform: "uppercase",
        fontFamily: "'Chakra Petch', sans-serif",
      },
      cell: {},
      rootPagination: {
        display: "flex",
        justifyContent: "flex-end",
        margin: "0 25px 10px 25px",
      },
      pagination: {
        color: theme.text.primary,
        fontWeight: "bolder",
        fontSize: 16,
      },
      rootPaginationItem: {
        border: `none`,
        color: theme.text.primary,
        fontWeight: "bolder",
      },
      ellipsisPagination: { border: "none" },
      pageSelected: {
        // backgroundColor: "transparent !important",
        border: `none`,
        color: theme.text.link,
        fontWeight: "bolder",
        fontSize: 16,
      },
    }),
  { index: 1 }
);

export default function TableWinner(props) {
  const classes = useStyles(props);
  const { headers, data, pagination, style, handleUpdatePagination } = props;
  const { t } = useTranslation();

  const renderCellData = (valueCell) => {
    switch (typeof valueCell) {
      case "string": {
        return (
          <Typography variant="body2" classes={{ root: classes.rootCellData }}>
            {valueCell}
          </Typography>
        );
      }
      default:
        return valueCell;
    }
  };

  return (
    <>
      <TableContainer classes={{ root: classes.root }} style={style}>
        <Table classes={{ root: classes.rootTable }}>
          <TableHead>
            <TableRow hover={false}>
              {headers?.map((col, index) => (
                <TableCell
                  key={col.key}
                  classes={{
                    root: classes.cellHeaderSticky,
                  }}
                  style={{ textAlign: col.align }}
                >
                  {col.render ? (
                    col.render
                  ) : (
                    <Typography
                      variant="body2"
                      style={{
                        width: col.width,
                      }}
                      classes={{ root: classes.textHeader }}
                    >
                      {col.name}
                    </Typography>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length === 0 && (
              <TableRow>
                <TableCell
                  classes={{
                    root: classes.cellBody,
                  }}
                  style={{ textAlign: "center", border: "none" }}
                  colSpan={headers.length}
                >
                  <Typography
                    variant="body1"
                    classes={{ root: classes.rootCellData }}
                  >
                    {t("No data")}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {data?.map((row, indexData) => (
              <TableRow key={indexData} role="checkbox" hover={false}>
                {headers.map((cell, index) => (
                  <TableCell
                    key={`${cell.key}-${indexData}`}
                    classes={{
                      root: classes.cellBody,
                    }}
                    style={{ textAlign: cell.align }}
                  >
                    {renderCellData(row[cell.key])}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && (
        <div className={classes.rootPagination}>
          <Pagination
            count={pagination.count}
            page={pagination.page}
            onChange={(_, value) => {
              handleUpdatePagination(value);
            }}
            variant="outlined"
            shape="rounded"
            classes={{ root: classes.pagination }}
            renderItem={(item) => (
              <PaginationItem
                classes={{
                  root: classes.rootPaginationItem,
                  ellipsis: classes.ellipsisPagination,
                  selected: classes.pageSelected,
                }}
                {...item}
                variant="outlined"
              />
            )}
          />
        </div>
      )}
    </>
  );
}
