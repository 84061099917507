import Cookies from "js-cookie";
import { useSnackbar } from "notistack";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { registration } from "../../apis/user.api";
import FormRegister from "../../components/Form/FormRegister";
import TopBarHandleAccount from "../../components/TopBar/TopBarHandleAccount";
import { PATH } from "../../const/paths";

export default function Register() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [refCode, setRefCode] = useState(null);

  const handleRegister = (values) => {
    registration(values)
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("register_token", res.data.access);
          enqueueSnackbar(
            t("Register success! Please go to email to verify your account"),
            { variant: "info" }
          );
          history.push(PATH.LOGIN);
        }
      })
      .catch((err) => {
        if (err.response) {
          const { data } = err.response;
          enqueueSnackbar(data.message, { variant: "error" });
        }
      });
  };

  useEffect(() => {
    let ref_code = Cookies.get("ref_code");
    if (history.location?.search) {
      let { r } = queryString.parse(history.location?.search);
      setRefCode(r);
      if (!ref_code || (ref_code && r !== ref_code)) {
        Cookies.set("ref_code", r, { expires: 30 });
      }
    } else {
      setRefCode(ref_code || "");
    }
  }, []);

  return (
    <div>
      <TopBarHandleAccount />
      <FormRegister handleRegister={handleRegister} refCode={refCode} />
    </div>
  );
}
