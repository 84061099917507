import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useState } from "react";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import { createStyles, makeStyles, Typography } from "@material-ui/core";
import { useEffect } from "react";
import { getNotificationSystem } from "../../apis/noti.api";
import { useSelector } from "react-redux";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      dialogPaper: {
        background: theme.color.light,
        boxShadow: "none",
        maxWidth: 500,
      },
      headerRoot: {
        position: "relative",
        textAlign: "center",
        paddingBottom: 5,
      },
      closeButton: {
        position: "absolute",
        padding: 0,
        right: 20,
        cursor: "pointer",
        minWidth: 0,
      },
      bodyRoot: {
        wordBreak: "break-word",
        padding: "0 16px 16px",
      },
      iconRoot: {
        // fill: theme.text.primary,
      },
      title: {
        fontWeight: 600,
        fontSize: 20,
        textAlign: "center",
        paddingBottom: 5,
      },
      content: { textAlign: "center" },
    }),
  { index: 1 }
);

export default function DialogNotiSystem() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState();
  const userProfile = useSelector((state) => state.userReducer.profile);

  const fetchNotiSystem = async () => {
    try {
      const notiSystem = localStorage.getItem("noti_system");
      const notiSystemParse = JSON.parse(notiSystem);
      let res = await getNotificationSystem();
      if (
        res.data?.results[0] &&
        ((notiSystemParse?.count < 2 &&
          notiSystemParse?.id === res.data?.results[0]?.id) ||
          !notiSystemParse)
      ) {
        setData(res.data.results[0]);
        localStorage.setItem(
          "noti_system",
          JSON.stringify({
            id: res.data.results[0].id,
            count:
              notiSystemParse?.id === res.data?.results[0]?.id
                ? (notiSystemParse?.id || 0) + 1
                : 1,
          })
        );
        setOpen(true);
      }
    } catch (error) {}
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (userProfile?.id) {
      fetchNotiSystem();
    }
  }, [userProfile]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle
          id="alert-dialog-title"
          classes={{ root: classes.headerRoot }}
        >
          <NotificationsActiveIcon
            fontSize="large"
            classes={{ root: classes.iconRoot }}
          />
          <Button classes={{ root: classes.closeButton }} onClick={handleClose}>
            <CloseOutlinedIcon />
          </Button>
        </DialogTitle>
        <DialogContent classes={{ root: classes.bodyRoot }}>
          <Typography variant="h5" className={classes.title}>
            {data?.title}
          </Typography>
          <Typography variant="body2" className={classes.content}>
            {data?.content}
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
}
