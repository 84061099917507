import {
  Button,
  createStyles,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Eyeclose from "../../assets/img/Eyeclose.png";
import Eyeopen from "../../assets/img/Eyeopen.png";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        display: "flex",
        padding: 20,
        width: "100%",
        marginLeft: theme.marginLayout,
        backgroundColor: theme.color.background,
        [theme.breakpoints.down("xs")]: {
          margin: 0,
          backgroundColor: "transparent",
        },
      },
      buttonBalance: {
        backgroundColor: "hsla(0, 0%, 100%, 0.05)",
        color: theme.text.secondary,
        marginLeft: 20,
        textTransform: "none",
      },
      buttonIncrease: {
        borderColor: "#ffcb6b",
        backgroundColor: "#ffcb6b",
        textTransform: "none",
        color: theme.text.primary,
        margin: "0 5px",
        padding: "0px 10px",
        borderRadius: 2,
        [theme.breakpoints.down("xs")]: {
          flexDirection: "column",
          alignItems: "flex-start",
          margin: "10px 0",
        },
      },
    }),
  { index: 1 }
);

export default function HeaderMyAssets(props) {
  const classes = useStyles(props);
  const { showBalance, handleHideBalance, handleShowBalance } = props;
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Typography variant="h5">{t("My assets")}</Typography>
      {showBalance ? (
        <Button className={classes.buttonBalance} onClick={handleHideBalance}>
          <img
            src={`${Eyeclose}`}
            alt="Eyeclose"
            style={{ marginRight: 5 }}
          ></img>
          <Typography variant="body2">{t("Hide balance")}</Typography>
        </Button>
      ) : (
        <Button className={classes.buttonBalance} onClick={handleShowBalance}>
          <img
            src={`${Eyeopen}`}
            alt="Eyeopen"
            style={{ marginRight: 5 }}
          ></img>
          <Typography variant="body2">{t("Show balance")}</Typography>
        </Button>
      )}
    </div>
  );
}
