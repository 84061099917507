import {
  Box,
  Button,
  Card,
  CardContent,
  createStyles,
  makeStyles,
  Typography,
} from "@material-ui/core";
import BigNumber from "bignumber.js";
import { find } from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import formatCurrency from "../../common/formatCurrency";
import { bch } from "../../const/currency";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {},
      card: {
        backgroundColor: theme.color.background,
        border: `1px solid ${theme.color.border}`,
        borderRadius: "10px",
      },
      cardContent: { padding: theme.spacing(4), position: "relative" },
      bonusRoot: { position: "absolute", right: 10, top: 10 },
      bonusContainer: {
        position: "relative",
        width: "max-content",
      },
      banner: {
        position: "relative",
        zIndex: 2,
        width: 50,
        height: 50,
        border: `3px solid ${theme.color.trendingUp}`,
        borderRadius: "50%",
        background: theme.text.demo,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      },
      bonusLabel: { color: "#000000", fontSize: 10 },
      bonusValue: {
        fontWeight: 700,
        color: "#000000",
        fontSize: 14,
        lineHeight: "12px",
      },
      ribbonLeft: {
        position: "absolute",
        clipPath: "polygon(55% 0, 100% 0, 51% 100%, 40% 74%, 0 100%)",
        background: theme.color.primary,
        width: 20,
        height: 20,
        bottom: -10,
        left: 5,
      },
      ribbonRight: {
        position: "absolute",
        clipPath: "polygon(0 0, 45% 0, 100% 100%, 60% 75%, 50% 100%)",
        background: theme.color.primary,
        width: 20,
        height: 20,
        bottom: -10,
        right: 5,
      },
      row: {
        display: "flex",
        justifyContent: "space-between",
      },
      typographyTitle: {
        fontWeight: "bold",
        color: theme.text.primary,
        textAlign: "center",
        marginBottom: 20,
      },
      typographyInfoLabel: {
        color: theme.text.secondary,
        fontSize: 14,
      },
      typographyInfoValue: { color: theme.text.success, fontSize: 14 },
      typographyPrice: { color: theme.text.primary, textAlign: "center" },
      minInvestContainer: {
        display: "flex",
        justifyContent: "space-between",
        // alignItems: "center",
        gap: 10,
        marginBottom: 20,
      },
      minInvestLabel: {
        fontSize: 12,
        color: theme.text.secondary,
        paddingTop: 5,
      },
      buttonContainer: {
        display: "flex",
        justifyContent: "center",
      },
      button: {
        color: theme.text.primary,
        borderColor: theme.color.primary,
        backgroundColor: theme.color.primary,
        borderRadius: 1,
        textTransform: "none",
        minWidth: 170,
        height: 40,
      },
      buttonDisabled: {
        color: `#757575 !important`,
        backgroundColor: "#bdbdbd",
      },
    }),
  { index: 1 }
);

function Bot(props) {
  const classes = useStyles(props);
  const { handleRentNow, botInfo } = props;
  const [firstInvestBCH, setFirstInvestBCH] = useState("0");
  const { t } = useTranslation();
  const currencies = useSelector(
    (state) => state.walletReducer.info?.results || []
  );

  useEffect(() => {
    const bchWallet = find(
      currencies.map((currency) => currency.c),
      { symbol: bch.symbol }
    );
    if (botInfo.first_invest && bchWallet?.usdt_rate) {
      const firstInvestBCH = new BigNumber(botInfo.first_invest)
        .div(bchWallet?.usdt_rate)
        .toFixed(2);
      setFirstInvestBCH(firstInvestBCH);
    }
  }, [botInfo.first_invest, currencies]);

  return (
    <div>
      <Card className={classes.card}>
        <CardContent classes={{ root: classes.cardContent }}>
          <div className={classes.bonusRoot}>
            <div className={classes.bonusContainer}>
              <div className={classes.banner}>
                <Typography variant="caption" className={classes.bonusLabel}>
                  Bonus
                </Typography>
                <Typography variant="body1" className={classes.bonusValue}>
                  5%
                </Typography>
              </div>
              <div className={classes.ribbonLeft}></div>
              <div className={classes.ribbonRight}></div>
            </div>
          </div>

          <div>
            <Typography variant="h5" className={classes.typographyTitle}>
              {botInfo.name?.toUpperCase()} {t("BOT")}
            </Typography>
          </div>
          <Box className={classes.row}>
            <Typography
              variant="caption"
              className={classes.typographyInfoLabel}
            >
              {t("Profit")}
            </Typography>
            <Typography
              variant="caption"
              className={classes.typographyInfoValue}
            >
              <strong>
                {Math.round(parseFloat(botInfo.min_profit) * 100)}% ~{" "}
                {Math.round(parseFloat(botInfo.max_profit) * 100)}%
              </strong>{" "}
              {t("yearly")}
            </Typography>
          </Box>
          <Box className={classes.minInvestContainer}>
            <Typography
              variant="caption"
              className={classes.typographyInfoLabel}
            >
              Min invest
            </Typography>
            <div>
              <Typography variant="h5" className={classes.typographyPrice}>
                {formatCurrency(parseFloat(botInfo.first_invest))}
              </Typography>
              <Typography variant="body2" className={classes.typographyPrice}>
                {formatCurrency(parseFloat(firstInvestBCH), "BCH")}
              </Typography>
            </div>
          </Box>
          <div className={classes.buttonContainer}>
            <Button
              className={classes.button}
              classes={{ disabled: classes.buttonDisabled }}
              variant="outlined"
              onClick={() => {
                handleRentNow(botInfo);
              }}
            >
              {t("Invest")}
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

Bot.propTypes = {
  botType: PropTypes.string,
  handleRentNow: PropTypes.func,
};

export default Bot;
