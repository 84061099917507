import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import moment from "moment";
import convertNumberToCurrency from "../../common/convertNumberToCurrency";
import { COMMISSION_TYPE } from "../../const/common";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        maxHeight: 400,
      },
      table: {
        border: `1px solid ${theme.color.border}`,
        borderBottom: "none",
      },
      tableCellHeader: {
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        border: "none",
        color: theme.text.secondary,
      },
      tableCellBody: {
        borderBottom: "1px solid #424242",
        color: "#fff",
      },
      rootPagination: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: 20,
        marginBottom: 50,
      },
      pagination: {
        color: theme.text.primary,
      },
      rootPaginationItem: {
        border: `1px solid ${theme.text.primary}`,
        color: theme.text.primary,
        borderRadius: 2,
      },
      ellipsisPagination: { border: "none" },
      pageSelected: {
        backgroundColor: "hsla(0, 0%, 100%, 0.1) !important",
        border: `1px solid ${theme.color.border}`,
      },
    }),
  { index: 1 }
);

function createData(date, total_volume, total_trade) {
  return { date, total_volume, total_trade };
}

function TableTradeVolume(props) {
  const classes = useStyles(props);
  const { dataTable, pagination, handleChangePage } = props;
  const [rows, setRows] = useState([]);
  const { t } = useTranslation();

  const columns = [
    { id: "date", label: t("Date"), minWidth: 100 },
    { id: "total_volume", label: t("Total trade volume"), minWidth: 100 },
    { id: "total_trade", label: t("Total trade"), minWidth: 100 },
  ];

  useEffect(() => {
    if (dataTable) {
      let rows = dataTable?.results.map((data) => {
        let date = moment(data.month)?.format("MM/YYYY");
        let { total_volume, total_trade } = data;
        total_volume = (
          <Typography variant="body2" style={{ color: "#23CA7A" }}>
            {convertNumberToCurrency(total_volume)}
          </Typography>
        );
        return createData(date, total_volume, total_trade);
      });
      setRows(rows);
    }
  }, [dataTable]);

  return (
    <>
      <TableContainer className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className={classes.tableCellHeader}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length === 0 ? (
              <TableRow tabIndex={-1}>
                <TableCell
                  colSpan={3}
                  className={classes.tableCellBody}
                ></TableCell>
              </TableRow>
            ) : (
              rows.map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          className={classes.tableCellBody}
                        >
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.rootPagination}>
        {dataTable?.count > pagination.page_size && (
          <Pagination
            count={Math.ceil(dataTable?.count / pagination.page_size || 0)}
            page={pagination.page}
            onChange={(_, value) => {
              handleChangePage(value);
            }}
            variant="outlined"
            shape="rounded"
            classes={{ root: classes.pagination }}
            renderItem={(item) => (
              <PaginationItem
                classes={{
                  root: classes.rootPaginationItem,
                  ellipsis: classes.ellipsisPagination,
                  selected: classes.pageSelected,
                }}
                {...item}
                variant="outlined"
              />
            )}
          />
        )}
      </div>
    </>
  );
}

TableTradeVolume.propTypes = {};

export default TableTradeVolume;
