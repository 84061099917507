import {
  Card,
  CardContent,
  CardHeader,
  createStyles,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        width: 464,
        margin: "15px 0 15px 15px",
        [theme.breakpoints.down("xs")]: { maxWidth: 328, margin: "15px auto" },
      },
      gridContainer: { margin: "15px 0" },
      rootList: { padding: 0 },
      card: {
        backgroundColor: "rgba(255, 255, 255, 0.03)",
        color: theme.text.primary,
      },
      cardHeader: { paddingBottom: 0 },
      cardContent: { paddingTop: 0, minWidth: 0 },
      typographySecondary: { color: theme.text.secondary, marginTop: 10 },
      email: { color: theme.text.link, textDecoration: "none" },
    }),
  { index: 1 }
);

export default function CardEmailSupport(props) {
  const classes = useStyles(props);
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          title={<Typography variant="body1">{t("Support")}</Typography>}
        />
        <CardContent className={classes.cardContent}>
          <Typography
            variant="body2"
            classes={{ root: classes.typographySecondary }}
          >
            {t("Email")}:{" "}
            <a
              href="mailto:support@alltrade.ventures"
              className={classes.email}
            >
              support@alltrade.ventures
            </a>
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}
