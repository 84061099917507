import { call, put, takeEvery } from "redux-saga/effects";
import { buyBotV2, depositBotV2, getUserBotV2 } from "../../apis/botv2.api";
import { TOAST_NOTI } from "../../const/common";
import * as types from "../../const/redux/botV2";
import {
  buyBotV2Success,
  depositBotV2Success,
  getBotV2Success,
} from "../actions/botV2Action";
import { callToastNotiSuccess } from "../actions/toastNotiAction";

function* getBotV2Sagas({ payload }) {
  try {
    let responseBotV2 = yield call(getUserBotV2);
    if (responseBotV2.status === 200) {
      let { data } = responseBotV2;
      yield put(getBotV2Success(data));
    }
  } catch (err) {
    let { data } = err.response;
    yield put(
      callToastNotiSuccess({
        noti: data.message,
        status: TOAST_NOTI.ERROR,
        code: data.code,
      })
    );
  }
}

function* buyBotV2Sagas({ payload }) {
  try {
    const { dataReq } = payload;
    let responseBotV2 = yield call(buyBotV2, dataReq);
    if (responseBotV2.status === 200) {
      let { data } = responseBotV2;
      yield put(buyBotV2Success(data));
    }
  } catch (err) {
    let { data } = err.response;
    yield put(
      callToastNotiSuccess({
        noti: data.message,
        status: TOAST_NOTI.ERROR,
        code: data.code,
      })
    );
  }
}

function* depositBotV2Sagas({ payload }) {
  try {
    const { dataReq } = payload;
    let responseBotV2 = yield call(depositBotV2, dataReq);
    if (responseBotV2.status === 200) {
      let { data } = responseBotV2;
      yield put(depositBotV2Success(data));
    }
  } catch (err) {
    let { data } = err.response;
    yield put(
      callToastNotiSuccess({
        noti: data.message,
        status: TOAST_NOTI.ERROR,
        code: data.code,
      })
    );
  }
}

export function* watchBotV2Async() {
  yield takeEvery(types.GET_BOT_V2_REQ, getBotV2Sagas);
  yield takeEvery(types.BUY_BOT_V2_REQ, buyBotV2Sagas);
  yield takeEvery(types.DEPOSIT_BOT_V2_REQ, depositBotV2Sagas);
}
