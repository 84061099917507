import {
  Button,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { buyBotV2, depositBotV2 } from "../../apis/botv2.api";
import BotV2BG from "../../assets/img/BotV2BG.png";
import DialogBuyBotV2 from "../../components/Dialog/DialogBuyBotV2";
import { TOAST_NOTI } from "../../const/common";
import { getBotPackageReq } from "../../redux/actions/botAction";
import { buyBotV2Req, getBotV2Req } from "../../redux/actions/botV2Action";
import { callToastNotiSuccess } from "../../redux/actions/toastNotiAction";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: "90%",
      margin: "0 auto",
      textAlign: "center",
      padding: "40px 0 80px 0",
      [theme.breakpoints.down("xs")]: {
        width: "80%",
      },
    },
    rootTitle: {
      width: "50%",
      margin: "0 auto",
      paddingBottom: 50,
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        paddingBottom: 20,
      },
    },
    typographyTitle: {
      marginBottom: 10,
    },
    button: {
      margin: "20px 0 40px 0",
      padding: "13px 0",
      width: 250,
      backgroundColor: "#FFAA3A",
      borderColor: "#FFAA3A",
      borderRadius: 2,
      textTransform: "none",
      fontSize: 18,
      fontWeight: 700,
      "&:hover": {
        color: theme.text.primary,
      },
      [theme.breakpoints.down("xs")]: {
        width: "90%",
      },
    },
    rootTable: {
      width: "80%",
      margin: "auto",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    image: {
      width: "55%",
      hieght: "auto",
      [theme.breakpoints.down("xs")]: {
        width: "95%",
      },
    },
  }),
  { index: 1 }
);

export default function BotV2Right(props) {
  const classes = useStyles();
  const [openDialogBuyBot, setOpenDialogBuyBot] = useState(false);
  const dispatch = useDispatch();
  const botV2Info = useSelector((state) => state.botV2Reducer.info);
  const walletsInfo = useSelector((state) => state.walletReducer.info);
  const { t } = useTranslation();

  const handleOpenBuyBot = () => {
    setOpenDialogBuyBot(true);
  };

  const handleCloseBuyBot = () => {
    setOpenDialogBuyBot(false);
  };

  const handlePay = async (amount) => {
    let dataReq = {
      amount,
    };

    await buyBotV2(dataReq)
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            callToastNotiSuccess({
              noti: t("Pay bot successfully"),
              status: "success",
            })
          );
          dispatch(getBotV2Req());
        } else
          dispatch(
            callToastNotiSuccess({
              noti: t("Pay bot failed"),
              status: "error",
            })
          );
      })
      .catch((err) => {
        let { data } = err.response;
        dispatch(
          callToastNotiSuccess({
            noti: data.message,
            status: TOAST_NOTI.ERROR,
            code: data.code,
          })
        );
      });

    handleCloseBuyBot();
  };

  useEffect(() => {
    dispatch(getBotPackageReq());
  }, []);

  return (
    <>
      {!botV2Info?.id && (
        <div className={classes.root}>
          <div className={classes.rootTitle}>
            <Typography
              variant="h5"
              classes={{ root: classes.typographyTitle }}
            >
              {t("Alltrade bot V2")}
            </Typography>
            <Button
              className={classes.button}
              variant="outlined"
              onClick={() => {
                // handleOpenBuyBot();
                handlePay(200);
              }}
            >
              {t("Buy bot V2")} (100$)
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <img src={BotV2BG} alt="BotV2BG" className={classes.image} />
          </div>
        </div>
      )}
      <DialogBuyBotV2
        open={openDialogBuyBot}
        handleClose={handleCloseBuyBot}
        walletInfo={
          walletsInfo.results?.filter((wallet) => wallet.c.symbol === "USDT")[0]
        }
        handlePay={handlePay}
      />
    </>
  );
}
