import { SCHEME, DOMAIN } from "../const/api";
import callApi from "../utils/apiCaller";

const headers = {
  //prettier-ignore
  "Content-Type": "application/json",
};

export const getUserWallet = () => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "get",
    url: `${SCHEME}://${DOMAIN}/v1/wallet/detail/`,
    headers: {
      ...headers,
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
  };
  return callApi(config);
};

export const generatWalletAddress = (curruncyId) => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "get",
    url: `${SCHEME}://${DOMAIN}/v1/wallet/detail/${curruncyId}/generate-address/`,
    headers: {
      ...headers,
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
  };
  return callApi(config);
};

export const getMainTracsaction = (params) => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "get",
    url: `${SCHEME}://${DOMAIN}/v1/wallet/main-transaction/`,
    headers: {
      ...headers,
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
    params,
  };
  return callApi(config);
};

export const withdrawWallet = (walletId, data) => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "post",
    url: `${SCHEME}://${DOMAIN}/v1/wallet/detail/${walletId}/withdraw/`,
    headers: {
      ...headers,
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
    data,
  };
  return callApi(config);
};

export const getDemoWallet = () => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "get",
    url: `${SCHEME}://${DOMAIN}/v1/trade/demo-wallet/`,
    headers: {
      ...headers,
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
  };
  return callApi(config);
};

export const resetDemoWallet = () => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "get",
    url: `${SCHEME}://${DOMAIN}/v1/trade/demo-wallet/reset/`,
    headers: {
      ...headers,
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
  };
  return callApi(config);
};

export const reGetUserWallet = () => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "get",
    url: `${SCHEME}://${DOMAIN}/v1/wallet/detail/usdt-balance/`,
    headers: {
      ...headers,
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
  };
  return callApi(config);
};

export const convertCurrency = (walletId, data) => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "post",
    url: `${SCHEME}://${DOMAIN}/v1/wallet/detail/${walletId}/convert/`,
    headers: {
      ...headers,
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
    data,
  };
  return callApi(config);
};

export const getConvertTransaction = (params) => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "get",
    url: `${SCHEME}://${DOMAIN}/v1/wallet/convert-transaction/`,
    headers: {
      ...headers,
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
    params,
  };
  return callApi(config);
};

export const convertBCHtoALA = (data) => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "post",
    url: `${SCHEME}://${DOMAIN}/v1/wallet/detail/convert-bch-ala/`,
    headers: {
      ...headers,
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
    data,
  };
  return callApi(config);
};
