import {
  Collapse,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import moment from "moment";
import { useEffect, useState } from "react";
import parse from "html-react-parser";

const useStyles = makeStyles(
  (theme) => ({
    listItemRoot: {
      borderBottom: `0.2px solid ${theme.color.border}`,
      padding: "5px 0",
    },
    listItenIconRoot: { paddingTop: 13, minWidth: 0, marginRight: 5 },
    typographyPrimary: {
      color: `${theme.text.primary}`,
    },
    typographySecondary: { color: `${theme.text.secondary}`, fontSize: 12 },
    typographyContent: {
      color: `${theme.text.secondary}`,
      whiteSpace: "pre-wrap",
    },
    collapse: {},
  }),
  { index: 1 }
);

export default function ItemNotification(props) {
  const classes = useStyles();
  const { noti, handleRead } = props;
  const [open, setOpen] = useState(true);

  const handleClick = (noti) => {
    if (!noti.read) handleRead(noti.id);
    setOpen(!open);
  };

  useEffect(() => {
    if (noti) {
      setOpen(noti.read);
    }
  }, [noti]);

  return (
    <ListItem
      button
      onClick={() => {
        handleClick(noti);
      }}
      classes={{ root: classes.listItemRoot }}
    >
      <Grid container>
        <Grid item sm={10} xs={9} style={{ display: "flex" }}>
          <ListItemIcon classes={{ root: classes.listItenIconRoot }}>
            <FiberManualRecordIcon
              style={{
                color: !noti.read ? "#2196f3" : "#9e9e9e",
                fontSize: 16,
              }}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                variant="h6"
                classes={{ root: classes.typographyPrimary }}
              >
                {noti.title}
              </Typography>
            }
            secondary={
              <>
                <Collapse
                  in={open}
                  timeout="auto"
                  collapsedHeight={20}
                  classes={{ root: classes.collapse }}
                >
                  <div className={classes.typographyContent}>
                    {parse(noti.content)}
                  </div>
                </Collapse>
              </>
            }
          />
        </Grid>
        <Grid item sm={2} xs={3} style={{ textAlign: "right", paddingTop: 5 }}>
          <Typography
            variant="body2"
            classes={{ root: classes.typographySecondary }}
          >
            {moment(noti.created).format("YYYY-MM-DD HH:mm:ss")}
          </Typography>
          {!open ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
        </Grid>
      </Grid>
    </ListItem>
  );
}
