import { Grid, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  disable2faAuthentication,
  enable2faAuthentication,
  updateAddress,
  verify2faAuthentication,
} from "../../apis/user.api";
import CardAccountVerification from "../../components/Card/CardAccountVerification";
import CardEmailSupport from "../../components/Card/CardEmailSupport";
import CardSecurity from "../../components/Card/CardSecurity";
import CardWithdrawAutomation from "../../components/Card/CardWithdrawAutomation";
import DialogChangePassword from "../../components/Dialog/DialogChangePassword";
import DialogUpdateAddress from "../../components/Dialog/DialogUpdateAddress";
import DialogVerify2FA from "../../components/Dialog/DialogVerify2FA";
import HeaderProfileInfo from "../../components/Header/HeaderProfileInfo";
import { TOAST_NOTI } from "../../const/common";
import { callToastNotiSuccess } from "../../redux/actions/toastNotiAction";
import {
  changePasswordReq,
  getUserProfileReq,
} from "../../redux/actions/userAction";

const useStyles = makeStyles(
  (theme) => ({
    root: { width: "100%", minHeight: "100vh" },
    coverCardRight: {
      marginBottom: 10,
      [theme.breakpoints.down("xs")]: {
        maxWidth: 328,
        margin: "0px auto 50px auto",
      },
    },
    coverCardLeft: {
      [theme.breakpoints.down("xs")]: {
        maxWidth: 328,
        margin: "0px auto",
      },
    },
  }),
  { index: 1 }
);

const address = "Hanoi";
export default function ProfileInfo(props) {
  const classes = useStyles(props);
  const { handleShowAccountVerification } = props;
  const [actionClick, setActionClick] = useState({ action: "", open: false });
  const [dataEnable2Fa, setDataEnable2Fa] = useState(null);
  const [enable2FA, setEnable2FA] = useState(false);
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.userReducer.profile);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  const callUpdateAddress = (userId, dataReq) => {
    updateAddress(userId, dataReq)
      .then((res) => {
        if (res.status === 200) {
          dispatch(getUserProfileReq());
        }
      })
      .catch((err) => {
        let { data } = err.response;
        dispatch(
          callToastNotiSuccess({
            noti: data.message,
            status: TOAST_NOTI.ERROR,
            code: data.code,
          })
        );
      });
  };

  const handleActionClick = (action) => {
    setActionClick({ action, open: true });
    if (
      action === "Verify 2FA" &&
      Object.keys(userProfile).length > 0 &&
      !enable2FA
    ) {
      handleEnable2fa();
    }
  };

  //2fa

  const handleEnable2fa = () => {
    let userId = userProfile?.id;
    enable2faAuthentication(userId)
      .then((res) => {
        if (res.status === 200) {
          let { data } = res;
          setDataEnable2Fa(data);
          dispatch(getUserProfileReq());
        }
      })
      .catch((err) => {
        if (err.response) {
          const { data } = err.response;
          enqueueSnackbar(data.message, { variant: "error" });
        }
      });
  };

  const handleDisable2fa = (values) => {
    if (Object.keys(userProfile).length > 0) {
      let userId = userProfile?.id;
      disable2faAuthentication(userId, values)
        .then((res) => {
          if (res.status === 200) {
            setActionClick({ ...actionClick, open: false });
            setEnable2FA(false);
            dispatch(getUserProfileReq());
          }
        })
        .catch((err) => {
          if (err.response) {
            const { data } = err.response;
            enqueueSnackbar(data.message, { variant: "error" });
          }
        });
    }
  };

  const handleVerifyTwoFaCode = (values) => {
    if (Object.keys(userProfile).length > 0) {
      let userId = userProfile?.id;
      verify2faAuthentication(userId, values)
        .then((res) => {
          if (res.status === 200) {
            setActionClick({ ...actionClick, open: false });
            setEnable2FA(true);
          }
        })
        .catch((err) => {
          if (err.response) {
            const { data } = err.response;
            enqueueSnackbar(data.message, { variant: "error" });
          }
        });
    }
  };

  //password

  const handleChangePassword = (values) => {
    dispatch(changePasswordReq(values));
    // changePassword(values);
  };

  //

  const handleCloseDialogAction = () => {
    setActionClick({ ...actionClick, open: false });
  };

  const handleAutoWithdraw = (userId, checked) => {
    let dataReq = { is_enable_auto_withdraw: checked };
    callUpdateAddress(userId, dataReq);
  };

  const handleUpdateAutoAddress = (userId, value) => {
    let dataReq = {
      auto_withdraw_address: value.address,
      otp_token: value.twoFa,
    };
    callUpdateAddress(userId, dataReq);
  };

  const handleUpdateAddress = (value) => {};

  const renderDialogAction = () => {
    let { action, open } = actionClick;
    if (action === "Verify account") {
      handleShowAccountVerification();
    }
    return (
      <>
        <DialogVerify2FA
          open={action === "Verify 2FA" ? open : false}
          handleClose={handleCloseDialogAction}
          dataEnable2Fa={dataEnable2Fa}
          handleVerifyTwoFaCode={handleVerifyTwoFaCode}
          enable2FA={enable2FA}
          handleDisable2fa={handleDisable2fa}
        />
        <DialogChangePassword
          open={action === "Change password" ? open : false}
          //   open={actionClick.open}
          handleClose={handleCloseDialogAction}
          handleChangePassword={handleChangePassword}
        />
        <DialogUpdateAddress
          open={action === "Update address" ? open : false}
          //   open={actionClick.open}
          handleClose={handleCloseDialogAction}
          handleUpdateAddress={handleUpdateAddress}
          address={address}
        />
      </>
    );
  };

  useEffect(() => {
    if (userProfile.two_factor_token?.status === "verified") {
      setEnable2FA(true);
    }
  }, [userProfile]);

  return (
    <div className={classes.root}>
      {Object.keys(userProfile).length > 0 && (
        <Grid container>
          <HeaderProfileInfo userProfile={userProfile} />
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <div className={classes.coverCardLeft}>
              <CardSecurity
                handleActionClick={handleActionClick}
                enable2FA={enable2FA}
              />
            </div>
            <div className={classes.coverCardRight}>
              {/* <CardAccountVerification
                handleActionClick={handleActionClick}
                profile={userProfile}
              /> */}
              <CardEmailSupport />
            </div>
          </Grid>
        </Grid>
      )}
      {renderDialogAction()}
    </div>
  );
}

ProfileInfo.propTypes = { handleShowAccountVerification: PropTypes.func };
