import {
  Button,
  Card,
  CardContent,
  CardHeader,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  Input,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import UltraBot from "../../assets/img/UltraBot.png";
import convertNumberToMoney from "../../common/convertNumberToMoney";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      rootDrawer: {
        backgroundColor: theme.body.backgroundColor,
        height: "100%",
      },
      rootCard: {
        backgroundColor: "rgba(255, 255, 255, 0.03)",
        height: "100%",
        boxShadow: "none",
      },
      rootCardHeader: {
        background: theme.color.background,
      },
      //   rootCardContent: { backgroundColor: "rgba(255, 255, 255, 0.03)" },
      coverImage: { textAlign: "center", margin: "0 0 5px 0" },
      dialogHeader: {
        color: "#fff",
        padding: "10px 20px",
      },
      closeButton: {
        position: "absolute",
        right: 2,
        top: 0,
        color: theme.text.primary,
      },
      dialogContent: {},
      dividers: { borderTopColor: "#272C3A" },
      textField: {
        marginBottom: 15,
        backgroundColor: "#2A2D35",
      },
      cssOutlinedInput: {
        "&$cssFocused $notchedOutline": {
          borderColor: `${theme.text.primary} !important`,
        },
      },
      notchedOutline: {
        borderWidth: "1px",
        borderColor: "#2A2D35 !important",
        borderRadius: "2px !important",
      },
      multilineColor: {
        color: theme.text.primary,
        fontSize: 13,
      },
      input: {
        "&::placeholder": {
          opacity: 0.2,
        },
      },
      typographySecondary: {
        color: theme.text.secondary,
      },
      typographyPrimary: {
        color: theme.text.primary,
      },
      typographyName: {
        color: theme.text.primary,
        fontWeight: 600,
        fontSize: 18,
        textAlign: "center",
      },
      typographyValue: { paddingLeft: 20 },
      buttonSubmit: {
        width: "100%",
        color: theme.text.primary,
        backgroundColor: theme.color.primary,
        textTransform: "none",
        borderRadius: 2,
        marginTop: 10,
      },
      paper: {
        width: 456,
        // minHeight: 573,
        // maxHeight: 700,
        backgroundColor: "#191D23",
      },
      imageBot: {
        width: 146,
        height: 146,
      },
      dialogActions: {
        padding: "0 15px 25px 15px",
      },
      rootContent: {
        padding: "10px 60px 20px 60px",
        [theme.breakpoints.down("xs")]: {
          padding: "10px 0",
        },
      },
      endAdornment: { color: theme.text.link, minWidth: 10, cursor: "pointer" },
      rootFormControl: {
        width: "100%",
        margin: "10px 0",
      },
      rootSelect: {
        color: theme.text.primary,
        padding: "10px 10px",
        border: "none",
      },
      rootMenu: {},
      iconMenu: {
        color: theme.text.primary,
      },
    }),
  { index: 1 }
);

export default function DialogBuyBotTrade({
  botSelected,
  handleClose,
  handlePayBot,
  wallet,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const [amount, setAmount] = useState("");

  const handleChangeAmount = (e) => {
    let value = e.target?.value;
    if (!isNaN(Number(value))) {
      setAmount(value);
    }
  };

  const handleAllAmount = () => {
    setAmount(wallet.balance);
  };

  useEffect(() => {
    if (!botSelected.open) {
      setAmount("");
    }
  }, [botSelected.open]);

  const renderContent = () => {
    return (
      <>
        <div className={classes.rootContent}>
          <div>
            <Typography variant="body1" className={classes.typographyName}>
              {botSelected.info.name?.toUpperCase()} BOT
            </Typography>
          </div>
          <div>
            <Typography
              variant="caption"
              className={classes.typographySecondary}
            >
              {t("Amount")}
            </Typography>
            <FormControl classes={{ root: classes.rootFormControl }}>
              <TextField
                className={classes.textField}
                fullWidth
                variant="outlined"
                id="groupName"
                InputLabelProps={{ shrink: false }}
                InputProps={{
                  endAdornment: (
                    <div
                      className={classes.endAdornment}
                      onClick={() => {
                        handleAllAmount();
                      }}
                    >
                      <Typography variant="caption">{t("All")}</Typography>
                    </div>
                  ),
                  classes: {
                    root: classes.cssOutlinedInput,
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                  className: classes.multilineColor,
                }}
                size="small"
                placeholder="0"
                value={amount}
                onChange={handleChangeAmount}
              />
            </FormControl>
          </div>
          <Grid container>
            <Grid item xs={5}>
              <Typography
                variant="caption"
                className={classes.typographySecondary}
              >
                {t("Available balances")}:
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography
                variant="caption"
                className={classes.typographyPrimary}
              >
                {wallet && convertNumberToMoney(parseFloat(wallet.balance))}{" "}
                {wallet?.c?.symbol}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div>
          <Button
            className={classes.buttonSubmit}
            onClick={() => {
              handlePayBot(amount);
            }}
          >
            {t("Pay")}
          </Button>
        </div>
      </>
    );
  };

  return (
    <>
      {matches ? (
        <Drawer
          anchor={"bottom"}
          open={botSelected.open}
          onClose={handleClose}
          classes={{ paper: classes.rootDrawer }}
        >
          <Card classes={{ root: classes.rootCard }}>
            <CardHeader
              title={
                <Typography
                  classes={{ root: classes.typographyPrimary }}
                  variant="h6"
                >
                  {t("Invest")}
                </Typography>
              }
              action={
                <IconButton
                  classes={{ root: classes.closeButton }}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>
              }
              classes={{ root: classes.cardHeader }}
            />
            <CardContent>{renderContent()}</CardContent>
          </Card>
        </Drawer>
      ) : (
        <Dialog
          open={botSelected.open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={false}
          classes={{ paper: classes.paper }}
        >
          <DialogTitle id="alert-dialog-title" className={classes.dialogHeader}>
            <Typography variant="body1">{t("Invest")}</Typography>
            <IconButton className={classes.closeButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            className={classes.dialogContent}
            dividers={true}
            classes={{ dividers: classes.dividers }}
          >
            {renderContent()}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

DialogBuyBotTrade.propTypes = {
  botSelected: PropTypes.object,
  handleClose: PropTypes.func,
  handlePayBot: PropTypes.func,
};
