import { all } from "redux-saga/effects";
import { watchBotAsync } from "./botSagas";
import { watchBotV2Async } from "./botV2Sagas";
import { watchReferralAsync } from "./referralSagas";
import { watchTransactionAsync } from "./transactionSagas";
import { watchUserAsync } from "./userSagas";
import { watchWalletAsync } from "./walletSagas";
import { watchTradingBotAsync } from "./tradingBotV2Sagas";

export default function* rootSaga() {
  yield all([
    watchUserAsync(),
    watchWalletAsync(),
    watchBotAsync(),
    watchReferralAsync(),
    watchTransactionAsync(),
    watchBotV2Async(),
    watchTradingBotAsync(),
  ]);
}
