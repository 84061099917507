import { AppBar, createStyles, makeStyles, Tab, Tabs } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        width: "100%",
        minHeight: "75vh",
        backgroundColor: theme.color.background,
        margin: "10px 0 0 10px",
        padding: "20px 100px 20px 100px",
        [theme.breakpoints.down("xs")]: {
          padding: "0 10px",
          backgroundColor: "transparent",
          margin: 0,
        },
      },
      appBar: {
        backgroundColor: "transparent",
        boxShadow: "none",
        padding: "0 10px",
      },
      typographySecondary: {
        color: theme.text.secondary,
      },
      typographyPrimary: {
        color: theme.text.primary,
      },
      tabs: { borderBottom: "2px solid #9e9e9e" },
      tab: {
        padding: 0,
        marginRight: 10,
        textTransform: "none",
        fontWeight: "bold",
        // color: "#848E9C",
        // fontFamily: [
        //   "-apple-system",
        //   "BlinkMacSystemFont",
        //   '"Segoe UI"',
        //   "Roboto",
        //   '"Helvetica Neue"',
        //   "Arial",
        //   "sans-serif",
        //   '"Apple Color Emoji"',
        //   '"Segoe UI Emoji"',
        //   '"Segoe UI Symbol"',
        // ].join(","),
        minWidth: 70,
        "&:hover": {
          color: "#fff",
          opacity: 1,
        },
        "&:focus": {
          color: "#fff",
        },
      },
    }),
  { index: 1 }
);

function PaperAgencyInfo(props) {
  const classes = useStyles(props);
  const { valueTab, handleChangeTab, children } = props;
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <AppBar position="static" classes={{ root: classes.appBar }}>
        <Tabs
          className={classes.tabs}
          value={valueTab}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="inherit"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            label={t("Dashboard")}
            className={classes.tab}
            disableRipple={true}
          />
          <Tab
            label={t("Commission")}
            className={classes.tab}
            disableRipple={true}
          />
          <Tab
            label={t("Invest commission")}
            className={classes.tab}
            disableRipple={true}
          />
          <Tab
            label={t("Network Management")}
            className={classes.tab}
            disableRipple={true}
          />
        </Tabs>
      </AppBar>
      <div>{children}</div>
    </div>
  );
}

PaperAgencyInfo.propTypes = {
  valueTab: PropTypes.number,
  handleChangeTab: PropTypes.func,
};

export default PaperAgencyInfo;
