import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { login, login2Fa } from "../../apis/user.api";
import FormLogin from "../../components/Form/FormLogin";
import FormLogin2Fa from "../../components/Form/FormLogin2Fa";
import TopBarHandleAccount from "../../components/TopBar/TopBarHandleAccount";
import { PATH } from "../../const/paths";

export default function Login() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [enable2Fa, setEnable2Fa] = useState(false);
  const [infoLogin, setInfoLogin] = useState();

  const handleLogin = (values) => {
    login(values)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.access) {
            localStorage.setItem("jwt_token", res.data.access);
            enqueueSnackbar(t("Login success"), { variant: "success" });
            history.push(PATH.PROFILE);
          } else {
            setEnable2Fa(true);
            setInfoLogin(res.data);
          }
        }
      })
      .catch((err) => {
        if (err.response) {
          const { data } = err.response;
          enqueueSnackbar(data.message, { variant: "error" });
        }
      });
  };

  const handleLogin2Fa = (values) => {
    let dataReq = {
      hotp_token: infoLogin?.hotp,
      user_id: infoLogin?.user_id,
      otp_token: values?.otp_token,
    };
    login2Fa(dataReq)
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("jwt_token", res.data.access);
          enqueueSnackbar(t("Login success"), { variant: "success" });
          history.push(PATH.PROFILE);
        }
      })
      .catch((err) => {
        if (err.response) {
          const { data } = err.response;
          enqueueSnackbar(data.message, { variant: "error" });
        }
      });
  };

  const handleBack = () => {
    setEnable2Fa(false);
  };

  return (
    <div>
      <TopBarHandleAccount />
      {enable2Fa ? (
        <FormLogin2Fa handleLogin={handleLogin2Fa} handleBack={handleBack} />
      ) : (
        <FormLogin handleLogin={handleLogin} />
      )}
    </div>
  );
}
