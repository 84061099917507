import {
  AppBar,
  createStyles,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import Logo from "../../assets/img/Logo.png";
import { PATH } from "../../const/paths";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        paddingBottom: 8,
        marginBottom: 30,
        [theme.breakpoints.down("xs")]: {
          marginBottom: 0,
          paddingBottom: 0,
        },
      },
      appBar: {
        backgroundColor: theme.color.background,
        [theme.breakpoints.down("xs")]: {
          padding: "0px 10px",
        },
      },
      toolBar: {
        color: "#888888",
        [theme.breakpoints.down("xs")]: {
          justifyContent: "space-between",
          padding: "10px 0",
        },
      },
      menuButton: {
        marginRight: 0,
      },
      title: {
        flexGrow: 1,
        fontWeight: "bold",
        // fontFamily: "Montserrat",
        fontStyle: "normal",
        cursor: "pointer",
      },
    }),
  { index: 1 }
);

function TopBarHandleAccount(props) {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const history = useHistory();

  const handleLink = (path) => {
    let jwt_token = localStorage.getItem("jwt_token");
    if (path === PATH.HOME && jwt_token) {
      history.push(PATH.TRADING_VIEW);
    } else history.push(path);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar variant="dense" className={classes.toolBar}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={() => {
              handleLink(PATH.HOME);
            }}
          >
            <img src={`${Logo}`} alt="Logo" />
          </IconButton>
          <Typography
            variant="h6"
            className={classes.title}
            onClick={() => {
              handleLink(PATH.HOME);
            }}
          >
            {t("Alltrade")}
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
}

TopBarHandleAccount.propTypes = {};

export default TopBarHandleAccount;
