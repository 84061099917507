const convertNumberToCurrency = (number) => {
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return formatter.format(parseFloat(number));
  //   return `${parseFloat(number)
  //     .toFixed(2)
  //     .replace(/\d(?=(\d{3})+\.)/g, "$&,")} USDT`;
};

export default convertNumberToCurrency;
