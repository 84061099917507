import {
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Pagination, PaginationItem } from "@material-ui/lab";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import convertNumberToCurrency from "../../common/convertNumberToCurrency";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        marginTop: 10,
      },
      tableCellHeader: {
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        border: "none",
        color: theme.text.secondary,
      },
      tableCellBody: {
        borderBottom: "1px solid #424242",
        color: "#fff",
      },
      coverInformation: {
        display: "flex",
      },
      textTitleInformation: {
        color: theme.text.secondary,
        marginRight: 50,
      },
      textAddress: {
        color: theme.text.primary,
      },
      rootPagination: {
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: 40,
        marginTop: 20,
      },
      pagination: {
        color: theme.text.primary,
      },
      rootPaginationItem: {
        border: `1px solid ${theme.color.light}`,
        color: theme.text.primary,
        borderRadius: 2,
      },
      ellipsisPagination: { border: "none" },
      pageSelected: {
        backgroundColor: "hsla(0, 0%, 100%, 0.1) !important",
        border: `1px solid ${theme.color.border}`,
      },
    }),
  { index: 1 }
);

function createData(created, action_type, amount, result_amount) {
  return { created, action_type, amount, result_amount };
}

function TableBoTradingHistory(props) {
  const classes = useStyles(props);
  const { pagination, handleChangePage, listOrder } = props;
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);
  const theme = useTheme();

  const columns = [
    {
      id: "created",
      label: t("Time"),
      minWidth: 100,
      align: "left",
    },
    {
      id: "action_type",
      label: t("Order"),
      minWidth: 50,
      align: "left",
    },
    {
      id: "amount",
      label: t("Amount"),
      minWidth: 80,
      align: "left",
    },
    {
      id: "result_amount",
      label: t("Amount received"),
      minWidth: 140,
      align: "left",
    },
  ];

  useEffect(() => {
    if (Object.keys(listOrder).length > 0) {
      let rows;
      rows = listOrder.results.map((transaction, index) => {
        let created = moment(transaction.created).format("DD-MM HH:mm:ss");
        let action_type =
          transaction.action_type === "down" ? t("Sell") : t("Buy");
        let amount = convertNumberToCurrency(transaction.amount);
        let result_amount = (
          <Typography
            variant="body2"
            style={{
              color:
                transaction.result === "win"
                  ? theme.color.trendingUp
                  : theme.color.trendingDown,
            }}
          >
            {convertNumberToCurrency(transaction.result_amount)}
          </Typography>
        );
        return createData(created, action_type, amount, result_amount);
      });

      setRows(rows);
    }
  }, [listOrder]);

  return (
    <>
      <TableContainer className={classes.root}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className={classes.tableCellHeader}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {listOrder.results ? (
              rows.map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          className={classes.tableCellBody}
                        >
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={9}></TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <div className={classes.rootPagination}>
          <Pagination
            count={Math.ceil(listOrder.count / pagination.page_size || 0)}
            page={pagination.page}
            variant="outlined"
            shape="rounded"
            onChange={(_, value) => {
              handleChangePage(value);
            }}
            classes={{ root: classes.pagination }}
            renderItem={(item) => (
              <PaginationItem
                classes={{
                  root: classes.rootPaginationItem,
                  ellipsis: classes.ellipsisPagination,
                  selected: classes.pageSelected,
                }}
                {...item}
                variant="outlined"
              />
            )}
          />
        </div>
      </TableContainer>
    </>
  );
}

TableBoTradingHistory.propTypes = {};

export default TableBoTradingHistory;
