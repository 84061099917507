import { AppBar, makeStyles } from "@material-ui/core";
import React from "react";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px !important`,
    width: "100%",
  },
  rootTabs: {
    flexGrow: 1,
    width: "100%",
    height: "max-content",
    paddingBottom: theme.spacing(2),
  },
  appBar: {
    backgroundColor: theme.color.background,
  },
  tabItem: {
    color: `${theme.text.secondary} !important`,
  },
  tabItemActive: {
    color: `${theme.text.primary} !important`,
  },
}));

export default function TabsTradingBot({ tabActive, handleChange, tabs }) {
  const classes = useStyles();
  return (
    <div className={classes.rootTabs}>
      <AppBar position={"relative"} className={classes.appBar}>
        <Tabs
          value={tabActive}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              label={tab.label}
              value={tab.value}
              className={
                tabActive === tab.value
                  ? classes.tabItemActive
                  : classes.tabItem
              }
            />
          ))}
        </Tabs>
      </AppBar>
    </div>
  );
}
