import {
  createStyles,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { forwardRef, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      cssOutlinedInput: {
        "&$cssFocused $notchedOutline": {
          borderColor: `${theme.text.primary} !important`,
        },
      },
      notchedOutline: {
        borderWidth: "1px",
        borderColor: `green !important`,
      },
      multilineColor: {
        color: theme.text.primary,
        fontSize: 13,
      },
      input: {
        "&::placeholder": {
          opacity: 0.2,
        },
      },
      label: {
        color: theme.text.secondary,
      },
    }),
  { index: 1 }
);

function FormUpdateAddress(props) {
  const classes = useStyles();
  const { handleUpdateAddress, wrapperRef, formik } = props;
  const { t } = useTranslation();

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        handleUpdateAddress();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <TextField
        id="address"
        name="address"
        label={
          <Typography variant="body2" classes={{ root: classes.label }}>
            {t("Address")}
          </Typography>
        }
        InputProps={{
          classes: {
            root: classes.cssOutlinedInput,
            notchedOutline: classes.notchedOutline,
            input: classes.input,
          },
          className: classes.multilineColor,
        }}
        onChange={formik.handleChange}
        value={formik.values.address}
        error={formik.touched.address && Boolean(formik.errors.address)}
        helperText={formik.touched.address && formik.errors.address}
      />
      <TextField
        id="twoFa"
        name="twoFa"
        label={
          <Typography variant="body2" classes={{ root: classes.label }}>
            {t("2FA Code")}
          </Typography>
        }
        InputProps={{
          classes: {
            root: classes.cssOutlinedInput,
            notchedOutline: classes.notchedOutline,
            input: classes.input,
          },
          className: classes.multilineColor,
        }}
        onChange={formik.handleChange}
        value={formik.values.twoFa}
        error={formik.touched.twoFa && Boolean(formik.errors.twoFa)}
        helperText={formik.touched.twoFa && formik.errors.twoFa}
      />
    </div>
  );
}

export default forwardRef(FormUpdateAddress);
