import {
  Button,
  List,
  ListItem,
  makeStyles,
  Popover,
  Typography,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { useState } from "react";

const useStyles = makeStyles(
  (theme) => ({
    buttonSelect: {
      backgroundColor: "#2A2D35",
      width: "100%",
      textTransform: "none",
      // minWidth: 250,
      borderRadius: 3,
      color: theme.text.primary,
      padding: "8px 8px",
      margin: "10px 0 15px 0",
      "&:hover": {
        backgroundColor: "rgba(0,0,0,.05)",
      },
    },
    labelButton: {
      display: "flex",
      justifyContent: "space-between",
    },
    iconArrow: {
      float: "right",
    },
    coverCurrency: {
      display: "flex",
      alignItems: "center",
    },
    typographyBtnPrimary: {
      margin: "0 5px",
    },
    typographyBtnSecondary: {
      margin: "0 5px",
      color: theme.text.secondary,
    },
    paperPopover: {
      width: 270,
    },
  }),
  { index: 1 }
);

export default function SelectTypeCurrency(props) {
  const classes = useStyles();
  const { handleSelectWallet, walletSelected, walletsInfo, disabled } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    if (!disabled) setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Button
        className={classes.buttonSelect}
        classes={{ label: classes.labelButton }}
        aria-describedby={id}
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        <div className={classes.coverCurrency}>
          <img
            src={`${walletSelected?.c?.icon_url}`}
            alt="icon"
            width={25}
            height={25}
          ></img>
          <Typography
            variant="body1"
            classes={{ root: classes.typographyBtnPrimary }}
          >
            {walletSelected?.c?.symbol}
          </Typography>
          <Typography
            variant="caption"
            classes={{ root: classes.typographyBtnSecondary }}
          >
            {walletSelected?.c?.name}
          </Typography>
        </div>
        {!disabled && (
          <ArrowDropDownIcon classes={{ root: classes.iconArrow }} />
        )}
      </Button>
      <Popover
        classes={{ paper: classes.paperPopover }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List>
          {walletsInfo?.results?.map((wallet) => {
            if (!wallet?.c?.can_deposit && !wallet?.c?.can_withdraw) {
              return null;
            }
            return (
              <ListItem
                button
                key={wallet.id}
                onClick={() => {
                  handleSelectWallet(wallet);
                  handleClose();
                }}
              >
                <div className={classes.coverCurrency}>
                  <img
                    src={`${wallet.c?.icon_url}`}
                    alt="icon"
                    width={25}
                    height={25}
                  ></img>
                  <Typography
                    variant="body1"
                    classes={{ root: classes.typographyBtnPrimary }}
                  >
                    {wallet.c?.symbol}
                  </Typography>
                  <Typography
                    variant="caption"
                    classes={{ root: classes.typographyBtnSecondary }}
                  >
                    {wallet.c?.name}
                  </Typography>
                </div>
              </ListItem>
            );
          })}
        </List>
      </Popover>
    </>
  );
}
