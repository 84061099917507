import { makeStyles } from "@material-ui/core";
import { cloneDeep } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTradeStatistic } from "../../apis/trade.api";
import PaperDashboard from "../../components/Body/BodyDashboard/PaperDashboard";
import TradingRateChart from "../../components/Chart/TradingRateChart";
import TableBoTradingHistory from "../../components/Table/TableBoTradingHistory";
import { getListOrderReq } from "../../redux/actions/transactionAction";

const useStyles = makeStyles(
  (theme) => ({
    root: {},
  }),
  { index: 1 }
);

export default function DashBoard() {
  const classes = useStyles();
  const [params, setParams] = useState({ page: 1, page_size: 10 });
  const [dataStatistic, setDataStatistic] = useState(null);
  const [dataRange, setDateRange] = useState({});
  const dispatch = useDispatch();
  const listOrder = useSelector((state) => state.transactionReducer?.listOrder);

  const callTradeStatistic = useCallback(() => {
    getTradeStatistic()
      .then((res) => {
        if (res.status === 200) {
          setDataStatistic(res.data);
        }
      })
      .catch();
  }, []);

  const callListOrder = useCallback(
    (params) => {
      dispatch(getListOrderReq(params));
    },
    [dispatch]
  );

  const handleChangePageOrderTradingHistory = (page) => {
    let newParams = { ...params, page };
    callListOrder(newParams);
    setParams(newParams);
  };

  const handleUpdateDateRange = (range) => {
    setDateRange(range);
    let newParams = cloneDeep(params);
    newParams = {
      ...newParams,
      from_date: moment(range.startDate).format("YYYY-MM-DD"),
      to_date: moment(range.endDate).format("YYYY-MM-DD"),
    };
    setParams(newParams);
  };

  const handleSearch = () => {
    callListOrder(params);
  };

  const renderTableHistory = () => {
    return (
      <TableBoTradingHistory
        listOrder={listOrder}
        pagination={params}
        handleChangePage={handleChangePageOrderTradingHistory}
      />
    );
  };

  const renderTradingRate = () => {
    return <>{dataStatistic && <TradingRateChart data={dataStatistic} />}</>;
  };

  useEffect(() => {
    let newParams = {
      ...params,
      from_date: moment().subtract(30, "days").format("YYYY-MM-DD"),
      to_date: moment(new Date()).format("YYYY-MM-DD"),
    };
    setDateRange({
      startDate: new Date(moment().subtract(30, "days").format()),
      endDate: new Date(),
    });
    setParams(newParams);
    callListOrder(newParams);
    callTradeStatistic();
  }, [callTradeStatistic, callListOrder]);

  return (
    <>
      <PaperDashboard
        renderTableHistory={renderTableHistory}
        renderTradingRate={renderTradingRate}
        data={dataStatistic}
        params={params}
        handleUpdateDateRange={handleUpdateDateRange}
        dataRange={dataRange}
        handleSearch={handleSearch}
      ></PaperDashboard>
    </>
  );
}
