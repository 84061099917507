import { createStyles, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        width: "90%",
        margin: "0 auto",
        textAlign: "center",
        padding: "40px 0 80px 0",
        [theme.breakpoints.down("xs")]: {
          width: "80%",
        },
      },
      rootTitle: {
        width: "50%",
        margin: "0 auto",
        paddingBottom: 50,
        [theme.breakpoints.down("xs")]: {
          width: "100%",
          paddingBottom: 20,
        },
      },
      typographyTitle: {
        marginBottom: 10,
      },
    }),
  { index: 1 }
);

function PaperBotPackage(props) {
  const classes = useStyles(props);
  const { children } = props;
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <div className={classes.rootTitle}>
        <Typography variant="h5" classes={{ root: classes.typographyTitle }}>
          {t("Alltrade bot package")}
        </Typography>
      </div>
      {children}
    </div>
  );
}

PaperBotPackage.propTypes = {};

export default PaperBotPackage;
