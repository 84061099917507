import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import convertNumberToMoney from "../../../common/convertNumberToMoney";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {},
      title: {
        color: theme.text.primary,
      },
      textField: {
        marginBottom: 15,
        backgroundColor: "#2A2D35",
      },
      cssOutlinedInput: {
        "&$cssFocused $notchedOutline": {
          borderColor: `${theme.text.primary} !important`,
        },
      },
      notchedOutline: {
        borderWidth: "1px",
        borderColor: "#2A2D35 !important",
        borderRadius: "2px !important",
      },
      multilineColor: {
        color: theme.text.primary,
        fontSize: 13,
      },
      input: {
        "&::placeholder": {
          opacity: 0.2,
        },
      },
      typographyLabel: {
        color: theme.text.secondary,
      },
      textPrimary: { color: theme.text.primary, padding: "10px 0" },
      coverGetAll: { cursor: "pointer", textAlign: "right" },
      endAdmornment: {
        color: theme.color.primary,
      },
      typographyValue: { paddingLeft: 20, color: theme.text.primary },
      buttonSubmit: {
        width: "100%",
        color: theme.text.primary,
        backgroundColor: theme.color.primary,
        textTransform: "none",
        borderRadius: 2,
        marginTop: 10,
      },
      gridItemLeft: {
        paddingBottom: 10,
      },
      gridItemRight: { textAlign: "right", paddingBottom: 10 },
      typographyWarning: { color: "#F0B90B" },
    }),
  { index: 1 }
);

const initialValues = {
  amount: "",
};

export default function FormDepositBot(props) {
  const classes = useStyles(props);
  const { walletCurrent, walletAla, handleDeposit } = props;
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .max(parseFloat(walletCurrent.balance), () =>
        t("The amount must be less than the available balance")
      )
      //   .min(5, () => `${t("Minumum 5")} ${walletCurrent.c?.symbol}`)
      .required(t("Not be empty")),
  });

  const onSubmit = (values) => {
    handleDeposit(values);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => onSubmit(values),
  });

  const handleGetAllAmount = () => {
    formik.setFieldValue("amount", parseFloat(walletCurrent.balance));
  };

  //   useEffect(() => {
  //     handleChangeAmount(formik.values.amount);
  //   }, [formik.values.amount]);

  return (
    <div className={classes.root}>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <Typography variant="body1" className={classes.textPrimary}>
            1 USDT ={" "}
            {parseFloat(1 / parseFloat(walletAla?.c?.usdt_rate)).toFixed(2)} ALA
          </Typography>
        </div>
        <div>
          <Typography variant="caption" className={classes.typographyLabel}>
            {t("Amount option")} (USDT)
          </Typography>
          <TextField
            className={classes.textField}
            fullWidth
            variant="outlined"
            id="amount"
            name="amount"
            type="number"
            InputLabelProps={{ shrink: false }}
            InputProps={{
              endAdornment: (
                <div
                  className={classes.coverGetAll}
                  onClick={handleGetAllAmount}
                >
                  <Typography
                    variant="caption"
                    className={classes.endAdmornment}
                  >
                    {t("All")}
                  </Typography>
                </div>
              ),
              classes: {
                root: classes.cssOutlinedInput,
                notchedOutline: classes.notchedOutline,
                input: classes.input,
              },
              className: classes.multilineColor,
            }}
            size="small"
            onChange={formik.handleChange}
            //   onBlur={formik.handleBlur}
            value={formik.values.amount}
            error={formik.touched.amount && Boolean(formik.errors.amount)}
            helperText={formik.touched.amount && formik.errors.amount}
          />
        </div>
        <Grid container>
          <Grid item xs={12} className={classes.gridItemLeft}>
            <Typography variant="caption" className={classes.typographyLabel}>
              {t("Available balances")}:
            </Typography>
            <Typography variant="caption" className={classes.typographyValue}>
              {convertNumberToMoney(parseFloat(walletCurrent.balance))}{" "}
              {walletCurrent.c?.symbol}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.gridItemLeft}>
            <Typography variant="caption" className={classes.typographyLabel}>
              {t("Amount received")}:
            </Typography>
            <Typography variant="caption" className={classes.typographyValue}>
              {convertNumberToMoney(
                parseFloat(formik.values.amount) /
                  parseFloat(walletAla?.c?.usdt_rate) || 0
              )}{" "}
              ALA
            </Typography>
          </Grid>
        </Grid>
        <div>
          <Button className={classes.buttonSubmit} type="submit">
            {t("Deposit")}
          </Button>
        </div>
      </form>
    </div>
  );
}

FormDepositBot.propTypes = {};
