import { SCHEME, DOMAIN } from "../const/api";
import callApi from "../utils/apiCaller";

const headers = {
  //prettier-ignore
  "Content-Type": "application/json",
};

export const getBotV2Package = () => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "get",
    url: `${SCHEME}://${DOMAIN}/v1/bot/bot-package-v2/`,
    headers: {
      ...headers,
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
  };
  return callApi(config);
};

export const buyBotV2Package = (data) => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "post",
    url: `${SCHEME}://${DOMAIN}/v1/bot/user-package/purchase/`,
    headers: {
      ...headers,
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
    data,
  };
  return callApi(config);
};

export const upgradeBotV2Package = (botId, data) => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "post",
    url: `${SCHEME}://${DOMAIN}/v1/bot/user-package/${botId}/upgrade/`,
    headers: {
      ...headers,
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
    data,
  };
  return callApi(config);
};
