import React from "react";
import Register from "../containers/Register/Register";

export default function RegisterPage() {
  return (
    <div>
      <Register />
    </div>
  );
}
