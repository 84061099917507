import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import moment from "moment";
import convertNumberToCurrency from "../../common/convertNumberToCurrency";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: { margin: "20px 0" },
      table: {
        border: `1px solid ${theme.color.border}`,
        borderBottom: "none",
      },
      tableCellHeader: {
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        border: "none",
        color: theme.text.secondary,
      },
      tableCellBody: {
        borderBottom: "1px solid #424242",
        color: "#fff",
      },
      rootPagination: {
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: 40,
      },
      pagination: {
        color: theme.text.primary,
      },
      rootPaginationItem: {
        border: `1px solid ${theme.text.primary}`,
        color: theme.text.primary,
        borderRadius: 2,
      },
      ellipsisPagination: { border: "none" },
      pageSelected: {
        backgroundColor: "hsla(0, 0%, 100%, 0.1) !important",
        border: `1px solid ${theme.color.border}`,
      },
    }),
  { index: 1 }
);

function createData(
  time,
  payment,
  number_transactions,
  number_traders,
  trade_volume
) {
  return { time, payment, number_transactions, number_traders, trade_volume };
}

function TableRefBoCommission(props) {
  const classes = useStyles(props);
  const { boCommission, pagination, handleChangePage } = props;
  const [rows, setRows] = useState([]);
  const { t } = useTranslation();

  const columns = [
    { id: "time", label: t("Time"), minWidth: 150 },
    {
      id: "payment",
      label: t("Commission"),
      minWidth: 100,
    },
    {
      id: "number_transactions",
      label: t("Number of transactions"),
      minWidth: 150,
    },
    {
      id: "number_traders",
      label: t("Number of traders"),
      minWidth: 150,
    },
    {
      id: "trade_volume",
      label: t("Trading volume"),
      minWidth: 150,
    },
  ];

  useEffect(() => {
    if (boCommission) {
      let rows = boCommission.results?.map((data) => {
        let time = data.day;
        let payment = (
          <Typography variant="body2" style={{ color: "#23CA7A" }}>
            {convertNumberToCurrency(data.commission_vol)}
          </Typography>
        );
        let number_transactions = Math.ceil(data.trade_count);
        let number_traders = Math.ceil(data.actor_count);
        let trade_volume = (
          <Typography variant="body2" style={{ color: "#23CA7A" }}>
            {convertNumberToCurrency(data.trade_vol)}
          </Typography>
        );
        return createData(
          time,
          payment,
          number_transactions,
          number_traders,
          trade_volume
        );
      });
      setRows(rows);
    }
  }, [boCommission]);

  return (
    <>
      <TableContainer className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className={classes.tableCellHeader}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={classes.tableCellBody}
                      >
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {boCommission && (
        <div className={classes.rootPagination}>
          <Pagination
            count={Math.ceil(boCommission.count / pagination.page_size || 0)}
            page={pagination.page}
            onChange={(_, value) => {
              handleChangePage(value);
            }}
            variant="outlined"
            shape="rounded"
            classes={{ root: classes.pagination }}
            renderItem={(item) => (
              <PaginationItem
                classes={{
                  root: classes.rootPaginationItem,
                  ellipsis: classes.ellipsisPagination,
                  selected: classes.pageSelected,
                }}
                {...item}
                variant="outlined"
              />
            )}
          />
        </div>
      )}
    </>
  );
}

TableRefBoCommission.propTypes = {};

export default TableRefBoCommission;
