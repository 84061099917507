import {
  Card,
  CardContent,
  createStyles,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Sale from "../../../assets/img/Sale.png";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        width: "100vw",
        minHeight: 700,
        background: "#06061C",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        [theme.breakpoints.down("xs")]: {
          paddingTop: 80,
          paddingBottom: 50,
        },
      },
      card: {
        width: 300,
        height: 310,
        backgroundColor: "#14149E",
        borderRadius: 8,
        textAlign: "center",
        padding: "5px 0",
        margin: 10,
        [theme.breakpoints.down("xs")]: {
          width: "90%",
        },
      },
      title: {
        color: theme.text.primary,
        margin: "15px 0 10px 0",
      },
      content: {
        color: theme.text.primary,
      },
    }),
  { index: 1 }
);

export default function PaperSecondFloor(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent>
          <img src={`${Sale}`} alt="Sale"></img>
          <Typography variant="h5" className={classes.title}>
            {t("Cheapest transaction fee in the market")}
          </Typography>
          <Typography variant="body2" className={classes.content}>
            {t("Only 4% transaction fee for each winning order")}
          </Typography>
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardContent>
          <img src={`${Sale}`} alt="Sale"></img>
          <Typography variant="h5" className={classes.title}>
            {t("Account security")}
          </Typography>
          <Typography variant="body2" className={classes.content}>
            {t(
              "Using 2FA and KYC verification method to protect user information and trading transaction"
            )}
          </Typography>
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardContent>
          <img src={`${Sale}`} alt="Sale"></img>
          <Typography variant="h5" className={classes.title}>
            {t("Trading bot diversified")}
          </Typography>
          <Typography variant="body2" className={classes.content}>
            {t("Multiple bot trading package are suitable for every one")}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}
