import * as types from "../../const/redux/tradingBotV2";

export const getBotV2PackageReq = () => {
  return {
    type: types.GET_BOT_V2_PACKAGE_REQ,
  };
};

export const getBotV2PackageSuccess = (data) => {
  return {
    type: types.GET_BOT_V2_PACKAGE_SUCCESS,
    payload: { data },
  };
};

export const getUserV2PackageReq = () => {
  return {
    type: types.GET_USER_V2_PACKAGE_REQ,
  };
};

export const getUserV2PackageSuccess = (data) => {
  return {
    type: types.GET_USER_V2_PACKAGE_SUCCESS,
    payload: { data },
  };
};

export const buyBotV2PackageReq = (dataReq) => {
  return {
    type: types.BUY_BOT_V2_PACKAGE_REQ,
    payload: { dataReq },
  };
};

export const buyBotV2PackageSuccess = (data) => {
  return {
    type: types.BUY_BOT_V2_PACKAGE_SUCCESS,
    payload: { data },
  };
};

export const upgradeBotV2PackageReq = (botId, dataReq) => {
  return {
    type: types.UPGRADE_BOT_V2_PACKAGE_REQ,
    payload: { botId, dataReq },
  };
};

export const upgradeBotV2PackageSuccess = (data) => {
  return {
    type: types.UPGRADE_BOT_V2_PACKAGE_SUCCESS,
    payload: { data },
  };
};
