import { useMediaQuery, useTheme } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { getCandleStickData } from "../apis/trade.api";
import CandleChartBundle from "../components/Chart/BOchart/CandleChartBundle";
import { TOAST_NOTI } from "../const/common";
import { callToastNotiSuccess } from "../redux/actions/toastNotiAction";

export default function BoChartPage() {
  const [initialBoData, setInitialBoData] = useState([]);
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXs = useMediaQuery(theme.breakpoints.down("xs"));
  const rootRef = useRef(null);

  const renderChart = useMemo(() => {
    if (initialBoData.length > 0)
      return (
        <CandleChartBundle
          rootRef={rootRef}
          data={
            !matchesXs
              ? initialBoData.slice(
                  initialBoData.length - 75,
                  initialBoData.length
                )
              : !matches
              ? initialBoData.slice(
                  initialBoData.length - 60,
                  initialBoData.length
                )
              : initialBoData.slice(
                  initialBoData.length - 40,
                  initialBoData.length
                )
          }
        ></CandleChartBundle>
      );
    return null;
  }, [initialBoData, matches, matchesXs]);

  useEffect(() => {
    getCandleStickData({ page: 1, page_size: 100 })
      .then((res) => {
        if (res.status === 200) {
          let { data } = res;
          let time = Math.floor(
            (
              ((moment().utc().minutes() % 10) +
                moment().utc().seconds() / 60) *
              2
            ).toFixed(2)
          );
          data?.results?.sort((a, b) => a.id - b.id);
          data?.results?.splice(0, 20 - time);
          let newData = data?.results.map((bo) => ({
            id: bo.id,
            volume: parseFloat(bo.volume),
            high: parseFloat(bo.high),
            low: parseFloat(bo.low),
            open: parseFloat(bo.open),
            close: parseFloat(bo.close),
            timestamp: parseFloat(bo.timestamp) * 1000,
          }));
          setInitialBoData(newData);
        }
      })
      .catch((err) => {
        let { data } = err.response;
        if (data) {
          dispatch(
            callToastNotiSuccess({
              noti: data.message,
              status: TOAST_NOTI.ERROR,
              code: data.code,
            })
          );
        }
      });
  }, []);

  return (
    <div
      style={{
        height: "100vh",
        backgroundColor: theme.body.backgroundColor,
        // paddingTop: 50,
      }}
      ref={rootRef}
    >
      {renderChart}
    </div>
  );
}
