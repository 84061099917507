import { createStyles, Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        padding: 20,

        [theme.breakpoints.down("xs")]: {
          padding: "0px 20px 10px 20px",
        },
      },
      gridBody: {
        paddingTop: 20,
        [theme.breakpoints.down("xs")]: {
          backgroundColor: "transparent",
        },
      },
    }),
  { index: 1 }
);

export default function BodyWallet(props) {
  const classes = useStyles(props);
  const { children } = props;

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} sm={9}>
        <Grid container className={classes.gridBody}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
}
