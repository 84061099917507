import { createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import convertNumberToUSDT from "../../../common/convertNumberToUSDT";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        width: "100%",
        backgroundColor: theme.color.background,
        margin: "10px 0 0 10px",
        padding: "40px 220px 40px 180px",
        [theme.breakpoints.down("xs")]: {
          padding: "15px 0 15px 15px",
          margin: "10px 0 0 5px",
          width: "97%",
        },
      },
      packageContainer: {
        display: "flex",
        justifyContent: "center",
      },
      typographySecondary: {
        color: theme.text.secondary,
      },
      typographyPrimary: {
        color: theme.text.primary,
      },
      statisticPackage: {
        marginTop: 10,
      },
    }),
  { index: 1 }
);

function PaperGeneralDataTradingBot(props) {
  const classes = useStyles(props);
  const { userPackage } = props;
  const { t } = useTranslation();

  return (
    <>
      <div className={classes.root}>
        <Grid container justify="center">
          {/* <Grid item xs={12} sm={12} md={4}>
          <Typography variant="body2" className={classes.typographySecondary}>
            {t("Total profit")}
          </Typography>
          <Typography variant="h6" className={classes.typographyPrimary}>
            $10,284.88
          </Typography>
        </Grid> */}
          {userPackage?.results?.map((packageData) => (
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              key={packageData?.id}
              className={classes.packageContainer}
            >
              <div>
                <Typography variant="h6" className={classes.typographyPrimary}>
                  {packageData?.package_data?.name} BOT
                </Typography>
                <div className={classes.statisticPackage}>
                  <Typography
                    variant="body2"
                    className={classes.typographySecondary}
                  >
                    {t("Profit index")}:{" "}
                    <span className={classes.typographyPrimary}>
                      {Math.round(
                        parseFloat(packageData?.package_data?.min_profit || 0) *
                          100
                      )}
                      % ~{" "}
                      {Math.round(
                        parseFloat(packageData?.package_data?.max_profit || 0) *
                          100
                      )}
                      % / {t("year")}
                    </span>
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.typographySecondary}
                  >
                    {t("Profit")}:{" "}
                    <span className={classes.typographyPrimary}>
                      {convertNumberToUSDT(packageData?.total_profit)}
                    </span>
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.typographySecondary}
                  >
                    {t("Capital value")}:{" "}
                    <span className={classes.typographyPrimary}>
                      {convertNumberToUSDT(packageData?.amount)}
                    </span>
                  </Typography>
                </div>
              </div>
            </Grid>
          ))}

          {/* <Grid item xs={12} sm={12} md={4}>
            <Typography variant="body2" className={classes.typographySecondary}>
              {t("Profit index")}
            </Typography>
            <Typography variant="h6" className={classes.typographyPrimary}>
              {Math.round(
                parseFloat(
                  userPackage?.results[0]?.package_data?.percent_profit || 0
                ) *
                  100 -
                  1
              )}{" "}
              ~{" "}
              {Math.round(
                parseFloat(
                  userPackage?.results[0]?.package_data?.percent_profit || 0
                ) * 100
              ) + 2}
              % / {t("month")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Typography variant="body2" className={classes.typographySecondary}>
              {t("Profit")}
            </Typography>
            <Typography variant="h6" className={classes.typographyPrimary}>
              {convertNumberToCurrency(
                parseFloat(userPackage?.results[0]?.claimed_profit)
              ) || 0}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Typography variant="body2" className={classes.typographySecondary}>
              {t("Total running time")}
            </Typography>
            <Typography variant="h6" className={classes.typographyPrimary}>
              {Math.floor(
                moment
                  .duration(
                    new Date(userPackage?.results[0]?.expired_at).valueOf() -
                      new Date().valueOf()
                  )
                  .asDays()
              ) > 0
                ? Math.floor(
                    moment
                      .duration(
                        new Date().valueOf() -
                          new Date(userPackage?.results[0]?.created).valueOf()
                      )
                      .asDays()
                  )
                : Math.floor(
                    moment
                      .duration(
                        new Date(
                          userPackage?.results[0]?.expired_at
                        ).valueOf() -
                          new Date(userPackage?.results[0]?.created).valueOf()
                      )
                      .asDays()
                  )}{" "}
              {t("Days")}
            </Typography>
          </Grid> */}
        </Grid>
      </div>
    </>
  );
}

PaperGeneralDataTradingBot.propTypes = {};

export default PaperGeneralDataTradingBot;
