import { makeStyles } from "@material-ui/core/styles";
import { find } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import TabsTradingBot from "../../components/Tabs/TabsTradingBot";
import { getUserV2PackageReq } from "../../redux/actions/tradingBotV2Action";
import { getUserWalletReq } from "../../redux/actions/walletAction";
import History from "../TradingBot/History";
import Statistic from "./Statistic";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px !important`,
    width: "100%",
  },
}));

export default function TradingBotV2() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [valueTab, setValueTab] = useState("bots");
  const { t } = useTranslation();

  const tabs = [
    { value: "bots", label: t("Bots"), component: <Statistic /> },
    {
      value: "my-bots",
      label: t("MyBots"),
      component: <History version={2} />,
    },
  ];

  useEffect(() => {
    dispatch(getUserV2PackageReq());
    dispatch(getUserWalletReq());
  }, []);

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  return (
    <div className={classes.root}>
      <TabsTradingBot
        tabActive={valueTab}
        tabs={tabs}
        handleChange={handleChangeTab}
      />
      {valueTab && find(tabs, { value: valueTab }).component}
    </div>
  );
}
