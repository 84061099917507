import { cloneDeep } from "lodash";
import * as types from "../../const/redux/loading";

let initialState = false;

const loadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CALL_LOADING: {
      let newState = cloneDeep(action.payload.data);
      return newState;
    }
    default:
      return state;
  }
};

export default loadingReducer;
