import { makeStyles, Typography } from "@material-ui/core";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Ticket from "../../../components/Body/BodyBingoStar/Ticket";
import BuyTicketContainer from "./BuyTicketContainer";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: "0 25px 10px 25px",
      [theme.breakpoints.down("xs")]: {
        padding: 0,
      },
    },
    coverTabs: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 10,
    },
    tabItem: {
      fontWeight: 700,
      cursor: "pointer",
    },
  }),
  { index: 1 }
);

export default function BingoTicket(props) {
  const classes = useStyles();
  const { handleCloseDialog, callNumberTickets } = props;
  const [tab, setTab] = useState(1);

  const { t } = useTranslation();

  const renderBody = () => {
    switch (tab) {
      case 1: {
        return (
          <Ticket turned={true} numberTicket={"123456"} dateTicket={moment()} />
        );
      }
      case 2: {
        return (
          <Ticket
            turned={false}
            numberTicket={"123456"}
            dateTicket={moment()}
          />
        );
      }
      case 3: {
        return <BuyTicketContainer />;
      }
      default:
        return null;
    }
  };

  return (
    <div className={classes.root}>
      {/* <div className={classes.coverTabs}>
        <Typography
          variant="h6"
          classes={{ root: classes.tabItem }}
          style={{ color: tab === 1 ? "#60961C" : "#DA9342" }}
          onClick={() => {
            setTab(1);
          }}
        >
          {t("Tickets have not turned")}
        </Typography>
        <Typography
          variant="h6"
          classes={{ root: classes.tabItem }}
          style={{ color: tab === 2 ? "#60961C" : "#DA9342" }}
          onClick={() => {
            setTab(2);
          }}
        >
          {t("Tickets have been turned")}
        </Typography>
        <Typography
          variant="h6"
          classes={{ root: classes.tabItem }}
          style={{ color: tab === 3 ? "#60961C" : "#DA9342" }}
          onClick={() => {
            setTab(3);
          }}
        >
          {t("Buy more tickets")}
        </Typography>
      </div> */}
      <BuyTicketContainer
        handleCloseDialog={handleCloseDialog}
        callNumberTickets={callNumberTickets}
      />
      {/* <div>{renderBody()}</div> */}
    </div>
  );
}
