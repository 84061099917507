import { makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import StepperComponent from "../../../components/Body/BodyProfile/StepperComponent";

// const stepItemStyles = makeStyles(
//   (theme) => ({
//     root: {
//       width: 77,
//       height: 77,
//       position: "relative",
//       //   backgroundImage: `url(${StepItem1})`,
//       //   backgroundRepeat: "no-repeat",
//       //   backgroundSize: "auto",
//       backgroundColor: "#ffc400",
//       borderRadius: "50%",
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//     },
//     cover: {
//       width: 64,
//       height: 64,
//       position: "relative",
//       backgroundColor: "#130900",
//       borderRadius: "50%",
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//       //   backgroundImage: `url(${StepItem2})`,
//       //   backgroundRepeat: "no-repeat",
//       //   backgroundSize: "auto",
//     },
//     textBold: {
//       fontWeight: "bolder",
//       color: "#ffc400",
//     },
//   }),
//   { index: 1 }
// );

// const StepItem = (props) => {
//   const classes = stepItemStyles();
//   const { step } = props;
//   const { t } = useTranslation();

//   return (
//     <div className={classes.root}>
//       <div className={classes.cover}>
//         <div>
//           <Typography variant="caption" classes={{ root: classes.textBold }}>
//             {t("Step")}
//           </Typography>
//           <Typography variant="h5" classes={{ root: classes.textBold }}>
//             {step}
//           </Typography>
//         </div>
//       </div>
//     </div>
//   );
// };

// const ArrowItem = () => {
//   return (
//     <div
//       style={{
//         width: 37,
//         height: 18,
//         position: "relative",
//         backgroundImage: `url(${ArrowStep})`,
//         backgroundRepeat: "no-repeat",
//         backgroundSize: "auto",
//       }}
//     ></div>
//   );
// };

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: "90%",
      margin: "auto",
      padding: "10px 0 15px 0",
    },
    coverStep: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "70%",
      margin: "auto",
    },
    coverTextStep: {
      display: "flex",
      justifyContent: "space-between",
      width: "70%",
      margin: "auto",
      marginBottom: 30,
    },
    textStep: {
      width: 80,
      fontWeight: "bolder",
      textAlign: "center",
      color: "#60961C",
    },
    textGuide: {
      fontWeight: "bolder",
      color: theme.text.primary,
      margin: "10px 0",
      textAlign: "left",
      fontFamily: "'Chakra Petch', sans-serif",
    },
    titleGuide: {
      fontWeight: "bolder",
      color: theme.text.primary,
      margin: "10px 0",
      fontFamily: "'Chakra Petch', sans-serif",
    },
  }),
  { index: 1 }
);

export default function Guide() {
  const classes = useStyles();
  const { t } = useTranslation();

  const steps = [
    t("Register alltrade.ventures"),
    t("Trade and receive tickets"),
    t("Win prizes"),
  ];

  return (
    <div className={classes.root}>
      {/* <StepperComponent activeStep={-1} steps={steps}>
        <div></div>
      </StepperComponent> */}
      {/* <div className={classes.coverStep}>
        <StepItem step={"01"} />
        <ArrowItem />
        <StepItem step={"02"} />
        <ArrowItem />
        <StepItem step={"03"} />
      </div>
      <div className={classes.coverTextStep}>
        <Typography variant="body1" classes={{ root: classes.textStep }}>
          {t("Register")} Atomex.to
        </Typography>
        <Typography
          variant="body1"
          classes={{ root: classes.textStep }}
          style={{ width: 120 }}
        >
          {t("Trade and receive tickets")}
        </Typography>
        <Typography variant="body1" classes={{ root: classes.textStep }}>
          {t("Win prizes")}
        </Typography>
      </div> */}
      <div>
        <Typography variant="h6" classes={{ root: classes.titleGuide }}>
          {t("ALLTRADE LOTTO GAME")}
        </Typography>
        <Typography variant="body2" classes={{ root: classes.textGuide }}>
          {t(
            "We are glad to launch a new game for our users. Any users who have tickets are able to join the games. Each ticket is equivalent to a game turn."
          )}
        </Typography>
        <Typography variant="body1" classes={{ root: classes.textGuide }}>
          {t("HOW TO GET THE TICKETS")}?
        </Typography>
        <Typography variant="body2" classes={{ root: classes.textGuide }}>
          {t("Users can get the tickets following one of the below ways")}:
        </Typography>
        <Typography variant="body2" classes={{ root: classes.textGuide }}>
          1. {t("Invest in bottrade V2")}:{" "}
          {t("Get 1 ticket for each $100 investment")}
        </Typography>
        <Typography variant="body2" classes={{ root: classes.textGuide }}>
          2. {t("Binary Trade")}:{" "}
          {t("Get 1 ticket for each $500 trading volume")}
        </Typography>
        <Typography variant="body2" classes={{ root: classes.textGuide }}>
          3. {t("Buy tickets")}: {t("$5/a ticket (Payment in ALA)")}
        </Typography>
        <Typography variant="body1" classes={{ root: classes.textGuide }}>
          {t("REWARDS")}
        </Typography>
        <Typography variant="body2" classes={{ root: classes.textGuide }}>
          - {t("Grand prize (1000 ALA)")}: {t("Winning numbers 666666")}
        </Typography>
        <Typography variant="body2" classes={{ root: classes.textGuide }}>
          - {t("1st Prize (500 ALA)")}:{" "}
          {t("Winning numbers include 6 identical numbers, except 666666")} (
          {t("For example")}: 111111, 222222, ...)
        </Typography>
        <Typography variant="body2" classes={{ root: classes.textGuide }}>
          - {t("2nd Prize (50 ALA)")}:{" "}
          {t("Winning numbers include 3 final identical numbers")} (
          {t("For example")}: 243111, 345888, ...)
        </Typography>
        <Typography variant="body2" classes={{ root: classes.textGuide }}>
          - {t("3rd Prize (20 ALA)")}:{" "}
          {t("Winning numbers include 2 final identical numbers")} (
          {t("For example")}: 243711, 345088, ...)
        </Typography>
      </div>
    </div>
  );
}
