export const GET_BOT_V2_PACKAGE_REQ = "GET_BOT_V2_PACKAGE_REQ";
export const GET_BOT_V2_PACKAGE_SUCCESS = "GET_BOT_V2_PACKAGE_SUCCESS";

export const GET_USER_V2_PACKAGE_REQ = "GET_USER_V2_PACKAGE_REQ";
export const GET_USER_V2_PACKAGE_SUCCESS = "GET_USER_V2_PACKAGE_SUCCESS";

export const BUY_BOT_V2_PACKAGE_REQ = "BUY_BOT_V2_PACKAGE_REQ";
export const BUY_BOT_V2_PACKAGE_SUCCESS = "BUY_BOT_V2_PACKAGE_SUCCESS";

export const UPGRADE_BOT_V2_PACKAGE_REQ = "UPGRADE_BOT_V2_PACKAGE_REQ";
export const UPGRADE_BOT_V2_PACKAGE_SUCCESS = "UPGRADE_BOT_V2_PACKAGE_SUCCESS";

export const DESTROY_TRADE_BOT_V2 = "DESTROY_TRADE_BOT_V2";
