import {
  AppBar,
  Avatar,
  Button,
  createStyles,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import lv0 from "../../../assets/img/0-03.png";
import lv1 from "../../../assets/img/1-03.png";
import lv10 from "../../../assets/img/10-03.png";
import lv2 from "../../../assets/img/2-03.png";
import lv3 from "../../../assets/img/3-03.png";
import lv4 from "../../../assets/img/4-03.png";
import lv5 from "../../../assets/img/5-03.png";
import lv6 from "../../../assets/img/6-03.png";
import lv7 from "../../../assets/img/7-03.png";
import lv8 from "../../../assets/img/8-03.png";
import lv9 from "../../../assets/img/9-03.png";
import convertNumberToCurrency from "../../../common/convertNumberToCurrency";
import convertNumberToMoney from "../../../common/convertNumberToMoney";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        width: "100%",
        backgroundColor: theme.color.background,
        margin: "10px 0 0 10px",
        padding: "20px 0",
        [theme.breakpoints.down("xs")]: {
          padding: 10,
          width: "100%",
          margin: 10,
          backgroundColor: "transparent",
        },
      },
      typographySecondary: {
        color: theme.text.secondary,
        margin: "10px 0",
      },
      typographyPrimary: {
        color: theme.text.primary,
        margin: "10px 0",
      },
      coverBoxTop: {
        padding: 10,
        borderRadius: 5,
        border: `1px solid ${theme.color.border}`,
        backgroundColor: `${theme.body.backgroundColor}`,
        // alignItems: "center",
        // height: 250,
        width: "100%",
        [theme.breakpoints.down("xs")]: {
          marginTop: 10,
          height: "auto",
        },
        // height: "100%",
      },
      coverBoxBotom: {
        marginTop: 10,
        padding: 10,
        borderRadius: 5,
        border: `1px solid ${theme.color.border}`,
        backgroundColor: `${theme.body.backgroundColor}`,
        // alignItems: "center",
        width: "100%",
        [theme.breakpoints.down("xs")]: {
          height: "auto",
          padding: 0,
        },
      },
      coverLevel: {
        margin: "10px 0",
        backgroundColor: `hsla(0, 0%, 100%, 0.05)`,
        borderRadius: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      },
      typographyInviter: { color: theme.text.primary, fontStyle: "italic" },
      coverConditionLevel: {
        margin: "10px 0",
      },
      listItem: {
        padding: "0px 15px ",
      },
      inviterRoot: {
        display: "flex",
        alignItems: "center",
        borderBottom: `0.5px solid ${theme.color.border}`,
        marginBottom: 10,
      },
      gridItemCenter: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      button: {
        textTransform: "none",
        marginRight: 10,
        // color: theme.text.primary,
      },
      avatar: {
        width: theme.spacing(10),
        height: theme.spacing(10),
      },
      icon: {
        width: theme.spacing(5),
        height: theme.spacing(5),
      },
      rootTabs: {
        width: "100%",
        // margin: "10px 0 0 10px",
        // padding: "20px 100px 20px 100px",
        [theme.breakpoints.down("xs")]: {
          //   padding: "0 10px",
          backgroundColor: "transparent",
          margin: 0,
        },
      },
      appBar: {
        backgroundColor: "transparent",
        boxShadow: "none",
        padding: "0 10px",
      },
      tabs: { borderBottom: "2px solid #9e9e9e" },
      tab: {
        padding: 0,
        marginRight: 10,
        textTransform: "none",
        fontWeight: "bold",
        minWidth: 70,
        "&:hover": {
          color: "#fff",
          opacity: 1,
        },
        "&:focus": {
          color: "#fff",
        },
      },
      tabPanel: {
        padding: "20px 10px",
      },
    }),
  { index: 1 }
);

export default function PaperInfoUser(props) {
  const classes = useStyles();
  const {
    renderTableDataUserTradeBo,
    handleBackSystemMng,
    infoUser,
    handleChangeTab,
    valueTab,
  } = props;
  const { t } = useTranslation();

  const renderSrcImageLevel = (bo_level) => {
    switch (bo_level) {
      case 0:
        return lv0;
      case 1:
        return lv1;
      case 2:
        return lv2;
      case 3:
        return lv3;
      case 4:
        return lv4;
      case 5:
        return lv5;
      case 6:
        return lv6;
      case 7:
        return lv7;
      case 8:
        return lv8;
      case 9:
        return lv9;
      case 10:
        return lv10;
      default:
        return lv0;
    }
  };

  useEffect(() => {
    return () => {
      handleBackSystemMng();
    };
  }, []);

  return (
    <div className={classes.root}>
      <div style={{ display: "flex", alignItems: "center", margin: "15px 0" }}>
        <Button
          classes={{ root: classes.button }}
          variant="contained"
          onClick={handleBackSystemMng}
        >
          {t("Back")}
        </Button>
        {/* <Typography variant="body2" className={classes.typographySecondary}>
          {t("Username")}:{" "}
        </Typography>
        <Typography variant="body1" style={{ marginLeft: 5 }}>
          {infoUser?.user?.username || ""}
        </Typography> */}
      </div>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <Grid
            container
            justify="center"
            spacing={2}
            classes={{ root: classes.coverBoxBotom }}
          >
            <Grid item xs={12} sm={12}>
              <Grid
                container
                justify="center"
                spacing={2}
                className={classes.coverLevel}
              >
                <Grid item xs={12} sm={12}>
                  <div>
                    <List>
                      <ListItem classes={{ root: classes.listItem }}>
                        <ListItemAvatar style={{ position: "relative" }}>
                          <img
                            src={renderSrcImageLevel(
                              parseInt(infoUser?.bo_level)
                            )}
                            alt="lv"
                            width={80}
                            height="auto"
                          ></img>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography variant="h6">
                              {infoUser?.user?.username}
                            </Typography>
                          }
                          secondary={
                            <>
                              <Typography
                                variant="body1"
                                className={classes.typographyInviter}
                              >
                                {t("Inviter")}:{" "}
                                {infoUser?.inviter?.username || ""}
                              </Typography>
                            </>
                          }
                        />
                      </ListItem>
                    </List>
                  </div>
                </Grid>
              </Grid>
              <Divider style={{ backgroundColor: "#fff", height: 0.25 }} />
              <div className={classes.coverConditionLevel}>
                {/* <Typography variant="body1">{t("Rank condition")}</Typography> */}
                <Grid container spacing={1} className={classes.coverStatistic}>
                  <Grid item sm={6} xs={6}>
                    <div>
                      <Typography
                        variant="body1"
                        className={classes.typographySecondary}
                      >
                        {t("F1 VIP")}
                      </Typography>
                      {infoUser?.fixed_bo_level ? (
                        <Typography variant="body1" style={{ marginTop: 5 }}>
                          {infoUser?.total_f1_active}
                        </Typography>
                      ) : (
                        <Typography variant="body1" style={{ marginTop: 5 }}>
                          {infoUser?.total_f1_active}/
                          {
                            infoUser?.bo_commission_required[
                              `${infoUser?.bo_level + 1}`
                            ]?.num_f1
                          }
                        </Typography>
                      )}
                    </div>
                  </Grid>
                  <Grid item sm={6} xs={6}>
                    <div>
                      <Typography
                        variant="body1"
                        className={classes.typographySecondary}
                      >
                        {t("F1 volume")}
                      </Typography>
                      {infoUser?.fixed_bo_level ? (
                        <Typography variant="body1" style={{ marginTop: 5 }}>
                          {infoUser?.volume_current_week}
                        </Typography>
                      ) : (
                        <Typography variant="body1" style={{ marginTop: 5 }}>
                          {infoUser?.volume_current_week}/
                          {
                            infoUser?.bo_commission_required[
                              `${infoUser?.bo_level + 1}`
                            ]?.volume_week
                          }
                        </Typography>
                      )}
                    </div>
                  </Grid>
                  {/* <Grid item sm={4} xs={12}>
                    <div>
                      <Typography variant="caption">
                        {infoUser?.fixed_bo_level
                          ? t("Level")
                          : t("Next level")}
                      </Typography>
                      <Typography variant="body1" style={{ marginTop: 5 }}>
                        {infoUser?.fixed_bo_level
                          ? infoUser?.bo_level
                          : renderNextLevel()}
                      </Typography>
                    </div>
                  </Grid> */}
                  <Grid item xs={6} sm={6}>
                    <Typography
                      variant="body1"
                      className={classes.typographySecondary}
                    >
                      {t("Traders")}
                    </Typography>
                    <Typography
                      variant="body1"
                      className={classes.typographyPrimary}
                    >
                      {infoUser?.total_network_count}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Typography
                      variant="body1"
                      className={classes.typographySecondary}
                    >
                      {t("VIP traders")}
                    </Typography>
                    <Typography
                      variant="body1"
                      className={classes.typographyPrimary}
                    >
                      {infoUser?.total_network_active}
                    </Typography>
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <Typography
                      variant="body1"
                      className={classes.typographySecondary}
                    >
                      {t("Investment")}
                    </Typography>
                    <Typography
                      variant="body1"
                      className={classes.typographyPrimary}
                      style={{ color: "#23CA7A" }}
                    >
                      {convertNumberToCurrency(
                        parseFloat(infoUser?.total_investment) || 0
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Typography
                      variant="body1"
                      className={classes.typographySecondary}
                    >
                      {t("Network investment")}
                    </Typography>
                    <Typography
                      variant="body1"
                      className={classes.typographyPrimary}
                      style={{ color: "#23CA7A" }}
                    >
                      {convertNumberToCurrency(
                        parseFloat(infoUser?.total_ref_investment) || 0
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Grid
            container
            justify="center"
            spacing={2}
            classes={{ root: classes.coverBoxBotom }}
          >
            <Grid item xs={6} sm={6}>
              <Typography
                variant="body1"
                className={classes.typographySecondary}
              >
                {t("Trade commission")}
              </Typography>
              <div style={{ paddingLeft: 15 }}>
                <Typography
                  variant="body2"
                  className={classes.typographyPrimary}
                  style={{ color: "#23CA7A" }}
                >
                  {convertNumberToCurrency(
                    parseFloat(infoUser?.summary_commission?.bo?.USDT || 0)
                  )}
                </Typography>{" "}
                <Typography
                  variant="body2"
                  className={classes.typographyPrimary}
                  style={{ color: "#2F86FA" }}
                >
                  {convertNumberToMoney(
                    parseFloat(
                      parseFloat(infoUser?.summary_commission?.bo?.ALA || 0)
                    )
                  )}{" "}
                  ALA
                </Typography>
              </div>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Typography
                variant="body1"
                className={classes.typographySecondary}
              >
                {t("VIP commission")}
              </Typography>
              <div style={{ paddingLeft: 15 }}>
                <Typography
                  variant="body2"
                  className={classes.typographyPrimary}
                  style={{ color: "#23CA7A" }}
                >
                  {convertNumberToCurrency(
                    parseFloat(infoUser?.summary_commission?.buy_ref?.USDT || 0)
                  )}
                </Typography>{" "}
                <Typography
                  variant="body2"
                  className={classes.typographyPrimary}
                  style={{ color: "#2F86FA" }}
                >
                  {convertNumberToMoney(
                    parseFloat(infoUser?.summary_commission?.buy_ref?.ALA || 0)
                  )}{" "}
                  ALA
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography
                variant="body1"
                className={classes.typographySecondary}
              >
                {t("Invest commission")}
              </Typography>
              <div style={{ paddingLeft: 15 }}>
                <Typography
                  variant="body2"
                  className={classes.typographyPrimary}
                  style={{ color: "#23CA7A" }}
                >
                  {convertNumberToCurrency(
                    parseFloat(infoUser?.summary_commission?.invest?.USDT || 0)
                  )}
                </Typography>{" "}
                <Typography
                  variant="body2"
                  className={classes.typographyPrimary}
                  style={{ color: "#2F86FA" }}
                >
                  {convertNumberToMoney(
                    parseFloat(infoUser?.summary_commission?.invest?.ALA || 0)
                  )}{" "}
                  ALA
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12} xs={12}>
          <Grid
            container
            justify="center"
            spacing={2}
            classes={{ root: classes.coverBoxBotom }}
          >
            <div className={classes.rootTabs}>
              {/* <AppBar position="static" classes={{ root: classes.appBar }}>
                  <Tabs
                    className={classes.tabs}
                    value={valueTab}
                    onChange={handleChangeTab}
                    indicatorColor="primary"
                    textColor="inherit"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab
                      label={t("All")}
                      className={classes.tab}
                      disableRipple={true}
                    />
                    <Tab
                      label={t("This month")}
                      className={classes.tab}
                      disableRipple={true}
                    />
                    <Tab
                      label={t("Last month")}
                      className={classes.tab}
                      disableRipple={true}
                    />
                  </Tabs>
                </AppBar> */}
              <div className={classes.tabPanel}>
                {renderTableDataUserTradeBo()}
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
