import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Drawer,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CloseCircle from "../../assets/img/CloseCircle.png";
import JamDelete from "../../assets/img/JamDelete.png";
import convertNumberToCurrency from "../../common/convertNumberToCurrency";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.color.background,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    rootCard: {
      backgroundColor: theme.color.background,
      boxShadow: "none",
    },
    typography: {
      color: theme.text.primary,
      width: "100%",
      hyphens: "auto",
      //   wordWrap: "break-word",
    },
    coverAmount: {
      backgroundColor: "#2A2D35",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      minHeight: 45,
      padding: "0 5px",
      borderRadius: 3,
    },
    buttonTransactionWeight: {
      backgroundColor: "#2A2D35",
      margin: 0,
      textAlign: "center",
      padding: "6px 5px",
      color: theme.text.primary,
      width: "100%",
      minHeight: 45,
      textTransform: "none",
    },
    gridContainer: {
      marginTop: 5,
    },
    buttonTransactionWeightLarge: {
      backgroundColor: "#2A2D35",
      margin: 0,
      textAlign: "center",
      padding: "6px 0",
      color: theme.text.primary,
      width: "100%",
      textTransform: "none",
      minHeight: 97,
      display: "flex",
      flexWrap: "wrap",
    },
    iconButton: {
      color: "#C4C4C4",
    },
  }),
  { index: 1 }
);

function DrawerTransactionVolume(props) {
  const classes = useStyles();
  const {
    openDrawer,
    handleCloseDrawer,
    amount,
    handleUpdateAmount,
    walletInfo,
  } = props;
  const { t } = useTranslation();
  const [amountState, setAmountState] = useState("");
  const [clickNumber, setClickNumber] = useState(false);

  const walletAmount = walletInfo.results?.reduce(
    (accumulator, currentWallet) => accumulator + currentWallet?.estimate_usdt,
    0
  );

  const handleUpAmountState = (number) => {
    setAmountState(`${parseFloat(amountState) + number}`);
  };

  const handleUpdateAmountState = (number) => {
    if (clickNumber) {
      setAmountState(`${amountState}${number}`);
    } else {
      setAmountState(`${number}`);
      setClickNumber(true);
    }
  };

  const handleDeleteChar = () => {
    if (amountState.length > 1) {
      setAmountState(`${amountState.slice(0, -1)}`);
    } else {
      setAmountState(`0`);
    }
  };

  const handleGetAllAmount = () => {
    setAmountState(`${walletAmount}`);
  };

  const handleResetAmount = () => {
    setAmountState(`${0}`);
  };

  const handleCompleted = () => {
    handleUpdateAmount(parseFloat(amountState));
    handleCloseDrawer();
  };

  useEffect(() => {
    if (openDrawer) {
      setAmountState(`${amount}`);
    }
  }, [openDrawer]);

  return (
    <Drawer
      anchor={"bottom"}
      open={openDrawer}
      onClose={handleCloseDrawer}
      classes={{ paper: classes.root }}
    >
      <Card classes={{ root: classes.rootCard }}>
        <CardHeader
          title={
            <Typography classes={{ root: classes.typography }} variant="h6">
              {t("Transaction volume")}
            </Typography>
          }
          action={
            <IconButton
              classes={{ root: classes.iconButton }}
              onClick={handleCloseDrawer}
            >
              <CloseIcon />
            </IconButton>
          }
        />
        <CardContent>
          <Grid container spacing={1} classes={{ root: classes.gridContainer }}>
            <Grid item xs={3}>
              <Button
                classes={{ root: classes.buttonTransactionWeight }}
                onClick={() => {
                  handleUpAmountState(-5);
                }}
              >
                <Typography variant="h6" classes={{ root: classes.typography }}>
                  -
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.coverAmount}>
                <Typography
                  variant="body1"
                  classes={{ root: classes.typography }}
                >
                  {convertNumberToCurrency(parseFloat(amountState))}
                </Typography>
                <img
                  src={`${CloseCircle}`}
                  alt="CloseCircle"
                  style={{ marginLeft: 10, cursor: "pointer" }}
                  onClick={handleResetAmount}
                ></img>
              </div>
            </Grid>
            <Grid item xs={3}>
              <Button
                classes={{ root: classes.buttonTransactionWeight }}
                onClick={() => {
                  handleUpAmountState(5);
                }}
              >
                <Typography variant="h6" classes={{ root: classes.typography }}>
                  +
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                classes={{ root: classes.buttonTransactionWeight }}
                onClick={() => {
                  handleUpAmountState(5);
                }}
              >
                <Typography
                  variant="body1"
                  classes={{ root: classes.typography }}
                >
                  +5
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                classes={{ root: classes.buttonTransactionWeight }}
                onClick={() => {
                  handleUpAmountState(10);
                }}
              >
                <Typography
                  variant="body1"
                  classes={{ root: classes.typography }}
                >
                  +10
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                classes={{ root: classes.buttonTransactionWeight }}
                onClick={() => {
                  handleUpAmountState(50);
                }}
              >
                <Typography
                  variant="body1"
                  classes={{ root: classes.typography }}
                >
                  +50
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                classes={{ root: classes.buttonTransactionWeight }}
                onClick={() => {
                  handleUpAmountState(100);
                }}
              >
                <Typography
                  variant="body1"
                  classes={{ root: classes.typography }}
                >
                  +100
                </Typography>
              </Button>
            </Grid>
          </Grid>

          <Grid container spacing={1} classes={{ root: classes.gridContainer }}>
            <Grid item xs={9}>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <Button
                    classes={{ root: classes.buttonTransactionWeight }}
                    onClick={() => {
                      handleUpdateAmountState(1);
                    }}
                  >
                    <Typography
                      variant="body1"
                      classes={{ root: classes.typography }}
                    >
                      1
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    classes={{ root: classes.buttonTransactionWeight }}
                    onClick={() => {
                      handleUpdateAmountState(2);
                    }}
                  >
                    <Typography
                      variant="body1"
                      classes={{ root: classes.typography }}
                    >
                      2
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    classes={{ root: classes.buttonTransactionWeight }}
                    onClick={() => {
                      handleUpdateAmountState(3);
                    }}
                  >
                    <Typography
                      variant="body1"
                      classes={{ root: classes.typography }}
                    >
                      3
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    classes={{ root: classes.buttonTransactionWeight }}
                    onClick={() => {
                      handleUpdateAmountState(4);
                    }}
                  >
                    <Typography
                      variant="body1"
                      classes={{ root: classes.typography }}
                    >
                      4
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    classes={{ root: classes.buttonTransactionWeight }}
                    onClick={() => {
                      handleUpdateAmountState(5);
                    }}
                  >
                    <Typography
                      variant="body1"
                      classes={{ root: classes.typography }}
                    >
                      5
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    classes={{ root: classes.buttonTransactionWeight }}
                    onClick={() => {
                      handleUpdateAmountState(6);
                    }}
                  >
                    <Typography
                      variant="body1"
                      classes={{ root: classes.typography }}
                    >
                      6
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Button
                classes={{ root: classes.buttonTransactionWeightLarge }}
                onClick={handleGetAllAmount}
              >
                <Typography
                  variant="body1"
                  classes={{ root: classes.typography }}
                >
                  {t("All")}
                </Typography>
              </Button>
            </Grid>
          </Grid>

          <Grid container spacing={1} classes={{ root: classes.gridContainer }}>
            <Grid item xs={9}>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <Button
                    classes={{ root: classes.buttonTransactionWeight }}
                    onClick={() => {
                      handleUpdateAmountState(7);
                    }}
                  >
                    <Typography
                      variant="body1"
                      classes={{ root: classes.typography }}
                    >
                      7
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    classes={{ root: classes.buttonTransactionWeight }}
                    onClick={() => {
                      handleUpdateAmountState(8);
                    }}
                  >
                    <Typography
                      variant="body1"
                      classes={{ root: classes.typography }}
                    >
                      8
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    classes={{ root: classes.buttonTransactionWeight }}
                    onClick={() => {
                      handleUpdateAmountState(9);
                    }}
                  >
                    <Typography
                      variant="body1"
                      classes={{ root: classes.typography }}
                    >
                      9
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    classes={{ root: classes.buttonTransactionWeight }}
                    onClick={() => {
                      handleUpdateAmountState(".");
                    }}
                  >
                    <Typography
                      variant="body1"
                      classes={{ root: classes.typography }}
                    >
                      .
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    classes={{ root: classes.buttonTransactionWeight }}
                    onClick={() => {
                      handleUpdateAmountState(0);
                    }}
                  >
                    <Typography
                      variant="body1"
                      classes={{ root: classes.typography }}
                    >
                      0
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    classes={{ root: classes.buttonTransactionWeight }}
                    onClick={() => {
                      handleDeleteChar();
                    }}
                  >
                    <img src={`${JamDelete}`} alt="JamDelete"></img>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Button
                classes={{ root: classes.buttonTransactionWeightLarge }}
                onClick={handleCompleted}
              >
                <Typography
                  variant="body1"
                  classes={{ root: classes.typography }}
                >
                  {t("Completed")}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Drawer>
  );
}

DrawerTransactionVolume.propTypes = {};

export default DrawerTransactionVolume;
