import { useEffect } from "react";
import { useHistory } from "react-router";
import PaperFifthFloor from "../../components/Body/BodyHomePage/PaperFifthFloor";
import PaperFirstFloor from "../../components/Body/BodyHomePage/PaperFirstFloor";
import PaperFourthFloor from "../../components/Body/BodyHomePage/PaperFourth";
import PaperSecondFloor from "../../components/Body/BodyHomePage/PaperSecondFloor";
import PaperThirdFloor from "../../components/Body/BodyHomePage/PaperThirdFloor";
import FooterHome from "../../components/Footer/FooterHome";
import TopBarHome from "../../components/TopBar/TopBarHome";
import { PATH } from "../../const/paths";

export default function Home() {
  const history = useHistory();

  const handleLink = (path) => {
    history.push(path);
  };

  useEffect(() => {
    let jwt_token = localStorage.getItem("jwt_token");
    if (jwt_token) {
      history.push(PATH.TRADING_VIEW);
    }
  }, []);

  return (
    <div>
      <PaperFirstFloor>
        <TopBarHome />
      </PaperFirstFloor>
      <PaperSecondFloor></PaperSecondFloor>
      <PaperThirdFloor></PaperThirdFloor>
      <PaperFourthFloor></PaperFourthFloor>
      <PaperFifthFloor handleLink={handleLink}></PaperFifthFloor>
      <FooterHome />
    </div>
  );
}
