import {
  createStyles,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Popover,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Gear from "../../assets/img/Gear.png";
import LanguageIcon from "@material-ui/icons/Language";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      iconButton: {
        fontSize: "16px",
      },
      listSetting: {
        width: 270,
        padding: 0,
        backgroundColor: "transparent",
        color: "#fff",
      },
      popover: {
        borderRadius: 2,
        backgroundColor: theme.color.background,
        border: `1px solid ${theme.color.border}`,
      },
      gridContainer: {
        alignItems: "center",
      },
      gridItemLanguage: {
        display: "flex",
        alignItems: "center",
      },
    }),
  { index: 1 }
);

function PopoverSetting(props) {
  const classes = useStyles(props);
  const { handleLogout, renderSelectLanguage } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <IconButton
        color="inherit"
        className={classes.iconButton}
        onClick={handleClick}
      >
        <img src={`${Gear}`} alt="Gear" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        classes={{ paper: classes.popover }}
      >
        <List
          component="nav"
          aria-label="main mailbox folders"
          classes={{ root: classes.listSetting }}
        >
          <ListItem>
            <Grid
              container
              spacing={2}
              classes={{ root: classes.gridContainer }}
            >
              <Grid item sm={4} classes={{ root: classes.gridItemLanguage }}>
                {/* <LanguageIcon style={{ marginRight: 5 }} /> */}
                {t("Language")}
              </Grid>
              <Grid item sm={8}>
                {renderSelectLanguage()}
              </Grid>
            </Grid>
          </ListItem>
        </List>
        <Divider style={{ backgroundColor: "#fff" }} />
        <List
          component="nav"
          aria-label="main mailbox folders"
          classes={{ root: classes.listSetting }}
        >
          <ListItem button onClick={handleLogout}>
            <ListItemText
              primary={
                <Typography variant="body2" style={{ textAlign: "center" }}>
                  {t("Log out")}
                </Typography>
              }
            />
          </ListItem>
        </List>
      </Popover>
    </>
  );
}

PopoverSetting.propTypes = {};

export default PopoverSetting;
