import { createStyles, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import TopBarHandleAccount from "../components/TopBar/TopBarHandleAccount";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        backgroundColor: theme.body.backgroundColor,
      },
      body: {
        width: "60%",
        margin: "auto",
        padding: "20px 0",
        color: theme.text.primary,
      },
      title: {
        color: theme.text.primary,
        fontWeight: 700,
      },
      subTitle: { color: theme.text.primary, fontSize: 14, fontWeight: 700 },
      textPrimary: {
        color: theme.text.primary,
        fontSize: 14,
      },
      coverList: {
        paddingLeft: 40,
      },
    }),
  { index: 1 }
);

export default function TermOfUse() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <TopBarHandleAccount />
      <div className={classes.body}>
        <div>
          <Typography variant="h5" classes={{ root: classes.title }}>
            {t("TERM OF USE")}
          </Typography>
        </div>
        <br />
        <div>
          <Typography variant="body1" classes={{ root: classes.subTitle }}>
            1. {t("Client agreement")}
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            {t(
              "The Term of use is the agreement between clients and the service operator, Alltrade.com. The agreement along with any additional terms or policies incorporated herein by reference, as amended from time to time, (hereinafter the “Agreement”) sets out the terms under which all business dealings shall be transacted between the customer (“You”, “Client”) and us (hereinafter referred to as the “Company”)"
            )}
            .
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            1.
            {t(
              "When registering on Alltrade.com, we may ask you to provide us with some personal information, including without limitation to"
            )}
            .
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            2.
            {t(
              "By clicking to accept, and by accessing the Site or using the services provided by the Company, you understand, represent, acknowledge and agree to be legally bound by this Agreement as well as other documentation/information published on the Website, including Privacy Policy, as if you had manually signed this Agreement"
            )}
            .
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            3.
            {t("By using the Site, you represent and warrant that")}:
          </Typography>
          <br />
          <ul className={classes.coverList}>
            <li>
              {t(
                "You are at least 18 years old and that you are an individual, legal person or other organization with full legal capacity to enter into this Terms of Service between you and Alltrade"
              )}
              .
            </li>
            <li>
              {t(
                "Not residing in any country where distribution or provision of the financial products or services offered by the company would be contrary to local laws or regulations (including of Vietnam, Germany, France, Austria, Belgium, Estonia, Bulgaria, Croatia, Cyprus, Netherlands, Czech Republic, Denmark, Italy, Finland, Greece, Hungary, Ireland, Latvia, Lithuania, Luxembourg, Malta, Poland, Portugal, Romania, Slovenia, Spain, Sweden, and Slovakia)"
              )}
            </li>
          </ul>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            {t(
              "If you are not, you and your guardian shall undertake all consequences resulting from your actions and we shall have the right to cancel or freeze your account in addition to filing claims against you and your guardian for compensation"
            )}
            .
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            4.
            {t(
              "The Client hereby acknowledges that each and any Operation, activity, transaction, order and/or communication performed by him/her on the Trading Platform, including without limitation through the Account, and the Website, shall be governed by and/or must be executed in accordance with the terms and conditions of this Agreement and other documentation/ information on the Website"
            )}
            .
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            5.
            {t(
              "By accepting this current agreement, the Client confirms that he/she is able to receive information, including amendments to the present Agreement either via email or through the Website"
            )}
            .
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            {t(
              "This Agreement has been published on Alltrade.com website and applies to all related services of Alltrade. Please read the following carefully to understand this legal notice and the terms and conditions pursuant to which an acquisition of financial contracts via this site is governed. If you do not accept this Agreement, please refrain from using the Site or use any of the services, functions, or features offered from time to time on the Site (“Services”). Your use of the Site, and/or Mobile Apps constitutes acceptance of this Agreement"
            )}
            .
          </Typography>
        </div>
        <br />
        <div>
          <Typography variant="body1" classes={{ root: classes.subTitle }}>
            2. {t("Definitions")}
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            {t(
              "In these Terms of Service and all documents incorporated herein by reference, the following words have the following meanings"
            )}
            :
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            1.
            {t(
              "“Account” means an account opened by Client with the Company for trading"
            )}
            .
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            2.
            {t("“AML” means anti-money laundering")}.
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            3.
            {t(
              "“Mobile Apps” mean mobile apps available for Android and iOS in order to optimize the use of the Services on a smartphone or tablet"
            )}
            .
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            4.
            {t(
              "“Transaction” means any type of transaction performed by Client in the Account, including but not limited to buy and sell transactions related to financial instruments, deposits and withdrawals"
            )}
            .
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            5.
            {t(
              "“Service(s)” means any of the services, functions, or features offered on the Site"
            )}
            .
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            6.
            {t("“Site” means the Internet website Alltrade.com")}.
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            7.
            {t("“You” or “your” means the client")}.
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            8.
            {t(
              "“We”, “us”, “Alltrade”, “the company” or “our” means Alltrade.com"
            )}
            .
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            9.
            {t(
              "“Terms of Service” means these terms and conditions of service, as they may be changed, amended, or updated from time to time, including the following Site policies and pages: the Privacy Policy and Risk Disclosure"
            )}
            .
          </Typography>
        </div>
        <br />
        <div>
          <Typography variant="body1" classes={{ root: classes.subTitle }}>
            3. {t("Service of company")}
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            1.
            {t(
              "Services – services provided by the Company to the Client through the Trading Platform of the Company, including without limitation to customer, analytics, news and marketing information services"
            )}
            .
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            2.
            {t(
              "The Company shall facilitate the execution of trade activities/orders and/or transactions of the Client but the Client hereby acknowledges and accepts that the Company shall not at any time provide any trust services and/or trading consultation or advisory services to the Client"
            )}
            .
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            3.
            {t(
              "The Company shall process all transactions/Operations of the Client in accordance with the terms and conditions of this Agreement and on an execution-only basis. The Company shall not manage the Client’s Account nor advise the Client in any way"
            )}
            .
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            4.
            {t(
              "The Company shall process the orders/transactions requested by the Client under this Agreement irrespective of whether such orders/transactions may result to not being beneficial for the Client. The Company is under no obligation, unless otherwise agreed in this Agreement and/or other documentation/information on the Website, to monitor or advise the Client on the status of any transaction/order, to make margin calls to the Client, or to close out any of the Client’s open positions. Unless otherwise specifically agreed, the Company is not obligated to process or attempt to process the Client’s order/transaction using quotes more favorable than those offered through the Trading Platform"
            )}
            .
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            5.
            {t(
              "The Company shall not be financially liable for any operations conducted by the Client through the Account and/or on the Trading Platform"
            )}
            .
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            6.
            {t(
              "The Client shall be liable for all orders given through his security information and any orders received in this manner by the Company shall be considered to have been given by the Client. So long as any order is submitted through the Account of a Client, the Company shall reasonably assume that such orders are submitted by Client and the Company shall not be under any obligation to investigate further into the matter. The Company shall not be liable to and/or does not maintain any legal relations with, any third party other than the Client"
            )}
            .
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            7.
            {t(
              "If the Client acts on behalf of any third party and/or on behalf of any third party’s name, the Company shall not accept this person as a Client and shall not be liable before this person regardless if such person was identified or not"
            )}
            .
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            8.
            {t(
              "Each Client shall be the only authorized user of the Company’s services and of the corresponding Account. The Client is granted an exclusive and non-assignable right to the use of and to access the Account and it is his/her responsibility to ensure that no other third party, including, without limitation, to any next of kin and/or to members of his/her immediate family, shall gain access to and/or trade through the Account assigned to her/him"
            )}
            .
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            9.
            {t(
              "It is understood and agreed by the Client that the Company may from time to time, at its sole discretion, utilize a third party to hold the Client’s funds and/or for the purpose of receiving payment execution services. These funds will be held in segregated accounts from such third party’s own funds and will not affect the rights of the Client to such funds"
            )}
            .
          </Typography>
        </div>
        <br />
        <div>
          <Typography variant="body1" classes={{ root: classes.subTitle }}>
            4. {t("Fee")}
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            {t(
              "The Company shall be entitled to receive a fee from the Client regarding the Service(s), provided by the Company"
            )}
            .
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            {t(
              "The Company may pay fee/commission to business introducers, referring agents, or other third parties based on written agreement. This fee/commission is related to the frequency/volume of transactions and/or other parameters. The Company has the right to amend its fees and charges from time to time"
            )}
            .
          </Typography>
        </div>
        <br />
        <div>
          <Typography variant="body1" classes={{ root: classes.subTitle }}>
            5. {t("Rights, obligations of the parties")}
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            5.1. {t("Rights, obligations of the client")}
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            1.
            {t("The client shall be entitled to")}:
          </Typography>
          <ul className={classes.coverList}>
            <li>
              {t(
                "Submit with the Company any order requesting the execution of a transaction/Operation on the Website in accordance with and subject to the terms and conditions of this Agreement"
              )}
              ;
            </li>
            <li>
              {t(
                "Request withdrawal of any amounts subject and in accordance with the Withdrawal Policy, and provided that the Company has no claims against the Client and/or the Client does not have any outstanding debts to the Company"
              )}
              ;
            </li>
            <li>
              {t(
                "Report to the Company any such problems, errors, or suspected system inadequacies that they may experience"
              )}
              .
            </li>
            <li>
              {t(
                "Unilaterally terminate the Agreement provided that there is no debt from the client toward the company"
              )}
              .
            </li>
          </ul>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            2.
            {t("Client is obliged")}:
          </Typography>
          <br />
          <ul className={classes.coverList}>
            <li>
              {t(
                "Acknowledges that the account shall be activated upon the deposit of funds into the Account"
              )}
              .
            </li>
            <li>
              {t(
                "Warrants that you shall at all times be compliant with and honor all terms and conditions of this Agreement"
              )}
            </li>
            <li>
              {t(
                "Warrants that you shall ensure that at all times the username and password issued by the Company in relation to the use of the Service(s) and the Account, will only be used by you and will not be disclosed to any other person"
              )}
              ;
            </li>
            <li>
              {t(
                "Shall be liable for all orders submitted through his/her security information and any orders received in this manner by the Company shall be considered to have been given by the Client"
              )}
              ;
            </li>
            <li>
              {t(
                "Confirms that any trading strategies and/or investment decisions and/or any activities performed by him/her through his/her Account and on the Trading Platform are made having in mind/considered/being aware of all risks involved and solely on the basis of his/her knowledge and upon his/her sole discretion"
              )}
              .
            </li>
            <li>
              {t(
                "Warrants that he/she shall take all necessary steps and action in order not to disclose any confidential information of the Company which the Company shall disclose to and/or make available to the Client from time to time"
              )}
              .
            </li>
            <li>
              {t(
                "Accepts any risk, including without limitation to any risk of financial loss, that arises from unauthorized access to and operation of his/her Account by third and/or unauthorised parties"
              )}
              .
            </li>
          </ul>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            5.2. {t("Rights, obligations of the company")}
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            1.
            {t("The Company shall be entitled to")}:
          </Typography>
          <br />
          <ul className={classes.coverList}>
            <li>
              {t(
                "To modify or update any provision of this Agreement at any time for any reason and any such changes to this Agreement will supersede and replace any previous provisions of this Agreement effective immediately upon posting on the Site"
              )}
              .
            </li>
            <li>
              {t(
                "The Company reserves the right to change, add or set as default the option payments rate, return rate, the possibility of adjusting the return rate, the possibility of acquiring the option type, the minimum and/or the maximum option amount, the possible expiration periods for one, several or all of the assets"
              )}
              .
            </li>
            <li>
              {t(
                "To engage any third party for the provision of Services in accordance with this Agreement, provided that such third party providers fully assume the Company’s obligations to keep all information received from Client as strictly confidential"
              )}
              .
            </li>
            <li>
              {t(
                "To utilize, at its sole discretion, a third party to hold Client’s funds and/or for purposes of receiving payment execution services"
              )}
              .
            </li>
            <li>
              {t(
                "To reject and immediately return funds deposited if the funds do not originate from a bank account owned by Client"
              )}
              .
            </li>
            <li>
              {t(
                "To set limits on the amounts that clients can withdraw on a daily or other periodic basis"
              )}
              .
            </li>
            <li>
              {t(
                "To contact Client with any question concerning this Agreement, particularly, in order to make certain in the Client’s intentions regarding Client’s actions on Client’s Account"
              )}
              .
            </li>
            <li>
              {t(
                "To revoke Client’s access to the Site at any time, where the Company deems necessary"
              )}
              .
            </li>
            <li>
              {t(
                "To temporarily or permanently suspend Client’s access to the Site and the Account, and/or terminate the Agreement, and/or take any other actions as the Company may see fit under the circumstances"
              )}
              .
            </li>
          </ul>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            2.
            {t("The company is obliged")}:
          </Typography>
          <br />
          <ul className={classes.coverList}>
            <li>
              {t(
                "To provide client with services on the site in accordance to the provisions of this agreement"
              )}
              ;
            </li>
            <li>{t("Compliance with the provisions of this agreement")}.</li>
          </ul>
        </div>
        <br />
        <div>
          <Typography variant="body1" classes={{ root: classes.subTitle }}>
            6. {t("Liability")}
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            1.{t("Provision of service")}
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            {t(
              "Alltrade will provide service at an “as is” and “commercially available” condition, and does not offer any form of warranty with regards to the Service’s reliability, stability, accuracy and completeness of the technology involved. Alltrade serves merely as a venue of transactions where coin-related information can be acquired and coin-related transactions can be conducted. We cannot control the quality, security or legality of the coin involved in any transaction, truthfulness of the transaction information, or capacity of the parties to any transaction to perform their obligations under the rules. You must carefully consider the associated investment risks, legal status and validity of the transaction information and investment decisions prior to your use of the Services provided"
            )}
            .
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            2.{t("Limitation of liability")}
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            {t(
              "Trading markets in digital tokens are volatile and shift quickly in terms of price, liquidity, market depth, and trading dynamics. Digital tokens also are subject to cybersecurity risk, including the risk of a cyberattack or breach"
            )}
            .
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            {t("You acknowledge and agree")}:
          </Typography>
          <br />
          <ul className={classes.coverList}>
            <li>
              {t(
                "To be fully responsible and liable for your trading and non-trading actions and inactions on the Site and all gains and losses sustained from your use of the Site and any of the Services; knowing the true status of your asset on the Site, even if presented incorrectly by the Site at any time"
              )}
              .
            </li>
            <li>
              {t(
                "To be fully responsible for safeguarding access to, and any information provided through, the Site and any of the Services, including, but not limited to, digital tokens wallet, digital tokens address, private keys, usernames, passwords, and bank account details"
              )}
              ;
            </li>
          </ul>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            {t(
              "The Company does not guarantee uninterrupted service, safe and errors-free, and immunity from unauthorized access to the trading sites' servers nor disruptions caused from damages, malfunctions or failures in hardware, software, communications and systems in the Client's computers and in the Company's suppliers"
            )}
            .
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            {t(
              "Supplying services by the Company depends, inter alia, on third parties and the Company bears no responsibility for any actions or omissions of third parties and bears no responsibility for any damage and/or loss and/or expense caused to the Client and/or third party as a result of and/or in relation to any aforesaid action or omission"
            )}
            .
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            {t(
              "The Company will bear no responsibility for any damage of any kind allegedly caused to the Client, which involves force majeure or any such event that the Company has no control of and which has influenced the accessibility of its trading site"
            )}
            .
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            {t(
              "Under no circumstances will the Company or its Agent(s) hold responsibility for direct or indirect damage of any kind, even if the Company or its Agent(s) had been notified of the possibility of aforesaid damages"
            )}
            .
          </Typography>
          <br />
        </div>
        <br />
        <div>
          <Typography variant="body1" classes={{ root: classes.subTitle }}>
            7. {t("Governing laws and jurisdiction")}
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            {t(
              "Laws regarding financial contracts vary throughout the world, and it is the responsibility of clients accessing this site to ensure that you understand and fully comply with any laws or regulations relevant in your country. Access to this site and the offering of financial contracts via this site in certain jurisdictions may be restricted and, accordingly, clients accessing this site are required to inform themselves about, and to observe, such restrictions"
            )}
            .
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            {t(
              "This contract shall be governed by and construed in accordance with laws of the country of incorporation of the Company (the “Jurisdiction”). In accepting these Terms of Service you irrevocably agree the Jurisdiction's court shall have exclusive jurisdiction to settle any claim or difference or dispute, which may arise out of, or in connection with any such contract or claim and you waive any rights to claim that such courts are not the appropriate forum"
            )}
            .
          </Typography>
        </div>
        <br />
        <div>
          <Typography variant="body1" classes={{ root: classes.subTitle }}>
            8. {t("Intellectual Property")}
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            1.
            {t(
              "The trademarks, service marks, and trade names, including both word marks and design marks are the property of Alltrade or other third parties. You agree not to appropriate, copy, display, or use the marks or other content without express, prior, written permission from Alltrade or the third-party owner of the marks, including without limitation, as a domain name, as social media profile/handle, or a website, in an advertisement, as or in connection with a phone number, as or in connection with an email address, in Internet search results, in meta data or code, or in any other manner"
            )}
            .
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            2.
            {t(
              ".Unless otherwise indicated, all materials on the Site are copyrighted by, and owned exclusively by Alltrade (“Copyrights”). We reserve all rights in its Copyrights. You agree not to appropriate, copy, display, or use the Copyrights or other content without express, prior, written permission from Alltrade"
            )}
            .
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            3.
            {t(
              "The site may provide certain social media features that enable you to link, send communications, or display certain content from the Site. You may use these features solely as they are provided by Alltrade. You may not establish a link from any website that is not owned by you, cause the site or portions of it to be displayed on or by any other site (for example, framing, deep linking, or in-line linking), or otherwise take any action with respect to the materials on the Site that is inconsistent with any other provision of these terms"
            )}
            .
          </Typography>
        </div>
        <br />
        <div>
          <Typography variant="body1" classes={{ root: classes.subTitle }}>
            9. {t("Termination")}
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            {t(
              "You agree that we have the right to immediately suspend your account (and any accounts beneficially owned by related entities or affiliates), freeze or lock the funds in all such accounts, and suspend your access to Alltrade if we suspect any such accounts to be in violation of the Terms of Service, Privacy Policy, AML/CTF acts or any applicable laws & regulations. We shall have the right to keep and use the transaction data or other information related to such accounts. The above account controls may also be applied in the following cases"
            )}
            :
          </Typography>
          <br />
          <ul className={classes.coverList}>
            <li>
              {t(
                "The account is subject to a governmental proceeding, criminal investigation or other pending litigation"
              )}
              .
            </li>
            <li>
              {t(
                "We identifies that the Client is involved and/or is using a high frequency trading software with the purpose of manipulating the Company’s systems and/or trading platform and/or has illegally and/or improperly and/or maliciously and/or knowingly gained an unfair advantage over and/or to the detriment of other clients"
              )}
              .
            </li>
            <li>{t("We detect unusual activity in the account")}.</li>
            <li>{t("We detect unauthorized access to the account")}.</li>
            <li>
              {t(
                "We are required to do so by a court order or command by a regulatory/government authority"
              )}
              .
            </li>
          </ul>
          <br />
          <Typography variant="body1" classes={{ root: classes.subTitle }}>
            {t("COMPLAINTS")}
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            {t(
              "If you have any questions or problems, you can contact us for getting support by sending us a ticket or email to"
            )}{" "}
            <a href="mailto:support@alltrade.ventures">
              support@alltrade.ventures
            </a>
            {t(
              "We will investigate each inquiry and provide a final response in the shortest time possible"
            )}
            .
          </Typography>
        </div>
      </div>
    </div>
  );
}
