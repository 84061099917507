import { cloneDeep } from "lodash";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  getConvertTransaction,
  getDemoWallet,
  getMainTracsaction,
  getUserWallet,
  reGetUserWallet,
  withdrawWallet,
} from "../../apis/wallet.api";
import { TOAST_NOTI } from "../../const/common";
import * as types from "../../const/redux/wallet";
import { callToastNotiSuccess } from "../actions/toastNotiAction";
import {
  getConvertTransactionSuccess,
  getDemoWalletSuccess,
  getMainTransactionSuccess,
  getUserWalletSuccess,
  reGetUserWalletSuccess,
  setLoadingWallet,
  withdrawWalletSuccess,
} from "../actions/walletAction";

function* getUserWalletSagas({ payload }) {
  try {
    let responseUserWallet = yield call(getUserWallet);
    if (responseUserWallet.status === 200) {
      let { data } = responseUserWallet;
      yield put(getUserWalletSuccess(data));
    }
  } catch (err) {
    let { data } = err.response;
    yield put(
      callToastNotiSuccess({
        noti: data.message,
        status: TOAST_NOTI.ERROR,
        code: data.code,
      })
    );
  }
}

function* reGetUserWalletSagas({ payload }) {
  try {
    let responseUserWallet = yield call(reGetUserWallet);
    let walletReducer = yield select((state) => state.walletReducer);
    if (responseUserWallet.status === 200) {
      let { data } = responseUserWallet;
      let info = cloneDeep(walletReducer.info);
      let results = info.results?.map((element) => {
        if (element.c.symbol === "USDT") {
          element.balance = parseFloat(data.balance);
        }
        return element;
      });
      yield put(reGetUserWalletSuccess({ ...info, results }));
    }
  } catch (err) {
    let { data } = err.response;
    yield put(
      callToastNotiSuccess({
        noti: data.message,
        status: TOAST_NOTI.ERROR,
        code: data.code,
      })
    );
  }
}

function* getMainTransactionSagas({ payload }) {
  try {
    const { params } = payload;
    yield put(setLoadingWallet(true));
    let responseMainTransaction = yield call(getMainTracsaction, params);
    if (responseMainTransaction.status === 200) {
      let { data } = responseMainTransaction;
      yield put(getMainTransactionSuccess(data));
    }
    yield put(setLoadingWallet(false));
  } catch (err) {
    let { data } = err.response;
    yield put(
      callToastNotiSuccess({
        noti: data.message,
        status: TOAST_NOTI.ERROR,
        code: data.code,
      })
    );
    yield put(setLoadingWallet(false));
  }
}

function* withdrawWalletSagas({ payload }) {
  try {
    const { walletId, dataReq, paramsTransaction } = payload;
    yield put(setLoadingWallet(true));
    let responseWithdrawal = yield call(withdrawWallet, walletId, dataReq);
    if (responseWithdrawal.status === 200) {
      yield put(setLoadingWallet(false));
      yield put(
        callToastNotiSuccess({
          noti: "Successful withdrawal",
          status: TOAST_NOTI.SUCCESS,
        })
      );
      const [responseUserWallet, responseMainTransaction] = yield all([
        call(getUserWallet),
        call(getMainTracsaction, paramsTransaction),
      ]);

      let dataWithdraw = { info: {}, transaction: {} };
      if (responseUserWallet.status === 200) {
        let { data } = responseUserWallet;
        dataWithdraw.info = data;
      }
      if (responseMainTransaction.status === 200) {
        let { data } = responseMainTransaction;
        dataWithdraw.transaction = data;
      }
      yield put(withdrawWalletSuccess(dataWithdraw));
    } else {
      yield put(setLoadingWallet(false));
    }
  } catch (err) {
    let { data } = err.response;
    yield put(setLoadingWallet(false));
    yield put(
      callToastNotiSuccess({
        noti: data.message,
        status: TOAST_NOTI.ERROR,
        code: data.code,
      })
    );
  }
}

function* getDemoWalletSagas({ payload }) {
  try {
    let responseDemoWallet = yield call(getDemoWallet);
    if (responseDemoWallet.status === 200) {
      let { data } = responseDemoWallet;
      yield put(getDemoWalletSuccess(data));
    }
  } catch (err) {
    let { data } = err.response;
    yield put(
      callToastNotiSuccess({
        noti: data.message,
        status: TOAST_NOTI.ERROR,
        code: data.code,
      })
    );
  }
}

function* getConvertTransactionSagas({ payload }) {
  try {
    const { params } = payload;
    yield put(setLoadingWallet(true));
    let responseConvertTransaction = yield call(getConvertTransaction, params);
    if (responseConvertTransaction.status === 200) {
      let { data } = responseConvertTransaction;
      yield put(getConvertTransactionSuccess(data));
    }
    yield put(setLoadingWallet(false));
  } catch (err) {
    let { data } = err.response;
    yield put(
      callToastNotiSuccess({
        noti: data.message,
        status: TOAST_NOTI.ERROR,
        code: data.code,
      })
    );
    yield put(setLoadingWallet(false));
  }
}

export function* watchWalletAsync() {
  yield takeEvery(types.GET_USER_WALLET_REQ, getUserWalletSagas);
  yield takeEvery(types.RE_GET_USER_WALLET_REQ, reGetUserWalletSagas);
  yield takeEvery(types.GET_MAIN_TRASACTION_REQ, getMainTransactionSagas);
  yield takeEvery(types.WITHDRAW_WALLET_REQ, withdrawWalletSagas);
  yield takeEvery(types.GET_DEMO_WALLET_REQ, getDemoWalletSagas);
  yield takeEvery(types.GET_CONVERT_TRASACTION_REQ, getConvertTransactionSagas);
}
