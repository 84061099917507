import {
  Button,
  Grid,
  LinearProgress,
  makeStyles,
  Popover,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import moment from "moment";
import { useTranslation } from "react-i18next";
import convertNumberToCurrency from "../../../common/convertNumberToCurrency";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { useState } from "react";
import { DateRangePicker } from "materialui-daterange-picker";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: "95%",
      margin: " 20px auto",
      [theme.breakpoints.down("xs")]: {
        width: "90%",
      },
    },
    rootGridItemTop: {
      backgroundColor: theme.color.background,
      minHeight: 150,
      padding: 10,
      borderRadius: 2,
    },
    rootGridItemLeft: {
      backgroundColor: theme.color.background,
      minHeight: 350,
      padding: 10,
      borderRadius: 2,
    },
    rootGridItemRight: {
      display: "flex",
    },
    noteRoot: {
      marginTop: 20,
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        justifyContent: "center",
      },
    },
    coverNote: {
      display: "flex",
      alignItems: "center",
    },
    gridCenter: {
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
    },
    itemImage: { display: "block", marginBottom: -15 },
    typographyBuy: { color: "#55ad82" },
    rootSell: {
      marginTop: 25,
    },
    typographySell: { color: "#C8533C" },
    rootProgress: {
      width: "90%",
      marginLeft: 20,
    },
    lineProgress: {
      backgroundColor: theme.color.trendingDown,
    },
    lineProgressPrimary: {
      backgroundColor: theme.color.trendingUp,
    },
    coverBox: {
      height: 200,
      backgroundColor: theme.color.background,
      borderRadius: 5,
      padding: 20,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    coverBoxBottom: {
      height: 130,
      width: "100%",
      backgroundColor: theme.color.background,
      borderRadius: 5,
      padding: 20,

      textAlign: "center",
    },
    circleNote: {
      width: 15,
      height: 15,
      borderRadius: "50%",
      marginRight: 5,
    },
    coverTitleTable: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        display: "block",
      },
    },
    buttonDateRange: {
      textTransform: "none",
      color: theme.text.primary,
      padding: 5,
      marginRight: 10,
      borderRadius: 0,
      borderBottom: `1px solid ${theme.color.disabled}`,
    },
    buttonSearch: {
      textTransform: "none",
      backgroundColor: theme.color.primary,
      color: theme.text.primary,
    },
    textDateRange: {
      color: theme.text.secondary,
      marginRight: 10,
    },
    popoverPaper: {
      [theme.breakpoints.down("xs")]: {
        maxWidth: "92vw",
        overflowX: "auto",
      },
    },
  }),
  { index: 1 }
);

export default function PaperDashboard(props) {
  const classes = useStyles();
  const {
    renderTradingRate,
    renderTableHistory,
    data,
    params,
    handleUpdateDateRange,
    dataRange,
    handleSearch,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenDateRage = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <div className={classes.rootGridItemLeft}>
            <Typography variant="h6">{t("Trading stats")}</Typography>
            <Grid container>
              <Grid
                item
                md={6}
                classes={{ root: classes.gridCenter }}
                style={{ overflow: "hidden" }}
                xs={12}
              >
                {renderTradingRate()}
              </Grid>
              <Grid item md={6} xs={12} classes={{ root: classes.noteRoot }}>
                <div>
                  <div className={classes.coverNote}>
                    <div
                      className={classes.circleNote}
                      style={{ border: `2px solid ${theme.color.trendingUp}` }}
                    ></div>
                    <Typography variant="body1">
                      {t("Total win round")}
                    </Typography>
                  </div>
                  <div className={classes.coverNote}>
                    <div
                      className={classes.circleNote}
                      style={{
                        border: `2px solid ${theme.color.trendingDown}`,
                      }}
                    ></div>
                    <Typography variant="body1">
                      {t("Total loss round")}
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
                style={{ borderRight: !matches ? "2px solid #bdbdbd" : "none" }}
                classes={{ root: classes.gridCenter }}
              >
                <div>
                  <Typography variant="body1">{t("Win rate")}</Typography>
                  <Typography variant="body1">
                    {Math.round((data?.total_win / data?.total_trade) * 100)}%
                  </Typography>
                </div>
              </Grid>
              <Grid item md={6} xs={12} classes={{ root: classes.gridCenter }}>
                <div>
                  <Typography variant="body1">
                    {t("Total transactions")}
                  </Typography>{" "}
                  <Typography variant="body1">{data?.total_trade}</Typography>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <div className={classes.coverBox}>
                <div>
                  <Typography variant="body1">{t("Net profit")}</Typography>
                  <Typography variant="h6">
                    {convertNumberToCurrency(data?.profit)}
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item sm={6} xs={12}>
              <div className={classes.coverBox}>
                <div>
                  <Typography variant="body1">{t("Total revenue")}</Typography>
                  <Typography variant="h6">
                    {" "}
                    {convertNumberToCurrency(data?.win_amount)}
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item sm={12} xs={12}>
              <div className={classes.coverBoxBottom}>
                <Typography variant="h6">{t("Transaction Summary")}</Typography>
                <div className={classes.rootProgress}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "30%",
                      }}
                    >
                      <Typography
                        variant="body2"
                        classes={{ root: classes.typographyBuy }}
                      >
                        {t("Buy").toUpperCase()}{" "}
                        {Math.round(
                          (data?.total_up /
                            (data?.total_up + data?.total_down)) *
                            100
                        )}
                        %
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        flexDirection: "row-reverse",
                        width: "30%",
                      }}
                    >
                      <Typography
                        variant="body2"
                        classes={{ root: classes.typographySell }}
                      >
                        {100 -
                          Math.round(
                            (data?.total_up /
                              (data?.total_up + data?.total_down)) *
                              100
                          )}
                        % {t("Sell").toUpperCase()}
                      </Typography>
                    </div>
                  </div>
                  <LinearProgress
                    variant="determinate"
                    value={Math.round(
                      (data?.total_up / (data?.total_up + data?.total_down)) *
                        100
                    )}
                    classes={{
                      root: classes.lineProgress,
                      barColorPrimary: classes.lineProgressPrimary,
                    }}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12} xs={12} style={{ marginTop: 20 }}>
          <div className={classes.coverTitleTable}>
            <Typography variant="h5">{t("Transaction history")}</Typography>
            <div>
              <Button
                classes={{ root: classes.buttonDateRange }}
                variant="text"
                onClick={handleOpenDateRage}
              >
                <Typography
                  variant="body2"
                  classes={{ root: classes.textDateRange }}
                >
                  {params?.from_date
                    ? moment(params?.from_date).format("YYYY/MM/DD")
                    : ""}{" "}
                  -{" "}
                  {params?.to_date
                    ? moment(params?.to_date).format("YYYY/MM/DD")
                    : ""}
                </Typography>
                <DateRangeIcon />
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                classes={{ paper: classes.popoverPaper }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <DateRangePicker
                  open={true}
                  initialDateRange={dataRange}
                  toggle={handleClose}
                  onChange={(range) => handleUpdateDateRange(range)}
                />
              </Popover>
              <Button
                classes={{ root: classes.buttonSearch }}
                variant="contained"
                onClick={handleSearch}
              >
                {t("Search")}
              </Button>
            </div>
          </div>
          <div>{renderTableHistory()}</div>
        </Grid>
      </Grid>
    </div>
  );
}
