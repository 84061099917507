import { createStyles, List, makeStyles, Tab, Tabs } from "@material-ui/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        backgroundColor: theme.color.background,
        height: "100%",
        marginLeft: 5,
      },
      tabs: {},
      tab: {
        padding: 0,
        margin: "0 5px",
        textTransform: "none",
        fontWeight: "bold",
        // color: "#848E9C",
        // fontFamily: [
        //   "-apple-system",
        //   "BlinkMacSystemFont",
        //   '"Segoe UI"',
        //   "Roboto",
        //   '"Helvetica Neue"',
        //   "Arial",
        //   "sans-serif",
        //   '"Apple Color Emoji"',
        //   '"Segoe UI Emoji"',
        //   '"Segoe UI Symbol"',
        // ].join(","),
        minWidth: 70,
        "&:hover": {
          color: "#fff",
          opacity: 1,
        },
        "&:focus": {
          color: "#fff",
        },
      },
      listRoot: {
        [theme.breakpoints.down("sm")]: {
          maxHeight: "75vh",
          overflow: "auto",
        },
      },
    }),
  { index: 1 }
);

export default function NavBarRight(props) {
  const classes = useStyles();
  const { children, value, handleChange } = props;
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Tabs
        className={classes.tabs}
        value={value}
        onChange={(e, newValue) => {
          handleChange(newValue);
        }}
        indicatorColor="primary"
        textColor="inherit"
        centered
      >
        <Tab
          label={t("Open order")}
          className={classes.tab}
          disableRipple={true}
        />
        <Tab
          label={t("Closing order")}
          className={classes.tab}
          disableRipple={true}
        />
      </Tabs>
      <List classes={{ root: classes.listRoot }}>{children}</List>
    </div>
  );
}
