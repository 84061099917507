import { call, put, takeEvery } from "redux-saga/effects";
import {
  changePassword,
  getUserProfile,
  getUserVerify,
} from "../../apis/user.api";
import { TOAST_NOTI } from "../../const/common";
import * as types from "../../const/redux/user";
import { callToastNotiSuccess } from "../actions/toastNotiAction";
import {
  getUserProfileSuccess,
  getUserVerifySuccess,
} from "../actions/userAction";

// function* registerUserSagas({ payload }) {
//   try {
//     const { dataReq } = payload;
//     yield call(registration, dataReq);
//   } catch (err) {
//     let { data } = err.response;
//     yield put(
//       callToastNotiSuccess({
//         noti: data.message,
//         status: TOAST_NOTI.ERROR,
//       })
//     );
//   }
//   if (responseRegiter.status === 200) {
//     let access_token = responseRegiter.data.access;
//     let dataReq = {
//       otp: "",
//     };
//     let responseVerifyRegistration = yield call(
//       verifyRegistration,
//       dataReq,
//       access_token
//     );
//   }
//   yield put(registerUserSuccess(dataReq));
// }

function* getUserProfileSagas({ payload }) {
  try {
    let responseRegiter = yield call(getUserProfile);
    if (responseRegiter.status === 200) {
      let { data } = responseRegiter;
      yield put(getUserProfileSuccess(data));
    }
  } catch (err) {
    let { data } = err.response;
    yield put(
      callToastNotiSuccess({
        noti: data.message,
        status: TOAST_NOTI.ERROR,
        code: data.code,
      })
    );
  }
}

function* getUserVerifySagas({ payload }) {
  try {
    let responseRegiter = yield call(getUserVerify);
    if (responseRegiter.status === 200) {
      let { data } = responseRegiter;
      yield put(getUserVerifySuccess(data));
    }
  } catch (err) {
    let { data } = err.response;
    yield put(
      callToastNotiSuccess({
        noti: data.message,
        status: TOAST_NOTI.ERROR,
        code: data.code,
      })
    );
  }
}

function* changePasswordSagas({ payload }) {
  try {
    const { dataReq } = payload;
    let responseChangePsw = yield call(changePassword, dataReq);
    if (responseChangePsw.status === 200) {
      yield put(
        callToastNotiSuccess({
          noti: "Change password success",
          status: TOAST_NOTI.SUCCESS,
        })
      );
    }
  } catch (err) {
    let { data } = err.response;
    yield put(
      callToastNotiSuccess({
        noti: data.message,
        status: TOAST_NOTI.ERROR,
        code: data.code,
      })
    );
  }
}

export function* watchUserAsync() {
  //   yield takeEvery(types.REGISTER_USER_REQ, registerUserSagas);
  yield takeEvery(types.GET_USER_PROFILE_REQ, getUserProfileSagas);
  yield takeEvery(types.GET_USER_VERIFY_REQ, getUserVerifySagas);
  yield takeEvery(types.CHANGE_PASSWORD_REQ, changePasswordSagas);
}
