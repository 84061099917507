export const REGISTER_USER_REQ = "REGISTER_USER_REQ";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";

export const VERIFY_REGISTRATION_USER_REQ = "VERIFY_REGISTRATION_USER_REQ";
export const VERIFY_REGISTRATION_USER_SUCCESS =
  "VERIFY_REGISTRATION_USER_SUCCESS";

export const GET_USER_PROFILE_REQ = "GET_USER_PROFILE_REQ";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";

export const GET_USER_VERIFY_REQ = "GET_USER_VERIFY_REQ";
export const GET_USER_VERIFY_SUCCESS = "GET_USER_VERIFY_SUCCESS";

export const CHANGE_PASSWORD_REQ = "CHANGE_PASSWORD_REQ";

export const DESTROY_USER_PROFILE = "DESTROY_USER_PROFILE";
