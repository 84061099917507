/* eslint-disable react-hooks/exhaustive-deps */
import { createStyles, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getReferralSummary,
  getReferralTransaction,
} from "../../apis/referral.api";
import PaperAgencyInfo from "../../components/Body/BodyAgency/PaperAgencyInfo";
import PaperAgencySummary from "../../components/Body/BodyAgency/PaperAgencySummary";
import PaperGeneralDataAgency from "../../components/Body/BodyAgency/PaperGeneralDataAgency";
import PaperAgencyTradeSummary from "../../components/Body/BodyAgencyTrade/PaperAgencyTradeSummary";
import HeaderAgentInfo from "../../components/Header/HeaderAgentInfo";
import TableCommissions from "../../components/Table/TableCommissions";
import TableListUnderReferral from "../../components/Table/TableListUnderReferral";
import TreeAgency from "../../components/Tree/TreeAgency";
import {
  getReferralTreelReq,
  getUnderReferrallReq,
} from "../../redux/actions/referralAction";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        width: "100%",
      },
      body: {
        margin: "0px 20px",
        [theme.breakpoints.down("xs")]: {
          margin: "0px 10px 0 0",
        },
      },
    }),
  { index: 1 }
);

export default function AgencyInfo() {
  const classes = useStyles();
  const [valueTab, setValueTab] = useState(0);
  const [paginationList, setPaginationList] = useState({
    page: 1,
    page_size: 10,
  });
  const [paginationCommission, setPaginationCommission] = useState({
    page: 1,
    page_size: 10,
  });
  const [commissions, setCommissions] = useState({});
  const dispatch = useDispatch();
  const referralInfo = useSelector((state) => state.referralReducer.info);
  const underReferral = useSelector((state) => state.referralReducer.underRef);

  const callReferralTransaction = (params) => {
    getReferralTransaction(params)
      .then((res) => {
        if (res.status === 200) {
          setCommissions(res.data);
        }
      })
      .catch();
  };

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const handleChangePageList = (page) => {
    let params = { ...paginationList, page };
    dispatch(getUnderReferrallReq(referralInfo.id, { ...params }));
    setPaginationList(params);
  };

  const handleChangePageCommissions = (page) => {
    let params = { ...paginationCommission, page };
    callReferralTransaction(params);
    setPaginationCommission(params);
  };

  const renderTabPanel = () => {
    switch (valueTab) {
      case 0:
        return (
          <TableCommissions
            commissions={commissions}
            handleChangePage={handleChangePageCommissions}
            pagination={paginationCommission}
          />
        );
      case 1:
        return (
          <TableListUnderReferral
            underReferral={underReferral}
            handleChangePage={handleChangePageList}
            pagination={paginationList}
          />
        );

      default:
        return null;
    }
  };

  useEffect(() => {
    let params = {
      page: 1,
      page_size: 10,
    };
    switch (valueTab) {
      case 0: {
        callReferralTransaction(params);
        setPaginationCommission(params);
        break;
      }
      case 1: {
        dispatch(getUnderReferrallReq(referralInfo.id, { ...params }));
        setPaginationList(params);
        break;
      }

      default:
        break;
    }
  }, [valueTab]);

  return (
    <div className={classes.root}>
      <HeaderAgentInfo referralInfo={referralInfo} />
      {/* <PaperAgencyTradeSummary
        referralBo={referralInfo}
        renderTableVolume={() => {}}
        dataTradingVolume={null}
        handleOpenUpgradeLevel={() => {}}
      /> */}
      <div className={classes.body}>
        <PaperAgencySummary referralInfo={referralInfo} />
        {/* <PaperGeneralDataAgency summaryRef={summaryRef} /> */}
        <PaperAgencyInfo valueTab={valueTab} handleChangeTab={handleChangeTab}>
          {renderTabPanel()}
        </PaperAgencyInfo>
      </div>
    </div>
  );
}
