import { call, put, takeEvery } from "redux-saga/effects";
import {
  getCandleStickData,
  getDemoListOrder,
  getListOrder,
} from "../../apis/trade.api";
import { TOAST_NOTI } from "../../const/common";
import * as types from "../../const/redux/transaction";
import { callToastNotiSuccess } from "../actions/toastNotiAction";
import {
  getCandleStickSuccess,
  getDemoListOrderSuccess,
  getListOrderSuccess,
  setLoadingOrder,
} from "../actions/transactionAction";

function* getCandleStickSagas({ payload }) {
  try {
    let responseCandleStick = yield call(getCandleStickData);
    if (responseCandleStick.status === 200) {
      let { data } = responseCandleStick;
      yield put(getCandleStickSuccess(data));
    }
  } catch (err) {
    let { data } = err.response;
    yield put(
      callToastNotiSuccess({
        noti: data.message,
        status: TOAST_NOTI.ERROR,
        code: data.code,
      })
    );
  }
}

function* getListOrderSagas({ payload }) {
  try {
    const { params } = payload;
    yield put(setLoadingOrder(true));
    let responseListOrder = yield call(getListOrder, params);
    if (responseListOrder.status === 200) {
      let { data } = responseListOrder;
      yield put(getListOrderSuccess(data));
    }
    yield put(setLoadingOrder(false));
  } catch (err) {
    let { data } = err.response;
    yield put(
      callToastNotiSuccess({
        noti: data.message,
        status: TOAST_NOTI.ERROR,
        code: data.code,
      })
    );
  }
}

function* getDemoListOrderSagas({ payload }) {
  try {
    const { params } = payload;
    yield put(setLoadingOrder(true));
    let responseDemoListOrder = yield call(getDemoListOrder, params);
    if (responseDemoListOrder.status === 200) {
      let { data } = responseDemoListOrder;
      yield put(getDemoListOrderSuccess(data));
    }
    yield put(setLoadingOrder(false));
  } catch (err) {
    let { data } = err.response;
    yield put(
      callToastNotiSuccess({
        noti: data.message,
        status: TOAST_NOTI.ERROR,
        code: data.code,
      })
    );
  }
}

export function* watchTransactionAsync() {
  yield takeEvery(types.GET_CANDLE_STICK_REQ, getCandleStickSagas);
  yield takeEvery(types.GET_LIST_ORDER_REQ, getListOrderSagas);
  yield takeEvery(types.GET_DEMO_LIST_ORDER_REQ, getDemoListOrderSagas);
}
