import React from "react";

export default function BotV2Icon() {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.175 12.3233C21.7196 12.3233 21.35 12.6846 21.35 13.1297V14.2049H20.8V10.9793C20.8 9.34825 19.4437 8.02254 17.775 8.02254H12.825V5.71196C13.6302 5.39263 14.2 4.62172 14.2 3.72179C14.2 2.53585 13.2133 1.57141 12 1.57141C10.7867 1.57141 9.8 2.53585 9.8 3.72179C9.8 4.62172 10.3698 5.39263 11.175 5.71196V8.02254H6.225C4.5563 8.02254 3.2 9.34825 3.2 10.9793V14.2049H2.65V13.1297C2.65 12.6846 2.2804 12.3233 1.825 12.3233C1.3696 12.3233 1 12.6846 1 13.1297V16.8928C1 17.338 1.3696 17.6992 1.825 17.6992C2.2804 17.6992 2.65 17.338 2.65 16.8928V15.8177H3.2V19.0432C3.2 20.6743 4.5563 22 6.225 22H17.775C19.4437 22 20.8 20.6743 20.8 19.0432V15.8177H21.35V16.8928C21.35 17.338 21.7196 17.6992 22.175 17.6992C22.6304 17.6992 23 17.338 23 16.8928V13.1297C23 12.6846 22.6304 12.3233 22.175 12.3233ZM6.5 13.3985C6.5 12.2125 7.4867 11.2481 8.7 11.2481C9.9133 11.2481 10.9 12.2125 10.9 13.3985C10.9 14.5844 9.9133 15.5489 8.7 15.5489C7.4867 15.5489 6.5 14.5844 6.5 13.3985ZM14.475 18.7744H9.525C9.0696 18.7744 8.7 18.4132 8.7 17.968C8.7 17.5229 9.0696 17.1616 9.525 17.1616H14.475C14.9304 17.1616 15.3 17.5229 15.3 17.968C15.3 18.4132 14.9304 18.7744 14.475 18.7744ZM15.3 15.5489C14.0867 15.5489 13.1 14.5844 13.1 13.3985C13.1 12.2125 14.0867 11.2481 15.3 11.2481C16.5133 11.2481 17.5 12.2125 17.5 13.3985C17.5 14.5844 16.5133 15.5489 15.3 15.5489Z"
        fill="#848E9C"
      />
    </svg>
  );
}
