import {
  Button,
  Card,
  CardContent,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import Eyeclose from "../../assets/img/Eyeclose.png";
import Eyeopen from "../../assets/img/Eyeopen.png";

const useStyles = makeStyles(
  (theme) => ({
    rootCard: {
      width: 366,
      minHeight: 200,
      margin: "auto",
      [theme.breakpoints.down("xs")]: {
        width: "100vw",
        height: "91vh",
        margin: 0,
        borderRadius: 0,
        backgroundColor: "#191D23",
      },
      //   backgroundColor: "#191D23",
    },
    rootCardHeader: {
      borderBottom: `1px solid ${theme.color.border}`,
      padding: "5px 15px",
    },
    typographyTitle: {
      textAlign: "center",
      margin: "5px 0 20px 0",
      [theme.breakpoints.down("xs")]: {
        color: theme.text.primary,
      },
    },
    iconButton: {
      color: theme.text.primary,
    },
    typographySecondary: {
      color: theme.text.secondary,
    },
    textField: {
      marginBottom: 15,
      //   backgroundColor: "#2A2D35",
    },
    cssOutlinedInput: {
      "&$cssFocused $notchedOutline": {
        borderColor: `${theme.text.primary} !important`,
      },
    },
    notchedOutline: {
      borderWidth: "1px",
      borderColor: `${theme.text.secondary} !important`,
      borderRadius: "2px !important",
    },
    multilineColor: {
      //   color: theme.text.primary,
      fontSize: 13,
    },
    input: {
      "&::placeholder": {
        opacity: 0.2,
      },
    },
    buttonSubmit: {
      width: "100%",
      color: theme.text.primary,
      backgroundColor: theme.color.primary,
      textTransform: "none",
      borderRadius: 2,
      marginTop: 10,
    },
    coverButtonFooter: {
      marginTop: 30,
    },
    buttonRegister: {
      color: theme.text.link,
      textTransform: "none",
      float: "left",
    },
    buttonForgotPassword: {
      color: theme.text.link,
      textTransform: "none",
      float: "right",
    },
    iconEye: {
      cursor: "pointer",
    },
  }),
  { index: 1 }
);

const initialValues = {
  password: "",
  password_confirm: "",
};

function FormResetPassword(props) {
  const classes = useStyles(props);
  const { handleResetPassword } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    password: Yup.string().required(t("Not be empty")),
    password_confirm: Yup.string()
      .required(t("Not be empty"))
      .oneOf([Yup.ref("password"), null], t("Password did not match")),
  });

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleShowRetypePassword = () => {
    setShowPasswordConfirm(!showPasswordConfirm);
  };

  const onSubmit = (values) => {
    handleResetPassword(values);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => onSubmit(values),
  });

  return (
    <div>
      <Card classes={{ root: classes.rootCard }}>
        {/* <CardHeader
          classes={{ root: classes.rootCardHeader }}
          title={
            <Typography
              variant="body1"
              classes={{ root: classes.typographyTitle }}
            >
              {t("Register")}
            </Typography>
          }
          action={
            <IconButton
              aria-label="close"
              classes={{ root: classes.iconButton }}
            >
              <CloseIcon />
            </IconButton>
          }
        /> */}
        <CardContent>
          <div>
            <Typography
              variant="h6"
              classes={{ root: classes.typographyTitle }}
            >
              {t("Update password")}
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              <div>
                <Typography
                  variant="caption"
                  className={classes.typographySecondary}
                >
                  {t("Password")}
                </Typography>
                <TextField
                  className={classes.textField}
                  fullWidth
                  variant="outlined"
                  id="password"
                  type={!showPassword && "password"}
                  name="password"
                  InputLabelProps={{ shrink: false }}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={handleShowPassword}>
                        {!showPassword ? (
                          <img src={`${Eyeopen}`} alt="Eyeopen"></img>
                        ) : (
                          <img src={`${Eyeclose}`} alt="Eyeclose"></img>
                        )}
                      </IconButton>
                    ),
                    classes: {
                      root: classes.cssOutlinedInput,
                      notchedOutline: classes.notchedOutline,
                      input: classes.input,
                    },
                    className: classes.multilineColor,
                  }}
                  size="small"
                  onChange={formik.handleChange}
                  //   onBlur={formik.handleBlur}
                  value={formik.values.password}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
              </div>
              <div>
                <Typography
                  variant="caption"
                  className={classes.typographySecondary}
                >
                  {t("Retype password")}
                </Typography>
                <TextField
                  className={classes.textField}
                  fullWidth
                  variant="outlined"
                  id="password_confirm"
                  type={!showPasswordConfirm && "password"}
                  name="password_confirm"
                  InputLabelProps={{ shrink: false }}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={handleShowRetypePassword}>
                        {!showPasswordConfirm ? (
                          <img src={`${Eyeopen}`} alt="Eyeopen"></img>
                        ) : (
                          <img src={`${Eyeclose}`} alt="Eyeclose"></img>
                        )}
                      </IconButton>
                    ),
                    classes: {
                      root: classes.cssOutlinedInput,
                      notchedOutline: classes.notchedOutline,
                      input: classes.input,
                    },
                    className: classes.multilineColor,
                  }}
                  size="small"
                  onChange={formik.handleChange}
                  //   onBlur={formik.handleBlur}
                  value={formik.values.password_confirm}
                  error={
                    formik.touched.password_confirm &&
                    Boolean(formik.errors.password_confirm)
                  }
                  helperText={
                    formik.touched.password_confirm &&
                    formik.errors.password_confirm
                  }
                />
              </div>
              <div>
                <Button
                  className={classes.buttonSubmit}
                  type="submit"
                  variant="outlined"
                >
                  {t("Update Password")}
                </Button>
              </div>
            </form>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

FormResetPassword.propTypes = {};

export default FormResetPassword;
