export const GET_CANDLE_STICK_REQ = "GET_CANDLE_STICK_REQ";
export const GET_CANDLE_STICK_SUCCESS = "GET_CANDLE_STICK_SUCCESS";

export const GET_LIST_ORDER_REQ = "GET_LIST_ORDER_REQ";
export const GET_LIST_ORDER_SUCCESS = "GET_LIST_ORDER_SUCCESS";

export const GET_DEMO_LIST_ORDER_REQ = "GET_DEMO_LIST_ORDER_REQ";
export const GET_DEMO_LIST_ORDER_SUCCESS = "GET_DEMO_LIST_ORDER_SUCCESS";

export const SET_LOADING_ORDER = "SET_LOADING_ORDER";
