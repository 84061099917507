import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { forgotPassword } from "../../apis/user.api";
import FormForgotPassword from "../../components/Form/FormForgotPassword";
import TopBarHandleAccount from "../../components/TopBar/TopBarHandleAccount";

export default function Login() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const handleSendMail = (values) => {
    let dataReq = { ...values, action: "request" };
    forgotPassword(dataReq)
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("jwt_token", res.data.access);
          enqueueSnackbar(t(res.data.msg), { variant: "success" });
          localStorage.setItem("email_forgot", values?.email);
        }
      })
      .catch((err) => {
        if (err.response) {
          const { data } = err.response;
          enqueueSnackbar(data.message, { variant: "error" });
        }
      });
  };

  return (
    <div>
      <TopBarHandleAccount />
      <FormForgotPassword handleSendMail={handleSendMail} />
    </div>
  );
}
