import React from "react";

export default function LotteryIcon() {
  return (
    <svg
      width={20}
      height={22}
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.4707 21.9999H3.21094V21.5943C3.21094 21.056 3.65611 20.6113 4.21258 20.6113H15.477C16.0256 20.6113 16.4787 21.0482 16.4787 21.5943V21.9999H16.4707Z"
        fill="#848E9C"
      />
      <path
        d="M5.53993 18.4658L4.75293 20.3537H14.8965L14.1811 18.5984C12.8853 19.1991 11.4623 19.5112 9.99166 19.5112C8.42561 19.5112 6.8993 19.1523 5.53993 18.4658Z"
        fill="#848E9C"
      />
      <path
        d="M7.36093 3.3252C5.59614 4.01949 4.17317 5.37687 3.41797 7.0931L10.0002 9.87028L7.36093 3.3252Z"
        fill="#848E9C"
      />
      <path
        d="M16.6696 7.27948C15.9621 5.54765 14.5789 4.15126 12.83 3.41016L10 9.86943L16.6696 7.27948Z"
        fill="#848E9C"
      />
      <path
        d="M12.6392 16.422C14.404 15.7277 15.827 14.3704 16.5822 12.6541L10 9.87695L12.6392 16.422Z"
        fill="#848E9C"
      />
      <path
        d="M3.32324 12.4669C4.03075 14.1987 5.41397 15.5951 7.16286 16.3362L9.99289 9.87695L3.32324 12.4669Z"
        fill="#848E9C"
      />
      <path
        d="M10.2391 3.97177C10.5889 3.55832 11.1931 2.6534 11.2487 1.49884C11.3362 -0.0925753 10.0006 0.00103726 10.0006 0.00103726C10.0006 0.00103726 8.66513 -0.0925753 8.74462 1.49884C8.80027 2.63779 9.38854 3.53491 9.73831 3.95617L9.76216 3.97957C9.88141 4.1356 10.1119 4.1356 10.2312 3.97957L10.2391 3.97177Z"
        fill="#848E9C"
      />
      <path
        d="M11.6618 0.766602C11.7175 0.992832 11.7413 1.24247 11.7334 1.5233C11.7175 1.89775 11.638 2.2488 11.5426 2.56865C15.0006 3.27074 17.6001 6.27414 17.6001 9.87043C17.6001 13.9816 14.1898 17.3282 10.0004 17.3282C5.811 17.3282 2.40066 13.9816 2.40066 9.87043C2.40066 6.27414 5.0081 3.27074 8.45819 2.56865C8.3628 2.2488 8.2833 1.89775 8.2674 1.5233C8.2515 1.24247 8.27535 0.992832 8.33895 0.766602C3.92696 1.53891 0.572266 5.32242 0.572266 9.87043C0.572266 14.9801 4.79346 19.1225 10.0004 19.1225C15.2073 19.1225 19.4285 14.9801 19.4285 9.87043C19.4285 5.32242 16.0738 1.53891 11.6618 0.766602Z"
        fill="#848E9C"
      />
    </svg>
  );
}
