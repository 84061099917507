import { useState } from "react";
import { useDispatch } from "react-redux";
import { getUserProfileReq } from "../../redux/actions/userAction";
import AccountVerification from "./AccountVerification";
import ProfileInfo from "./ProfileInfo";

export default function Profile() {
  const [showAccountVerification, setShowAccountVerification] = useState(false);
  const dispatch = useDispatch();

  const handleShowAccountVerification = () => {
    setShowAccountVerification(true);
  };

  const handleCloseAccountVerification = () => {
    setShowAccountVerification(false);
    dispatch(getUserProfileReq());
  };

  return (
    <>
      {!showAccountVerification && (
        <ProfileInfo
          handleShowAccountVerification={handleShowAccountVerification}
        />
      )}
      {/* <AccountVerification
        showAccountVerification={showAccountVerification}
        handleCloseAccountVerification={handleCloseAccountVerification}
      /> */}
    </>
  );
}
