import {
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Pagination, PaginationItem } from "@material-ui/lab";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import convertNumberToMoney from "../../common/convertNumberToMoney";
import { MAIN_TRANSACTION } from "../../const/common";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      tableCellHeader: {
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        border: "none",
        color: theme.text.secondary,
      },
      tableCellBody: {
        borderBottom: "1px solid #424242",
        color: "#fff",
      },
      rootPagination: {
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: 40,
        marginTop: 20,
      },
      pagination: {
        color: theme.text.primary,
      },
      rootPaginationItem: {
        border: `1px solid ${theme.color.light}`,
        color: theme.text.primary,
        borderRadius: 2,
      },
      ellipsisPagination: { border: "none" },
      pageSelected: {
        backgroundColor: "hsla(0, 0%, 100%, 0.1) !important",
        border: `1px solid ${theme.color.border}`,
      },
      coverLoading: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
          height: 80,
        },
      },
    }),
  { index: 1 }
);

function createData(time, from, to, status) {
  return { time, from, to, status };
}

function TableConvertTransaction(props) {
  const classes = useStyles(props);
  const { walletTransaction, pagination, handleChangePage, loading } = props;
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);
  const theme = useTheme();

  const columns = [
    { id: "time", label: t("Time"), align: "left", minWidth: 100 },
    { id: "from", label: t("From"), align: "left", minWidth: 100 },
    { id: "to", label: t("To"), align: "left", minWidth: 100 },
    { id: "status", label: t("Status"), align: "left", minWidth: 100 },
  ];

  const colorStatus = (status) => {
    switch (status) {
      case MAIN_TRANSACTION.STATUS.SUCCEED:
        return theme.color.trendingUp;
      case MAIN_TRANSACTION.STATUS.FAILED:
        return theme.color.trendingDown;
      case MAIN_TRANSACTION.STATUS.PENDING:
        return "#fffde7";
      default:
        return "#fff";
    }
  };

  useEffect(() => {
    if (Object.keys(walletTransaction).length > 0) {
      let rows;
      rows = walletTransaction.results.map((transaction, index) => {
        let time = moment(transaction.created).format("MM-DD HH:mm:ss");
        let from = (
          <Typography
            variant="body2"
            style={{ color: theme.color.trendingDown }}
          >
            {convertNumberToMoney(parseFloat(transaction.amount))}{" "}
            {transaction.conversion_pair?.base?.symbol}
          </Typography>
        );
        let to = (
          <Typography variant="body2" style={{ color: theme.color.trendingUp }}>
            {convertNumberToMoney(parseFloat(transaction.converted_amount))}{" "}
            {transaction.conversion_pair?.pair?.symbol}
          </Typography>
        );
        let status = (
          <Typography
            variant="body2"
            style={{ color: colorStatus(transaction.status) }}
          >
            {transaction.status}
          </Typography>
        );
        return createData(time, from, to, status);
      });

      setRows(rows);
    }
  }, [walletTransaction]);

  return (
    <>
      <TableContainer className={classes.root}>
        <Table aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className={classes.tableCellHeader}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow hover role="checkbox" tabIndex={-1}>
                <TableCell colSpan={6}>
                  {" "}
                  {/* TODO add loading */}
                  <div className={classes.coverLoading}>
                    <CircularProgress style={{ color: "#fff" }} size={25} />
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              rows.map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          className={classes.tableCellBody}
                        >
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.rootPagination}>
        <Pagination
          count={Math.ceil(walletTransaction.count / pagination.page_size || 0)}
          page={pagination.page}
          variant="outlined"
          shape="rounded"
          onChange={handleChangePage}
          classes={{ root: classes.pagination }}
          renderItem={(item) => (
            <PaginationItem
              classes={{
                root: classes.rootPaginationItem,
                ellipsis: classes.ellipsisPagination,
                selected: classes.pageSelected,
              }}
              {...item}
              variant="outlined"
            />
          )}
        />
      </div>
    </>
  );
}

export default TableConvertTransaction;
