export const USDTCurrency = "usdt";
export const ALACurrency = "ala";

export const TOAST_NOTI = {
  ERROR: "error",
  SUCCESS: "success",
};

export const MAIN_TRANSACTION = {
  STATUS: {
    PENDING: "pending",
    SUCCEED: "succeed",
    FAILED: "failed",
    CONFIRM: "confirm",
    REFUNDED: "refunded",
  },
};

export const USER_PACKAGE = {
  STATUS: {
    PENDING: "pending",
    ACTIVE: "active",
    PAUSE: "pause",
    FINISHED: "finished",
  },
};

export const COMMISSION_TYPE = {
  com_buy_ref: "Commission buy ref",
  com_invest_bot: "Commission invest bot",
  com_ref_revenue_bot: "Commission level bot revenue",
  com_ref_bot_profit: "Commission bot profit",
  com_bo_volume: "Commission bo",
  com_buy_bot: "Commission buy bot",
};

export const ACTION_BOT_V2_TYPE = {
  initial: "Initial",
  upgrade_usdt: "Deposit",
  profit: "Profit",
  withdraw: "Withdraw",
};

export const optionsLanguage = [
  { title: "English", id: "en" },
  { title: "Tiếng Việt", id: "vi" },
  { title: "日本", id: "ja" },
];

export const KYC_STATUS = {
  PENDING: "pending",
  ACCEPTED: "accepted",
  REJECTED: "rejected",
  INACTIVE: null,
};

export const ACCOUNT = {
  REAL: "real",
  DEMO: "demo",
};
