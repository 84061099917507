import React from "react";

export default function DashboardIcon() {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0001 2.83302C9.90189 2.83061 7.84683 3.42871 6.07762 4.55666C4.30841 5.68462 2.89894 7.29532 2.01568 9.19854C1.13241 11.1018 0.812254 13.218 1.09301 15.2973C1.37376 17.3766 2.24369 19.3322 3.60008 20.933L3.80008 21.1663H20.2001L20.4001 20.933C21.7565 19.3322 22.6264 17.3766 22.9071 15.2973C23.1879 13.218 22.8677 11.1018 21.9845 9.19854C21.1012 7.29532 19.6917 5.68462 17.9225 4.55666C16.1533 3.42871 14.0983 2.83061 12.0001 2.83302ZM17.7334 9.15301L13.7867 13.0263C13.9885 13.4528 14.0329 13.9368 13.9123 14.3929C13.7917 14.849 13.5137 15.2477 13.1276 15.5187C12.7414 15.7897 12.2719 15.9154 11.8019 15.8737C11.332 15.832 10.892 15.6255 10.5596 15.2907C10.2272 14.956 10.0238 14.5145 9.98544 14.0443C9.94704 13.5741 10.0761 13.1055 10.3498 12.7212C10.6235 12.337 11.0242 12.0618 11.4811 11.9445C11.938 11.8271 12.4217 11.875 12.8467 12.0797L16.7867 8.20635L17.7334 9.15301ZM2.40008 13.2663H4.66674V14.5997H2.37341C2.37341 14.3397 2.34008 14.0863 2.34008 13.8197C2.34008 13.553 2.35341 13.453 2.36674 13.2663H2.40008ZM5.65341 6.59968L7.29341 8.23968L6.31341 9.15968L4.66674 7.52635C4.96229 7.18917 5.28083 6.87286 5.62008 6.57968L5.65341 6.59968ZM12.6667 6.52635H11.3334V4.19301H12.0001C12.2467 4.19301 12.4667 4.19301 12.6667 4.22635V6.52635ZM21.6601 13.8263C21.6601 14.0863 21.6601 14.353 21.6267 14.6063H19.2801V13.273H21.6334C21.6467 13.453 21.6601 13.6397 21.6601 13.8263Z"
        fill="#848E9C"
      />
    </svg>
  );
}
