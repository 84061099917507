import {
  Card,
  CardContent,
  Grid,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import moment from "moment";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import LogoXS from "../../assets/img/LogoXS.png";
import UsdtXS from "../../assets/img/UsdtXS.png";
import convertNumberToCurrency from "../../common/convertNumberToCurrency";
import {
  ALACurrency,
  MAIN_TRANSACTION,
  USDTCurrency,
} from "../../const/common";
import ContentCopyLink from "../../assets/img/ContentCopyLink.png";
import { useDispatch } from "react-redux";
import { callToastNotiSuccess } from "../../redux/actions/toastNotiAction";
import { TOAST_NOTI } from "../../const/common";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: "100%",
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    rootCardContent: {
      borderBottom: `1px solid ${theme.color.border}`,
      padding: "10px 0 !important",
    },
    rootGridLeft: {},
    rootGridRight: {
      textAlign: "right",
    },
    typographyPrimary: {
      color: theme.text.primary,
      padding: "5px 0",
    },
    typographySecondary: {
      color: theme.text.secondary,
      padding: "5px 0",
    },
    typographyValue: {
      color: theme.color.trendingUp,
      padding: "5px 0",
    },
    textAddress: {
      color: theme.text.link,
      textDecoration: "none",
      cursor: "pointer",
      padding: "5px 0",
    },
    rootLogo: {
      display: "flex",
      alignItems: "center",
      padding: "5px 0",
    },
    image: {
      marginRight: 10,
      width: "15%",
    },
    rootAction: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      color: theme.text.primary,
      padding: "5px 0",
    },
    icon: { marginLeft: 10 },
  }),
  { index: 1 }
);

function CardDepositHistory(props) {
  const classes = useStyles(props);
  const { handleExchange, transaction } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();

  const handleCopeLink = (link) => {
    navigator.clipboard.writeText(link);
    dispatch(
      callToastNotiSuccess({ noti: t("Copied"), status: TOAST_NOTI.SUCCESS })
    );
  };

  const colorStatus = (status) => {
    switch (status) {
      case MAIN_TRANSACTION.STATUS.SUCCEED:
        return theme.color.trendingUp;
      case MAIN_TRANSACTION.STATUS.FAILED:
        return theme.color.trendingDown;
      case MAIN_TRANSACTION.STATUS.PENDING:
        return "#fffde7";
      default:
        return "#fff";
    }
  };

  return (
    <Card classes={{ root: classes.root }}>
      <CardContent classes={{ root: classes.rootCardContent }}>
        <Grid container>
          <Grid item xs={6} classes={{ root: classes.rootGridLeft }}>
            <div className={classes.rootLogo}>
              <img
                src={`${transaction?.wallet?.c?.icon_url}`}
                alt={`${transaction?.wallet?.c?.name}`}
                className={classes.image}
              ></img>
              <Typography
                variant="body2"
                classes={{ root: classes.typographyPrimary }}
              >
                {transaction?.wallet?.c?.symbol}({transaction?.wallet?.c?.name})
              </Typography>
            </div>
            <Typography
              variant="body2"
              classes={{ root: classes.typographySecondary }}
            >
              {t("Time")}
            </Typography>
            <Typography
              variant="body2"
              classes={{ root: classes.typographySecondary }}
            >
              {t("Value")}
            </Typography>
            <Typography
              variant="body2"
              classes={{ root: classes.typographySecondary }}
            >
              {t("Status")}
            </Typography>
            <Typography
              variant="body2"
              classes={{ root: classes.typographySecondary }}
            >
              {t("Address/user_id")}
            </Typography>
            <Typography
              variant="body2"
              classes={{ root: classes.typographySecondary }}
            >
              {t("Transaction hash")}
            </Typography>
          </Grid>
          <Grid item xs={6} classes={{ root: classes.rootGridRight }}>
            <div
              className={classes.rootAction}
              onClick={() => {
                handleExchange(t("Deposit"), transaction.wallet);
              }}
            >
              <Typography
                variant="body2"
                classes={{ root: classes.typographyPrimary }}
              >
                {t("Deposit")}
              </Typography>
              <ArrowForwardIosIcon classes={{ root: classes.icon }} />
            </div>
            <Typography
              variant="body2"
              classes={{ root: classes.typographyPrimary }}
            >
              {moment(transaction?.created).format("YYYY-MM-DD HH:mm:ss")}
            </Typography>
            <Typography
              variant="body2"
              classes={{ root: classes.typographyValue }}
            >
              +{convertNumberToCurrency(parseFloat(transaction?.amount))}
            </Typography>
            <Typography
              variant="body2"
              classes={{ root: classes.typographyPrimary }}
              style={{ color: colorStatus(transaction.status) }}
            >
              {transaction?.status}
            </Typography>{" "}
            <Typography
              variant="body2"
              classes={{ root: classes.textAddress }}
              onClick={() => {
                handleCopeLink(`${transaction.transaction_detail?.address}`);
              }}
              title={transaction.transaction_detail?.address}
            >
              {transaction.transaction_detail?.address &&
              transaction.transaction_detail?.address?.length > 18
                ? `${transaction.transaction_detail?.address?.substring(
                    0,
                    15
                  )}...`
                : transaction.transaction_detail?.address}
              {transaction.transaction_detail?.address && (
                <img
                  src={`${ContentCopyLink}`}
                  alt="ContentCopyLink"
                  //   style={{ marginLeft: 5 }}
                ></img>
              )}
            </Typography>
            {transaction.transaction_detail?.is_internal ? (
              <Typography
                variant="body2"
                classes={{ root: classes.textAddress }}
                onClick={() => {
                  handleCopeLink(
                    !(transaction.tx_type === "deposit")
                      ? transaction.transaction_detail?.internal_detail.to_user
                      : transaction.transaction_detail?.internal_detail
                          .from_user
                  );
                }}
              >
                <span style={{ color: "#ffffff" }}>
                  {transaction.tx_type === "deposit" ? t("From") : t("To")}:{" "}
                </span>
                {!(transaction.tx_type === "deposit")
                  ? transaction.transaction_detail?.internal_detail.to_user
                  : transaction.transaction_detail?.internal_detail
                      .from_user}{" "}
                <img
                  src={`${ContentCopyLink}`}
                  alt="ContentCopyLink"
                  //   style={{ marginLeft: 5 }}
                ></img>
              </Typography>
            ) : (
              <Typography
                variant="body2"
                classes={{ root: classes.textAddress }}
                onClick={() => {
                  handleCopeLink(`${transaction.transaction_detail?.tx_hash}`);
                }}
                title={transaction.transaction_detail?.tx_hash}
              >
                {transaction.transaction_detail?.tx_hash &&
                transaction.transaction_detail?.tx_hash?.length > 18
                  ? `${transaction.transaction_detail?.tx_hash?.substring(
                      0,
                      15
                    )}...`
                  : transaction.transaction_detail?.tx_hash}
                {transaction.transaction_detail?.tx_hash && (
                  <img
                    src={`${ContentCopyLink}`}
                    alt="ContentCopyLink"
                    //   style={{ marginLeft: 5 }}
                  ></img>
                )}
              </Typography>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

CardDepositHistory.propTypes = {
  transaction: PropTypes.object,
  handleExchange: PropTypes.func,
};

export default CardDepositHistory;
