export const PATH = {
  TRADING: "/Trading",
  TRADING_VIEW: "/Trading/View",
  TRANSACTION: "/Trading/Transaction",
  WALLET: "/Trading/Wallet",
  PROFILE: "/Trading/Profile",
  TRADINGBOT: "/Trading/Trading-view",
  TRADINGBOTV2: "/Trading/Trading-bot-v2",
  BOTV2: "/Trading/Bot-v2",
  AGENCY: "/Trading/Agency",
  AGENCY_TRADE: "/Trading/Agency-trade",
  DASHBOARD: "/Trading/Dashboard",
  NOTIFICATION: "/Trading/Notification",
  LOTTERY: "/Trading/lottery",
  HOME: "/home",
  LOGIN: "/login",
  REGISTER: "/register",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password/:otp",
  VERIFYREGISTRATION: "/verify-registration/:otp",
  BO_CHART: "/bo-chart",
  PRIVACY_POLICY: "/privacy-policy",
  TERM_OF_USE: "/term-of-use",
};
