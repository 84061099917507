import {
  Card,
  CardContent,
  CardHeader,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Drawer,
  IconButton,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";
import FormDepositBot from "../Body/BodyBotV2/FormDepositBot";
import FormConvertCurrency from "../Body/BodyWallet/FormConvertCurrency";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      dialogHeader: {
        color: "#fff",
        padding: "10px 20px",
      },
      closeButton: {
        position: "absolute",
        right: 2,
        top: 0,
        color: theme.text.primary,
      },
      dialogContent: {
        padding: "20px",
      },
      dividers: { borderTopColor: "#272C3A" },
      typographySecondary: {
        color: theme.text.secondary,
      },
      typographyPrimary: {
        color: theme.text.primary,
      },
      paperDialog: {
        // width: 456,
        // minHeight: 573,
        // maxHeight: 700,
        backgroundColor: "#191D23",
        boxShadow: "none ",
      },
      imageBot: {
        width: 146,
        height: 146,
      },
      rootDrawer: {
        backgroundColor: theme.color.background,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
      },
      rootCard: { backgroundColor: theme.color.background },
      paperCard: { boxShadow: "none" },
      cardHeader: {
        borderBottom: `1px solid ${theme.color.border}`,
        marginBottom: 5,
      },
      iconButton: { color: theme.text.primary },
    }),
  { index: 1 }
);

export default function DialogConvertCurrency(props) {
  const classes = useStyles(props);
  const { open, handleClose, children } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <>
      {matches ? (
        <Drawer
          anchor={"bottom"}
          open={open}
          onClose={handleClose}
          classes={{ paper: classes.rootDrawer }}
        >
          <Card classes={{ root: classes.rootCard, paper: classes.paperCard }}>
            <CardHeader
              title={
                <Typography
                  classes={{ root: classes.typographyPrimary }}
                  variant="h6"
                >
                  {t("Convert currency")}
                </Typography>
              }
              action={
                <IconButton
                  classes={{ root: classes.iconButton }}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>
              }
              classes={{ root: classes.cardHeader }}
            />
            <CardContent>{children} </CardContent>
          </Card>
        </Drawer>
      ) : (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={false}
          classes={{
            paper: classes.paperDialog,
          }}
        >
          <DialogTitle id="alert-dialog-title" className={classes.dialogHeader}>
            <Typography variant="body1">{t("Convert currency")}</Typography>
            <IconButton className={classes.closeButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            className={classes.dialogContent}
            dividers={true}
            classes={{ dividers: classes.dividers }}
          >
            {children}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
