import {
  Avatar,
  createStyles,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Logo2Large from "../../assets/img/Logo2Large.png";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        display: "flex",
        width: "100%",
        marginLeft: theme.marginLayout,
        backgroundColor: theme.color.background,
        height: 72,
        [theme.breakpoints.down("xs")]: {
          backgroundColor: "transparent",
        },
      },
      item: { padding: "0 16px" },
      itemText: { paddingLeft: 10 },
      typographyPrimary: { color: theme.text.primary },
      typographySecondary: { color: theme.text.secondary },
      avatar: {
        backgroundColor: "transparent",
        border: `1px solid ${theme.color.light}`,
        width: theme.spacing(5),
        height: theme.spacing(5),
        fontSize: 16,
      },
    }),
  { index: 1 }
);

export default function HeaderProfileInfo(props) {
  const classes = useStyles(props);
  const { userProfile } = props;
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <List className={classes.list}>
        <ListItem className={classes.item}>
          <Avatar classes={{ root: classes.avatar }}>
            {userProfile?.username?.substring(0, 2)?.toUpperCase() || ""}
          </Avatar>
          {/* <img src={`${Logo2Large}`} alt="Logo2Large"></img> */}
          <ListItemText
            className={classes.itemText}
            primary={
              <Typography className={classes.typographyPrimary} variant="body2">
                {userProfile?.username || ""}
              </Typography>
            }
            secondary={
              <>
                {/* <Typography
                  className={classes.typographySecondary}
                  variant="body2"
                >
                  {t("User ID")}: <span>{userProfile?.id || ""}</span>
                </Typography> */}
                <Typography
                  className={classes.typographySecondary}
                  variant="body2"
                >
                  {t("Email")}: <span>{userProfile?.email || ""}</span>
                </Typography>
              </>
            }
          />
        </ListItem>
      </List>
    </div>
  );
}
