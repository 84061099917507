import {
  createStyles,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import TradingBotSystem from "../../../assets/img/TradingBotSystem.png";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        width: "100vw",
        minHeight: 600,
        padding: "5%",
        background: "#fff",
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        // direction: "row-reverse",
        [theme.breakpoints.down("xs")]: {
          direction: "column-reverse",
        },
        // alignItems: "center",
      },
      image: {
        float: "right",
        width: "100%",
      },
      title: {
        fontSize: 32,
        marginBottom: 30,
      },
      content: {},
      rootRight: {
        maxWidth: 400,
        marginTop: 50,
        marginLeft: 50,
        [theme.breakpoints.down("xs")]: {
          marginLeft: 10,
          marginTop: 10,
        },
      },
    }),
  { index: 1 }
);

export default function PaperThirdFloor(props) {
  const classes = useStyles();
  const { children } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <div className={classes.root}>
      {matches && (
        <div className={classes.rootRight}>
          <Typography variant="h5" className={classes.title}>
            {t("Trading bot system")}
          </Typography>
          <Typography variant="body2" className={classes.content}>
            - {t("Make trading more easy and effective by using the AI system")}
          </Typography>
          <Typography variant="body2" className={classes.content}>
            - {t("10 – 30% Monthly profit guarantee")}
          </Typography>
          <Typography variant="body2" className={classes.content}>
            - {t("Profit will be paid at 12PM every day")}
          </Typography>
        </div>
      )}
      <div>
        <img
          src={`${TradingBotSystem}`}
          alt="TradingBotSystem"
          className={classes.image}
        ></img>
      </div>
      {!matches && (
        <div className={classes.rootRight}>
          <Typography variant="h5" className={classes.title}>
            {t("Trading bot system")}
          </Typography>
          <Typography variant="body2" className={classes.content}>
            - {t("Make trading more easy and effective by using the AI system")}
          </Typography>
          <Typography variant="body2" className={classes.content}>
            - {t("10 – 30% Monthly profit guarantee")}
          </Typography>
          <Typography variant="body2" className={classes.content}>
            - {t("Profit will be paid at 12PM every day")}
          </Typography>
        </div>
      )}
    </div>
  );
}
