import { cloneDeep } from "lodash";
import * as types from "../../const/redux/bot";

let initialState = {
  info: {},
  buySuccess: false,
  botBought: {},
  userPackage: {},
};

const botReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_BOT_PACKAGE_SUCCESS: {
      let newState = cloneDeep(state);
      newState.info = cloneDeep(action.payload.data);
      return newState;
    }
    case types.BUY_BOT_PACKAGE_SUCCESS: {
      let newState = cloneDeep(state);
      newState.buySuccess = true;
      newState.botBought = cloneDeep(action.payload.data);
      return newState;
    }
    case types.GET_USER_PACKAGE_SUCCESS: {
      let newState = cloneDeep(state);
      newState.userPackage = cloneDeep(action.payload.data);
      return newState;
    }
    case types.DESTROY_TRADE_BOT: {
      let newState = cloneDeep(initialState);
      return newState;
    }
    default:
      return state;
  }
};

export default botReducer;
