import { cloneDeep } from "lodash";
import * as types from "../../const/redux/referral";

let initialState = {
  info: {},
  bo: {},
  tree: [],
  underRef: {},
};

const referralReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_REFERRAL_DETAIL_SUCCESS:
    case types.BUY_REFERRAL_CODE_SUCCESS: {
      let newState = cloneDeep(state);
      newState.info = cloneDeep(action.payload.data);
      return newState;
    }
    case types.GET_REFERRAL_BO_DETAIL_SUCCESS: {
      let newState = cloneDeep(state);
      newState.bo = cloneDeep(action.payload.data);
      return newState;
    }
    case types.GET_REFERRAL_TREE_SUCCESS: {
      let newState = cloneDeep(state);
      newState.tree = cloneDeep(action.payload.data);
      return newState;
    }
    case types.GET_UNDER_REFERRAL_SUCCESS: {
      let newState = cloneDeep(state);
      newState.underRef = cloneDeep(action.payload.data);
      return newState;
    }
    case types.DESTROY_REFERRAL: {
      let newState = cloneDeep(initialState);
      return newState;
    }
    case types.DESTROY_TREE: {
      let newState = cloneDeep(state);
      newState.tree = [];
      return newState;
    }
    default:
      return state;
  }
};

export default referralReducer;
