import { createStyles, makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import Upload from "../../assets/img/Upload.png";
import IdFront from "../../assets/img/IdFront.png";
import IdBack from "../../assets/img/IdBack.png";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: { marginTop: 20 },
      rootUpload: {
        display: "flex",
        // width: "100%",
        justifyContent: "center",
        flexWrap: "wrap",
      },
      coverTitle: { margin: "0 40px" },
      typographyPrimary: { padding: "10px 0", color: theme.text.primary },
      typographySecondary: { color: theme.text.secondary },
      dropzone: {
        width: 223,
        height: 112,
        border: "1px dashed #989898",
        textAlign: "center",
        paddingTop: 20,
        borderRadius: 5,
        margin: "5px 5px",
      },
      coverImage: {
        borderRadius: 5,
        margin: "5px 5px",
      },
      image: {
        width: 223,
        height: 112,
      },
    }),
  { index: 1 }
);

function UploadIdentityFiles(props) {
  const classes = useStyles(props);
  const { files, handleSetFiles, type, src } = props;
  const { t } = useTranslation();

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      handleSetFiles(
        type,
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const renderImage = (src, files, typeFiles) => {
    if (src) {
      return <img src={src} alt="typeFiles" className={classes.image} />;
    } else if (files.length) {
      return files.map((file, index) => (
        <img
          src={file.preview}
          alt=""
          key={file.name}
          className={classes.image}
        />
      ));
    }
    return (
      <img
        src={typeFiles === "front" ? `${IdFront}` : `${IdBack}`}
        alt="typeFiles"
        className={classes.image}
      />
    );
  };

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <div className={classes.root}>
      {type === "front" ? (
        <div className={classes.coverTitle}>
          <Typography variant="body2" className={classes.typographyPrimary}>
            {t("ID Card / Passport front view")}
          </Typography>
        </div>
      ) : (
        <div className={classes.coverTitle}>
          <Typography variant="body2" className={classes.typographyPrimary}>
            {t("Passport / ID Card back")}
          </Typography>
        </div>
      )}
      <div className={classes.rootUpload}>
        <div
          {...getRootProps({ className: "dropzone" })}
          className={classes.dropzone}
        >
          <input {...getInputProps()} />
          <img src={`${Upload}`} alt="Upload"></img>
          <div>
            <Typography
              variant="caption"
              className={classes.typographySecondary}
            >
              Click hoặc kéo thả ảnh vào đây.
            </Typography>
          </div>
        </div>
        <div className={classes.coverImage}>
          {renderImage(src, files, type)}
        </div>
      </div>
    </div>
  );
}

UploadIdentityFiles.propTypes = {
  files: PropTypes.array,
  handleSetFiles: PropTypes.func,
  type: PropTypes.string,
};

export default UploadIdentityFiles;
