import { makeStyles, Popover, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import convertNumberToCurrency from "../../common/convertNumberToCurrency";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

export default function PopoverNodeTree(props) {
  const classes = useStyles();
  const { handlePopoverClose, anchorEl, open, node, bot } = props;
  const { t } = useTranslation();

  return (
    <>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography variant="body2">
          {t("Name")}: {node?.id}
        </Typography>
        {bot ? (
          <>
            <Typography variant="body2">
              {t("Invest")}: {convertNumberToCurrency(node?.total_investment)}
            </Typography>
            <Typography variant="body2">
              {t("Network investment")}:{" "}
              {convertNumberToCurrency(node?.total_ref_investment)}
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="body2">
              {t("Level")}: {node?.bo_level}
            </Typography>
            <Typography variant="body2">
              {t("Transaction volume")}:{" "}
              {convertNumberToCurrency(node?.total_volume)}
            </Typography>
            <Typography variant="body2">
              {t("Commission")}:{" "}
              {convertNumberToCurrency(node?.total_commission)}
            </Typography>
          </>
        )}
        {/* <Typography variant="body2">
          {t("Email")}: {node?.email}
        </Typography>
        {node?.parent && (
          <Typography variant="body2">
            {t("Parent")}: {node?.parent}
          </Typography>
        )} */}
      </Popover>
    </>
  );
}
