import {
  Button,
  createStyles,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        display: "flex",
        padding: 20,
        width: "100%",
        marginLeft: theme.marginLayout,
        backgroundColor: theme.color.background,
        [theme.breakpoints.down("xs")]: {
          margin: 0,
          backgroundColor: "transparent",
        },
      },
      button: {
        color: theme.text.primary,
        textTransform: "none",
        borderColor: theme.color.border,
        marginLeft: 15,
        fontSize: 16,
        padding: "3px 10px",
      },
    }),
  { index: 1 }
);

export default function HeaderNotification(props) {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <Typography variant="h5">{t("Notification")}</Typography>
      <Button
        variant="outlined"
        classes={{ root: classes.button }}
        onClick={() => {
          history.goBack();
        }}
      >
        {t("Back")}
      </Button>
    </div>
  );
}
