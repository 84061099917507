import {
  createStyles,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import LogoLargeWhite from "../../assets/img/LogoLargeWhite.png";
import { PATH } from "../../const/paths";
import AlltradePDF from "../../assets/doc/Alltrade.pdf";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        // display: "flex",
        // alignItems: "center",
        // justifyContent: "center",
        padding: "10px 100px 15px 150px",
        backgroundColor: "#111114",
        position: "relative",
        flexWrap: "wrap",
        width: "100vw",
        [theme.breakpoints.down("xs")]: {
          padding: "10px 20px",
        },
      },
      typographyName: {
        // fontSize: 24,
        color: theme.text.primary,
        [theme.breakpoints.down("xs")]: {
          fontSize: 14,
        },
        // margin: "0 20px",
      },
      typographyContent: {
        // fontSize: 24,
        color: theme.text.secondary,
        paddingTop: "10px",
        [theme.breakpoints.down("xs")]: {
          fontSize: 12,
          paddingTop: "5px",
        },
        // margin: "0 80px",
      },
      textLink: {
        color: theme.text.secondary,
        paddingTop: 5,
        cursor: "pointer",
        "&:hover": {
          color: "#757575",
          textDecoration: "underline",
        },
        [theme.breakpoints.down("xs")]: {
          fontSize: 14,
        },
      },
      textLinkDownload: {
        color: theme.text.secondary,
        paddingTop: 5,
        cursor: "pointer",
        textDecoration: "none",
        "&:hover": {
          color: "#757575",
          textDecoration: "underline",
        },
        [theme.breakpoints.down("xs")]: {
          fontSize: 14,
        },
      },
      coverLogo: {
        flexGrow: 3,
        display: "flex",
        alignItems: "center",
        padding: "10px 0",
        [theme.breakpoints.down("xs")]: {
          padding: "10px 0",
        },
      },
      imageLogo: {
        width: 50,
        height: "auto",
        marginRight: 20,
        [theme.breakpoints.down("xs")]: {
          width: 40,
        },
      },
      coverContent: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
      },
    }),
  { index: 1 }
);

function FooterHome(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const history = useHistory();

  return (
    <div className={classes.root}>
      <div className={classes.coverLogo}>
        <img
          src={`${LogoLargeWhite}`}
          alt="LogoLargeWhite"
          className={classes.imageLogo}
        />
        <Typography className={classes.typographyName} variant="h6">
          {t("ALLTRADE")}
        </Typography>
      </div>
      <div className={classes.coverContent}>
        <div>
          <div style={{ display: "flex" }}>
            <Typography
              className={classes.textLink}
              variant="body2"
              style={{ marginRight: 15 }}
              onClick={() => {
                history.push(PATH.TERM_OF_USE);
              }}
            >
              {t("Term of use")}
            </Typography>
            <Typography
              className={classes.textLink}
              variant="body2"
              style={{ marginRight: 15 }}
              onClick={() => {
                history.push(PATH.PRIVACY_POLICY);
              }}
            >
              {t("Privacy policy")}
            </Typography>
            {/* <a
              className={classes.textLinkDownload}
              href={AlltradePDF}
              style={{ marginRight: 15 }}
              download="Alltrade-ALA-whitepaper.pdf"
            >
              {t("White Paper")}
            </a> */}
            <a className={classes.textLinkDownload} href="/logo.png" download>
              {t("Download logo")}
            </a>
          </div>
          {!matches ? (
            <Typography className={classes.typographyContent} variant="body2">
              APA TECHNOLOGY PTE.LTD
            </Typography>
          ) : (
            <Typography className={classes.typographyContent} variant="body2">
              ©2021 APA TECHNOLOGY PTE.LTD
            </Typography>
          )}

          <Typography className={classes.typographyContent} variant="body2">
            200 JALAN SULTAN #05-02 TEXTILE CENTRE SINGAPORE (199018)
          </Typography>
        </div>
        {!matches && (
          <div>
            <Typography className={classes.typographyContent} variant="h6">
              ©2021 APA TECHNOLOGY PTE.LTD
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
}

FooterHome.propTypes = {};

export default FooterHome;
