import { createStyles, Grid, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, useHistory } from "react-router-dom";
import DialogBanner from "../components/Dialog/DialogBanner";
import DrawerSideBar from "../components/Drawer/DrawerSideBar";
import SideBar from "../components/SideBar/SideBar";
import TopBarTrading from "../components/TopBar/TopBarTrading";
import { PATH } from "../const/paths";
import { destroyTradingBot } from "../redux/actions/botAction";
import { destroyReferral } from "../redux/actions/referralAction";
import {
  destroyUserProfile,
  getUserProfileReq,
} from "../redux/actions/userAction";
import { destroyWallet, setAccount } from "../redux/actions/walletAction";
import routes from "../routes/tradingRoutes";
import Cookies from "js-cookie";
import moment from "moment";
import { ACCOUNT } from "../const/common";
import { getNotification } from "../apis/noti.api";
import { setNotification } from "../redux/actions/notificationAction";
import DialogNotiSystem from "../components/Dialog/DialogNotiSystem";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        backgroundColor: theme.body.backgroundColor,
        color: "#fff",
        minHeight: "100vh",
      },
      rootTopBar: {},
      rootSideBar: {
        [theme.breakpoints.down("xs")]: {
          display: "none",
        },
      },
      body: {},
    }),
  { index: 1 }
);

export default function TradingPage() {
  const classes = useStyles();
  const [openDrawerSideBar, setOpenDrawerSideBar] = useState(false);
  const [openBanner, setOpenBanner] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.userReducer.profile);
  const notification = useSelector((state) => state.notificationReducer);

  const callNotification = (params) => {
    getNotification(params)
      .then((res) => {
        if (res.status === 200) {
          let { data } = res;
          if (data?.results.length > 0) {
            dispatch(setNotification(true));
          } else dispatch(setNotification(false));
        }
      })
      .catch();
  };

  const handleOpenDrawerSideBar = () => {
    setOpenDrawerSideBar(true);
  };

  const handleCloseDrawerSideBar = () => {
    setOpenDrawerSideBar(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("jwt_token");
    localStorage.removeItem("noti_system");
    dispatch(destroyUserProfile());
    dispatch(destroyWallet());
    dispatch(destroyTradingBot());
    dispatch(destroyReferral());
    history.push(PATH.HOME);
  };

  const handleCloseBanner = () => {
    setOpenBanner(false);
  };

  const showBody = (routes) => {
    let result = null;
    if (routes.length > 0) {
      result = routes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        );
      });
    }
    return result;
  };

  useEffect(() => {
    let account = localStorage.getItem("account");
    if (account) {
      dispatch(setAccount(account));
    } else dispatch(setAccount(ACCOUNT.REAL));

    dispatch(getUserProfileReq());
    let current = moment().format("YYYY-MM-DD");
    let countBanner = Cookies.get("countBanner");
    let timeBanner = Cookies.get("timeBanner");
    let countInDay = Cookies.get("countInDay");
    if (countBanner && timeBanner && countInDay) {
      if (parseInt(countBanner) < 24 && timeBanner !== current) {
        setOpenBanner(true);
        Cookies.set("countBanner", parseInt(countBanner) + 1, {
          expires: 30,
        });
        Cookies.set("timeBanner", current, { expires: 30 });
        Cookies.set("countInDay", 1, { expires: 30 });
      } else if (timeBanner === current && countInDay < 3) {
        setOpenBanner(true);
        Cookies.set("countBanner", parseInt(countBanner) + 1, {
          expires: 30,
        });
        Cookies.set("countInDay", parseInt(countInDay) + 1, { expires: 30 });
      }
    } else {
      setOpenBanner(true);
      Cookies.set("countBanner", 1, { expires: 30 });
      Cookies.set("timeBanner", current, { expires: 30 });
      Cookies.set("countInDay", 1, { expires: 30 });
    }

    let pagination = { page: 1, page_size: 10 };
    let params = { ...pagination, is_read: 0 };
    callNotification(params);
  }, []);

  return (
    <div className={classes.root}>
      <Grid container spacing={1} className={classes.rootTopBar}>
        <TopBarTrading
          handleOpenDrawerSideBar={handleOpenDrawerSideBar}
          handleLogout={handleLogout}
          userProfile={userProfile}
          notification={notification}
        />
      </Grid>
      <div style={{ display: "flex", width: "100%" }}>
        <div className={classes.rootSideBar}>
          <SideBar />
        </div>
        <Grid container>{showBody(routes)}</Grid>
      </div>
      <DrawerSideBar
        openDrawer={openDrawerSideBar}
        handleCloseDrawer={handleCloseDrawerSideBar}
      >
        <SideBar handleCloseDrawer={handleCloseDrawerSideBar} />
      </DrawerSideBar>
      <DialogBanner open={false} handleClose={handleCloseBanner} />
      <DialogNotiSystem />
    </div>
  );
}
