import { makeStyles } from "@material-ui/core";
import { BOCHART } from "../../const/colorChart";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: "flex",
      borderRadius: 10,
      justifyContent: "center",
      alignItems: "flex-start",
      padding: "1px 0",
      margin: "15px 30px",
      width: 150,
      flexWrap: "wrap",
      flexDirection: "column",
      height: 110,
      backgroundColor: "#12161C",
      [theme.breakpoints.down("sm")]: {
        padding: "1px 0px",
        width: 70,
        height: 60,
        // flexDirection: "row",
        // height: 30,
        // alignItems: "center",
        // width: "100%",
        margin: "7px 14px",
      },
    },
    point: {
      width: 15,
      height: 15,
      borderRadius: "50%",
      margin: 5,
      [theme.breakpoints.down("sm")]: {
        width: 10,
        height: 10,
        margin: 2,
      },
    },
  }),
  { index: 1 }
);

function TradingPoint(props) {
  const classes = useStyles(props);
  const { handleOpenDrawerResultHistory, data } = props;

  return (
    <div className={classes.root} onClick={handleOpenDrawerResultHistory}>
      {data.map((point, index) => (
        <div
          key={`${point?.id}${index}` || index}
          className={classes.point}
          style={{
            backgroundColor:
              !point.open && !point.close
                ? BOCHART.DEFAULT
                : point.open < point.close
                ? BOCHART.BUY
                : BOCHART.SELL,
          }}
        ></div>
      ))}
    </div>
  );
}

TradingPoint.propTypes = {};

export default TradingPoint;
