import { call, put, takeEvery } from "redux-saga/effects";
import {
  buyBotPackage,
  getBotPackage,
  getUserPackage,
  upgradeBotPackage,
} from "../../apis/bot.api";
import { TOAST_NOTI } from "../../const/common";
import * as types from "../../const/redux/bot";
import {
  buyBotPackageSuccess,
  getBotPackageSuccess,
  getUserPackageSuccess,
} from "../actions/botAction";
import { callToastNotiSuccess } from "../actions/toastNotiAction";

function* getBotPackageSagas({ payload }) {
  try {
    let responseBotPackage = yield call(getBotPackage);
    if (responseBotPackage.status === 200) {
      let { data } = responseBotPackage;
      yield put(getBotPackageSuccess(data));
    }
  } catch (err) {
    let { data } = err.response;
    yield put(
      callToastNotiSuccess({
        noti: data.message,
        status: TOAST_NOTI.ERROR,
        code: data.code,
      })
    );
  }
}

function* buyBotPackageSagas({ payload }) {
  try {
    const { dataReq } = payload;
    let responseBuyBotPackage = yield call(buyBotPackage, dataReq);
    if (responseBuyBotPackage.status === 200) {
      let { data } = responseBuyBotPackage;
      yield put(buyBotPackageSuccess(data));
    }
  } catch (err) {
    let { data } = err.response;
    yield put(
      callToastNotiSuccess({
        noti: data.message,
        status: TOAST_NOTI.ERROR,
        code: data.code,
      })
    );
  }
}

function* getUserPackageSagas({ payload }) {
  try {
    const params = { version: 1 };
    let responseUserPackage = yield call(getUserPackage, params);
    if (responseUserPackage.status === 200) {
      let { data } = responseUserPackage;
      yield put(getUserPackageSuccess(data));
    }
  } catch (err) {
    let { data } = err.response;
    yield put(
      callToastNotiSuccess({
        noti: data.message,
        status: TOAST_NOTI.ERROR,
        code: data.code,
      })
    );
  }
}

function* upgradeBotPackageSagas({ payload }) {
  try {
    const { botId, dataReq } = payload;
    let responseBuyBotPackage = yield call(upgradeBotPackage, botId, dataReq);
    if (responseBuyBotPackage.status === 200) {
      let { data } = responseBuyBotPackage;
      yield put(
        callToastNotiSuccess({
          noti: "Upgrade success",
          status: TOAST_NOTI.SUCCESS,
          code: data.code,
        })
      );
    }
  } catch (err) {
    let { data } = err.response;
    yield put(
      callToastNotiSuccess({
        noti: data.message,
        status: TOAST_NOTI.ERROR,
        code: data.code,
      })
    );
  }
}

export function* watchBotAsync() {
  yield takeEvery(types.GET_BOT_PACKAGE_REQ, getBotPackageSagas);
  yield takeEvery(types.BUY_BOT_PACKAGE_REQ, buyBotPackageSagas);
  yield takeEvery(types.GET_USER_PACKAGE_REQ, getUserPackageSagas);
  yield takeEvery(types.UPGRADE_BOT_PACKAGE_REQ, upgradeBotPackageSagas);
}
