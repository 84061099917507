import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  TextField,
  Typography,
  Popover,
  List,
  ListItem,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Autocomplete } from "@material-ui/lab";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import convertNumberToMoney from "../../common/convertNumberToMoney";
import { TOAST_NOTI } from "../../const/common";
import { callToastNotiSuccess } from "../../redux/actions/toastNotiAction";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {},
      typographyLabel: {
        color: theme.text.secondary,
      },
      typographyValue: { paddingLeft: 20, color: theme.text.primary },
      buttonSubmit: {
        width: "100%",
        color: theme.text.primary,
        backgroundColor: theme.color.primary,
        textTransform: "none",
        borderRadius: 2,
        marginTop: 10,
      },
      gridItemLeft: {
        paddingBottom: 10,
        display: "flex",
      },
      rootSelect: {
        width: "100% !important",
        margin: "10px 0",
      },
      clearIndicator: {
        color: theme.text.primary,
        display: "none",
      },
      popupIndicator: { color: theme.text.primary },
      inputRoot: {
        color: theme.text.primary,
        backgroundColor: theme.color.border,
      },
      typographyLabel: {
        color: theme.text.secondary,
      },
      buttonSelect: {
        backgroundColor: "#2A2D35",
        width: "100%",
        textTransform: "none",
        // minWidth: 250,
        borderRadius: 3,
        color: theme.text.primary,
        padding: "8px 8px",
        margin: "10px 0 15px 0",
        "&:hover": {
          backgroundColor: "rgba(0,0,0,.05)",
        },
      },
      labelButton: {
        display: "flex",
        justifyContent: "space-between",
      },
      typographySecondary: {
        color: theme.text.secondary,
      },
      typographyPrimary: {
        color: theme.text.primary,
      },
      gridItem: {
        padding: "5px 0",
      },
      iconArrow: {
        float: "right",
      },
      coverCurrency: {
        display: "flex",
        alignItems: "center",
      },
      typographyBtnPrimary: {
        margin: "0 5px",
        color: theme.text.primary,
      },
      typographyBtnSecondary: {
        margin: "0 5px",
        color: theme.text.secondary,
      },
      paperPopover: { backgroundColor: "#2A2D35" },
    }),
  { index: 1 }
);

export default function FormUpgradeLevel(props) {
  const classes = useStyles(props);
  const { walletsInfo, handleUpgrade, loading, dataUpgradeLevel } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  let availableBalance =
    parseFloat(
      walletsInfo?.results?.filter((wallet) => wallet.c?.name === "Alpha")[0]
        ?.balance
    ) || 0;

  const initialValues = {
    info: null,
  };

  const validationSchema = Yup.object().shape({});

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let { info } = values;
      if (info.week > availableBalance) {
        dispatch(
          callToastNotiSuccess({
            noti: t("The balance is not enough"),
            status: TOAST_NOTI.ERROR,
          })
        );
      } else handleUpgrade(info);
    },
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (dataUpgradeLevel.length > 0) {
      formik.setFieldValue("info", dataUpgradeLevel[1]);
    }
  }, [dataUpgradeLevel]);

  return (
    <div className={classes.root}>
      <form onSubmit={formik.handleSubmit}>
        {/* <div>
          <Typography variant="caption" className={classes.typographyLabel}>
            {t("Amount option")} ({dataConvert.base?.symbol})
          </Typography>
        </div> */}
        <Grid container>
          <Grid item xs={12} className={classes.gridItemLeft}>
            <Typography variant="body1" className={classes.typographyLabel}>
              {t("Available balances")}:
            </Typography>
            <Typography variant="body1" className={classes.typographyValue}>
              {convertNumberToMoney(availableBalance)} ALA
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" className={classes.typographyLabel}>
              {t("Select level")}:
            </Typography>
            <Button
              className={classes.buttonSelect}
              classes={{ label: classes.labelButton }}
              aria-describedby={id}
              variant="contained"
              color="primary"
              onClick={handleClick}
            >
              <div className={classes.coverCurrency}>
                <Typography
                  variant="body1"
                  classes={{ root: classes.typographyBtnPrimary }}
                >
                  {formik.values.info &&
                    `Level ${formik.values.info?.level} - `}
                  <span style={{ color: "#2F86FA" }}>
                    {convertNumberToMoney(formik.values.info?.week || 0)} ALA
                  </span>{" "}
                  {t("a week")}
                </Typography>
              </div>
              {open ? (
                <ArrowDropDownIcon classes={{ root: classes.iconArrow }} />
              ) : (
                <ArrowDropUpIcon classes={{ root: classes.iconArrow }} />
              )}
            </Button>
            <Popover
              classes={{ paper: classes.paperPopover }}
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
            >
              <List style={{ minWidth: anchorEl?.clientWidth || 0 }}>
                {dataUpgradeLevel.map((data) => {
                  return (
                    <ListItem
                      button
                      key={data.level}
                      onClick={() => {
                        handleClose();
                        formik.setFieldValue("info", data);
                      }}
                    >
                      <div className={classes.coverCurrency}>
                        <Typography
                          variant="body1"
                          classes={{ root: classes.typographyBtnPrimary }}
                        >
                          {`Level ${data.level} - `}{" "}
                          <span style={{ color: "#2F86FA" }}>
                            {convertNumberToMoney(data.week || 0)} ALA
                          </span>{" "}
                          {t("a week")}
                        </Typography>
                      </div>
                    </ListItem>
                  );
                })}
              </List>
            </Popover>
            {/* <Autocomplete
              id="combo-box-demo"
              classes={{
                root: classes.rootSelect,
                clearIndicator: classes.clearIndicator,
                popupIndicator: classes.popupIndicator,
                inputRoot: classes.inputRoot,
              }}
              value={formik.values.info}
              options={dataUpgradeLevel}
              getOptionLabel={(option) =>
                `Level ${option.level} - ${convertNumberToMoney(
                  option.week
                )} ALA a week`
              }
              onChange={(_, value) => {
                formik.setFieldValue("info", value);
              }}
              renderInput={(params) => (
                <TextField {...params} variant="filled" />
              )}
            /> */}
          </Grid>
        </Grid>
        <div>
          <Button
            className={classes.buttonSubmit}
            type="submit"
            disabled={loading}
            variant="outlined"
          >
            {loading ? (
              <div className={classes.coverLoading}>
                <CircularProgress style={{ color: "#fff" }} size={25} />
              </div>
            ) : (
              t("Upgrade")
            )}
          </Button>
        </div>
      </form>
    </div>
  );
}
