import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import moment from "moment";
import convertNumberToCurrency from "../../common/convertNumberToCurrency";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: { margin: "20px 0" },
      table: {
        border: `1px solid ${theme.color.border}`,
        borderBottom: "none",
      },
      tableCellHeader: {
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        border: "none",
        color: theme.text.secondary,
      },
      tableCellBody: {
        borderBottom: "1px solid #424242",
        color: "#fff",
      },
      rootPagination: {
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: 40,
      },
      pagination: {
        color: theme.text.primary,
      },
      rootPaginationItem: {
        border: `1px solid ${theme.text.primary}`,
        color: theme.text.primary,
        borderRadius: 2,
      },
      ellipsisPagination: { border: "none" },
      pageSelected: {
        backgroundColor: "hsla(0, 0%, 100%, 0.1) !important",
        border: `1px solid ${theme.color.border}`,
      },
    }),
  { index: 1 }
);

function createData(user, level, code, inviter) {
  return { user, level, code, inviter };
}

function TableListUnderReferral(props) {
  const classes = useStyles(props);
  const { underReferral, pagination, handleChangePage } = props;
  const [rows, setRows] = useState([]);
  const { t } = useTranslation();

  const columns = [
    // { id: "id", label: t("User id"), minWidth: 50 },
    // { id: "email", label: t("Email"), minWidth: 150 },
    { id: "user", label: t("User"), minWidth: 150 },
    // {
    //   id: "date",
    //   label: t("Registration Date"),
    //   minWidth: 170,
    // },
    {
      id: "level",
      label: t("Level"),
      minWidth: 100,
    },
    {
      id: "code",
      label: t("Code"),
      minWidth: 50,
    },

    {
      id: "inviter",
      label: t("Inviter"),
      minWidth: 50,
    },
  ];

  useEffect(() => {
    if (Object.keys(underReferral).length > 0) {
      let rows = underReferral.results?.map((data) => {
        let user = data.user?.username;
        let code = data?.ref_code;
        let level = data?.level;
        let inviter = data?.inviter?.username;
        return createData(user, level, code, inviter);
      });
      setRows(rows);
    }
  }, [underReferral]);

  return (
    <>
      <TableContainer className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className={classes.tableCellHeader}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={classes.tableCellBody}
                      >
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.rootPagination}>
        <Pagination
          count={Math.ceil(underReferral.count / pagination.page_size || 0)}
          page={pagination.page}
          onChange={(_, value) => {
            handleChangePage(value);
          }}
          variant="outlined"
          shape="rounded"
          classes={{ root: classes.pagination }}
          renderItem={(item) => (
            <PaginationItem
              classes={{
                root: classes.rootPaginationItem,
                ellipsis: classes.ellipsisPagination,
                selected: classes.pageSelected,
              }}
              {...item}
              variant="outlined"
            />
          )}
        />
      </div>
    </>
  );
}

TableListUnderReferral.propTypes = {};

export default TableListUnderReferral;
