import { createStyles, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import {
  AdvancedChart,
  CryptocurrencyMarket,
  TickerTape,
} from "react-tradingview-embed";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        width: "100%",
      },
      body: {
        padding: "0px 10px",
        [theme.breakpoints.down("xs")]: {
          padding: "0px",
        },
      },
      chart: {
        width: "100%",
        height: "calc(100vh - 130px) !important",
        [theme.breakpoints.down("md")]: {
          height: "calc(100vh - 50vh) !important",
        },
      },
      cryptoMarket: {
        width: "100%",
        height: "calc(100vh - 130px) !important",
        overflowX: "auto",
        overflowY: "hidden",
        [theme.breakpoints.down("md")]: {
          height: "calc(100vh - 50vh - 120px) !important",
        },
      },
    }),
  { index: 1 }
);

export default function TradingView() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        {" "}
        <Grid item xs={12}>
          <TickerTape
            widgetProps={{ displayMode: "dark", isTransparent: true }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} classes={{ root: classes.body }}>
        <Grid item lg={6} xs={12} classes={{ root: classes.chart }}>
          <AdvancedChart
            widgetProps={{
              theme: "dark",
              allow_symbol_change: true,
              symbol: "BTCUSDT",
              width: "100%",
              height: "100%",
            }}
            widgetPropsAny={{}}
          />
        </Grid>
        <Grid
          item
          lg={6}
          xs={12}
          classes={{ root: classes.cryptoMarket }}
          className="crypto-market"
        >
          <CryptocurrencyMarket
            widgetProps={{ colorTheme: "dark", width: "100%", height: "100%" }}
          />
        </Grid>
      </Grid>
    </div>
  );
}
