import * as types from "../../const/redux/user";

export const registerUserReq = (dataReq) => {
  return {
    type: types.REGISTER_USER_REQ,
    payload: {
      dataReq,
    },
  };
};

export const registerUserSuccess = (data) => {
  return {
    type: types.REGISTER_USER_SUCCESS,
    payload: {
      data,
    },
  };
};

export const changePasswordReq = (dataReq) => {
  return {
    type: types.CHANGE_PASSWORD_REQ,
    payload: {
      dataReq,
    },
  };
};

export const getUserProfileReq = () => {
  return {
    type: types.GET_USER_PROFILE_REQ,
  };
};

export const getUserProfileSuccess = (data) => {
  return {
    type: types.GET_USER_PROFILE_SUCCESS,
    payload: { data },
  };
};

export const getUserVerifyReq = () => {
  return {
    type: types.GET_USER_VERIFY_REQ,
  };
};

export const getUserVerifySuccess = (data) => {
  return {
    type: types.GET_USER_VERIFY_SUCCESS,
    payload: { data },
  };
};

export const destroyUserProfile = () => {
  return {
    type: types.DESTROY_USER_PROFILE,
  };
};
