import * as types from "../../const/redux/referral";

export const getReferralDetailReq = () => {
  return {
    type: types.GET_REFERRAL_DETAIL_REQ,
  };
};

export const getReferralDetailSuccess = (data) => {
  return {
    type: types.GET_REFERRAL_DETAIL_SUCCESS,
    payload: {
      data,
    },
  };
};

export const getReferralBoDetailReq = () => {
  return {
    type: types.GET_REFERRAL_BO_DETAIL_REQ,
  };
};

export const getReferralDetailBoSuccess = (data) => {
  return {
    type: types.GET_REFERRAL_BO_DETAIL_SUCCESS,
    payload: {
      data,
    },
  };
};

export const buyReferralCodeReq = (refId) => {
  return {
    type: types.BUY_REFERRAL_CODE_REQ,
    payload: {
      refId,
    },
  };
};

export const buyReferralCodeSuccess = (data) => {
  return {
    type: types.BUY_REFERRAL_CODE_SUCCESS,
    payload: {
      data,
    },
  };
};

export const getReferralTreelReq = (refId) => {
  return {
    type: types.GET_REFERRAL_TREE_REQ,
    payload: {
      refId,
    },
  };
};

export const getReferralTreelSuccess = (data) => {
  return {
    type: types.GET_REFERRAL_TREE_SUCCESS,
    payload: {
      data,
    },
  };
};

export const getBoTreelReq = (refId) => {
  return {
    type: types.GET_BO_TREE_REQ,
    payload: {
      refId,
    },
  };
};

export const getBoTreelSuccess = (data) => {
  return {
    type: types.GET_BO_TREE_SUCCESS,
    payload: {
      data,
    },
  };
};

export const getUnderReferrallReq = (refId, params) => {
  return {
    type: types.GET_UNDER_REFERRAL_REQ,
    payload: {
      refId,
      params,
    },
  };
};

export const getUnderReferrallSuccess = (data) => {
  return {
    type: types.GET_UNDER_REFERRAL_SUCCESS,
    payload: {
      data,
    },
  };
};

export const destroyReferral = () => {
  return {
    type: types.DESTROY_REFERRAL,
  };
};

export const destroyTree = (refId) => {
  return {
    type: types.DESTROY_TREE,
    payload: {
      refId,
    },
  };
};
