import * as types from "../../const/redux/botV2";

export const getBotV2Req = () => {
  return {
    type: types.GET_BOT_V2_REQ,
  };
};

export const getBotV2Success = (data) => {
  return {
    type: types.GET_BOT_V2_SUCCESS,
    payload: { data },
  };
};

export const buyBotV2Req = (dataReq) => {
  return {
    type: types.BUY_BOT_V2_REQ,
    payload: { dataReq },
  };
};

export const buyBotV2Success = (data) => {
  return {
    type: types.BUY_BOT_V2_SUCCESS,
    payload: { data },
  };
};

export const depositBotV2Req = (dataReq) => {
  return {
    type: types.DEPOSIT_BOT_V2_REQ,
    payload: { dataReq },
  };
};

export const depositBotV2Success = (data) => {
  return {
    type: types.DEPOSIT_BOT_V2_SUCCESS,
    payload: { data },
  };
};

export const destroyBotV2 = () => {
  return {
    type: types.DESTROY_BOT_V2,
  };
};
