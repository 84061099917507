import {
  Button,
  createStyles,
  FormControl,
  FormHelperText,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      coverField: { marginBottom: 15 },
      formcontrolCountry: {
        width: "100%",
      },
      selectRoot: {
        backgroundColor: "#2A2D35",
        padding: "10px 10px",
        color: "#fff",
        borderRadius: 2,
      },
      textField: {
        backgroundColor: "#2A2D35",
      },
      cssOutlinedInput: {
        "&$cssFocused $notchedOutline": {
          borderColor: `red !important`,
        },
      },
      notchedOutline: {
        borderWidth: "1px",
        borderColor: "#2A2D35 !important",
        borderRadius: "2px !important",
      },
      multilineColor: {
        color: theme.text.primary,
        fontSize: 13,
      },
      input: {
        "&::placeholder": {
          opacity: 0.2,
        },
      },
      typographySecondary: {
        color: theme.text.secondary,
      },
      typographyPrimary: {
        color: theme.text.primary,
      },
      typographyValue: { paddingLeft: 20 },
      buttonSubmit: {
        width: 150,
        float: "right",
        color: theme.text.primary,
        backgroundColor: theme.color.primary,
        textTransform: "none",
        borderRadius: 2,
        marginTop: 10,
      },
    }),
  { index: 1 }
);

export default function FormProfile(props) {
  const classes = useStyles(props);
  const { formik, countryList } = props;
  const { t } = useTranslation();

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className={classes.coverField}>
          <Typography variant="caption" className={classes.typographySecondary}>
            {t("Nation")}
          </Typography>
          <div>
            <FormControl classes={{ root: classes.formcontrolCountry }}>
              <Select
                labelId="demo-simple-select-label"
                id="country_code"
                name="country_code"
                classes={{ root: classes.selectRoot }}
                value={formik.values.country_code}
                onChange={(e) => {
                  formik.setFieldValue("country_code", e.target.value);
                }}
              >
                {countryList?.map((country) => (
                  <MenuItem value={country.code} key={country.id}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText
                error={
                  formik.touched.country_code &&
                  Boolean(formik.errors.country_code)
                }
              >
                {formik.touched.country_code && formik.errors.country_code}
              </FormHelperText>
            </FormControl>
          </div>
        </div>
        <div className={classes.coverField}>
          <Typography variant="caption" className={classes.typographySecondary}>
            {t("Last name")}
          </Typography>
          <TextField
            className={classes.textField}
            fullWidth
            variant="outlined"
            id="first_name"
            name="first_name"
            InputLabelProps={{ shrink: false }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                notchedOutline: classes.notchedOutline,
                input: classes.input,
              },
              className: classes.multilineColor,
            }}
            size="small"
            onChange={formik.handleChange}
            value={formik.values.first_name}
          />
          <FormHelperText
            error={
              formik.touched.first_name && Boolean(formik.errors.first_name)
            }
          >
            {formik.touched.first_name && formik.errors.first_name}
          </FormHelperText>
        </div>
        <div className={classes.coverField}>
          <Typography variant="caption" className={classes.typographySecondary}>
            {t("First name")}
          </Typography>
          <TextField
            className={classes.textField}
            fullWidth
            variant="outlined"
            id="last_name"
            name="last_name"
            InputLabelProps={{ shrink: false }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                notchedOutline: classes.notchedOutline,
                input: classes.input,
              },
              className: classes.multilineColor,
            }}
            size="small"
            onChange={formik.handleChange}
            value={formik.values.last_name}
          />
          <FormHelperText
            error={formik.touched.last_name && Boolean(formik.errors.last_name)}
          >
            {formik.touched.last_name && formik.errors.last_name}
          </FormHelperText>
        </div>
        <div className={classes.coverField}>
          <Typography variant="caption" className={classes.typographySecondary}>
            {t("Identtity card number")}/{t("passport")}
          </Typography>
          <TextField
            className={classes.textField}
            fullWidth
            variant="outlined"
            id="id_number"
            name="id_number"
            InputLabelProps={{ shrink: false }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                notchedOutline: classes.notchedOutline,
                input: classes.input,
              },
              className: classes.multilineColor,
            }}
            size="small"
            onChange={formik.handleChange}
            //   onBlur={formik.handleBlur}
            value={formik.values.id_number}
          />
          <FormHelperText
            error={formik.touched.id_number && Boolean(formik.errors.id_number)}
          >
            {formik.touched.id_number && formik.errors.id_number}
          </FormHelperText>
        </div>
        <div>
          <Button
            className={classes.buttonSubmit}
            type="submit"
            variant="outlined"
          >
            {t("Next")}
          </Button>
        </div>
      </form>
    </div>
  );
}

FormProfile.propTypes = {
  handleNextStep: PropTypes.func,
};
