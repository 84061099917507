import { createStyles, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import TopBarHandleAccount from "../components/TopBar/TopBarHandleAccount";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        backgroundColor: theme.body.backgroundColor,
      },
      body: {
        width: "60%",
        margin: "auto",
        padding: "20px 0",
        color: theme.text.primary,
      },
      title: {
        color: theme.text.primary,
        fontWeight: 700,
      },
      subTitle: { color: theme.text.primary, fontSize: 14, fontWeight: 700 },
      textPrimary: {
        color: theme.text.primary,
        fontSize: 14,
      },
      coverList: {
        paddingLeft: 40,
      },
    }),
  { index: 1 }
);

export default function PrivacyPolicy() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <TopBarHandleAccount />
      <div className={classes.body}>
        <div>
          <Typography variant="h5" classes={{ root: classes.title }}>
            {t("PRIVACY POLICY")}
          </Typography>
          <br />
          <Typography
            variant="body1"
            classes={{ root: classes.textPrimary }}
            style={{ fontStyle: "italic" }}
          >
            {t("Latest updated")}: November 6th, 2020
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            {t(
              "Protecting the privacy and safeguarding the personal and financial information of our clients and website visitors is one of our highest priorities."
            )}
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            {t("The aim of this Policy is to")}:
          </Typography>
          <br />
          <ul className={classes.coverList}>
            <li>
              {t(
                "Ensure that you understand what personal data we collect about you, the reasons why we collect and use it, and who we share it with"
              )}
              ;
            </li>
            <li>
              {t(
                "Explain the way we use the personal data that you share with us in order to give you a great experience when you are using our services"
              )}
              ;
            </li>
            <li>
              {t(
                "Explain your rights and choices in relation to the personal data we collect and process about you and how we will protect your privacy"
              )}
              .
            </li>
          </ul>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            {t(
              "We assure you that we take all measures necessary to ensure the confidentiality of the personal data that you provide to the companies"
            )}
            .
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            {t(
              "By opening an account on Alltrade.com, you give your consent to the collection and use of personal information by Alltrade as explained in this Privacy Policy"
            )}
            .
          </Typography>
        </div>
        <br />
        <div>
          <Typography variant="body1" classes={{ root: classes.subTitle }}>
            1. {t("Information collection")}
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            {t(
              "We collect several different types of information for various purposes to provide and improve our best Service to you"
            )}
            .
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            <b>{t("Information concerning your account.")}</b>{" "}
            {t(
              "When registering on Alltrade.com, we may ask you to provide us with some personal information, including without limitation to"
            )}
            .
          </Typography>
          <br />
          <ul className={classes.coverList}>
            <li>{t("Email address")}</li>
            <li>{t("First name and last name")}</li>
            <li>{t("Phone number")}</li>
            <li>{t("Username and password")}</li>
            <li>{t("Address, City, Country")}</li>
            <li>{t("Transactions made by you")}</li>
            <li>{t("Cookies and Usage Data")}</li>
          </ul>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            <b>{t("Device information and access logs")}</b>{" "}
            {t(
              "When you use the Services, we may obtain and maintain certain electronic identification or log data that are automatically provided by your device such as: internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. This information is necessary as part of our ongoing efforts to detect and deter unauthorized or fraudulent use of, or abuse of, the Services, as well as to provide you with customized options, and optimize the Services for your device"
            )}
            .
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            <b>{t("Tracking Cookies Data")}</b>{" "}
            {t(
              "Like with most other Internet sites, we also utilize “cookies” to track a user’s habit and preferences within our websites as well as record our performance and check the effect of online advertising"
            )}
            .
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            {t(
              "Cookies are small text files sent from Web servers that may be stored on your computer. Cookies enable us to capture how you arrived at our site, when you return, which pages on our site you visit, and to recognize that you are already logged on when we receive a page request from your browser"
            )}
            .
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            {t(
              "Cookies are readable only by us and do not contain any personal information. You can opt to set your browser to refuse all cookies, or to notify you when a cookie is being sent. However, if you set your browser to disable cookies, you may not be able to use some functions of our Website"
            )}
            .
          </Typography>
        </div>
        <br />
        <div>
          <Typography variant="body1" classes={{ root: classes.subTitle }}>
            2. {t("Use of Personal Information")}
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            {t(
              "We use your personal information for the following purposes in accordance with this Privacy Policy"
            )}
            :
          </Typography>
          <br />
          <ul className={classes.coverList}>
            <li>{t("To establish and manage your account")}</li>
            <li>
              {t(
                "To provide you with the information, products and services that you request from us"
              )}
            </li>
            <li>{t("To comply with our legal and regulatory obligations")}</li>
            <li>
              {t(
                "To perform our obligations under any contract we have with you"
              )}
            </li>
            <li>
              {t(
                "To administer our relationship with you including resolving queries or issues"
              )}
            </li>
            <li>{t("To investigate any complaint you may make")}</li>
            <li>
              {t(
                "To provide evidence in any dispute or anticipated dispute between you and us"
              )}
            </li>
            <li>{t("To enhance our customer service and products")}</li>
            <li>{t("To undertaking product development and analysis")}</li>
            <li>
              {t(
                "To protect against or deter fraudulent, illegal or harmful actions"
              )}
            </li>
          </ul>
        </div>
        <br />
        <div>
          <Typography variant="body1" classes={{ root: classes.subTitle }}>
            3. {t("Information Disclosure")}
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            {t(
              "We do not share your personal data with third parties who are not affiliated with your activity on our website"
            )}
            .
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            {t(
              "The persons to whom we may transfer your personal data process it on our behalf and strictly in accordance with our requirements, instructions, and Privacy Policy"
            )}
            .
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            {t(
              "We may disclose your personal data in the event that such a disclosure is necessary for the following purposes"
            )}
            :
          </Typography>
          <br />
          <ul className={classes.coverList}>
            <li>
              {t(
                "Compliance with the requirements of legislation, a court decision, or the request of a state authority or body"
              )}
              ;
            </li>
            <li>
              {t(
                "Ensuring compliance by users of the trading platform with the Terms of Use and conducting internal investigations"
              )}
              ;
            </li>
            <li>
              {t(
                "Preventing, detecting, suppressing, and investigating cases of fraud, as well as improving security systems on Alltrade.com"
              )}
              ;
            </li>
            <li>
              {t(
                "Protecting the rights and property of the Companies and users of the Trading Platform and society as a whole"
              )}
              ;
            </li>
            <li>{t("In other cases provided by law")}.</li>
          </ul>
        </div>
        <br />
        <div>
          <Typography variant="body1" classes={{ root: classes.subTitle }}>
            4. {t("Information Security")}
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            {t(
              "To fulfill our commitment to respecting and protecting your privacy and the confidentiality of your personal information, we have implemented appropriate technical and organizational security standards to help prevent unauthorized access or disclosure, misuse, alternation, or destruction of your information"
            )}
            .
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            {t(
              "Because no method of electronic transmission or method of data storage is perfect or impenetrable, we cannot guarantee that your personal information will be absolutely safe from intrusion during transmission or while stored in our systems"
            )}
            .
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            {t(
              "To help protect your privacy and confidentiality of your personal information, please do not share your personal information with others unless you clearly understand the purpose of their request for it and you know with whom you are dealing. Beside, you should set up a strong security password and enable Two-factor authentication (2FA)"
            )}
            .
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            {t(
              "In the event you suspect any unauthorized use of your account or any other breach of security, please contact us via email"
            )}{" "}
            <a href="mailto:support@alltrade.ventures">
              support@alltrade.ventures
            </a>
          </Typography>
        </div>
        <br />
        <div>
          <Typography variant="body1" classes={{ root: classes.subTitle }}>
            5. {t("Information Retention and Deletion")}
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            {t(
              "To fulfill our commitment to respecting and protecting your privacy and the confidentiality of your personal information, we have implemented appropriate technical and organizational security standards to help prevent unauthorized access or disclosure, misuse, alternation, or destruction of your information"
            )}
            .
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            {t(
              "Because no method of electronic transmission or method of data storage is perfect or impenetrable, we cannot guarantee that your personal information will be absolutely safe from intrusion during transmission or while stored in our systems"
            )}
            .
          </Typography>
        </div>
        <br />
        <div>
          <Typography variant="body1" classes={{ root: classes.subTitle }}>
            6. {t("Your rights")}
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            {t(
              "The General Data Protection Regulation or “GDPR” gives certain rights to individuals in relation to their personal data. Accordingly, we are happy to offer transparency and access controls to help users take advantage of those rights. As available and except as limited under applicable law, the rights afforded to individuals are"
            )}
            :
          </Typography>
          <br />
          <ul className={classes.coverList}>
            <li>
              {t(
                "The right to access, update or erase your personal information. You have the right to access, update or request erasure of your Personal Data directly within your account section. If you are unable to perform these actions yourself, please contact us to assist you"
              )}
              .
            </li>
            <li>
              {t(
                "The right of rectification.You have the right to obtain from us without undue delay the rectification of inaccurate personal information concerning you"
              )}
              .
            </li>
            <li>
              {t(
                "The right to object. You may object to processing of your personal data. Please note that in some cases, we may have compelling legitimate grounds to process your information which we need to comply with"
              )}
              .
            </li>
            <li>
              {t(
                "The right to data portability. You have the right to request a copy of an electronic file containing your personal data processed by the company in a machine - readable format"
              )}
              .
            </li>
            <li>
              {t(
                "The right to withdraw consent. If we are processing your Personal Data based on your consent (as indicated at the time of collection of such data), you have the right to withdraw your consent at any time. Please note, however, that if you exercise this right, you may have to then provide express consent on a case-by-case basis for the use or disclosure of certain of your Personal Data, if such use or disclosure is necessary to enable you to utilize some or all of our Services"
              )}
              .
            </li>
          </ul>
        </div>
        <br />
        <div>
          <Typography variant="body1" classes={{ root: classes.subTitle }}>
            7. {t("Changes to this Policy")}
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            {t(
              "From time to time, we may make changes to this Privacy Policy, but the content of such changes will never limit your rights with respect to your personal data to a greater extent than is established by applicable law"
            )}
            .
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            {t(
              "Changes to this Privacy Policy are effective from the moment of posting the amended text of the Privacy Policy and update the “effective date” at the top of this Privacy Policy"
            )}
            .
          </Typography>
        </div>
        <br />
        <div>
          <Typography variant="body1" classes={{ root: classes.subTitle }}>
            8. {t("Contact us")}
          </Typography>
          <br />
          <Typography variant="body1" classes={{ root: classes.textPrimary }}>
            {t(
              "If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us through email"
            )}{" "}
            <a href="mailto:support@alltrade.ventures">
              support@alltrade.ventures
            </a>
            .
          </Typography>
        </div>
      </div>
    </div>
  );
}
