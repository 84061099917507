import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import WinBingoBG from "../../../assets/img/WinBingo.png";
import convertNumberToMoney from "../../../common/convertNumberToMoney";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: "20px 20px 40px 20px",
    },
    title: {
      //   fontWeight: 700,
      color: theme.text.primary,
    },
    money: {
      fontWeight: 700,
      color: theme.text.primary,
      marginBottom: 30,
    },
    img: {
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        height: "auto",
      },
    },
  }),
  { index: 1 }
);

export default function WinBingo(props) {
  const { amount } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Typography variant="h5" classes={{ root: classes.title }}>
        {t("Congrats you won")}
      </Typography>
      <Typography variant="h4" classes={{ root: classes.money }}>
        {convertNumberToMoney(amount)} ALA
      </Typography>
      <img src={WinBingoBG} alt="bg" className={classes.img} />
    </div>
  );
}
