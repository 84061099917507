const convertNumberToUSDT = (number) => {
  var formatter = new Intl.NumberFormat("en-US");

  return `${formatter.format(parseFloat(number))} USDT`;
  //   return `${parseFloat(number)
  //     .toFixed(2)
  //     .replace(/\d(?=(\d{3})+\.)/g, "$&,")} USDT`;
};

export default convertNumberToUSDT;
