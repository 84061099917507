import { StepConnector } from "@material-ui/core";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 470,
    margin: "auto",
    [theme.breakpoints.down("xs")]: {
      width: 320,
    },
  },
  stepper: {
    background: "transparent",
    padding: "20px 0",
  },
  step: {},
  backButton: {
    marginRight: 2,
  },
  instructions: {
    marginTop: 2,
    marginBottom: 2,
  },
  label: { color: theme.text.secondary },
  labelActive: { color: `${theme.text.primary} !important` },
  labelCompleted: { color: `${theme.text.primary} !important` },
  icon: {
    fontSize: 30,
    color: "transparent",
    border: `1px solid #848E9C`,
    borderRadius: "50%",
  },
  iconActive: {
    color: `${theme.color.primary} !important`,
    border: "none",
  },
  iconCompleted: {
    backgroundColor: `${theme.color.primary}`,
    color: `${theme.color.background} !important`,
    border: `1px solid ${theme.color.primary}`,
  },
  iconText: {},
  connectorRoot: {
    backgroundColor: `${theme.color.light}`,
  },
  lineConnector: {
    height: 2,
    border: 0,
    backgroundColor: "transparent",
    borderRadius: 1,
  },
  connectorActive: {
    backgroundColor: `${theme.color.primary}`,
  },
  connectorComplete: {
    backgroundColor: `${theme.color.primary}`,
  },
}));

export default function StepperComponent(props) {
  const classes = useStyles(props);
  const { children, activeStep, steps } = props;

  return (
    <div className={classes.root}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        classes={{ root: classes.stepper }}
        connector={
          <StepConnector
            classes={{
              root: classes.connectorRoot,
              line: classes.lineConnector,
              active: classes.connectorActive,
              completed: classes.connectorComplete,
            }}
          />
        }
      >
        {steps.map((label) => (
          <Step key={label} classes={{ root: classes.step }}>
            <StepLabel
              classes={{
                label: classes.label,
                active: classes.labelActive,
                completed: classes.labelCompleted,
              }}
              StepIconProps={{
                classes: {
                  root: classes.icon,
                  active: classes.iconActive,
                  completed: classes.iconCompleted,
                  text: classes.iconText,
                },
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>{children}</div>
    </div>
  );
}
