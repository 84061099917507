import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  createTradeOrder,
  getOrderResult,
  createDemoTradeOrder,
  getDemoOrderResult,
} from "../../apis/trade.api";
import AudioOrder from "../../assets/audio/order.wav";
import TradeSuccess from "../../assets/audio/tradeSuccess.wav";
import DialogOrderTradeSuccess from "../../components/Dialog/DialogOrderTradeSuccess";
import DrawerTransactionVolume from "../../components/Drawer/DrawerTransactionVolume";
import BitcoinTrendTab from "../../components/NavBar/BitcoinTrendTab";
import CircularProgressWithLabel from "../../components/Progress/CircularProgressWithLabel";
import { ACCOUNT } from "../../const/common";
import { callToastNotiSuccess } from "../../redux/actions/toastNotiAction";
import {
  getDemoWalletReq,
  getUserWalletReq,
  reGetUserWalletReq,
} from "../../redux/actions/walletAction";

const audioOrder = new Audio(AudioOrder);
const orderSuccess = new Audio(TradeSuccess);

export default function PlayBoTab(props) {
  const { boData, handleChangeTabOrder, time } = props;
  const [amount, setAmount] = useState(10);
  const [systemSecond, setSystemSecond] = useState(null);
  const [
    openDrawerTransactionVolume,
    setOpenDrawerTransactionVolume,
  ] = useState(false);
  const [isOrdered, setIsOrdered] = useState(false);
  const [openTradeSuccess, setOpenTradeSuccess] = useState(false);
  const [valueWin, setValueWin] = useState(0);
  const [disableOrder, setDisableOrder] = useState(false);
  const [loadingFailed, setLoadingFailed] = useState(false);
  const walletInfo = useSelector((state) => state.walletReducer?.info);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const walletAccount = useSelector((state) => state.walletReducer?.account);
  const demoBalance = useSelector((state) => state.walletReducer.demo?.balance);
  const account = useSelector((state) => state.walletReducer.account);
  let walletAmount = walletInfo.results?.filter(
    (wallet) => wallet.c?.symbol === "USDT"
  )[0]?.balance;

  if (account === "demo") {
    walletAmount = parseFloat(demoBalance);
  }

  const callCreateTradeOrder = async (dataReq) => {
    audioOrder.play();
    setAmount(10);
    setDisableOrder(true);
    setLoadingFailed(true);
    if (walletAccount === ACCOUNT.REAL) {
      await createTradeOrder(dataReq)
        .then((res) => {
          if (res.status === 200) {
            dispatch(reGetUserWalletReq());
            dispatch(
              callToastNotiSuccess({
                noti: t("Order successfully"),
                status: "success",
              })
            );
            setDisableOrder(false);
            setLoadingFailed(false);
            handleChangeTabOrder(0);
          } else {
            setDisableOrder(false);
            setLoadingFailed(false);
            dispatch(
              callToastNotiSuccess({
                noti: t("Order failed"),
                status: "error",
              })
            );
          }
        })
        .catch((err) => {
          let { data } = err.response;
          if (data && data.message === "Order FaIl") {
            setTimeout(() => {
              setDisableOrder(false);
              setLoadingFailed(false);
              dispatch(
                callToastNotiSuccess({
                  noti: t("Order failed"),
                  status: "error",
                })
              );
            }, 2000);
          } else if (data.code === 400) {
            setDisableOrder(false);
            setLoadingFailed(false);
            dispatch(
              callToastNotiSuccess({
                noti: data.message,
                status: "error",
              })
            );
          } else {
            setDisableOrder(false);
            setLoadingFailed(false);
            dispatch(
              callToastNotiSuccess({
                noti: data.message,
                status: "error",
              })
            );
          }
        });
    } else {
      await createDemoTradeOrder(dataReq)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            dispatch(getDemoWalletReq());
            dispatch(
              callToastNotiSuccess({
                noti: t("Order successfully"),
                status: "success",
              })
            );
            setDisableOrder(false);
            setLoadingFailed(false);
            handleChangeTabOrder(0);
          } else {
            setDisableOrder(false);
            setLoadingFailed(false);
            dispatch(
              callToastNotiSuccess({
                noti: t("Order failed"),
                status: "error",
              })
            );
          }
        })
        .catch((err) => {
          let { data } = err.response;
          if (data && data.message === "Order FaIl") {
            setTimeout(() => {
              setDisableOrder(false);
              setLoadingFailed(false);
              dispatch(
                callToastNotiSuccess({
                  noti: t("Order failed"),
                  status: "error",
                })
              );
            }, 2000);
          } else if (data.code === 400) {
            setDisableOrder(false);
            setLoadingFailed(false);
            dispatch(
              callToastNotiSuccess({
                noti: data.message,
                status: "error",
              })
            );
          } else {
            setDisableOrder(false);
            setLoadingFailed(false);
            dispatch(
              callToastNotiSuccess({
                noti: data.message,
                status: "error",
              })
            );
          }
        });
    }
  };

  const handleUpAmount = (number) => {
    setAmount(amount + number);
  };

  const handleDownAmount = (number) => {
    if (amount >= number) {
      setAmount(amount - number);
    }
  };

  const handleUpdateAmount = (amount) => {
    setAmount(amount);
  };

  const handleBuy = () => {
    if (amount > walletAmount) {
      dispatch(
        callToastNotiSuccess({
          noti: t("Exceed the amount in the wallet"),
          status: "error",
        })
      );
    } else {
      let dataReq = {
        action_type: "up",
        amount: amount,
        price_enter: boData[boData.length - 1].close,
        // timestamp: time,
      };

      if (systemSecond <= 30) {
        if (parseFloat(amount) >= 1) {
          callCreateTradeOrder(dataReq);
        } else
          dispatch(
            callToastNotiSuccess({
              noti: t("Minimum transaction 1$"),
              status: "warning",
            })
          );
      } else {
        dispatch(
          callToastNotiSuccess({
            noti: t("buy trade failed", { second: 60 - systemSecond }),
            status: "error",
          })
        );
      }
    }
  };

  const handleSell = () => {
    if (amount > walletAmount) {
      dispatch(
        callToastNotiSuccess({
          noti: t("Exceed the amount in the wallet"),
          status: "error",
        })
      );
    } else {
      let dataReq = {
        action_type: "down",
        amount: amount,
        price_enter: boData[boData.length - 1].close,
        // timestamp: time,
      };
      if (systemSecond <= 30) {
        if (parseFloat(amount) >= 1) {
          callCreateTradeOrder(dataReq);
        } else
          dispatch(
            callToastNotiSuccess({
              noti: t("Minimum transaction 1$"),
              status: "warning",
            })
          );
      } else {
        dispatch(
          callToastNotiSuccess({
            noti: t("sell trade failed", { second: 60 - systemSecond }),
            status: "error",
          })
        );
      }
    }
  };

  const handleOpenDrawerTransactionVolume = () => {
    setOpenDrawerTransactionVolume(true);
  };

  const handleCloseDrawerTransactionVolume = () => {
    setOpenDrawerTransactionVolume(false);
  };

  const handleCloseTradeSuccess = () => {
    setOpenTradeSuccess(false);
    setValueWin(0);
  };

  const renderCircleProgress = () => {
    return <CircularProgressWithLabel systemSecond={systemSecond} />;
  };

  useEffect(() => {
    dispatch(getUserWalletReq());
    dispatch(getDemoWalletReq());
  }, []);

  useEffect(() => {
    if (time) {
      setSystemSecond(moment(time * 1000).seconds());
    }
  }, [time]);

  useEffect(() => {
    // console.log(time);
    // console.log(parseInt(moment().utc().valueOf() / 1000));
    if ((systemSecond === 0 || systemSecond === 1) && !isOrdered) {
      handleUpdateAmount(10);
      //   let time = parseInt(moment().utc().valueOf() / 1000);
      if (walletAccount === ACCOUNT.REAL)
        getOrderResult(time - 10)
          .then((res) => {
            if (res.status === 200) {
              setIsOrdered(true);
              let { data } = res;
              if (parseInt(data) > 0) {
                dispatch(getUserWalletReq());
                setValueWin(data);
                setOpenTradeSuccess(true);
              }
            }
          })
          .catch();
      else
        getDemoOrderResult(time - 10)
          .then((res) => {
            if (res.status === 200) {
              let { data } = res;
              if (parseInt(data) > 0) {
                dispatch(getDemoWalletReq());
                setValueWin(data);
                setOpenTradeSuccess(true);
              }
            }
          })
          .catch();
    } else if (isOrdered) {
      setIsOrdered(false);
    }
    if (systemSecond === 0) {
      handleChangeTabOrder(1);
    }
    if (systemSecond === 3) {
      setOpenTradeSuccess(false);
    }
    // const timer = setInterval(() => {
    //   setSystemSecond((prevSystemSecond) => moment().utc().seconds());
    // }, 1000);
    // return () => {
    //   clearInterval(timer);
    // };
  }, [systemSecond]);

  useEffect(() => {
    if (openTradeSuccess) {
      orderSuccess.play();
    }
  }, [openTradeSuccess]);

  return (
    <>
      <BitcoinTrendTab
        handleOpenDrawerTransactionVolume={handleOpenDrawerTransactionVolume}
        walletAmount={walletAmount}
        amount={amount}
        systemSecond={systemSecond}
        handleDownAmount={handleDownAmount}
        handleUpAmount={handleUpAmount}
        handleUpdateAmount={handleUpdateAmount}
        handleBuy={handleBuy}
        handleSell={handleSell}
        renderCircleProgress={renderCircleProgress}
        disableOrder={disableOrder}
        loadingOrder={loadingFailed}
      />
      <DrawerTransactionVolume
        handleCloseDrawer={handleCloseDrawerTransactionVolume}
        openDrawer={openDrawerTransactionVolume}
        amount={amount}
        walletInfo={walletInfo}
        handleUpdateAmount={handleUpdateAmount}
      />
      <DialogOrderTradeSuccess
        walletAccount={walletAccount}
        open={openTradeSuccess}
        handleClose={handleCloseTradeSuccess}
        value={valueWin}
      />
    </>
  );
}
