/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { find } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PaperBotPackage from "../../components/Body/BodyTradingBot/PaperBotPackage";
import PaperBuyBotSuccess from "../../components/Body/BodyTradingBot/PaperBuyBotSuccess";
import CardBot from "../../components/Card/CardBot";
import DialogBuyBotTrade from "../../components/Dialog/DialogBuyBotTrade";
import TableBotPackage from "../../components/Table/TableBotPackage";
import {
  buyBotPackageReq,
  getBotPackageReq,
  upgradeBotPackageReq,
} from "../../redux/actions/botAction";

const useStyles = makeStyles(
  (theme) => ({
    rootTable: {
      width: "80%",
      margin: "auto",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
  }),
  { index: 1 }
);

export default function BotPackage(props) {
  const classes = useStyles();
  const [botSelected, setBotSelected] = useState({
    info: {},
    open: false,
  });
  const [openPaperBotSuccess, setOpenPaperBotSuccess] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const dispatch = useDispatch();
  const botsInfo = useSelector((state) => state.botReducer.info);
  const buySuccess = useSelector((state) => state.botReducer.buySuccess);
  const botBought = useSelector((state) => state.botReducer.botBought);
  const walletInfo = useSelector((state) => state.walletReducer.info);
  const userPackage = useSelector((state) => state.botReducer.userPackage);

  console.log(userPackage);

  const handleRentNow = (botInfo) => {
    setBotSelected({ info: botInfo, open: true });
  };

  const handleCloseDialogBuyBot = () => {
    setBotSelected({ ...botSelected, open: false });
  };

  const handleCloseDialogBuyBotSuccess = () => {
    setOpenPaperBotSuccess(false);
  };

  const handlePayBot = (amount) => {
    let userPackageBought = userPackage.results;
    let botSelectedId = botSelected.info?.id;

    if (
      Array.isArray(userPackageBought) &&
      userPackageBought.length > 0 &&
      userPackageBought
        .map((userPackage) => userPackage.package_data?.id)
        .includes(Number(botSelected.info?.id))
    ) {
      let packageData = find(
        userPackageBought,
        (userPackage) =>
          Number(userPackage.package_data?.id) === Number(botSelectedId)
      );
      let dataReq = {
        amount,
      };
      dispatch(upgradeBotPackageReq(packageData?.id, dataReq));
      setBotSelected({ ...botSelected, open: false });
    } else {
      let dataReq = {
        bot_package: botSelectedId,
        amount,
      };
      dispatch(buyBotPackageReq(dataReq));
      setBotSelected({ ...botSelected, open: false });
    }
  };

  useEffect(() => {
    if (buySuccess) {
      setOpenPaperBotSuccess(true);
    }
  }, [buySuccess]);

  useEffect(() => {
    dispatch(getBotPackageReq());
  }, []);

  return (
    <>
      {!openPaperBotSuccess && Object.keys(botsInfo).length > 0 && (
        <PaperBotPackage>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            {botsInfo.results?.map((bot) => (
              <CardBot
                key={bot.id}
                botType="ultra"
                handleRentNow={handleRentNow}
                botInfo={bot}
              />
            ))}
          </div>
          {!matches && (
            <div className={classes.rootTable}>
              <TableBotPackage botsInfo={botsInfo} />
            </div>
          )}
        </PaperBotPackage>
      )}
      {/* <PaperBuyBotSuccess
        botSelected={botSelected.info}
        handleClose={handleCloseDialogBuyBotSuccess}
        buyBotSuccess={openPaperBotSuccess}
        botBought={botBought}
      />

      <DialogBuyBotTrade
        botSelected={botSelected}
        handleClose={handleCloseDialogBuyBot}
        handlePayBot={handlePayBot}
        wallet={
          walletInfo?.results?.filter((wallet) => wallet.c.symbol === "USDT")[0]
        }
      /> */}
    </>
  );
}
