import { pickBy } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getBoInvestCommission,
  getBoNetworkDetail,
  getBoUnderRef,
  getBoUserCommission,
  getRefBoCommission,
  getRefMonthCommission,
  getUpgradeLevelConfig,
  upgradeLevel,
} from "../../apis/referral.api";
import PaperAgencyTradeInfo from "../../components/Body/BodyAgencyTrade/PaperAgencyTradeInfo";
import PaperAgencyTradeSummary from "../../components/Body/BodyAgencyTrade/PaperAgencyTradeSummary";
import PaperInfoUser from "../../components/Body/BodyAgencyTrade/PaperInfoUser";
import PaperSystemMng from "../../components/Body/BodyAgencyTrade/PaperSystemMng";
import DialogUpgradeLevel from "../../components/Dialog/DialogUpgradeLevel";
import FormUpgradeLevel from "../../components/Form/FormUpgradeLevel";
import HeaderAgentInfo from "../../components/Header/HeaderAgentInfo";
import TableBoInvestCommission from "../../components/Table/TableBoInvestCommission";
import TableBoUnderRef from "../../components/Table/TableBoUnderRef";
import TableDataUserTradeBo from "../../components/Table/TableDataUserTradeBo";
import TableRefBoCommission from "../../components/Table/TableRefBoCommission";
import TableTradeVolume from "../../components/Table/TableTradeVolume";
import TreeAgency from "../../components/Tree/TreeAgency";
import { TOAST_NOTI } from "../../const/common";
import {
  getBoTreelReq,
  getReferralBoDetailReq,
} from "../../redux/actions/referralAction";
import { callToastNotiSuccess } from "../../redux/actions/toastNotiAction";
import { getUserWalletReq } from "../../redux/actions/walletAction";

export default function AgencyInfo() {
  const [valueTab, setValueTab] = useState(0);
  const [paginationList, setPaginationList] = useState({
    page: 1,
    page_size: 10,
  });
  const [boCommission, setBoCommission] = useState();
  const [dataTradingVolume, setDataTradingVolume] = useState();
  const [paginationTradingVolume, setPaginationTradingVolume] = useState({
    page: 1,
    page_size: 10,
  });
  const [dataBoUnderRef, setDataBoUnderRef] = useState();
  const [paginationBoUnderRef, setPaginationBoUnderRef] = useState({
    page: 1,
    page_size: 10,
  });
  const [paramsBoUnderRef, setParamsBoUnderRef] = useState({});
  const [dataBoInvestCom, setDataBoInvestCom] = useState();
  const [paginationBoInvestCom, setPaginationBoInvestCom] = useState({
    page: 1,
    page_size: 10,
  });
  const [paramsBoInvestCom, setParamsBoInvestCom] = useState({});
  const [boUserCommission, setBoUserCommission] = useState();
  const [valueTabInfoUser, setValueTabInfoUser] = useState(0);
  const [paramsDataUser, setParamsDataUser] = useState({
    page: 1,
    page_size: 10,
  });
  const [loading, setLoading] = useState(false);
  const [userClick, setUserClick] = useState(null);
  const [openUpgradeLevel, setOpenUpgradeLevel] = useState(false);
  const [dataUpgradeLevel, setDataUpgradeLevel] = useState([]);
  const dispatch = useDispatch();
  const referralTree = useSelector((state) => state.referralReducer.tree);
  const referralBo = useSelector((state) => state.referralReducer.bo);
  const walletsInfo = useSelector((state) => state.walletReducer.info);
  const { t } = useTranslation();

  const callBoNetworkDetail = (params) => {
    getBoNetworkDetail(params)
      .then((res) => {
        if (res.status === 200) {
          setUserClick(res.data);
        }
      })
      .catch();
  };

  const callBoUnderRef = (params) => {
    getBoUnderRef(params)
      .then((res) => {
        // dispatch(callLoading(false));
        setLoading(false);
        if (res.status === 200) {
          const { data } = res;
          setDataBoUnderRef(data);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const callMonthCommission = (params) => {
    let newParams = { ...params, extra_fields: "total_volume" };
    getRefMonthCommission(newParams)
      .then((res) => {
        if (res.status === 200) {
          const { data } = res;
          setDataTradingVolume(data);
        }
      })
      .catch();
  };

  const callBoCommission = (params) => {
    setLoading(true);
    getRefBoCommission({
      ...params,
      extra_fields: "commission_vol,trade_count,trade_vol",
    })
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          setBoCommission(res.data);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const callBoInvestCom = async (params) => {
    setLoading(true);
    await getBoInvestCommission({
      ...params,
      extra_fields: "invest_com_usdt,invest_com_ala,com_usdt,com_ala",
    })
      .then((res) => {
        if (res.status === 200) {
          setDataBoInvestCom(res.data);
        }
      })
      .catch((err) => {});
    setLoading(false);
  };

  const callBoUserCommission = async (params) => {
    setLoading(true);
    await getBoUserCommission({
      ...params,
      extra_fields: "invest_com_usdt,invest_com_ala,com_usdt,com_ala",
    })
      .then((res) => {
        if (res.status === 200) {
          setBoUserCommission(res.data);
        }
      })
      .catch((err) => {});
    setLoading(false);
  };

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const handleChangePageList = (page) => {
    let params = { ...paginationList, page };
    callBoCommission(params);

    setPaginationList(params);
  };

  const handleChangePageTradingVolume = (page) => {
    let params = { ...paginationTradingVolume, page };
    callMonthCommission(params);
    setPaginationTradingVolume(params);
  };

  const handleChangePageBoInvestCom = (page) => {
    let params = { ...paginationBoInvestCom, page };
    callBoInvestCom(params);

    setPaginationBoInvestCom(params);
  };

  const handleChangePageBoUnderRef = (page) => {
    let paramsPagination = { ...paginationBoUnderRef, page };
    let params = {
      ...paramsBoUnderRef,
      ...paramsPagination,
    };
    callBoUnderRef(params);
    setPaginationBoUnderRef(paramsPagination);
  };

  const handleResetPageBoUnderRef = () => {
    setPaginationBoUnderRef({ page: 1, page_size: 10 });
  };

  const handleUpdateParamsBoUnderRef = (params) => {
    let newParams = { ...paramsBoUnderRef, ...params };
    newParams = pickBy(newParams, (param) => !!(param !== null));
    setLoading(true);
    // dispatch(callLoading(true));
    callBoUnderRef({ ...newParams, page: 1, page_size: 10 });
    setParamsBoUnderRef({ ...newParams });
    setPaginationBoUnderRef({ page: 1, page_size: 10 });
  };

  const handleClickUser = (row) => {
    callBoNetworkDetail({ ref_id: row.id });
    const userId = row.user?.id;
    let params = { ...paramsDataUser, user_id: userId };
    setParamsDataUser(params);
    callBoUserCommission(params);
  };

  const handleBackSystemMng = () => {
    setUserClick(null);
  };

  const handleChangeTabInfoUser = (event, newValue) => {
    setValueTabInfoUser(newValue);
  };

  const handleChangePageDataUser = (page) => {
    let newParams = { ...paramsDataUser, page };
    callBoCommission(newParams);
    setParamsDataUser(newParams);
  };

  const handleSortDataUserTrade = (idCol, direction) => {
    let newParams = {
      ...paramsDataUser,
      page: 1,
      sort_field: idCol,
      sort_type: direction,
    };
    callBoCommission(newParams);
    setParamsDataUser(newParams);
  };

  const handleSortDataNetworkMng = (idCol, direction) => {
    let newParams = {
      ...paramsBoUnderRef,
      sort_field: idCol,
      sort_type: direction,
    };
    setParamsBoUnderRef({ ...newParams });
    setPaginationBoUnderRef({ page: 1, page_size: 10 });
    callBoUnderRef({ ...newParams, page: 1, page_size: 10 });
  };

  const handleOpenUpgradeLevel = () => {
    setOpenUpgradeLevel(true);
    dispatch(getUserWalletReq());
    getUpgradeLevelConfig()
      .then((res) => {
        if (res.status === 200) {
          setDataUpgradeLevel(res.data);
        }
      })
      .catch();
  };

  const handleCloseUpgradeLevel = () => {
    setOpenUpgradeLevel(false);
  };

  const handleUpgradeLevel = (info) => {
    let dataReq = {
      bo_level: info.level,
      period: "week",
    };
    let refId = referralBo?.id;
    handleCloseUpgradeLevel();
    upgradeLevel(refId, dataReq)
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            callToastNotiSuccess({
              noti: t("Upgrade level successfully"),
              status: "success",
            })
          );
          dispatch(getReferralBoDetailReq());
        } else
          dispatch(
            callToastNotiSuccess({
              noti: t("Upgrade level failed"),
              status: "error",
            })
          );
      })
      .catch((err) => {
        let { data } = err.response;
        dispatch(
          callToastNotiSuccess({
            noti: data.message,
            status: TOAST_NOTI.ERROR,
            code: data.code,
          })
        );
      });
  };

  const renderTableVolume = () => {
    return (
      <TableTradeVolume
        dataTable={dataTradingVolume}
        pagination={paginationTradingVolume}
        handleChangePage={handleChangePageTradingVolume}
      />
    );
  };

  const renderTableSystemMng = () => {
    return (
      <TableBoUnderRef
        dataTable={dataBoUnderRef}
        pagination={paginationBoUnderRef}
        handleChangePage={handleChangePageBoUnderRef}
        handleResetPageBoUnderRef={handleResetPageBoUnderRef}
        loading={loading}
        handleClickUser={handleClickUser}
        handleSort={handleSortDataNetworkMng}
      />
    );
  };

  const renderTableDataUserTradeBo = () => {
    return (
      <TableDataUserTradeBo
        dataTable={boUserCommission}
        pagination={paramsDataUser}
        loading={loading}
        handleChangePage={handleChangePageDataUser}
        handleSort={handleSortDataUserTrade}
      />
    );
  };

  const renderTabPanel = () => {
    switch (valueTab) {
      case 0:
        return (
          <>
            <PaperAgencyTradeSummary
              referralBo={referralBo}
              renderTableVolume={renderTableVolume}
              dataTradingVolume={dataTradingVolume}
              handleOpenUpgradeLevel={handleOpenUpgradeLevel}
            />
            <DialogUpgradeLevel
              open={openUpgradeLevel}
              handleClose={handleCloseUpgradeLevel}
            >
              <FormUpgradeLevel
                loading={false}
                handleUpgrade={handleUpgradeLevel}
                walletsInfo={walletsInfo}
                dataUpgradeLevel={dataUpgradeLevel}
              />
            </DialogUpgradeLevel>
          </>
        );
      case 1:
        return (
          <TableRefBoCommission
            boCommission={boCommission}
            handleChangePage={handleChangePageList}
            pagination={paginationList}
          />
        );
      case 2:
        return (
          <>
            <TableBoInvestCommission
              dataBoInvestCom={dataBoInvestCom}
              handleChangePage={handleChangePageBoInvestCom}
              pagination={paginationBoInvestCom}
            />
            {/* {referralTree.length > 0 && (
              <TreeAgency referralTree={referralTree} />
            )} */}
          </>
        );
      case 3:
        if (!userClick)
          return (
            <PaperSystemMng
              renderTableSystemMng={renderTableSystemMng}
              handleUpdateParamsBoUnderRef={handleUpdateParamsBoUnderRef}
              paramsBoUnderRef={paramsBoUnderRef}
            />
          );
        else
          return (
            <PaperInfoUser
              renderTableDataUserTradeBo={renderTableDataUserTradeBo}
              handleBackSystemMng={handleBackSystemMng}
              infoUser={userClick}
              valueTab={valueTabInfoUser}
              handleChangeTab={handleChangeTabInfoUser}
            />
          );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (referralBo.id) {
      let params = { page: 1, page_size: 10 };
      switch (valueTab) {
        case 0: {
          callMonthCommission(params);
          setPaginationTradingVolume(params);
          break;
        }
        case 1: {
          callBoCommission(params);
          setPaginationList(params);
          break;
        }
        case 2: {
          callBoInvestCom(params);
          setPaginationBoInvestCom(params);
          //   dispatch(getBoTreelReq(referralBo.id));
          break;
        }
        case 3: {
          callBoUnderRef(params);
          setParamsBoUnderRef(params);
          //   setParamsBoUnderRef({});
          break;
        }
        default:
          break;
      }
    }
  }, [valueTab]);

  useEffect(() => {
    if (userClick) {
      switch (valueTabInfoUser) {
        case 0: {
          let params = {
            user_id: paramsDataUser.user_id,
            page: paramsDataUser.page,
            page_size: paramsDataUser.page_size,
          };
          setParamsDataUser(params);
          callBoUserCommission(params);
          break;
        }
        case 1: {
          let from_date = moment().startOf("month").format("YYYY-MM-DD");
          let to_date = moment().format("YYYY-MM-DD");
          let params = { ...paramsDataUser, from_date, to_date };
          setParamsDataUser(params);
          callBoUserCommission(params);
          break;
        }
        case 2: {
          let from_date = moment()
            .subtract(1, "months")
            .startOf("month")
            .format("YYYY-MM-DD");
          let to_date = moment()
            .subtract(1, "months")
            .endOf("month")
            .format("YYYY-MM-DD");
          let params = { ...paramsDataUser, from_date, to_date };
          setParamsDataUser(params);
          callBoUserCommission(params);
          break;
        }
        default:
          break;
      }
    }
  }, [valueTabInfoUser]);

  return (
    <div style={{ width: "100%" }}>
      <HeaderAgentInfo referralInfo={referralBo} />
      {/* <PaperGeneralDataAgency summaryRef={summaryRef} /> */}
      <PaperAgencyTradeInfo
        valueTab={valueTab}
        handleChangeTab={handleChangeTab}
      >
        {renderTabPanel()}
      </PaperAgencyTradeInfo>
    </div>
  );
}
