import { Button, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import VerifySuccess from "../../../assets/img/VerifySuccess.png";

const useStyles = makeStyles((theme) => ({
  root: { textAlign: "center" },
  image: { margin: "30px 0 10px 0" },
  typography: {
    paddingBottom: 20,
    display: "block",
    color: theme.text.primary,
  },
  button: {
    color: theme.text.primary,
    borderColor: theme.color.primary,
    backgroundColor: theme.color.primary,
    borderRadius: 1,
    textTransform: "none",
    minWidth: 100,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));

export default function PaperProfileSubmitted(props) {
  const classes = useStyles(props);
  const { backToProfile } = props;
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <img
        src={`${VerifySuccess}`}
        alt="VerifySuccess"
        className={classes.image}
      ></img>
      <Typography variant="body1" className={classes.typography}>
        {t("Profile submitted successfully")}
      </Typography>
      <Typography variant="caption" className={classes.typography}>
        {t(
          "We will review your application and complete verification within 7 working days"
        )}
        .
      </Typography>
      <Button
        className={classes.button}
        variant="outlined"
        onClick={backToProfile}
      >
        {t("Back to profile")}
      </Button>
    </div>
  );
}
