import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import convertNumberToMoney from "../../../common/convertNumberToMoney";
import { bch, usdt } from "../../../const/currency";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        marginTop: 20,
      },
      textField: {
        marginBottom: 15,
        backgroundColor: "#2A2D35",
      },
      cssOutlinedInput: {
        "&$cssFocused $notchedOutline": {
          borderColor: `${theme.text.primary} !important`,
        },
      },
      notchedOutline: {
        borderWidth: "1px",
        borderColor: "#2A2D35 !important",
        borderRadius: "2px !important",
      },
      multilineColor: {
        color: theme.text.primary,
        fontSize: 13,
      },
      input: {
        "&::placeholder": {
          opacity: 0.2,
        },
      },
      typographyLabel: {
        color: theme.text.secondary,
      },
      coverPasteAddress: {
        minWidth: 85,
        cursor: "pointer",
        textAlign: "right",
      },
      endAdmornmentReceivingAddress: {
        color: theme.color.primary,
      },
      coverWithdrewAll: { minWidth: 80, cursor: "pointer", textAlign: "right" },
      endAdmornmentWithdrawalValue: {
        color: theme.color.primary,
      },
      endAdmornmentWithdrawalValueSecondary: {
        color: theme.text.secondary,
        paddingLeft: 5,
      },
      typographyValue: { paddingLeft: 20, color: theme.text.primary },
      buttonSubmit: {
        width: "100%",
        color: theme.text.primary,
        backgroundColor: theme.color.primary,
        textTransform: "none",
        borderRadius: 2,
        marginTop: 10,
        "&:hover": {
          backgroundColor: theme.color.primaryHover,
        },
      },
      buttonDisable: {
        backgroundColor: theme.color.disabled,
        color: "#37474f !important",
      },
      gridItemLeft: {
        paddingBottom: 10,
      },
      gridItemRight: { textAlign: "right", paddingBottom: 10 },
      typographyWarning: { color: "#F0B90B" },
    }),
  { index: 1 }
);

const initialValues = {
  address: "",
  amount: "",
};

export default function FormWithdrawal(props) {
  const classes = useStyles(props);
  const {
    networkType,
    handleTransfer,
    walletSelected,
    handleChangeAmount,
    withdrawFee,
    withdrawValue,
  } = props;
  const { t } = useTranslation();

  let min = 0;
  if (
    networkType === t("Internal") &&
    walletSelected.c?.symbol === bch.symbol
  ) {
    min = bch.minWithdrawInternal;
  } else if (
    networkType === t("Internal") &&
    walletSelected.c?.symbol === usdt.symbol
  ) {
    min = usdt.minWithdrawInternal;
  } else if (walletSelected.c?.min_withdraw) {
    min = parseInt(walletSelected.c?.min_withdraw);
  }

  let max = 0;
  if (walletSelected.c?.max_withdraw) {
    max = parseInt(walletSelected.c?.max_withdraw);
  }

  const validationSchema = Yup.object().shape({
    address: Yup.string().required(t("Not be empty")),
    amount: Yup.number()
      .max(max, () =>
        t("max withdraw", { max: max, symbol: walletSelected.c?.symbol })
      )
      .min(min, () =>
        t("min withdraw", { min: min, symbol: walletSelected.c?.symbol })
      )
      .required(t("Not be empty")),
  });

  const onSubmit = (values) => {
    handleTransfer(values);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => onSubmit(values),
  });

  const handlePasteAddress = (e) => {
    navigator.clipboard
      .readText()
      .then((text) => {
        formik.setFieldValue("address", text);
      })
      .catch();
  };

  const handleWithdrewAll = () => {
    handleChangeAmount(parseFloat(walletSelected.balance));
    formik.setFieldValue("amount", parseFloat(walletSelected.balance));
  };

  const renderProcessingFee = () => {
    // switch (networkType) {
    //   case t("Internal"): {
    //     return 0;
    //   }
    //   default:
    //     return convertNumberToMoney(parseFloat(withdrawFee || 0));
    // }
    return convertNumberToMoney(parseFloat(withdrawFee || 0));
  };

  useEffect(() => {
    handleChangeAmount(formik.values.amount, networkType);
  }, [formik.values.amount]);

  return (
    <div className={classes.root}>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <Typography variant="caption" className={classes.typographyLabel}>
            {t("Receiving Address")}
          </Typography>
          <TextField
            className={classes.textField}
            fullWidth
            variant="outlined"
            id="address"
            name="address"
            InputLabelProps={{ shrink: false }}
            InputProps={{
              endAdornment: (
                <div
                  className={classes.coverPasteAddress}
                  onClick={handlePasteAddress}
                >
                  <Typography
                    variant="caption"
                    className={classes.endAdmornmentReceivingAddress}
                  >
                    {t("Paste Address")}
                  </Typography>
                </div>
              ),
              classes: {
                root: classes.cssOutlinedInput,
                notchedOutline: classes.notchedOutline,
                input: classes.input,
              },
              className: classes.multilineColor,
            }}
            size="small"
            placeholder={t("Enter The Recipient Id")}
            onChange={formik.handleChange}
            //   onBlur={formik.handleBlur}
            value={formik.values.address}
            error={formik.touched.address && Boolean(formik.errors.address)}
            helperText={formik.touched.address && formik.errors.address}
          />
        </div>
        <div>
          <Typography variant="caption" className={classes.typographyLabel}>
            {t("Withdrawal Value")}
          </Typography>
          <TextField
            className={classes.textField}
            fullWidth
            variant="outlined"
            id="amount"
            name="amount"
            type="number"
            InputLabelProps={{ shrink: false }}
            InputProps={{
              endAdornment: (
                <div style={{ display: "flex" }}>
                  <div
                    className={classes.coverWithdrewAll}
                    onClick={() => {
                      handleWithdrewAll();
                    }}
                  >
                    <Typography
                      variant="caption"
                      className={classes.endAdmornmentWithdrawalValue}
                    >
                      {t("Withdraw All")}
                    </Typography>
                  </div>
                  <Typography
                    variant="caption"
                    className={classes.endAdmornmentWithdrawalValueSecondary}
                  >
                    {walletSelected.c?.symbol}
                  </Typography>
                </div>
              ),
              classes: {
                root: classes.cssOutlinedInput,
                notchedOutline: classes.notchedOutline,
                input: classes.input,
              },
              className: classes.multilineColor,
            }}
            size="small"
            placeholder={`${t("Minimum")} ${min} ${walletSelected.c?.symbol}`}
            onChange={formik.handleChange}
            //   onBlur={formik.handleBlur}
            value={formik.values.amount}
            error={formik.touched.amount && Boolean(formik.errors.amount)}
            helperText={formik.touched.amount && formik.errors.amount}
          />
        </div>
        <Grid container>
          <Grid item xs={12} className={classes.gridItemLeft}>
            <Typography variant="caption" className={classes.typographyLabel}>
              {t("Available balances")}:
            </Typography>
            <Typography variant="caption" className={classes.typographyValue}>
              {convertNumberToMoney(parseFloat(walletSelected.balance))}{" "}
              {walletSelected.c?.symbol}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.gridItemRight}>
            <Typography variant="caption" className={classes.typographyLabel}>
              {t("Processing fee")}:
            </Typography>
            <Typography variant="caption" className={classes.typographyValue}>
              {renderProcessingFee()} {walletSelected.c?.symbol}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.gridItemRight}>
            <Typography variant="caption" className={classes.typographyLabel}>
              {t("The number to be received")}:
            </Typography>
            <Typography variant="caption" className={classes.typographyValue}>
              {convertNumberToMoney(
                parseFloat(withdrawValue - withdrawFee || 0)
              )}{" "}
              {walletSelected.c?.symbol}
            </Typography>
          </Grid>
        </Grid>
        <div>
          <Button
            classes={{
              disabled: classes.buttonDisable,
              root: classes.buttonSubmit,
            }}
            type="submit"
            disabled={
              !(walletSelected.user?.two_factor_token?.status === "verified")
            }
          >
            {/* {networkType === t("Internal") ? t("Transfer") : t("Withdraw")} */}
            {!(walletSelected.user?.two_factor_token?.status === "verified")
              ? t("Must enable 2FA to withdraw")
              : networkType === t("Internal")
              ? t("Transfer")
              : t("Withdraw")}
          </Button>
        </div>
        {!walletSelected.user?.two_factor_token?.status === "verified" && (
          <div style={{ textAlign: "center", paddingTop: 10 }}>
            <Typography variant="caption" className={classes.typographyWarning}>
              {t("You must have 2FA enabled to request a withdrawal")}.
            </Typography>
            <Typography variant="caption" className={classes.typographyWarning}>
              {t("Turn on 2FA here")}
            </Typography>
          </div>
        )}
      </form>
    </div>
  );
}

FormWithdrawal.propTypes = {
  transactionType: PropTypes.string,
  handleTransfer: PropTypes.func,
};
