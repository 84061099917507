import { call, put, takeEvery } from "redux-saga/effects";
import {
  buyBotV2Package,
  getBotV2Package,
  upgradeBotV2Package,
} from "../../apis/tradingBotV2.api";
import { TOAST_NOTI } from "../../const/common";
import * as types from "../../const/redux/tradingBotV2";
import {
  buyBotV2PackageSuccess,
  getBotV2PackageSuccess,
  getUserV2PackageSuccess,
} from "../actions/tradingBotV2Action";
import { callToastNotiSuccess } from "../actions/toastNotiAction";
import { getUserPackage } from "../../apis/bot.api";

function* getBotV2PackageSagas({ payload }) {
  try {
    let responseBotPackage = yield call(getBotV2Package);
    if (responseBotPackage.status === 200) {
      let { data } = responseBotPackage;
      console.log("data:", data);
      yield put(getBotV2PackageSuccess(data));
    }
  } catch (err) {
    let { data } = err.response;
    yield put(
      callToastNotiSuccess({
        noti: data.message,
        status: TOAST_NOTI.ERROR,
        code: data.code,
      })
    );
  }
}

function* buyBotV2PackageSagas({ payload }) {
  try {
    const { dataReq } = payload;
    let responseBuyBotPackage = yield call(buyBotV2Package, dataReq);
    if (responseBuyBotPackage.status === 200) {
      let { data } = responseBuyBotPackage;
      yield put(buyBotV2PackageSuccess(data));
    }
  } catch (err) {
    let { data } = err.response;
    yield put(
      callToastNotiSuccess({
        noti: data.message,
        status: TOAST_NOTI.ERROR,
        code: data.code,
      })
    );
  }
}

function* getUserV2PackageSagas({ payload }) {
  try {
    const params = { version: 2 };
    let responseUserPackage = yield call(getUserPackage, params);
    if (responseUserPackage.status === 200) {
      let { data } = responseUserPackage;
      yield put(getUserV2PackageSuccess(data));
    }
  } catch (err) {
    let { data } = err.response;
    yield put(
      callToastNotiSuccess({
        noti: data.message,
        status: TOAST_NOTI.ERROR,
        code: data.code,
      })
    );
  }
}

function* upgradeBotV2PackageSagas({ payload }) {
  try {
    const { botId, dataReq } = payload;
    let responseBuyBotPackage = yield call(upgradeBotV2Package, botId, dataReq);
    if (responseBuyBotPackage.status === 200) {
      let { data } = responseBuyBotPackage;
      yield put(
        callToastNotiSuccess({
          noti: "Upgrade success",
          status: TOAST_NOTI.SUCCESS,
          code: data.code,
        })
      );
    }
  } catch (err) {
    let { data } = err.response;
    yield put(
      callToastNotiSuccess({
        noti: data.message,
        status: TOAST_NOTI.ERROR,
        code: data.code,
      })
    );
  }
}

export function* watchTradingBotAsync() {
  yield takeEvery(types.GET_BOT_V2_PACKAGE_REQ, getBotV2PackageSagas);
  yield takeEvery(types.BUY_BOT_V2_PACKAGE_REQ, buyBotV2PackageSagas);
  yield takeEvery(types.GET_USER_V2_PACKAGE_REQ, getUserV2PackageSagas);
  yield takeEvery(types.UPGRADE_BOT_V2_PACKAGE_REQ, upgradeBotV2PackageSagas);
}
