import {
  Button,
  Card,
  CardContent,
  CardHeader,
  createStyles,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Password from "../../assets/img/Password.png";
import Verify2FA from "../../assets/img/Verify2FA.png";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        width: 464,
        minHeight: 227,
        margin: "15px 0 15px 15px",
        [theme.breakpoints.down("xs")]: {
          maxWidth: 328,
          margin: "15px auto",
          marginBottom: 0,
        },
      },
      gridContainer: { margin: "15px 0" },
      rootList: { padding: 0 },
      card: {
        backgroundColor: "rgba(255, 255, 255, 0.03)",
        color: theme.text.primary,
      },
      cardHeader: { paddingBottom: 0 },
      cardContent: { paddingTop: 0, minWidth: 0 },
      listItem: { padding: 0 },
      listItemText: { margin: 0 },
      buttonAction: {
        color: "#C4C4C4",
        borderColor: "#C4C4C4",
        marginRight: "-10px",
        borderRadius: 2,
        textTransform: "none",
        width: 120,
        [theme.breakpoints.down("xs")]: {
          marginRight: 5,
          float: "right",
        },
      },
      typographyPrimary: { color: theme.text.primary },
      typographySecondary: { color: theme.text.secondary },
      divider: { backgroundColor: theme.text.secondary },
    }),
  { index: 1 }
);

export default function CardSecurity(props) {
  const classes = useStyles(props);
  const { handleActionClick, enable2FA } = props;
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          title={<Typography variant="body1">{t("Security")}</Typography>}
        />
        <CardContent className={classes.cardContent}>
          <Grid container spacing={1} classes={{ root: classes.gridContainer }}>
            <Grid item sm={8} xs={12}>
              <List classes={{ root: classes.rootList }}>
                <ListItem className={classes.listItem}>
                  <img
                    src={`${Verify2FA}`}
                    alt="Verify2FA"
                    style={{ marginRight: 10 }}
                  ></img>
                  <ListItemText
                    classes={{ root: classes.listItemText }}
                    primary={
                      <Typography
                        variant="body2"
                        className={classes.typographyPrimary}
                      >
                        {t("Verify 2FA")}
                      </Typography>
                    }
                    secondary={
                      <Typography
                        variant="caption"
                        className={classes.typographySecondary}
                      >
                        {t("Required for withdrawal or update for security")}
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item sm={4} xs={12}>
              {!enable2FA ? (
                <Button
                  variant="outlined"
                  className={classes.buttonAction}
                  onClick={() => {
                    handleActionClick("Verify 2FA");
                  }}
                >
                  {t("Turn on")}
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  className={classes.buttonAction}
                  onClick={() => {
                    handleActionClick("Verify 2FA");
                  }}
                >
                  {t("Turn off")}
                </Button>
              )}
            </Grid>
          </Grid>

          <Divider className={classes.divider}></Divider>
          <Grid container spacing={1} classes={{ root: classes.gridContainer }}>
            <Grid item sm={8} xs={12}>
              <List classes={{ root: classes.rootList }}>
                <ListItem className={classes.listItem}>
                  <img
                    src={`${Password}`}
                    alt="Password"
                    style={{ marginRight: 10 }}
                  ></img>
                  <ListItemText
                    className={classes.listItemText}
                    primary={
                      <Typography
                        variant="body2"
                        className={classes.typographyPrimary}
                      >
                        {t("Password")}
                      </Typography>
                    }
                    secondary={
                      <Typography
                        variant="caption"
                        className={classes.typographySecondary}
                      >
                        {t("You must have 2FA enabled to change your password")}
                        {/* Bạn phải bật 2FA để đổi mật khẩu */}
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Button
                variant="outlined"
                className={classes.buttonAction}
                onClick={() => {
                  handleActionClick("Change password");
                }}
              >
                {t("Change password")}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

CardSecurity.propTypes = { handleActionClick: PropTypes.func };
