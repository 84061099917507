import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { Slide, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function ToastLoading() {
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (loadingReducer) {
      enqueueSnackbar(
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 30,
          }}
        >
          {/* TODO add loading */}
          <Typography variant="body2">
            <CircularProgress style={{ color: "#fff" }} size={25} />
          </Typography>
        </div>,
        {
          anchorOrigin: {
            vertical: matches ? "top" : "bottom",
            horizontal: matches ? "center" : "left",
          },

          //   variant: "info",
          TransitionComponent: Slide,
        }
      );
    }
  }, [loadingReducer]);

  return <div></div>;
}
