import * as types from "../../const/redux/toastNoti";

export const callToastNotiReq = () => {
  return {
    type: types.CALL_TOAST_NOTI_REQ,
  };
};

export const callToastNotiSuccess = (data) => {
  return {
    type: types.CALL_TOAST_NOTI_SUCCESS,
    payload: {
      data,
    },
  };
};
