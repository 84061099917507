import {
  LinearProgress,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import TradingItemDown from "../../assets/img/TradingItemDown.png";
import TradingItemUp from "../../assets/img/TradingItemUp.png";

const useStyles = makeStyles(
  (theme) => ({
    itemImage: { display: "block", marginBottom: -15 },
    typographyBuy: { color: "#55ad82" },
    rootSell: {
      marginTop: 25,
    },
    typographySell: { color: "#C8533C" },
    root: {
      width: "100%",
      marginLeft: 20,
    },
    lineProgress: {
      backgroundColor: theme.color.trendingDown,
    },
    lineProgressPrimary: {
      backgroundColor: theme.color.trendingUp,
    },
  }),
  { index: 1 }
);

function TradingLine(props) {
  const classes = useStyles(props);
  const { data } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  let boCount = 100;
  let buyCount = data?.opinion || 50;

  const renderBuy = () => {
    let length = Math.round((buyCount / boCount) * 16);
    let arrBuy = [];
    for (let i = 0; i < length; i++) {
      arrBuy.push(
        <img
          src={`${TradingItemUp}`}
          alt="TradingItemUp"
          className={classes.itemImage}
          key={i}
        ></img>
      );
    }
    return arrBuy;
  };

  const renderSell = () => {
    let length = 16 - Math.round((buyCount / boCount) * 16);
    let arrCell = [];
    for (let i = 0; i < length; i++) {
      arrCell.push(
        <img
          src={`${TradingItemDown}`}
          alt="TradingItemDown"
          className={classes.itemImage}
          key={i}
        ></img>
      );
    }
    return arrCell;
  };

  return (
    <>
      {!matches ? (
        <div>
          <div>
            <Typography
              variant="body2"
              classes={{ root: classes.typographyBuy }}
            >
              {t("Buy").toUpperCase()}
            </Typography>
            <Typography
              variant="body2"
              classes={{ root: classes.typographyBuy }}
            >
              {parseFloat((buyCount / boCount) * 100).toFixed(2)}%
            </Typography>
          </div>
          {renderBuy()}
          {renderSell()}
          <div className={classes.rootSell}>
            <Typography
              variant="body2"
              classes={{ root: classes.typographySell }}
            >
              {t("Sell").toUpperCase()}
            </Typography>
            <Typography
              variant="body2"
              classes={{ root: classes.typographySell }}
            >
              {100 - parseFloat((buyCount / boCount) * 100).toFixed(2)}%
            </Typography>
          </div>
        </div>
      ) : (
        <div className={classes.root}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "30%",
              }}
            >
              <Typography
                variant="body2"
                classes={{ root: classes.typographyBuy }}
              >
                {t("Buy").toUpperCase()}
              </Typography>
              <Typography
                variant="body2"
                classes={{ root: classes.typographyBuy }}
              >
                {parseFloat((buyCount / boCount) * 100).toFixed(2)}%
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "row-reverse",
                width: "30%",
              }}
            >
              <Typography
                variant="body2"
                classes={{ root: classes.typographySell }}
              >
                {t("Sell").toUpperCase()}
              </Typography>
              <Typography
                variant="body2"
                classes={{ root: classes.typographySell }}
              >
                {100 - parseFloat((buyCount / boCount) * 100).toFixed(2)}%
              </Typography>
            </div>
          </div>
          <LinearProgress
            variant="determinate"
            value={parseFloat((buyCount / boCount) * 100).toFixed(2)}
            classes={{
              root: classes.lineProgress,
              barColorPrimary: classes.lineProgressPrimary,
            }}
          />
        </div>
      )}
    </>
  );
}

TradingLine.propTypes = {};

export default TradingLine;
