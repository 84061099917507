import { cloneDeep } from "lodash";
import * as types from "../../const/redux/transaction";

let initialState = {
  candleStick: {},
  listOrder: {},
  loadingOrder: false,
};

const transactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CANDLE_STICK_SUCCESS: {
      let newState = cloneDeep(state);
      newState.candleStick = cloneDeep(action.payload.data);
      return newState;
    }
    case types.GET_LIST_ORDER_SUCCESS:
    case types.GET_DEMO_LIST_ORDER_SUCCESS: {
      let newState = cloneDeep(state);
      newState.listOrder = cloneDeep(action.payload.data);
      return newState;
    }
    case types.SET_LOADING_ORDER: {
      let newState = cloneDeep(state);
      newState.loadingOrder = cloneDeep(action.payload.data);
      return newState;
    }
    default:
      return state;
  }
};

export default transactionReducer;
