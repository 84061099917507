import { makeStyles, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router";
import { updatePassword, validateOtpForgot } from "../../apis/user.api";
import FormResetPassword from "../../components/Form/FormResetPassword";
import TopBarHandleAccount from "../../components/TopBar/TopBarHandleAccount";
import { PATH } from "../../const/paths";

const useStyles = makeStyles(
  (theme) => ({
    title: {
      textAlign: "center",
      fontWeight: 700,
      marginTop: 150,
      color: theme.text.danger,
    },
  }),
  { index: 1 }
);

export default function ResetPassword(props) {
  const classes = useStyles(props);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const match = useRouteMatch();
  const [validateOtp, setValidateOtp] = useState(false);
  const [messageError, setMessageErro] = useState("");
  const history = useHistory();

  const handleResetPassword = (values) => {
    let email = localStorage.getItem("email_forgot");
    let dataReq = {
      action: "change_password",
      email,
      otp: match.params?.otp,
      password: values.password,
    };
    updatePassword(dataReq)
      .then((res) => {
        if (res.status === 200) {
          //   localStorage.setItem("jwt_token", res.data.access);
          //   enqueueSnackbar(t(res.data.msg), { variant: "success" });
          history.push(PATH.LOGIN);
          localStorage.removeItem("email_forgot");
        }
      })
      .catch((err) => {
        if (err.response) {
          const { data } = err.response;
          enqueueSnackbar(data.message, { variant: "error" });
        }
      });
  };

  useEffect(() => {
    if (match.params?.otp) {
      let email = localStorage.getItem("email_forgot");
      let dataReq = {
        action: "validate",
        email,
        otp: match.params?.otp,
      };
      validateOtpForgot(dataReq)
        .then((res) => {
          if (res.status === 200) {
            setValidateOtp(true);
          }
        })
        .catch((err) => {
          if (err.response) {
            const { data } = err.response;
            // enqueueSnackbar(data.message, { variant: "error" });
            setMessageErro(data.message);
            setValidateOtp(false);
          }
        });
    }
  }, [match.params?.otp]);

  return (
    <div>
      <TopBarHandleAccount />
      {validateOtp ? (
        <FormResetPassword handleResetPassword={handleResetPassword} />
      ) : (
        <Typography variant="h3" classes={{ root: classes.title }}>
          {!!messageError && messageError}
        </Typography>
      )}
    </div>
  );
}
