import AppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getUserPackageReq } from "../../redux/actions/botAction";
import { getUserWalletReq } from "../../redux/actions/walletAction";
import BotPackage from "./BotPackage";
import History from "./History";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px !important`,
    width: "100%",
  },
  rootTabs: {
    flexGrow: 1,
    width: "100%",
    height: "max-content",
    paddingBottom: theme.spacing(2),
  },
  appBar: {
    backgroundColor: theme.color.background,
  },
  tabItem: {
    color: `${theme.text.secondary} !important`,
  },
  tabItemActive: {
    color: `${theme.text.primary} !important`,
  },
}));

export default function TradingBot() {
  const dispatch = useDispatch();
  const userPackage = useSelector((state) => state.botReducer.userPackage);
  const classes = useStyles();
  const [valueTab, setValueTab] = useState("bots");
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getUserPackageReq());
    dispatch(getUserWalletReq());
  }, []);

  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };

  return (
    <div className={classes.root}>
      <div className={classes.rootTabs}>
        <AppBar position={"relative"} className={classes.appBar}>
          <Tabs
            value={valueTab}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs example"
          >
            <Tab
              label={t("Bots")}
              value="bots"
              className={
                valueTab === "bots" ? classes.tabItemActive : classes.tabItem
              }
            />
            <Tab
              label={t("MyBots")}
              value="my-bots"
              className={
                valueTab === "my-bots" ? classes.tabItemActive : classes.tabItem
              }
            />
          </Tabs>
        </AppBar>
      </div>
      {valueTab && <>{valueTab === "my-bots" ? <History /> : <BotPackage />}</>}
    </div>
  );
}
