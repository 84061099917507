import { combineReducers } from "redux";
import userReducer from "./userReducer";
import walletReducer from "./walletReducer";
import toastNotiReducer from "./toastNotiReducer";
import botReducer from "./botReducer";
import referralReducer from "./referralReducer";
import transactionReducer from "./transactionReducer";
import loadingReducer from "./loadingReducer";
import notificationReducer from "./notificationReducer";
import botV2Reducer from "./botV2Reducer";
import tradingBotV2Reducer from "./tradingBotV2Reducer";

const rootReducer = combineReducers({
  userReducer,
  walletReducer,
  toastNotiReducer,
  botReducer,
  referralReducer,
  transactionReducer,
  loadingReducer,
  notificationReducer,
  botV2Reducer,
  tradingBotV2Reducer,
});

export default rootReducer;
