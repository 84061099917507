import {
  Breadcrumbs,
  createStyles,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        display: "flex",
        width: "100%",
        marginLeft: theme.marginLayout,
        backgroundColor: theme.color.background,
        height: 72,
        padding: "5px 0 0 15px",
      },
      breadcrumb: {
        color: theme.text.secondary,
      },
    }),
  { index: 1 }
);

export default function HeaderAccountVerification(props) {
  const classes = useStyles(props);
  const { backToProfile } = props;
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={">"}
        className={classes.breadcrumb}
      >
        <Typography
          variant="caption"
          onClick={() => {
            backToProfile();
          }}
        >
          {t("Profile")}
        </Typography>
        <Typography variant="caption">{t("Verify account")}</Typography>
      </Breadcrumbs>
    </div>
  );
}
