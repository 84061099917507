import {
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Banner01 from "../../assets/img/Banner01.png";
import Banner02 from "../../assets/img/Banner02.png";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      paperRoot: {
        backgroundColor: "transparent",
        boxShadow: "none",
        maxWidth: "600px",
        // [theme.breakpoints.down("xs")]: {
        //   width: 400,
        // },
      },
      closeButton: {
        position: "absolute",
        right: 25,
        top: 85,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 65,
        [theme.breakpoints.down("xs")]: {
          right: 0,
          top: 65,
          width: 60,
        },
      },
      dialogContent: {
        backgroundColor: "transparent",
        padding: 10,
        borderRadius: 10,
        textAlign: "center",
      },
      text: {
        color: theme.text.success,
        textAlign: "center",
        fontWeight: 700,
      },
    }),
  { index: 1 }
);

export default function DialogBanner(props) {
  const classes = useStyles(props);
  const { open, handleClose } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.paperRoot }}
      maxWidth={false}
    >
      <DialogTitle>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <img
            src={Banner02}
            alt="Banner02"
            width={"100%"}
            height={"auto"}
          ></img>
        </IconButton>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <img
          src={Banner01}
          alt="Banner01"
          width={matches ? "90%" : "80%"}
          height={"auto"}
        ></img>
      </DialogContent>
    </Dialog>
  );
}
