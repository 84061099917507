import {
  AppBar,
  Avatar,
  Badge,
  Button,
  createStyles,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import Logo from "../../assets/img/Logo.png";
import Menu from "../../assets/img/Menu.png";
import Bell from "../../assets/img/Bell.png";
import { PATH } from "../../const/paths";
import PopoverAccountContainer from "../../containers/common/PopoverAccountContainer";
import PopoverSetting from "../PopOver/PopoverSetting";
import PopOverNoti from "../PopOver/PopOverNoti";
import SelectLanguage from "../Select/SelectLanguage";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        paddingBottom: 8,
      },
      appBar: {
        backgroundColor: theme.color.background,
        // [theme.breakpoints.down("xs")]: {
        //   backgroundColor: theme.body.backgroundColor,
        // },
      },
      toolBar: {
        color: "#888888",
        [theme.breakpoints.down("xs")]: {
          justifyContent: "space-between",
          padding: "10px 0",
        },
      },
      menuButton: {
        marginRight: 0,
      },
      badge: {
        marginRight: 10,
      },
      anchorOriginTopRightRectangle: { top: 3, right: 3 },
      title: {
        flexGrow: 1,
        fontWeight: "bold",
        // fontFamily: "Montserrat",
        fontStyle: "normal",
        cursor: "pointer",
      },
      iconButton: {
        fontSize: "16px",
      },
      list: {
        color: "#fff",
        padding: 10,
      },
      listSetting: {
        padding: 0,
        backgroundColor: "transparent",
        color: "#fff",
      },
      listItem: { padding: 0 },
      avatar: {
        backgroundColor: "transparent",
        border: `1px solid ${theme.color.light}`,
        width: theme.spacing(4),
        height: theme.spacing(4),
        fontSize: 14,
      },
      popover: {
        borderRadius: 2,
        backgroundColor: theme.color.background,
      },
      titlePage: {
        color: theme.text.primary,
      },
    }),
  { index: 1 }
);

function TopBarTrading(props) {
  const classes = useStyles(props);
  const { handleOpenDrawerSideBar, handleLogout, userProfile, notification } =
    props;
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const history = useHistory();

  const { location } = history;

  const handleLink = (path) => {
    let jwt_token = localStorage.getItem("jwt_token");
    if (path === PATH.HOME && jwt_token) {
      history.push(PATH.TRADING_VIEW);
    } else history.push(path);
  };

  const renderTitlePage = (location) => {
    switch (location.pathname.toLowerCase()) {
      case PATH.TRANSACTION.toLowerCase():
        return (
          <>
            {/* <Typography variant="h6" classes={{ root: classes.titlePage }}>
              {t("Transaction")}
            </Typography> */}
            <PopoverAccountContainer />
          </>
        );
      case PATH.WALLET.toLowerCase(): {
        return (
          <Typography variant="h6" classes={{ root: classes.titlePage }}>
            {t("Wallet")}
          </Typography>
        );
      }
      case PATH.TRADINGBOT.toLowerCase(): {
        return (
          <Typography variant="h6" classes={{ root: classes.titlePage }}>
            {t("Trading bot")}
          </Typography>
        );
      }
      case PATH.AGENCY.toLowerCase(): {
        return (
          <Typography variant="h6" classes={{ root: classes.titlePage }}>
            {t("Agency")}
          </Typography>
        );
      }
      case PATH.LOTTERY.toLowerCase(): {
        return (
          <Typography variant="h6" classes={{ root: classes.titlePage }}>
            {t("Lotteries")}
          </Typography>
        );
      }
      case PATH.PROFILE.toLowerCase(): {
        return (
          <Typography variant="h6" classes={{ root: classes.titlePage }}>
            {t("Profile")}
          </Typography>
        );
      }
      case PATH.DASHBOARD.toLowerCase(): {
        return (
          <Typography variant="h6" classes={{ root: classes.titlePage }}>
            {t("Dashboard")}
          </Typography>
        );
      }
      default:
        return null;
    }
  };

  const renderSelectLanguage = () => {
    return <SelectLanguage />;
  };

  const renderUpMedium = () => {
    return (
      <AppBar position="static" className={classes.appBar}>
        <Toolbar variant="dense" className={classes.toolBar}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={() => {
              handleLink(PATH.HOME);
            }}
          >
            <img src={`${Logo}`} alt="Logo" />
          </IconButton>
          <Typography
            variant="h5"
            className={classes.title}
            onClick={() => {
              handleLink(PATH.HOME);
            }}
          >
            AllTrade
          </Typography>
          <Badge
            color="error"
            variant="dot"
            invisible={!notification}
            onClick={() => {
              handleLink(PATH.NOTIFICATION);
            }}
            classes={{
              root: classes.badge,
              anchorOriginTopRightRectangle:
                classes.anchorOriginTopRightRectangle,
            }}
          >
            <IconButton
              color="inherit"
              className={classes.iconButton}
              size="small"
            >
              <img src={`${Bell}`} alt="Bell" />
            </IconButton>
          </Badge>
          {/* <PopOverNoti /> */}

          <PopoverSetting
            handleLogout={handleLogout}
            renderSelectLanguage={renderSelectLanguage}
          />
          <List className={classes.list}>
            <ListItem className={classes.listItem}>
              <ListItemAvatar>
                <IconButton
                  onClick={() => {
                    handleLink(PATH.PROFILE);
                  }}
                >
                  <Avatar classes={{ root: classes.avatar }}>
                    {userProfile?.username?.substring(0, 2)?.toUpperCase() ||
                      ""}
                  </Avatar>
                  {/* <img src={`${Logo2}`} alt="Logo2" /> */}
                </IconButton>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <p style={{ fontSize: "12px" }}>
                    {userProfile?.username || ""}
                  </p>
                }
                secondary={
                  <p style={{ color: "#8B8B8B", fontSize: "12px" }}>
                    {userProfile?.email || ""}
                    {/* <span style={{ opacity: 0.5 }}>ID: </span> */}
                    {/* {userProfile?.email || ""} */}
                  </p>
                }
              />
            </ListItem>
          </List>
        </Toolbar>
      </AppBar>
    );
  };

  const renderDownExtraSmall = () => {
    return (
      <>
        <AppBar position="fixed" classes={{ root: classes.appBar }}>
          <Toolbar variant="dense" classes={{ root: classes.toolBar }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={handleOpenDrawerSideBar}
              >
                <img src={`${Menu}`} alt="Menu" />
              </Button>
              {renderTitlePage(location)}
            </div>
            {/* <SelectLanguage /> */}
            <div style={{ paddingRight: 10 }}>
              <Badge
                badgeContent={4}
                color="error"
                invisible={!notification}
                classes={{
                  root: classes.badge,
                  anchorOriginTopRightRectangle:
                    classes.anchorOriginTopRightRectangle,
                }}
                onClick={() => {
                  handleLink(PATH.NOTIFICATION);
                }}
              >
                <IconButton
                  color="inherit"
                  className={classes.iconButton}
                  size="small"
                >
                  <img src={`${Bell}`} alt="Bell" />
                </IconButton>
              </Badge>
              <PopoverSetting
                handleLogout={handleLogout}
                renderSelectLanguage={renderSelectLanguage}
              />
            </div>
          </Toolbar>
        </AppBar>
        <div style={{ height: 60 }}></div>
      </>
    );
  };

  return (
    <div className={classes.root}>
      {matches ? renderDownExtraSmall() : renderUpMedium()}
    </div>
  );
}

TopBarTrading.propTypes = {};

export default TopBarTrading;
