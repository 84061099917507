import {
  Button,
  createStyles,
  makeStyles,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import convertNumberToCurrency from "../../../common/convertNumberToCurrency";
import hideString from "../../../common/hideString";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      buttonAccount: (props) => ({
        width: "95%",
        padding: "15px 30px",
        marginRight: 20,
        color: props.selected ? theme.text.primary : theme.text.secondary,
        border: `1.5px solid ${
          props.selected ? theme.color.primary : theme.text.secondary
        }`,
        textTransform: "none",
      }),
      typographyTitle: {
        [theme.breakpoints.down("xs")]: {
          fontSize: 12,
        },
      },
      typographyMoney: {
        [theme.breakpoints.down("xs")]: {
          fontSize: 24,
        },
      },
    }),
  { index: 1 }
);

function ButtonAccount(props) {
  const classes = useStyles(props);
  const { accountType, handleSelectAccountType, showBalance, walletsInfo } =
    props;
  const { t } = useTranslation();

  const renderTitle = (type) => {
    switch (type) {
      case "real": {
        return t("Real account");
      }
      case "demo": {
        return t("Demo account");
      }
      default:
        return null;
    }
  };

  return (
    <Button
      variant="outlined"
      className={classes.buttonAccount}
      size="large"
      onClick={() => {
        handleSelectAccountType(accountType);
      }}
    >
      <div>
        <Typography variant="body2" classes={{ root: classes.typographyTitle }}>
          {renderTitle(accountType)}
        </Typography>
        {showBalance ? (
          <Typography variant="h5" classes={{ root: classes.typographyMoney }}>
            {convertNumberToCurrency(
              walletsInfo.results?.reduce(
                (accumulator, currentWallet) =>
                  accumulator + currentWallet?.estimate_usdt,
                0
              ) || 0
            )}
          </Typography>
        ) : (
          <Typography variant="h5" classes={{ root: classes.typographyMoney }}>
            {hideString("$19,000.00")}
          </Typography>
        )}
      </div>
    </Button>
  );
}

ButtonAccount.propTypes = {
  accountType: PropTypes.string,
  handleSelectAccountType: PropTypes.func,
  selected: PropTypes.bool,
};

export default ButtonAccount;
