import { cloneDeep } from "lodash";
import * as types from "../../const/redux/botV2";

let initialState = {
  info: {},
  botBought: {},
  userPackage: {},
};

const botV2Reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_BOT_V2_SUCCESS: {
      let newState = cloneDeep(state);
      newState.info = cloneDeep(action.payload.data);
      return newState;
    }
    case types.BUY_BOT_V2_SUCCESS: {
      let newState = cloneDeep(state);
      newState.botBought = cloneDeep(action.payload.data);
      return newState;
    }
    default:
      return state;
  }
};

export default botV2Reducer;
