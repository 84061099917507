import {
  Button,
  createStyles,
  makeStyles,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import BackgroundAgent from "../../../assets/img/BackgroundAgent.png";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        margin: " 20px auto",
        textAlign: "center",
        width: "100%",
        // [theme.breakpoints.down("xs")]: {
        //  marginT
        // },
      },
      title: {
        width: "45%",
        margin: "auto",
        padding: "0 0 15px 0",
        fontSize: 28,
        color: theme.text.primary,
      },
      typographyPrimary: {
        color: theme.text.primary,
        padding: "5px 0",
        fontSize: 16,
      },
      button: {
        margin: "20px 0 40px 0",
        padding: "13px 0",
        width: 250,
        backgroundColor: "#FFAA3A",
        borderColor: "#FFAA3A",
        borderRadius: 2,
        textTransform: "none",
        fontSize: 18,
        fontWeight: 700,
        "&:hover": {
          color: theme.text.primary,
        },
        [theme.breakpoints.down("xs")]: {
          width: "90%",
        },
      },
      image: {
        [theme.breakpoints.down("xs")]: {
          width: "90%",
        },
      },
    }),
  { index: 1 }
);

function PaperBuyAgencyTradeRights(props) {
  const classes = useStyles(props);
  const { handleOpenBuyAgentRights } = props;
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <div className={classes.rootBody}>
        <Typography variant="h5" className={classes.title}>
          {t("You need to buy Agency license to receive trading commissions")}
        </Typography>
        <Button
          className={classes.button}
          variant="outlined"
          onClick={() => {
            handleOpenBuyAgentRights();
          }}
        >
          {t("Buy agency license ")}
        </Button>
      </div>
      <div>
        <img
          src={`${BackgroundAgent}`}
          alt="BackgroundAgent"
          className={classes.image}
        ></img>
      </div>
    </div>
  );
}

PaperBuyAgencyTradeRights.propTypes = {
  handleOpenBuyAgentRights: PropTypes.func,
};

export default PaperBuyAgencyTradeRights;
