import {
  createStyles,
  makeStyles,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        paddingLeft: 15,
        width: "100%",
        [theme.breakpoints.down("xs")]: { marginTop: 30 },
      },
      typographyLabel: {
        color: theme.text.secondary,
      },
      tabs: { borderBottom: "2px solid #9e9e9e" },
      tab: {
        padding: 0,
        marginRight: 10,
        textTransform: "none",
        fontWeight: "bold",
        // color: "#848E9C",
        // fontFamily: [
        //   "-apple-system",
        //   "BlinkMacSystemFont",
        //   '"Segoe UI"',
        //   "Roboto",
        //   '"Helvetica Neue"',
        //   "Arial",
        //   "sans-serif",
        //   '"Apple Color Emoji"',
        //   '"Segoe UI Emoji"',
        //   '"Segoe UI Symbol"',
        // ].join(","),
        minWidth: 70,
        "&:hover": {
          color: "#fff",
          opacity: 1,
        },
        "&:focus": {
          color: "#fff",
        },
      },
    }),
  { index: 1 }
);

export default function Network(props) {
  const classes = useStyles(props);
  const {
    children,
    transactionType,
    handleChangeTab,
    valueTab,
    walletSelected,
  } = props;

  const { t } = useTranslation();

  const renderPropsTabDeposit = (network) => {
    switch (true) {
      case network.includes("TRC20"): {
        return t("TRC20");
      }
      case network.includes("BEP20"): {
        return t("BEP20");
      }
      default:
        return "";
    }
  };

  const renderPropsTabWithdraw = (network) => {
    switch (true) {
      case network.includes("TRC20"): {
        return t("TRC20");
      }
      case network.includes("BEP20"): {
        return t("BEP20");
      }
      default:
        return "";
    }
  };

  const renderTabs = () => {
    switch (transactionType) {
      case t("Deposit"): {
        return (
          <Tabs
            className={classes.tabs}
            value={valueTab}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="inherit"
          >
            {walletSelected.all_address?.map((address) => {
              return (
                <Tab
                  key={address.id}
                  label={renderPropsTabDeposit(address.network)}
                  value={renderPropsTabDeposit(address.network)}
                  className={classes.tab}
                  disableRipple={true}
                />
              );
            })}
          </Tabs>
        );
      }
      case t("Withdraw"): {
        return (
          <Tabs
            className={classes.tabs}
            value={valueTab}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="inherit"
          >
            <Tab
              label={t("Internal")}
              value={t("Internal")}
              className={classes.tab}
              disableRipple={true}
            />
            {walletSelected.c?.networks?.map((address, index) => {
              return (
                <Tab
                  key={index}
                  label={renderPropsTabWithdraw(address)}
                  value={renderPropsTabWithdraw(address)}
                  className={classes.tab}
                  disableRipple={true}
                />
              );
            })}

            {/* <Tab
              label={t("TRC20")}
              value={t("TRC20")}
              className={classes.tab}
              disableRipple={true}
            /> */}
            {/* <Tab
              label={t("BEP20")}
              value={t("BEP20")}
              className={classes.tab}
              disableRipple={true}
            /> */}
          </Tabs>
        );
      }
      default:
        return null;
    }
  };

  return (
    <div className={classes.root}>
      <Typography variant="body2" className={classes.typographyLabel}>
        {t("Network")}
      </Typography>
      {renderTabs()}
      {children}
    </div>
  );
}
