import {
  Button,
  Grid,
  makeStyles,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import BGBuyTicket from "../../../assets/img/BGBuyTicket.png";
import convertNumberToMoney from "../../../common/convertNumberToMoney";
import SelectTypeCurrency from "../../Select/SelectTypeCurrency";

const useStyles = makeStyles(
  (theme) => ({
    root: { margin: "40px 0" },
    ticket: {
      width: 610,
      height: 280,
      padding: 10,
      margin: "auto",
      backgroundImage: `url(${BGBuyTicket})`,
      backgroundRepeat: "no-repeat",
      //   backgroundSize: "auto",
      backgroundPosition: "center",
      backgroundSize: "cover",
      [theme.breakpoints.down("xs")]: {
        backgroundImage: "none",
        width: "100%",
        padding: 0,
      },
    },
    gridRoot: {
      width: "95%",
      margin: "auto",
      justifyContent: "center",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    textbold: {
      fontWeight: 700,
      color: theme.text.primary,
      fontFamily: "'Chakra Petch', sans-serif",
    },
    coverButtons: {
      marginTop: 30,
      [theme.breakpoints.down("xs")]: {
        marginTop: 10,
        display: "flex",
      },
    },
    button: {
      textTransform: "none",
      fontSize: 16,
      fontWeight: 700,
      width: "35%",
      margin: 10,
      fontFamily: "'Chakra Petch', sans-serif",
      borderRadius: theme.radius.secondary,
      [theme.breakpoints.down("xs")]: {
        width: "50%",
      },
    },
    coverSetQuantity: {
      display: "flex",
      alignItems: "center",
    },
    btnMinus: {
      height: 40,
      borderColor: `#989898 !important`,
      borderRadius: 0,
      borderTopLeftRadius: theme.radius.secondary,
      borderBottomLeftRadius: theme.radius.secondary,
      color: theme.text.primary,
    },
    btnPlus: {
      height: 40,
      borderColor: `#989898 !important`,
      borderRadius: 0,
      borderTopRightRadius: theme.radius.secondary,
      borderBottomRightRadius: theme.radius.secondary,
      color: theme.text.primary,
    },
    textField: {},
    cssOutlinedInput: {
      height: 40,
      "&$cssFocused $notchedOutline": {
        borderColor: `#989898 !important`,
      },
    },
    notchedOutline: {
      borderColor: `#989898 !important`,
      borderRadius: "0 !important",
    },
    multilineColor: {
      fontSize: 20,
      fontWeight: 700,
    },
    input: {
      padding: "6px 14px",
      textAlign: "center",
      fontFamily: "'Chakra Petch', sans-serif",
      color: theme.text.primary,
      "&::placeholder": {
        opacity: 0.2,
      },
    },
  }),
  { index: 1 }
);

export default function BoxBuyTicket(props) {
  const classes = useStyles();
  const {
    walletsInfo,
    walletSelected,
    handleSelectWallet,
    handleCloseDialog,
    handleSetQuantity,
  } = props;
  const [quantity, setQuantity] = useState(1);
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <div className={classes.root}>
      <div className={classes.ticket}>
        <Typography variant="h6" classes={{ root: classes.textbold }}>
          {t("Each ticket purchase worth")}{" "}
          {convertNumberToMoney(5 / parseFloat(walletSelected?.c?.usdt_rate))}{" "}
          ALA
        </Typography>
        <div>
          <Grid container spacing={2} classes={{ root: classes.gridRoot }}>
            <Grid item sm={4} xs={4}>
              <Typography
                variant="body2"
                classes={{ root: classes.textbold }}
                style={{ paddingTop: 15 }}
              >
                {t("Currency type")}:
              </Typography>
            </Grid>
            <Grid item sm={8} xs={8}>
              <SelectTypeCurrency
                disabled
                handleSelectWallet={handleSelectWallet}
                walletSelected={walletSelected}
                walletsInfo={walletsInfo}
              />
              <Grid container spacing={1}>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <Typography
                    variant="body2"
                    classes={{ root: classes.textbold }}
                  >
                    {t("Total balance")}:{" "}
                    {convertNumberToMoney(parseFloat(walletSelected?.balance))}
                  </Typography>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <Typography
                    variant="body2"
                    classes={{ root: classes.textbold }}
                  >
                    {t("Available balances")}:{" "}
                    {convertNumberToMoney(
                      parseFloat(walletSelected?.estimate_usdt)
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={4} xs={4}>
              <Typography variant="body2" classes={{ root: classes.textbold }}>
                {t("Quantity")}:
              </Typography>
            </Grid>
            <Grid
              item
              sm={8}
              xs={8}
              classes={{ root: classes.coverSetQuantity }}
            >
              <Button
                variant="outlined"
                classes={{ root: classes.btnMinus }}
                onClick={() => {
                  if (quantity > 0) setQuantity(quantity - 1);
                }}
              >
                -
              </Button>
              <TextField
                classes={{ root: classes.textField }}
                fullWidth
                variant="outlined"
                size="small"
                type="number"
                value={quantity}
                id="groupName"
                name="otp_token"
                onChange={(e) => {
                  setQuantity(parseFloat(e.target.value));
                }}
                InputLabelProps={{ shrink: false }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                  className: classes.multilineColor,
                }}
              />
              <Button
                variant="outlined"
                classes={{ root: classes.btnPlus }}
                onClick={() => {
                  setQuantity(quantity + 1);
                }}
              >
                +
              </Button>
            </Grid>
            <Grid item sm={4} xs={4}></Grid>
            <Grid
              item
              sm={8}
              xs={8}
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              <Typography variant="body2" classes={{ root: classes.textbold }}>
                {t("Amount to pay")}:{" "}
                {convertNumberToMoney(
                  (5 / parseFloat(walletSelected?.c?.usdt_rate)) * quantity
                )}{" "}
                ALA
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={classes.coverButtons}>
        <Button
          variant="outlined"
          classes={{ root: classes.button }}
          style={{
            color: theme.text.secondary,
            borderColor: theme.text.secondary,
          }}
          onClick={handleCloseDialog}
        >
          {t("Cancel")}
        </Button>
        <Button
          variant="outlined"
          classes={{ root: classes.button }}
          style={{
            color: theme.color.primary,
            borderColor: theme.color.primary,
          }}
          onClick={() => {
            handleSetQuantity(quantity);
          }}
        >
          {t("Confirm purchase")}
        </Button>
      </div>
    </div>
  );
}
