import { cloneDeep } from "lodash";
import * as types from "../../const/redux/wallet";

let initialState = {
  info: {},
  transaction: {},
  loading: false,
  demo: {},
  account: "real",
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USER_WALLET_SUCCESS:
    case types.RE_GET_USER_WALLET_SUCCESS: {
      let newState = cloneDeep(state);
      newState.info = cloneDeep(action.payload.data);
      return newState;
    }
    case types.GET_MAIN_TRASACTION_SUCCESS:
    case types.GET_CONVERT_TRASACTION_SUCCESS: {
      let newState = cloneDeep(state);
      newState.transaction = cloneDeep(action.payload.data);
      return newState;
    }
    case types.WITHDRAW_WALLET_SUCCESS: {
      let newState = cloneDeep(action.payload.data);
      return newState;
    }
    case types.DESTROY_WALLET: {
      let newState = cloneDeep(initialState);
      return newState;
    }
    case types.SET_LOADING_WALLET: {
      let newState = cloneDeep(state);
      newState.loading = cloneDeep(action.payload.data);
      return newState;
    }
    case types.GET_DEMO_WALLET_SUCCESS: {
      let newState = cloneDeep(state);
      newState.demo = cloneDeep(action.payload.data);
      return newState;
    }
    case types.SET_ACCOUNT: {
      let newState = cloneDeep(state);
      newState.account = cloneDeep(action.payload.data);
      return newState;
    }
    default:
      return state;
  }
};

export default userReducer;
