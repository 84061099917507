import {
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Pagination, PaginationItem } from "@material-ui/lab";
import BigNumber from "bignumber.js";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: { margin: "20px 0" },
      table: {
        border: `1px solid ${theme.color.border}`,
        borderBottom: "none",
      },
      tableCellHeader: {
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        border: "none",
        color: theme.text.secondary,
      },
      tableCellBody: {
        borderBottom: "1px solid #424242",
        color: "#fff",
      },
      rootPagination: {
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: 40,
      },
      pagination: {
        color: theme.text.primary,
      },
      rootPaginationItem: {
        border: `1px solid ${theme.text.primary}`,
        color: theme.text.primary,
        borderRadius: 2,
      },
      ellipsisPagination: { border: "none" },
      pageSelected: {
        backgroundColor: "hsla(0, 0%, 100%, 0.1) !important",
        border: `1px solid ${theme.color.border}`,
      },
      coverOrderValue: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      typographySide: {
        textTransform: "uppercase",
        fontWeight: 500,
      },
      typographySell: {
        color: theme.color.trendingDown,
      },
      typographyBuy: { color: theme.color.trendingUp },
    }),
  { index: 1 }
);

function createData(symbol, exchange, time, side, price, realizedProfit) {
  return { symbol, exchange, time, side, price, realizedProfit };
}

function TableTradeHistory(props) {
  const classes = useStyles(props);
  const { tradeHistory, pagination, handleChangePage } = props;
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);

  console.log(tradeHistory);

  const columns = [
    { id: "symbol", label: t("Symbol"), minWidth: 100 },
    { id: "exchange", label: t("Exchange"), minWidth: 100 },
    { id: "time", label: t("Time"), minWidth: 170 },
    {
      id: "side",
      label: t("Side"),
      minWidth: 100,
      align: "center",
    },
    {
      id: "price",
      label: t("Price"),
      minWidth: 150,
    },
    {
      id: "realizedProfit",
      label: t("Realized Profit"),
      minWidth: 150,
    },
  ];

  useEffect(() => {
    if (tradeHistory.results?.length > 0) {
      let rows = tradeHistory.results?.map((transaction) => {
        console.log(transaction);
        let symbol = transaction.pair;
        let exchange = transaction.exchange;
        let time = moment(transaction?.created).format("YYYY-MM-DD HH:mm:ss");
        let side = (
          <Typography
            variant="body2"
            classes={{
              root: classes.typographySide,
            }}
            className={
              transaction.action_type === "long"
                ? classes.typographyBuy
                : classes.typographySell
            }
          >
            {transaction.action_type}
          </Typography>
        );
        let price = `${new BigNumber(transaction?.price).toFormat(2)} USDT`;
        let realizedProfit = `${new BigNumber(transaction?.profit).toFormat(
          2
        )} USDT`;
        return createData(symbol, exchange, time, side, price, realizedProfit);
      });
      setRows(rows);
    }
  }, [tradeHistory]);

  return (
    <>
      <TableContainer className={classes.root}>
        <Table aria-label="sticky table" className={classes.table}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className={classes.tableCellHeader}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={classes.tableCellBody}
                      >
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.rootPagination}>
        <Pagination
          count={Math.ceil(tradeHistory?.count / pagination.page_size || 0)}
          page={pagination.page}
          onChange={(_, value) => {
            handleChangePage(value);
          }}
          variant="outlined"
          shape="rounded"
          classes={{ root: classes.pagination }}
          renderItem={(item) => (
            <PaginationItem
              classes={{
                root: classes.rootPaginationItem,
                ellipsis: classes.ellipsisPagination,
                selected: classes.pageSelected,
              }}
              {...item}
              variant="outlined"
            />
          )}
        />
      </div>
    </>
  );
}

TableTradeHistory.propTypes = {};

export default TableTradeHistory;
