import {
  Button,
  Card,
  CardContent,
  CardHeader,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Drawer,
  IconButton,
  makeStyles,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useFormik } from "formik";
import QRCode from "qrcode.react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import ContentCopy from "../../assets/img/ContentCopy.png";
import Eyeclose from "../../assets/img/Eyeclose.png";
// import QRCode from "../../assets/img/QRCode.png";
import Eyeopen from "../../assets/img/Eyeopen.png";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      rootDrawer: {
        backgroundColor: theme.body.backgroundColor,
        height: "100%",
      },
      rootCard: {
        backgroundColor: "rgba(255, 255, 255, 0.03)",
        height: "100%",
        boxShadow: "none",
      },
      rootCardHeader: {
        background: theme.color.background,
      },
      //   rootCardContent: { backgroundColor: "rgba(255, 255, 255, 0.03)" },
      rootPaper: { boxShadow: "none !important" },
      dialogHeader: {
        backgroundColor: "#191D23",
        color: "#fff",
        padding: "10px 20px",
      },
      closeButton: {
        position: "absolute",
        right: 2,
        top: 0,
        color: theme.text.primary,
      },
      dialogContent: {
        backgroundColor: "#191D23",
        padding: "20px 30px 30px 30px",
      },
      dividers: { borderTopColor: "#272C3A" },
      textField: {
        marginBottom: 15,
        backgroundColor: "#2A2D35",
      },
      cssOutlinedInput: {
        "&$cssFocused $notchedOutline": {
          borderColor: `${theme.text.primary} !important`,
        },
      },
      notchedOutline: {
        borderWidth: "1px",
        borderColor: "#2A2D35 !important",
        borderRadius: "2px !important",
      },
      multilineColor: {
        color: theme.text.primary,
        fontSize: 13,
      },
      input: {
        "&::placeholder": {
          opacity: 0.2,
        },
      },
      typographySecondary: {
        color: theme.text.secondary,
      },
      typographyPrimary: {
        color: theme.text.primary,
      },
      typographyValue: { paddingLeft: 20 },
      buttonSubmit: {
        width: "100%",
        color: theme.text.primary,
        backgroundColor: theme.color.primary,
        textTransform: "none",
        borderRadius: 2,
        marginTop: 10,
      },
      imgContentCopy: { marginLeft: 10 },
      backupCode: {
        marginBottom: 10,
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
      },
      coverCode: {
        display: "flex",
        alignItems: "center",
        marginLeft: 10,
        cursor: "pointer",
      },
      coverPasteCode: { width: 125, cursor: "pointer", textAlign: "right" },
      endAdornment: {
        color: theme.text.link,
      },
    }),
  { index: 1 }
);

const initialValues = {
  otp_token: "",
  password: "",
};

const validationSchema = Yup.object().shape({
  otp_token: Yup.string().required("Không được bỏ trống"),
  password: Yup.string().required("Không được bỏ trống"),
});

export default function DialogVerify2FA(props) {
  const classes = useStyles(props);
  const {
    open,
    handleClose,
    dataEnable2Fa,
    handleVerifyTwoFaCode,
    enable2FA,
    handleDisable2fa,
  } = props;
  const { t } = useTranslation();
  const [showPsw, setShowPse] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  const onSubmit = (values) => {
    if (enable2FA) handleDisable2fa(values);
    else handleVerifyTwoFaCode(values);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => onSubmit(values),
  });

  const handleHidePsw = () => {
    setShowPse(false);
  };

  const handleShowPsw = () => {
    setShowPse(true);
  };

  const handleCopeCode = (qrCode) => {
    navigator.clipboard.writeText(qrCode);
  };

  const handlePasteCode = () => {
    navigator.clipboard
      .readText()
      .then((text) => {
        formik.setFieldValue("otp_token", text);
      })
      .catch();
  };

  const renderContent = () => {
    return (
      <>
        {!enable2FA && (
          <>
            <Typography variant="caption" className={classes.typographyPrimary}>
              {t(
                `Open your Google Authenticator app and scan the QR code below or enter the backup code into the app. This code is used to restore your 'Google Authentication' when you change or lose your phone. Please back up this key before activating 'Google Authentication'.`
              )}
            </Typography>
            <div style={{ textAlign: "center", margin: "24px 0 5px 0" }}>
              {/* <img src={`${QRCode}`} alt="QRCode"></img> */}
              <div>
                <QRCode
                  id="qrcode"
                  value={dataEnable2Fa?.format_otp_token || ""}
                  size={150}
                  level={"H"}
                  includeMargin={true}
                />
              </div>
            </div>
            <div className={classes.backupCode}>
              <div>
                <Typography
                  variant="caption"
                  className={classes.typographySecondary}
                >
                  {t("Backup code")}:{" "}
                </Typography>
              </div>
              <div
                className={classes.coverCode}
                onClick={() => {
                  handleCopeCode(dataEnable2Fa?.otp_token || "");
                }}
              >
                <Typography
                  variant="caption"
                  className={classes.typographyPrimary}
                >
                  {dataEnable2Fa?.otp_token || ""}
                  <img
                    src={`${ContentCopy}`}
                    alt="ContentCopy"
                    className={classes.imgContentCopy}
                  ></img>
                </Typography>
              </div>
            </div>
          </>
        )}
        <form onSubmit={formik.handleSubmit}>
          <div>
            <Typography
              variant="caption"
              className={classes.typographySecondary}
            >
              {t("Password")}
            </Typography>
            <TextField
              className={classes.textField}
              fullWidth
              variant="outlined"
              id="groupName"
              name="password"
              type={!showPsw && "password"}
              InputLabelProps={{ shrink: false }}
              InputProps={{
                endAdornment: (
                  <>
                    {showPsw ? (
                      <img
                        src={`${Eyeclose}`}
                        alt="Eyeclose"
                        style={{ cursor: "pointer" }}
                        onClick={handleHidePsw}
                      ></img>
                    ) : (
                      <img
                        src={`${Eyeopen}`}
                        alt="Eyeopen"
                        style={{ cursor: "pointer" }}
                        onClick={handleShowPsw}
                      ></img>
                    )}
                  </>
                ),
                classes: {
                  root: classes.cssOutlinedInput,
                  notchedOutline: classes.notchedOutline,
                  input: classes.input,
                },
                className: classes.multilineColor,
              }}
              size="small"
              onChange={formik.handleChange}
              //   onBlur={formik.handleBlur}
              value={formik.values.password}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </div>
          <div>
            <Typography
              variant="caption"
              className={classes.typographySecondary}
            >
              {t("2FA Code")}
            </Typography>
            <TextField
              className={classes.textField}
              fullWidth
              variant="outlined"
              id="groupName"
              name="otp_token"
              InputLabelProps={{ shrink: false }}
              InputProps={{
                endAdornment: (
                  <div
                    className={classes.coverPasteCode}
                    onClick={handlePasteCode}
                  >
                    <Typography
                      variant="caption"
                      className={classes.endAdornment}
                    >
                      {t("Paste the code")}
                    </Typography>
                  </div>
                ),
                classes: {
                  root: classes.cssOutlinedInput,
                  notchedOutline: classes.notchedOutline,
                  input: classes.input,
                },
                className: classes.multilineColor,
              }}
              size="small"
              placeholder={`${t(
                "Enter the 2FA code taken from the application"
              )}`}
              onChange={formik.handleChange}
              //   onBlur={formik.handleBlur}
              value={formik.values.otp_token}
              error={
                formik.touched.otp_token && Boolean(formik.errors.otp_token)
              }
              helperText={formik.touched.otp_token && formik.errors.otp_token}
            />
          </div>
          <div>
            <Button className={classes.buttonSubmit} type="submit">
              {enable2FA
                ? t("Disable 2FA authentication")
                : t("Enable 2FA authentication")}
            </Button>
          </div>
        </form>
      </>
    );
  };

  return (
    <>
      {matches ? (
        <Drawer
          anchor={"bottom"}
          open={open}
          onClose={handleClose}
          classes={{ paper: classes.rootDrawer }}
        >
          <Card classes={{ root: classes.rootCard }}>
            <CardHeader
              classes={{ root: classes.rootCardHeader }}
              title={
                <>
                  <Typography
                    variant="body1"
                    className={classes.typographyPrimary}
                  >
                    {t("Verify 2FA")}
                  </Typography>
                </>
              }
              action={
                <IconButton
                  className={classes.closeButton}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>
              }
            />
            <CardContent classes={{ root: classes.rootCardContent }}>
              {renderContent()}
            </CardContent>
          </Card>
        </Drawer>
      ) : (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          classes={{ paper: classes.rootPaper }}
        >
          <DialogTitle id="alert-dialog-title" className={classes.dialogHeader}>
            <Typography variant="body1">{t("Verify 2FA")}</Typography>
            <IconButton className={classes.closeButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            className={classes.dialogContent}
            dividers={true}
            classes={{ dividers: classes.dividers }}
          >
            {renderContent()}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

DialogVerify2FA.propTypes = {};
