import {
  Button,
  Card,
  CardContent,
  CardHeader,
  createStyles,
  IconButton,
  makeStyles,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Switch from "react-switch";
import FormUpdateAddress from "../Form/FormUpdateAddress";
import * as Yup from "yup";
import { useFormik } from "formik";
import DoneIcon from "@material-ui/icons/Done";
import CreateIcon from "@material-ui/icons/Create";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        width: 464,
        // height: 170,
        margin: "15px 0 0 15px",
        [theme.breakpoints.down("xs")]: { maxWidth: 328, margin: 0 },
      },
      card: {
        backgroundColor: "rgba(255, 255, 255, 0.03)",
        color: theme.text.primary,
      },
      cardHeader: { paddingBottom: 10 },
      cardContent: { paddingTop: 0, minWidth: 0 },
      typographySecondary: { color: theme.text.secondary, display: "block" },
      coverButton: {
        [theme.breakpoints.down("xs")]: {
          display: "flex",
          justifyContent: "flex-end",
        },
      },
      buttonAction: {
        color: "#C4C4C4",
        borderColor: "#C4C4C4",
        marginRight: 10,
        borderRadius: 2,
        textTransform: "none",
        width: 120,
        [theme.breakpoints.down("xs")]: {
          marginRight: 5,
        },
      },
      typographyOff: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        color: theme.text.primary,
      },
      typographyOn: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        color: theme.text.primary,
      },
      coverContent: {
        width: "50%",
        display: "flex",
        // justifyContent: "space-between",
        alignItems: "center",
      },
      iconButton: {
        color: theme.color.light,
      },
      cssOutlinedInput: {
        "&$cssFocused $notchedOutline": {
          borderColor: `${theme.text.primary} !important`,
        },
      },
      notchedOutline: {
        borderWidth: "1px",
        borderColor: `green !important`,
      },
      multilineColor: {
        color: theme.text.primary,
        fontSize: 13,
      },
      input: {
        "&::placeholder": {
          opacity: 0.2,
        },
      },
      label: {
        color: theme.text.secondary,
      },
    }),
  { index: 1 }
);

export default function CardWithdrawAutomation(props) {
  const classes = useStyles(props);
  const { handleAutoWithdraw, user, handleUpdateAutoAddress } = props;
  const [updateAddress, setUpdateAddress] = useState(false);
  const [initialValues, setInitialValues] = useState({
    address: "",
    twoFa: "",
  });
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const wrapperRef = useRef(null);

  const validationSchema = Yup.object().shape({
    // address: Yup.string().required(t("Not be empty")),
  });

  const onSubmit = (values) => {};

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => onSubmit(values),
  });

  const handleUpdateAddress = () => {
    let address = formik.getFieldProps("address").value;
    let twoFa = formik.getFieldProps("twoFa").value;
    let value = {
      address,
      twoFa,
    };
    handleUpdateAutoAddress(user?.id, value);
    setUpdateAddress(!updateAddress);
  };

  const handleChangeSwitch = (checked) => {
    handleAutoWithdraw(user?.id, checked);
  };

  const handleOpenUpdateAddress = () => {
    setUpdateAddress(!updateAddress);
  };

  const renderSwitchAction = () => {
    return (
      <Switch
        onChange={handleChangeSwitch}
        checked={user?.is_enable_auto_withdraw}
        offColor="#272C3A"
        onColor="#2F86FA"
        width={70}
        uncheckedIcon={
          <Typography variant="body2" classes={{ root: classes.typographyOff }}>
            {t("Off")}
          </Typography>
        }
        checkedIcon={
          <Typography variant="body2" classes={{ root: classes.typographyOn }}>
            {t("On")}
          </Typography>
        }
      />
    );
  };

  useEffect(() => {
    if (updateAddress) {
      formik.setFieldValue("address", user?.auto_withdraw_address || 0);
    }
  }, [updateAddress]);

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          title={
            <Typography variant="body1">{t("Withdraw automation")}</Typography>
          }
          action={renderSwitchAction()}
        />
        <CardContent className={classes.cardContent}>
          <form onSubmit={formik.handleSubmit}>
            <div className={classes.coverContent} ref={wrapperRef}>
              {updateAddress ? (
                <>
                  <FormUpdateAddress
                    handleUpdateAddress={handleOpenUpdateAddress}
                    wrapperRef={wrapperRef}
                    formik={formik}
                  />
                </>
              ) : (
                <Typography
                  variant="body2"
                  className={classes.typographySecondary}
                >
                  {user?.auto_withdraw_address}
                </Typography>
              )}

              {updateAddress ? (
                <IconButton
                  classes={{ root: classes.iconButton }}
                  //   type="submit"
                  onClick={() => {
                    handleUpdateAddress();
                  }}
                >
                  <DoneIcon />
                </IconButton>
              ) : (
                <IconButton
                  classes={{ root: classes.iconButton }}
                  onClick={() => {
                    handleOpenUpdateAddress();
                  }}
                >
                  <CreateIcon />
                </IconButton>
              )}
            </div>
          </form>

          {/* {matches && renderButtonAction()} */}
        </CardContent>
      </Card>
    </div>
  );
}

CardWithdrawAutomation.propTypes = { handleActionClick: PropTypes.func };
