import * as types from "../../const/redux/transaction";

export const getCandleStickReq = () => {
  return {
    type: types.GET_CANDLE_STICK_REQ,
  };
};

export const getCandleStickSuccess = (data) => {
  return {
    type: types.GET_CANDLE_STICK_SUCCESS,
    payload: {
      data,
    },
  };
};

export const getListOrderReq = (params) => {
  return {
    type: types.GET_LIST_ORDER_REQ,
    payload: {
      params,
    },
  };
};

export const getListOrderSuccess = (data) => {
  return {
    type: types.GET_LIST_ORDER_SUCCESS,
    payload: {
      data,
    },
  };
};

export const getDemoListOrderReq = (params) => {
  return {
    type: types.GET_DEMO_LIST_ORDER_REQ,
    payload: {
      params,
    },
  };
};

export const getDemoListOrderSuccess = (data) => {
  return {
    type: types.GET_DEMO_LIST_ORDER_SUCCESS,
    payload: {
      data,
    },
  };
};

export const setLoadingOrder = (data) => {
  return {
    type: types.SET_LOADING_ORDER,
    payload: {
      data,
    },
  };
};
