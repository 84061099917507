import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router";
import { verifyRegistration } from "../../apis/user.api";
import BodyVerifyRegistration from "../../components/Body/BodyVerifyRegistration/BodyVerifyRegistration";
import TopBarHandleAccount from "../../components/TopBar/TopBarHandleAccount";

export default function VerifyRegistration() {
  const match = useRouteMatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    let { otp } = match.params;
    let dataReq = {
      otp,
    };
    // let register_token = localStorage.getItem("register_token");
    // if (register_token) {
    verifyRegistration(dataReq)
      .then((res) => {
        // enqueueSnackbar(t("Verify account success"), { variant: "success" });
        setMessage(t("Verify account success"));
        localStorage.removeItem("register_token");
        setStatus("success");
      })
      .catch((err) => {
        if (err.response) {
          const { data } = err.response;
          //   enqueueSnackbar(data.message, { variant: "error" });
          setMessage(data.message);
          setStatus("failed");
        }
      });
    // }
  }, []);

  return (
    <div>
      <TopBarHandleAccount />
      <BodyVerifyRegistration status={status} message={message} />
    </div>
  );
}
