import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Pagination, PaginationItem } from "@material-ui/lab";
import moment from "moment";
import convertNumberToCurrency from "../../common/convertNumberToCurrency";
import { useTranslation } from "react-i18next";
import TrendingUp from "../../assets/img/TrendingUp.png";
import TrendingDown from "../../assets/img/TrendingDown.png";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: { margin: "20px 0" },
      table: {
        border: `1px solid ${theme.color.border}`,
        borderBottom: "none",
      },
      tableCellHeader: {
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        border: "none",
        color: theme.text.secondary,
      },
      tableCellBody: {
        borderBottom: "1px solid #424242",
        color: "#fff",
      },
      rootPagination: {
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: 40,
      },
      pagination: {
        color: theme.text.primary,
      },
      rootPaginationItem: {
        border: `1px solid ${theme.text.primary}`,
        color: theme.text.primary,
        borderRadius: 2,
      },
      ellipsisPagination: { border: "none" },
      pageSelected: {
        backgroundColor: "hsla(0, 0%, 100%, 0.1) !important",
        border: `1px solid ${theme.color.border}`,
      },
      coverOrderValue: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      typographySell: {
        marginLeft: 5,
        color: theme.color.trendingDown,
      },
      typographyBuy: { marginLeft: 5, color: theme.color.trendingUp },
    }),
  { index: 1 }
);

function createData(time, market, order, value, profitloss) {
  return { time, market, order, value, profitloss };
}

function TableBotRecentOrder(props) {
  const classes = useStyles(props);
  const {
    transactionTradingBot,
    paginationTransaction,
    handleChangePage,
  } = props;
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);

  const columns = [
    { id: "time", label: t("Time"), minWidth: 170 },
    { id: "market", label: t("Market"), minWidth: 100 },
    {
      id: "order",
      label: t("Order"),
      minWidth: 170,
      align: "center",
    },
    {
      id: "value",
      label: t("Value"),
      minWidth: 170,
    },
    {
      id: "profitloss",
      label: t("Profit/Loss"),
      minWidth: 170,
    },
  ];

  useEffect(() => {
    if (transactionTradingBot.results?.length > 0) {
      let rows = transactionTradingBot.results?.map((transaction) => {
        let time = moment(transaction?.timestamp).format("YYYY-MM-DD HH:mm:ss");
        let market = "BTC/USD";
        let order = null;
        if (transaction?.action_type === "down")
          order = (
            <div className={classes.coverOrderValue}>
              <img src={TrendingDown} alt="TrendingDown" />
              <Typography
                variant="body2"
                classes={{ root: classes.typographySell }}
              >
                {t("Sell")}
              </Typography>
            </div>
          );
        else
          order = (
            <div className={classes.coverOrderValue}>
              <img src={TrendingUp} alt="TrendingUp" />
              <Typography
                variant="body2"
                classes={{ root: classes.typographyBuy }}
              >
                {t("Buy")}
              </Typography>
            </div>
          );
        let value = convertNumberToCurrency(transaction?.amount);
        let profitloss = null;
        if (transaction?.result === "lose") {
          profitloss = (
            <Typography
              variant="body2"
              classes={{ root: classes.typographySell }}
            >
              {convertNumberToCurrency(transaction?.result_amount)}
            </Typography>
          );
        } else {
          profitloss = (
            <Typography
              variant="body2"
              classes={{ root: classes.typographyBuy }}
            >
              +{convertNumberToCurrency(transaction?.result_amount)}
            </Typography>
          );
        }
        return createData(time, market, order, value, profitloss);
      });
      setRows(rows);
    }
  }, [transactionTradingBot]);

  return (
    <>
      <TableContainer className={classes.root}>
        <Table aria-label="sticky table" className={classes.table}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className={classes.tableCellHeader}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={classes.tableCellBody}
                      >
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.rootPagination}>
        <Pagination
          count={Math.ceil(
            transactionTradingBot?.count / paginationTransaction.page_size || 0
          )}
          page={paginationTransaction.page}
          onChange={(_, value) => {
            handleChangePage(value);
          }}
          variant="outlined"
          shape="rounded"
          classes={{ root: classes.pagination }}
          renderItem={(item) => (
            <PaginationItem
              classes={{
                root: classes.rootPaginationItem,
                ellipsis: classes.ellipsisPagination,
                selected: classes.pageSelected,
              }}
              {...item}
              variant="outlined"
            />
          )}
        />
      </div>
    </>
  );
}

TableBotRecentOrder.propTypes = {};

export default TableBotRecentOrder;
