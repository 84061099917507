import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReferralDetailReq } from "../../redux/actions/referralAction";
import AgencyInfo from "./AgencyInfo";
import AgencyRights from "./AgencyRights";

export default function Agency() {
  const dispatch = useDispatch();
  const referralInfo = useSelector((state) => state.referralReducer.info);

  useEffect(() => {
    dispatch(getReferralDetailReq());

    // return () => {
    //   dispatch(destroyReferral());
    //   dispatch(destroyWallet());
    // };
  }, []);

  return (
    <>
      {Object.keys(referralInfo).length > 0 && (
        <>{!!referralInfo.ref_code ? <AgencyInfo /> : <AgencyRights />}</>
      )}
    </>
  );
}
