import {
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import BoxBingoStar from "../../components/Box/BoxBingoStar";
import BingoStar from "../../assets/img/BingoStar.png";
import { useTranslation } from "react-i18next";
import BingoStarContainer from "./BingoStarContainer";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getUserWalletReq } from "../../redux/actions/walletAction";

const useStyles = makeStyles(
  (theme) => ({
    root: { width: "100%" },
    header: {
      padding: 15,
      backgroundColor: theme.color.background,
      marginLeft: theme.marginLayout,
    },
  }),
  { index: 1 }
);

export default function Lottery() {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  useEffect(() => {
    dispatch(getUserWalletReq());
  }, []);

  return (
    <div className={classes.root}>
      {!matches && (
        <div className={classes.header}>
          <Typography variant="h6">{t("Lucky game")}</Typography>
        </div>
      )}
      {/* <div className={classes.root}>
        <img src={CommingSoon2} alt="CommingSoon2" className={classes.img} />
      </div> */}
      <div>
        {/* <BoxBingoStar
          img={BingoStar}
          name={"Bingo Stars"}
          intro={`${t(
            "Winning lottery game, each game requires a ticket. Tickets will be earned through each transaction."
          )}`}
        /> */}
        <BingoStarContainer />
      </div>
    </div>
  );
}
