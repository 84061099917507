import { createStyles, makeStyles, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { optionsLanguage } from "../../const/common";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      autocompleteRoot: {
        width: 150,
        padding: 0,
      },
      inputRoot: {
        padding: "0px 9px !important",
        color: theme.text.primary,
        border: `1px solid  ${theme.text.secondary}`,
        borderRadius: 2,
      },
      popupIndicator: {
        color: theme.text.primary,
      },
    }),
  { index: 1 }
);

export default function SelectLanguage() {
  const classes = useStyles();
  const [value, setValue] = useState("en");
  const { i18n } = useTranslation();

  const handleChangeLanguage = (language) => {
    setValue(language);
    Cookies.set("language", language, { expires: 1 });
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    let language = Cookies.get("language");
    if (language) {
      setValue(language);
    }
  }, []);

  return (
    <>
      <Autocomplete
        classes={{
          inputRoot: classes.inputRoot,
          root: classes.autocompleteRoot,
          popupIndicator: classes.popupIndicator,
        }}
        id="combo-box-demo"
        options={optionsLanguage.map((option) => option.id)}
        getOptionLabel={(optionId) =>
          optionsLanguage.filter((option) => option.id === optionId)[0]?.title
        }
        onChange={(_, value) => {
          handleChangeLanguage(value);
        }}
        value={value}
        disableClearable={true}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
      />
    </>
  );
}
