import * as types from "../../const/redux/wallet";

export const getUserWalletReq = () => {
  return {
    type: types.GET_USER_WALLET_REQ,
  };
};

export const getUserWalletSuccess = (data) => {
  return {
    type: types.GET_USER_WALLET_SUCCESS,
    payload: { data },
  };
};

export const getMainTransactionReq = (params) => {
  return {
    type: types.GET_MAIN_TRASACTION_REQ,
    payload: { params },
  };
};

export const getMainTransactionSuccess = (data) => {
  return {
    type: types.GET_MAIN_TRASACTION_SUCCESS,
    payload: { data },
  };
};

export const withdrawWalletReq = (walletId, dataReq, paramsTransaction) => {
  return {
    type: types.WITHDRAW_WALLET_REQ,
    payload: { walletId, dataReq, paramsTransaction },
  };
};

export const withdrawWalletSuccess = (data) => {
  return {
    type: types.WITHDRAW_WALLET_SUCCESS,
    payload: { data },
  };
};

export const destroyWallet = () => {
  return {
    type: types.DESTROY_WALLET,
  };
};

export const setLoadingWallet = (data) => {
  return {
    type: types.SET_LOADING_WALLET,
    payload: {
      data,
    },
  };
};

export const getDemoWalletReq = () => {
  return {
    type: types.GET_DEMO_WALLET_REQ,
  };
};

export const getDemoWalletSuccess = (data) => {
  return {
    type: types.GET_DEMO_WALLET_SUCCESS,
    payload: { data },
  };
};

export const setAccount = (data) => {
  return {
    type: types.SET_ACCOUNT,
    payload: { data },
  };
};

export const reGetUserWalletReq = () => {
  return {
    type: types.RE_GET_USER_WALLET_REQ,
  };
};

export const reGetUserWalletSuccess = (data) => {
  return {
    type: types.RE_GET_USER_WALLET_SUCCESS,
    payload: { data },
  };
};

export const getConvertTransactionReq = (params) => {
  return {
    type: types.GET_CONVERT_TRASACTION_REQ,
    payload: { params },
  };
};

export const getConvertTransactionSuccess = (data) => {
  return {
    type: types.GET_CONVERT_TRASACTION_SUCCESS,
    payload: { data },
  };
};
