import {
  Button,
  createStyles,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Popover,
  TextField,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { useState } from "react";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        padding: "0 10px",
      },
      titleTop: { padding: "15px 0" },
      coverSearchTable: {
        display: "flex",
        alignItems: "flex-end",
        marginBottom: 20,
        [theme.breakpoints.down("xs")]: {
          display: "block",
        },
      },
      coverSelectTypeTable: {
        marginRight: 15,
        borderRight: `1px solid ${theme.color.disabled}`,
        [theme.breakpoints.down("xs")]: {
          borderRight: "none",
          marginBottom: 10,
        },
      },
      buttonContainedDefault: {
        textTransform: "none",
        margin: "5px 15px 5px 0",
        backgroundColor: `hsla(0, 0%, 100%, 0.3)`,
        color: theme.text.primary,
        marginBottom: 0,
      },
      buttonOutlined: {
        textTransform: "none",
        marginRight: 15,
        border: `1px solid ${theme.color.disabled}`,
        color: theme.text.primary,
        borderRadius: 3,
      },
      buttonFilterOutlined: {
        textTransform: "none",
        marginLeft: 15,
        border: `1px solid ${theme.color.disabled}`,
        color: theme.text.primary,
        borderRadius: 3,
        [theme.breakpoints.down("xs")]: {
          margin: "5px 10px 5px 0px",
        },
      },
      buttonPeriodSelected: {
        textTransform: "none",
        marginLeft: 15,
        backgroundColor: theme.color.primary,
        color: theme.text.primary,
        borderRadius: 3,
        [theme.breakpoints.down("xs")]: {
          margin: "5px 10px 5px 0px",
        },
      },
      buttonContainedPrimary: {
        textTransform: "none",
        marginRight: 15,
        color: theme.text.primary,
        backgroundColor: theme.color.primary,
        borderRadius: 1,
      },
      coverPaperBottom: {
        backgroundColor: theme.body.backgroundColor,
        padding: 10,
        minHeight: 300,
        borderRadius: 10,
        [theme.breakpoints.down("xs")]: {
          paddingLeft: 0,
        },
      },
      coverTitleBottom: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        [theme.breakpoints.down("xs")]: {
          display: "block",
        },
      },
      coverFilterTable: {
        display: "flex",
        alignItems: "flex-end",
        [theme.breakpoints.down("xs")]: {
          display: "block",
        },
      },
      coverSelectFilterTable: {
        display: "flex",
        alignItems: "flex-end",
        flexWrap: "wrap",
        // [theme.breakpoints.down("xs")]: {
        //   flexDirection: "column",
        //   alignItems: "flex-start",
        // },
      },
      list: { padding: 0 },
      listItem: {
        padding: "0 12px",
      },
      listItemSelected: {
        backgroundColor: "#e0e0e0 !important",
      },
      inputSearch: { marginRight: 10 },
      cssOutlinedInput: {
        "&$cssFocused $notchedOutline": {
          borderColor: `red !important`,
        },
      },
      notchedOutline: {
        borderWidth: "1px",
        borderColor: `${theme.color.disabled}`,
        borderRadius: "2px !important",
      },
      multilineColor: {
        color: theme.text.primary,
        fontSize: 13,
      },
      input: {
        padding: "8px 14px",
        "&::placeholder": {
          opacity: 0.2,
        },
      },
      cssLabelInput: {
        marginTop: -3,
        fontSize: 14,
        color: theme.text.secondary,
      },
    }),
  { index: 1 }
);

export default function PaperSystemMng(props) {
  const classes = useStyles();
  const {
    renderTableSystemMng,
    handleUpdateParamsBoUnderRef,
    paramsBoUnderRef,
  } = props;
  const { t } = useTranslation();
  const [levelSearch, setLevelSearch] = useState(100);
  const [networkLevelSearch, setNetworkLevelSearch] = useState(100);
  const [usernameSearch, setUsernameSearch] = useState("");
  const [openNetwork, setOpenNetwork] = useState(false);
  const [openAgency, setOpenAgency] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const levels = [
    { key: 100, value: `${t("All agency level")}` },
    { key: 0, value: `Lv0` },
    { key: 1, value: `Lv1` },
    { key: 2, value: `Lv2` },
    { key: 3, value: `Lv3` },
    { key: 4, value: `Lv4` },
    { key: 5, value: `Lv5` },
    { key: 6, value: `Lv6` },
    { key: 7, value: `Lv7` },
    { key: 8, value: `Lv8` },
    { key: 9, value: `Lv9` },
    { key: 10, value: `Lv10` },
  ];

  const networkLevels = [
    { key: 100, value: `${t("All network level")}` },
    { key: 1, value: `F1` },
    { key: 2, value: `F2` },
    { key: 3, value: `F3` },
    { key: 4, value: `F4` },
    { key: 5, value: `F5` },
    { key: 6, value: `F6` },
    { key: 7, value: `F7` },
    { key: 8, value: `F8` },
    { key: 9, value: `F9` },
    { key: 10, value: `F10` },
  ];

  const handleClick = (event, type) => {
    setAnchorEl(event.currentTarget);
    if (type === 0) {
      setOpenNetwork(true);
      setOpenAgency(false);
    } else {
      setOpenNetwork(false);
      setOpenAgency(true);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className={classes.root}>
      <div>
        <Typography variant="h6" classes={{ root: classes.titleTop }}>
          {t("Member Management")}
        </Typography>

        {/* <Typography variant="caption">{t("Search table")}</Typography> */}

        <div className={classes.coverSearchTable}>
          <div className={classes.coverSelectTypeTable}>
            <>
              <Button
                variant="contained"
                classes={{ root: classes.buttonContainedDefault }}
                size="small"
                onClick={(e) => {
                  handleClick(e, 1);
                }}
              >
                {
                  levels?.filter(
                    (levelFilter) => levelFilter.key === levelSearch
                  )[0]?.value
                }{" "}
                <ArrowDropDownIcon />
              </Button>
            </>
            <>
              <Button
                variant="contained"
                classes={{ root: classes.buttonContainedDefault }}
                size="small"
                onClick={(e) => {
                  handleClick(e, 0);
                }}
              >
                {
                  networkLevels?.filter(
                    (levelFilter) => levelFilter.key === networkLevelSearch
                  )[0]?.value
                }{" "}
                <ArrowDropDownIcon />
              </Button>
            </>

            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {openNetwork && (
                <List classes={{ root: classes.list }}>
                  {networkLevels.map((level) => (
                    <ListItem
                      button
                      selected={level.key === networkLevelSearch}
                      key={`${level.key}${level.value}`}
                      classes={{
                        root: classes.listItem,
                        selected: classes.listItemSelected,
                      }}
                      onClick={() => {
                        let params = {
                          network_level: level.key === 100 ? null : level.key,
                        };
                        handleUpdateParamsBoUnderRef(params);
                        setNetworkLevelSearch(level.key);
                        handleClose();
                      }}
                    >
                      <ListItemText primary={level.value} />
                    </ListItem>
                  ))}
                </List>
              )}
              {openAgency && (
                <List classes={{ root: classes.list }}>
                  {levels?.map((level) => (
                    <ListItem
                      button
                      selected={level.key === levelSearch}
                      key={`${level.key}${level.value}`}
                      classes={{
                        root: classes.listItem,
                        selected: classes.listItemSelected,
                      }}
                      onClick={() => {
                        let params = {
                          bo_level: level.key === 100 ? null : level.key,
                        };
                        handleUpdateParamsBoUnderRef(params);
                        setLevelSearch(level.key);
                        handleClose();
                      }}
                    >
                      <ListItemText primary={level.value} />
                    </ListItem>
                  ))}
                </List>
              )}
            </Popover>
          </div>
          <div style={{ display: "flex", alignItems: "flex-end" }}>
            {/* {renderTypeSearch()} */}
            <TextField
              variant="outlined"
              size="small"
              label={t("Nickname")}
              classes={{ root: classes.inputSearch }}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabelInput,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  notchedOutline: classes.notchedOutline,
                  input: classes.input,
                },
                className: classes.multilineColor,
              }}
              value={usernameSearch}
              onChange={(e) => {
                setUsernameSearch(e.target.value);
              }}
            />
            <Button
              variant="contained"
              classes={{ root: classes.buttonContainedPrimary }}
              size="small"
              onClick={() => {
                let params = {
                  username: usernameSearch,
                  //   bo_level: levelSearch === 100 ? null : levelSearch,
                };
                handleUpdateParamsBoUnderRef(params);
              }}
            >
              {t("Search")}
            </Button>
          </div>
        </div>
      </div>
      <div className={classes.coverPaperBottom}>
        <div className={classes.coverTitleBottom}>
          <Typography variant="h6">{t("Search results")}</Typography>
          <div className={classes.coverFilterTable}>
            {/* <Typography variant="body2">{t("Period")}:</Typography> */}
            <div className={classes.coverSelectFilterTable}></div>
            <Button
              variant="outlined"
              classes={{
                root:
                  paramsBoUnderRef?.period !== 0
                    ? classes.buttonFilterOutlined
                    : classes.buttonPeriodSelected,
              }}
              size="small"
              onClick={() => {
                handleUpdateParamsBoUnderRef({ period: 0 });
              }}
            >
              {t("Today")}
            </Button>
            <Button
              variant="outlined"
              classes={{
                root:
                  paramsBoUnderRef?.period !== 1
                    ? classes.buttonFilterOutlined
                    : classes.buttonPeriodSelected,
              }}
              size="small"
              onClick={() => {
                handleUpdateParamsBoUnderRef({ period: 1 });
              }}
            >
              {t("Yesterday")}
            </Button>
            <Button
              variant="outlined"
              classes={{
                root:
                  paramsBoUnderRef?.period !== 7
                    ? classes.buttonFilterOutlined
                    : classes.buttonPeriodSelected,
              }}
              size="small"
              onClick={() => {
                handleUpdateParamsBoUnderRef({ period: 7 });
              }}
            >
              {t("The last 7 days")}
            </Button>
            <Button
              variant="outlined"
              classes={{
                root:
                  paramsBoUnderRef?.period !== 30
                    ? classes.buttonFilterOutlined
                    : classes.buttonPeriodSelected,
              }}
              size="small"
              onClick={() => {
                handleUpdateParamsBoUnderRef({ period: 30 });
              }}
            >
              {t("The last 30 days")}
            </Button>
            <Button
              variant="outlined"
              classes={{
                root:
                  typeof paramsBoUnderRef?.period !== "undefined"
                    ? classes.buttonFilterOutlined
                    : classes.buttonPeriodSelected,
              }}
              size="small"
              onClick={() => {
                handleUpdateParamsBoUnderRef({ period: null });
              }}
            >
              {t("All")}
            </Button>
          </div>
        </div>
        <div>{renderTableSystemMng()}</div>
      </div>
    </div>
  );
}
