import { DOMAIN, SCHEME } from "../const/api";
import callApi from "../utils/apiCaller";

const headers = {
  //prettier-ignore
  "Content-Type": "application/json",
};

export const getReferralDetail = () => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "get",
    url: `${SCHEME}://${DOMAIN}/v1/ref/detail/`,
    headers: {
      ...headers,
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
  };
  return callApi(config);
};

export const buyReferralCode = (refId) => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "post",
    url: `${SCHEME}://${DOMAIN}/v1/ref/detail/${refId}/purchase-code/`,
    headers: {
      ...headers,
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
  };
  return callApi(config);
};

export const getReferralTree = (refId) => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "get",
    url: `${SCHEME}://${DOMAIN}/v1/ref/detail/treedata/`,
    headers: {
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
  };
  return callApi(config);
};

export const getUnderReferral = (refId, params) => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "get",
    url: `${SCHEME}://${DOMAIN}/v1/ref/detail/${refId}/ref-network/`,
    headers: {
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
    params,
  };
  return callApi(config);
};

export const getReferralSummary = () => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "get",
    url: `${SCHEME}://${DOMAIN}/v1/ref/detail/summary/`,
    headers: {
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
  };
  return callApi(config);
};

export const getReferralTransaction = (params) => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "get",
    url: `${SCHEME}://${DOMAIN}/v1/ref/transaction/`,
    headers: {
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
    params,
  };
  return callApi(config);
};

export const getReferralBoDetail = () => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "get",
    url: `${SCHEME}://${DOMAIN}/v1/ref/detail/bo/`,
    headers: {
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
  };
  return callApi(config);
};

export const getRefBoCommission = (params) => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "get",
    url: `${SCHEME}://${DOMAIN}/v1/ref/detail/bo-commission/`,
    headers: {
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
    params,
  };
  return callApi(config);
};

export const getRefMonthCommission = (params) => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "get",
    url: `${SCHEME}://${DOMAIN}/v1/ref/detail/month-commissions/`,
    headers: {
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
    params,
  };
  return callApi(config);
};

export const getBoUnderRef = (params) => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "get",
    url: `${SCHEME}://${DOMAIN}/v1/ref/detail/bo-underrefs/`,
    headers: {
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
    params,
  };
  return callApi(config);
};

export const getBoTree = (refId) => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "get",
    url: `${SCHEME}://${DOMAIN}/v1/ref/detail/treedata-bo/`,
    headers: {
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
  };
  return callApi(config);
};

export const getBoNetworkDetail = (params) => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "get",
    url: `${SCHEME}://${DOMAIN}/v1/ref/detail/network-detail/`,
    headers: {
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
    params,
  };
  return callApi(config);
};

export const getBoInvestCommission = (params) => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "get",
    url: `${SCHEME}://${DOMAIN}/v1/ref/day-overview/`,
    headers: {
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
    params,
  };
  return callApi(config);
};

export const getBoUserCommission = (params) => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "get",
    url: `${SCHEME}://${DOMAIN}/v1/ref/month-overview/`,
    headers: {
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
    params,
  };
  return callApi(config);
};

export const getUpgradeLevelConfig = () => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "get",
    url: `${SCHEME}://${DOMAIN}/v1/ref/detail/upgrade-level-config/`,
    headers: {
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
  };
  return callApi(config);
};

export const upgradeLevel = (id, data) => {
  let jwt_token = localStorage.getItem("jwt_token");
  let config = {
    method: "post",
    url: `${SCHEME}://${DOMAIN}/v1/ref/detail/${id}/upgrade-level/`,
    headers: {
      //prettier-ignore
      "Authorization": "Bearer " + jwt_token,
    },
    data,
  };
  return callApi(config);
};
