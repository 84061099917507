/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-comment-textnodes */
import {
  Button,
  createStyles,
  makeStyles,
  Typography,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import ContentCopyLink from "../../assets/img/ContentCopyLink.png";
import { TOAST_NOTI } from "../../const/common";
import { callToastNotiSuccess } from "../../redux/actions/toastNotiAction";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        display: "flex",
        padding: 20,
        width: "100%",
        marginLeft: theme.marginLayout,
        backgroundColor: theme.color.background,
        [theme.breakpoints.down("xs")]: {
          background: "transparent",
          padding: "5px 10px",
        },
      },
      title: {
        fontSize: 28,
        [theme.breakpoints.down("xs")]: {
          display: "none",
        },
      },
      image: {
        width: 150,
        height: 150,
      },
      coverLink: {
        margin: "0 50px 0 30px",
        [theme.breakpoints.down("xs")]: {
          margin: "0 50px 0 10px",
        },
      },
      typographyHideLink: {
        display: "block",
        margin: "10px 5px 10px 0",
        color: theme.text.primary,
      },
      typographyReferralLink: {
        margin: "10px 0",
        color: theme.text.secondary,
      },
      typographyLink: {
        color: theme.color.primary,
        textDecoration: "none",
        cursor: "pointer",
      },
      typographyCaptionQRcode: {
        textAlign: "center",
      },
      buttonHideReferral: {
        textTransform: "none",
        padding: 0,
        color: theme.text.primary,
      },
    }),
  { index: 1 }
);

export default function HeaderAgentInfo(props) {
  const classes = useStyles(props);
  const { referralInfo } = props;
  const { t } = useTranslation();
  const [showLink, setShowLink] = useState(false);
  const dispatch = useDispatch();

  const handleCopeLink = (link) => {
    navigator.clipboard.writeText(link);
    dispatch(
      callToastNotiSuccess({ noti: t("Copied"), status: TOAST_NOTI.SUCCESS })
    );
  };

  return (
    <div className={classes.root}>
      <Typography variant="h5" className={classes.title}>
        {t("Agency")}
      </Typography>
      <div className={classes.coverLink}>
        <Button
          variant="text"
          classes={{ root: classes.buttonHideReferral }}
          onClick={() => {
            setShowLink(false);
          }}
        >
          <Typography variant="body2" className={classes.typographyHideLink}>
            {t("Referral link")}
          </Typography>
          {/* <ArrowDropUpIcon /> */}
        </Button>
        {referralInfo?.ref_code && (
          <div>
            <Typography
              variant="body1"
              className={classes.typographyLink}
              onClick={() => {
                handleCopeLink(
                  `${window.location.origin}/register?r=${referralInfo?.ref_code}`
                );
              }}
            >
              {window.location.origin}/register?r=
              {referralInfo?.ref_code}{" "}
              <img src={`${ContentCopyLink}`} alt="ContentCopyLink"></img>
            </Typography>
          </div>
        )}
      </div>
      {/* {showLink && (
        <div>
          <img src={`${QRCode}`} alt="QRCode" className={classes.image}></img>
          <Typography
            variant="body2"
            className={classes.typographyCaptionQRcode}
          >
            {t("Scan the QR code")}
          </Typography>
        </div>
      )} */}
    </div>
  );
}
