import {
  Card,
  createStyles,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Paper,
  Popover,
  Radio,
  Typography,
  useTheme,
  withStyles,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import DollarDemo from "../../assets/img/DollarDemo.png";
import DollarReal from "../../assets/img/DollarReal.png";
import convertNumberToCurrency from "../../common/convertNumberToCurrency";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import { ACCOUNT } from "../../const/common";

const RadioCustom = withStyles({
  root: {
    color: "#2F86FA",
    "&$checked": {
      color: "#2F86FA",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      accountCard: {
        padding: "5px 5px 5px 10px",
        cursor: "pointer",
        backgroundColor: "hsla(0, 0%, 100%, 0.14)",
        "&:hover": {
          backgroundColor: `${theme.body.backgroundColor}`,
        },
        borderRadius: 3,
      },
      popoverPaper: { backgroundColor: `${theme.body.backgroundColor}` },
      paper: { width: 350, backgroundColor: `${theme.body.backgroundColor}` },
      headerAccountsCard: {
        padding: "5px 10px",
        color: theme.text.primary,
      },
      list: { padding: 0 },
      listItem: {
        padding: "0 10px",
        "&:hover": {
          backgroundColor: theme.color.border,
        },
      },
      itemSelected: { backgroundColor: `hsla(0, 0%, 100%, 0.14) !important` },
      buttonActionAccount: {
        textTransform: "none",
        backgroundColor: "#535763",
      },
      typographyPrimary: {
        color: theme.text.primary,
      },
      typographySecondaryReal: {
        color: theme.text.link,
      },
      typographySecondaryDemo: {
        color: theme.text.demo,
      },
    }),
  { index: 1 }
);

function PopOverAccount(props) {
  const classes = useStyles(props);
  const {
    walletAmount,
    account,
    walletDemo,
    handleSelectAccount,
    handleResetDemo,
  } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Card
        classes={{ root: classes.accountCard }}
        style={{
          color: account === ACCOUNT.REAL ? theme.text.link : theme.text.demo,
        }}
        onClick={handleClick}
      >
        {account === ACCOUNT.REAL ? (
          <>
            {" "}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="body2">{t("Real account")}</Typography>{" "}
              <ArrowDropDownIcon />
            </div>
            <div>
              <Typography variant="body1">
                {convertNumberToCurrency(walletAmount || 0)}
              </Typography>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="body2">{t("Demo account")}</Typography>{" "}
              <ArrowDropDownIcon />
            </div>
            <div>
              <Typography variant="body1">
                {convertNumberToCurrency(walletDemo.balance || 0)}
              </Typography>
            </div>
          </>
        )}
      </Card>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        style={{ borderRadius: 0 }}
        classes={{ paper: classes.popoverPaper }}
      >
        <Paper classes={{ root: classes.paper }} square>
          <Typography className={classes.headerAccountsCard}>
            {t("My account")}
          </Typography>
          <List className={classes.list}>
            <ListItem
              //   button
              classes={{
                root: classes.listItem,
                selected: classes.itemSelected,
              }}
              //   onClick={() => {
              //     handleClose();
              //     handleSelectAccount(ACCOUNT.REAL);
              //   }}
              selected={account === ACCOUNT.REAL}
            >
              <ListItemAvatar>
                <RadioCustom
                  checked={account === ACCOUNT.REAL}
                  onChange={() => {
                    handleClose();
                    handleSelectAccount(ACCOUNT.REAL);
                  }}
                  value="c"
                  name="radio-button-demo"
                  inputProps={{ "aria-label": "C" }}
                />
              </ListItemAvatar>
              <img
                src={`${DollarReal}`}
                alt="DollarReal"
                style={{ marginRight: "10px", marginBottom: 15 }}
              ></img>
              <ListItemText
                primary={
                  <Typography
                    variant="body2"
                    className={classes.typographyPrimary}
                  >
                    {t("Real account")}
                  </Typography>
                }
                secondary={
                  <Typography
                    variant="body2"
                    className={classes.typographySecondaryReal}
                  >
                    {convertNumberToCurrency(walletAmount || 0)}
                  </Typography>
                }
              />
              {/* <Button
                variant="contained"
                className={classes.buttonActionAccount}
              >
                <Typography
                  variant="body2"
                  className={classes.typographyPrimary}
                >
                  {t("Deposit")}
                </Typography>
              </Button> */}
            </ListItem>
            <ListItem
              //   button
              classes={{
                root: classes.listItem,
                selected: classes.itemSelected,
              }}
              //   onClick={() => {
              //     handleClose();
              //     handleSelectAccount(ACCOUNT.DEMO);
              //   }}
              selected={account === ACCOUNT.DEMO}
            >
              <ListItemAvatar>
                <RadioCustom
                  checked={account === ACCOUNT.DEMO}
                  onChange={() => {
                    handleClose();
                    handleSelectAccount(ACCOUNT.DEMO);
                  }}
                  value="c"
                  name="radio-button-demo"
                  inputProps={{ "aria-label": "C" }}
                />
              </ListItemAvatar>

              <img
                src={`${DollarDemo}`}
                alt="DollarDemo"
                style={{ marginRight: "10px", marginBottom: 15 }}
              ></img>
              <ListItemText
                primary={
                  <Typography
                    variant="body2"
                    className={classes.typographyPrimary}
                  >
                    {t("Demo account")}
                  </Typography>
                }
                secondary={
                  <Typography
                    variant="body2"
                    className={classes.typographySecondaryDemo}
                  >
                    {convertNumberToCurrency(walletDemo.balance || 0)}
                  </Typography>
                }
              />
              <IconButton
                onClick={() => {
                  handleResetDemo();
                }}
              >
                <AutorenewIcon style={{ color: "#fff" }} />
              </IconButton>
            </ListItem>
          </List>
        </Paper>
      </Popover>
    </>
  );
}

PopOverAccount.propTypes = {};

export default PopOverAccount;
