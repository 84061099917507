import {
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Pagination, PaginationItem } from "@material-ui/lab";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import convertNumberToCurrency from "../../common/convertNumberToCurrency";
import ContentCopyLink from "../../assets/img/ContentCopyLink.png";
import { useDispatch } from "react-redux";
import { callToastNotiSuccess } from "../../redux/actions/toastNotiAction";
import { MAIN_TRANSACTION, TOAST_NOTI } from "../../const/common";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      tableCellHeader: {
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        border: "none",
        color: theme.text.secondary,
      },
      tableCellBody: {
        borderBottom: "1px solid #424242",
        color: "#fff",
      },
      coverInformation: {
        display: "flex",
      },
      textTitleInformation: {
        color: theme.text.secondary,
        marginRight: 50,
      },
      textAddress: {
        color: theme.text.link,
        textDecoration: "none",
        cursor: "pointer",
      },
      rootPagination: {
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: 40,
        marginTop: 20,
      },
      pagination: {
        color: theme.text.primary,
      },
      rootPaginationItem: {
        border: `1px solid ${theme.color.light}`,
        color: theme.text.primary,
        borderRadius: 2,
      },
      ellipsisPagination: { border: "none" },
      pageSelected: {
        backgroundColor: "hsla(0, 0%, 100%, 0.1) !important",
        border: `1px solid ${theme.color.border}`,
      },
      rootLogo: {
        display: "flex",
        alignItems: "center",
      },
      image: {
        marginRight: 10,
        width: "10%",
      },
      typographyPrimary: {
        color: theme.text.primary,
        padding: "5px 0",
      },
      coverLoading: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
          height: 80,
        },
      },
    }),
  { index: 1 }
);

function createData(time, currency, value, status, address, hash) {
  return { time, currency, value, status, address, hash };
}

function TableTransactionHistory(props) {
  const classes = useStyles(props);
  const { walletTransaction, pagination, handleChangePage, loading } = props;
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);
  const theme = useTheme();
  const dispatch = useDispatch();

  const columns = [
    { id: "time", label: t("Time"), align: "left", minWidth: 100 },
    { id: "currency", label: t("Currency"), align: "left", minWidth: 50 },
    {
      id: "value",
      label: t("Value"),
      minWidth: 100,
      align: "left",
    },
    {
      id: "status",
      label: t("Status"),
      minWidth: 50,
      align: "left",
    },
    {
      id: "address",
      label: t("Address/user_id"),
      minWidth: 200,
      align: "left",
    },
    {
      id: "hash",
      label: t("Transaction hash"),
      minWidth: 200,
      align: "left",
    },
  ];

  const handleCopeLink = (link) => {
    navigator.clipboard.writeText(link);
    dispatch(
      callToastNotiSuccess({ noti: t("Copied"), status: TOAST_NOTI.SUCCESS })
    );
  };

  const colorStatus = (status) => {
    switch (status) {
      case MAIN_TRANSACTION.STATUS.SUCCEED:
        return theme.color.trendingUp;
      case MAIN_TRANSACTION.STATUS.FAILED:
        return theme.color.trendingDown;
      case MAIN_TRANSACTION.STATUS.PENDING:
        return "#fffde7";
      default:
        return "#fff";
    }
  };

  useEffect(() => {
    if (Object.keys(walletTransaction).length > 0) {
      let rows;
      rows = walletTransaction.results.map((transaction, index) => {
        let time = moment(transaction.created).format("MM-DD HH:mm:ss");
        let currency = (
          <div className={classes.rootLogo}>
            <img
              src={`${transaction?.wallet?.c?.icon_url}`}
              alt={`${transaction?.wallet?.c?.name}`}
              className={classes.image}
            ></img>
            <Typography
              variant="body2"
              classes={{ root: classes.typographyPrimary }}
            >
              {transaction?.wallet?.c?.symbol}({transaction?.wallet?.c?.name})
            </Typography>
          </div>
        );
        let value =
          transaction.tx_type === "withdraw" ? (
            <Typography
              variant="body2"
              style={{ color: theme.color.trendingDown }}
            >
              -{convertNumberToCurrency(parseFloat(transaction.amount))}
            </Typography>
          ) : (
            <Typography
              variant="body2"
              style={{ color: theme.color.trendingUp }}
            >
              +{convertNumberToCurrency(parseFloat(transaction.amount))}
            </Typography>
          );
        let status = (
          <Typography
            variant="body2"
            style={{ color: colorStatus(transaction.status) }}
          >
            {transaction.status}
          </Typography>
        );
        let address = (
          <div className={classes.coverInformation} key={index}>
            <Typography
              variant="body2"
              classes={{ root: classes.textAddress }}
              onClick={() => {
                handleCopeLink(`${transaction.transaction_detail?.address}`);
              }}
              title={transaction.transaction_detail?.address}
            >
              {transaction.transaction_detail?.address &&
              transaction.transaction_detail?.address?.length > 25
                ? `${transaction.transaction_detail?.address?.substring(
                    0,
                    22
                  )}...`
                : transaction.transaction_detail?.address}
              {transaction.transaction_detail?.address && (
                <img
                  src={`${ContentCopyLink}`}
                  alt="ContentCopyLink"
                  //   style={{ marginLeft: 5 }}
                ></img>
              )}
            </Typography>
          </div>
        );
        let hash = (
          <div className={classes.coverInformation} key={index}>
            {transaction.transaction_detail?.is_internal ? (
              <Typography
                variant="body2"
                classes={{ root: classes.textAddress }}
                onClick={() => {
                  handleCopeLink(
                    !(transaction.tx_type === "deposit")
                      ? transaction.transaction_detail?.internal_detail.to_user
                      : transaction.transaction_detail?.internal_detail
                          .from_user
                  );
                }}
              >
                <span style={{ color: "#ffffff" }}>
                  {transaction.tx_type === "deposit" ? t("From") : t("To")}:{" "}
                </span>
                {!(transaction.tx_type === "deposit")
                  ? transaction.transaction_detail?.internal_detail.to_user
                  : transaction.transaction_detail?.internal_detail.from_user}
                <img
                  src={`${ContentCopyLink}`}
                  alt="ContentCopyLink"
                  //   style={{ marginLeft: 5 }}
                ></img>
              </Typography>
            ) : (
              <Typography
                variant="body2"
                classes={{ root: classes.textAddress }}
                onClick={() => {
                  handleCopeLink(`${transaction.transaction_detail?.tx_hash}`);
                }}
                title={transaction.transaction_detail?.tx_hash}
              >
                {transaction.transaction_detail?.tx_hash &&
                transaction.transaction_detail?.tx_hash?.length > 25
                  ? `${transaction.transaction_detail?.tx_hash?.substring(
                      0,
                      22
                    )}...`
                  : transaction.transaction_detail?.tx_hash}
                {transaction.transaction_detail?.tx_hash && (
                  <img
                    src={`${ContentCopyLink}`}
                    alt="ContentCopyLink"
                    //   style={{ marginLeft: 5 }}
                  ></img>
                )}
              </Typography>
            )}
          </div>
        );
        return createData(time, currency, value, status, address, hash);
      });

      setRows(rows);
    }
  }, [walletTransaction]);

  return (
    <>
      <TableContainer className={classes.root}>
        <Table aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className={classes.tableCellHeader}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow hover role="checkbox" tabIndex={-1}>
                <TableCell colSpan={6}>
                  {" "}
                  {/* TODO add loading */}
                  <div className={classes.coverLoading}>
                    <CircularProgress style={{ color: "#fff" }} size={25} />
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              rows.map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          className={classes.tableCellBody}
                        >
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
        <div className={classes.rootPagination}>
          <Pagination
            count={Math.ceil(
              walletTransaction.count / pagination.page_size || 0
            )}
            page={pagination.page}
            variant="outlined"
            shape="rounded"
            onChange={handleChangePage}
            classes={{ root: classes.pagination }}
            renderItem={(item) => (
              <PaginationItem
                classes={{
                  root: classes.rootPaginationItem,
                  ellipsis: classes.ellipsisPagination,
                  selected: classes.pageSelected,
                }}
                {...item}
                variant="outlined"
              />
            )}
          />
        </div>
      </TableContainer>
    </>
  );
}

TableTransactionHistory.propTypes = {};

export default TableTransactionHistory;
