import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

const useStyles = makeStyles(
  (theme) => ({
    rootCard: {
      width: 456,
      height: 300,
      margin: "auto",
      backgroundColor: "#191D23",
      [theme.breakpoints.down("xs")]: {
        width: "100vw",
        height: "91vh",
        margin: 0,
        borderRadius: 0,
      },
    },
    rootCardHeader: {
      borderBottom: `1px solid ${theme.color.border}`,
      padding: "5px 15px",
    },
    typographyTitle: {
      textAlign: "center",
      color: theme.text.primary,
    },
    iconButton: {
      color: theme.text.primary,
    },
    typographySecondary: {
      color: theme.text.secondary,
    },
    textField: {
      marginBottom: 15,
      backgroundColor: "#2A2D35",
    },
    cssOutlinedInput: {
      "&$cssFocused $notchedOutline": {
        borderColor: `${theme.text.primary} !important`,
      },
    },
    notchedOutline: {
      borderWidth: "1px",
      borderColor: "#2A2D35 !important",
      borderRadius: "2px !important",
    },
    multilineColor: {
      color: theme.text.primary,
      fontSize: 13,
    },
    input: {
      "&::placeholder": {
        opacity: 0.2,
      },
    },
    buttonSubmit: {
      width: "100%",
      color: theme.text.primary,
      backgroundColor: theme.color.primary,
      textTransform: "none",
      borderRadius: 2,
      marginTop: 10,
    },
    coverButtonFooter: {
      marginTop: 30,
    },
    buttonRegister: {
      color: theme.text.link,
      textTransform: "none",
      float: "left",
    },
    buttonForgotPassword: {
      color: theme.text.link,
      textTransform: "none",
      float: "right",
    },
    coverPasteCode: { minWidth: 90, cursor: "pointer", textAlign: "right" },
    endAdornment: {
      color: theme.text.link,
    },
  }),
  { index: 1 }
);

const initialValues = {
  otp_token: "",
};

function FormLogin2Fa(props) {
  const classes = useStyles(props);
  const { handleLogin, handleBack } = props;
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    otp_token: Yup.string().required(t("Not be empty")),
  });

  const onSubmit = (values) => {
    handleLogin(values);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => onSubmit(values),
  });

  const handlePasteCode = () => {
    navigator.clipboard
      .readText()
      .then((text) => {
        formik.setFieldValue("otp_token", text);
      })
      .catch();
  };

  return (
    <div>
      <Card classes={{ root: classes.rootCard }}>
        <CardHeader
          classes={{ root: classes.rootCardHeader }}
          title={
            <Typography
              variant="body1"
              classes={{ root: classes.typographyTitle }}
            >
              {t("Login")}
            </Typography>
          }
          action={
            <IconButton
              aria-label="close"
              classes={{ root: classes.iconButton }}
            >
              <CloseIcon />
            </IconButton>
          }
        />
        <CardContent>
          <div>
            <Typography
              variant="body1"
              classes={{ root: classes.typographyTitle }}
            >
              {/* {t("Welcome to Alltrade.com")} */}
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              <div>
                <Typography
                  variant="caption"
                  className={classes.typographySecondary}
                >
                  {t("2FA Code")}
                </Typography>
                <TextField
                  className={classes.textField}
                  fullWidth
                  variant="outlined"
                  name="otp_token"
                  id="otp_token"
                  InputLabelProps={{ shrink: false }}
                  InputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      notchedOutline: classes.notchedOutline,
                      input: classes.input,
                    },
                    className: classes.multilineColor,
                    endAdornment: (
                      <div
                        onClick={handlePasteCode}
                        className={classes.coverPasteCode}
                      >
                        <Typography
                          variant="caption"
                          className={classes.endAdornment}
                        >
                          {t("Paste the code")}
                        </Typography>
                      </div>
                    ),
                  }}
                  size="small"
                  onChange={formik.handleChange}
                  //   onBlur={formik.handleBlur}
                  value={formik.values.otp_token}
                  error={
                    formik.touched.otp_token && Boolean(formik.errors.otp_token)
                  }
                  helperText={
                    formik.touched.otp_token && formik.errors.otp_token
                  }
                />
              </div>
              <div>
                <Button
                  className={classes.buttonSubmit}
                  type="submit"
                  variant="outlined"
                >
                  {t("Login")}
                </Button>
              </div>
            </form>
            <div className={classes.coverButtonFooter}>
              <Button
                classes={{ root: classes.buttonRegister }}
                variant="text"
                onClick={handleBack}
              >
                {t("Back")}
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

FormLogin2Fa.propTypes = {};

export default FormLogin2Fa;
