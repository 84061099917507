import {
  Button,
  Card,
  CardContent,
  CardHeader,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import BigNumber from "bignumber.js";
import { find } from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import convertNumberToMoney from "../../common/convertNumberToMoney";
import { bch, usdt } from "../../const/currency";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      rootDrawer: {
        backgroundColor: theme.body.backgroundColor,
        height: "100%",
      },
      rootCard: {
        backgroundColor: "rgba(255, 255, 255, 0.03)",
        height: "100%",
        boxShadow: "none",
      },
      rootCardHeader: {
        background: theme.color.background,
      },
      //   rootCardContent: { backgroundColor: "rgba(255, 255, 255, 0.03)" },
      coverImage: { textAlign: "center", margin: "0 0 5px 0" },
      dialogHeader: {
        color: "#fff",
        padding: "10px 20px",
      },
      closeButton: {
        position: "absolute",
        right: 2,
        top: 0,
        color: theme.text.primary,
      },
      dialogContent: {},
      dividers: { borderTopColor: "#272C3A" },
      textField: {
        marginBottom: 15,
        backgroundColor: "#2A2D35",
      },
      cssOutlinedInput: {
        "&$cssFocused $notchedOutline": {
          borderColor: `${theme.text.primary} !important`,
        },
      },
      notchedOutline: {
        borderWidth: "1px",
        borderColor: "#2A2D35 !important",
        borderRadius: "2px !important",
      },
      multilineColor: {
        color: theme.text.primary,
        fontSize: 13,
        paddingLeft: 0,
      },
      input: {
        paddingLeft: 10,
        "&::placeholder": {
          opacity: 0.2,
        },
      },
      typographySecondary: {
        color: theme.text.secondary,
      },
      typographyPrimary: {
        color: theme.text.primary,
      },
      typographyName: {
        color: theme.text.primary,
        fontWeight: 600,
        fontSize: 18,
        textAlign: "center",
      },
      typographyValue: { paddingLeft: 20 },
      buttonSubmit: {
        width: "100%",
        color: theme.text.primary,
        backgroundColor: theme.color.primary,
        textTransform: "none",
        borderRadius: 2,
        marginTop: 10,
      },
      paper: {
        width: 456,
        // minHeight: 573,
        // maxHeight: 700,
        backgroundColor: "#191D23",
      },
      imageBot: {
        width: 146,
        height: 146,
      },
      dialogActions: {
        padding: "0 15px 25px 15px",
      },
      rootContent: {
        padding: "10px 60px 20px 60px",
        [theme.breakpoints.down("xs")]: {
          padding: "10px 0",
        },
      },
      startAdornment: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 5,
        padding: 10,
        background: theme.color.background,
      },
      endAdornment: {
        color: theme.text.link,
        minWidth: 10,
        cursor: "pointer",
      },
      rootFormControl: {
        width: "100%",
        margin: "10px 0",
      },
      rootSelect: {
        color: theme.text.primary,
        padding: "10px 10px",
        border: "none",
      },
      rootMenu: {},
      iconMenu: {
        color: theme.text.primary,
      },
      selectRoot: {
        display: "flex",
        alignItems: "center",
        gap: "5px",
        padding: 10,
      },
      iconSelect: { fill: theme.text.primary },
      currencyItem: {
        display: "flex",
        alignItems: "center",
        gap: 5,
      },
      currencyImg: {
        width: 20,
      },
      currencySymbol: {
        lineHeight: 0,
        fontWeight: 600,
        color: theme.text.primary,
      },
      radioLabel: { color: theme.text.primary, fontSize: 14 },
      radioRoot: {
        color: theme.text.primary,
        "&$checked": {
          color: theme.text.primary,
        },
      },
      checked: {},
    }),
  { index: 1 }
);

export default function DialogInvestTradingBotV2({
  botSelected,
  handleClose,
  handlePayBot,
  wallets,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const [amount, setAmount] = useState("");
  const [walletIdSelected, setWalletIdSelected] = useState();

  const handleChangeAmount = (e) => {
    let value = e.target?.value;
    if (!isNaN(Number(value))) {
      setAmount(value);
    }
  };

  const handleChangeWallet = (e) => {
    setWalletIdSelected(Number(e.target.value));
  };

  useEffect(() => {
    if (!botSelected.open) {
      setAmount("");
    }
  }, [botSelected.open]);

  useEffect(() => {
    setWalletIdSelected(wallets[0]?.id);
  }, [wallets]);

  const bchToken = find(
    wallets.map((wallet) => wallet.c),
    { symbol: bch.symbol }
  );

  const usdtToken = find(
    wallets.map((wallet) => wallet.c),
    { symbol: usdt.symbol }
  );

  const walletSelected = find(wallets, { id: walletIdSelected });

  const renderContent = () => {
    return (
      <>
        <div className={classes.rootContent}>
          <div>
            <Typography variant="body1" className={classes.typographyName}>
              {botSelected.info.name?.toUpperCase()} BOT
            </Typography>
          </div>
          <div>
            <Typography
              variant="caption"
              className={classes.typographySecondary}
            >
              {t("Wallet")}
            </Typography>
            <RadioGroup
              value={walletIdSelected + ""}
              aria-label="gender"
              name="customized-radios"
            >
              <Grid container spacing={3}>
                {wallets.map((wallet) => (
                  <Grid key={wallet.id} item xs={6}>
                    <FormControlLabel
                      classes={{ label: classes.radioLabel }}
                      value={wallet.id + ""}
                      control={
                        <Radio
                          classes={{
                            root: classes.radioRoot,
                            checked: classes.checked,
                          }}
                          color="primary"
                        />
                      }
                      label={wallet.c?.symbol}
                      onChange={handleChangeWallet}
                    />
                  </Grid>
                ))}
              </Grid>
            </RadioGroup>
          </div>

          <div>
            <Typography
              variant="caption"
              className={classes.typographySecondary}
            >
              {t("Amount")}
            </Typography>
            <FormControl classes={{ root: classes.rootFormControl }}>
              <TextField
                className={classes.textField}
                fullWidth
                variant="outlined"
                id="groupName"
                InputLabelProps={{ shrink: false }}
                InputProps={{
                  startAdornment: (
                    <div className={classes.startAdornment}>
                      <img
                        src={usdtToken?.icon_url}
                        alt={usdtToken?.symbol}
                        className={classes.currencyImg}
                      />
                      <Typography
                        variant="caption"
                        className={classes.currencySymbol}
                      >
                        {usdtToken?.symbol}
                      </Typography>
                    </div>
                  ),
                  classes: {
                    root: classes.cssOutlinedInput,
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                  className: classes.multilineColor,
                }}
                size="small"
                placeholder="0"
                value={amount}
                onChange={handleChangeAmount}
              />
            </FormControl>
          </div>
          <Grid container>
            <Grid item xs={5}>
              <Typography
                variant="caption"
                className={classes.typographySecondary}
              >
                {t("Amount")} ({walletSelected?.c?.symbol}):
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography
                variant="caption"
                className={classes.typographyPrimary}
              >
                {amount && walletSelected.c?.usdt_rate
                  ? new BigNumber(amount || 0)
                      .div(walletSelected.c?.usdt_rate)
                      .toFormat(walletSelected.c?.symbol === bch.symbol ? 5 : 2)
                  : 0}{" "}
                {walletSelected?.c?.symbol}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={5}>
              <Typography
                variant="caption"
                className={classes.typographySecondary}
              >
                {t("Available balances")}:
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography
                variant="caption"
                className={classes.typographyPrimary}
              >
                {walletSelected &&
                  new BigNumber(walletSelected.balance).toFormat(
                    walletSelected.c?.symbol === bch.symbol ? 5 : 2
                  )}{" "}
                {walletSelected?.c?.symbol}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div>
          <Button
            className={classes.buttonSubmit}
            onClick={() => {
              handlePayBot(amount, walletSelected.c?.symbol);
            }}
          >
            {t("Pay")}
          </Button>
        </div>
      </>
    );
  };

  return (
    <>
      {matches ? (
        <Drawer
          anchor={"bottom"}
          open={botSelected.open}
          onClose={handleClose}
          classes={{ paper: classes.rootDrawer }}
        >
          <Card classes={{ root: classes.rootCard }}>
            <CardHeader
              title={
                <Typography
                  classes={{ root: classes.typographyPrimary }}
                  variant="h6"
                >
                  {t("Invest")}
                </Typography>
              }
              action={
                <IconButton
                  classes={{ root: classes.closeButton }}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>
              }
              classes={{ root: classes.cardHeader }}
            />
            <CardContent>{renderContent()}</CardContent>
          </Card>
        </Drawer>
      ) : (
        <Dialog
          open={botSelected.open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={false}
          classes={{ paper: classes.paper }}
        >
          <DialogTitle id="alert-dialog-title" className={classes.dialogHeader}>
            <Typography variant="body1">{t("Invest")}</Typography>
            <IconButton className={classes.closeButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            className={classes.dialogContent}
            dividers={true}
            classes={{ dividers: classes.dividers }}
          >
            {renderContent()}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

DialogInvestTradingBotV2.propTypes = {
  botSelected: PropTypes.object,
  handleClose: PropTypes.func,
  handlePayBot: PropTypes.func,
  wallets: PropTypes.array,
};
