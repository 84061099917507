import {
  Button,
  Checkbox,
  FormControlLabel,
  List,
  makeStyles,
} from "@material-ui/core";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getNotification, updateReadNotification } from "../../apis/noti.api";
import CheckReadall from "../../assets/img/CheckReadall.png";
import ItemNotification from "../../components/Body/BodyNotification/ItemNotification";
import HeaderNotification from "../../components/Header/HeaderNotificaton";
import { setNotification } from "../../redux/actions/notificationAction";

const useStyles = makeStyles(
  (theme) => ({
    root: { width: "100%" },
    body: {
      width: "99%",
      backgroundColor: theme.color.background,
      margin: 5,
      minHeight: "85vh",
      padding: "20px 0 10px 0",
    },
    coverList: {
      margin: "auto",
      width: "60%",
      [theme.breakpoints.down("xs")]: {
        width: "90%",
      },
    },
    coverAction: { display: "flex", justifyContent: "flex-end" },
    checkboxPrimary: {
      color: "#9e9e9e",
    },
    checkboxChecked: { color: "#9e9e9e !important" },
    button: {
      color: "#fff",
      border: `1px solid ${theme.color.border}`,
      borderRadius: 2,
      padding: "5px 7px",
      margin: 5,
      minWidth: 0,
    },
    rootPagination: {
      display: "flex",
      justifyContent: "flex-end",
      marginBottom: 40,
    },
    pagination: {
      color: theme.text.primary,
    },
    rootPaginationItem: {
      border: `1px solid ${theme.text.primary}`,
      color: theme.text.primary,
      borderRadius: 2,
    },
    ellipsisPagination: { border: "none" },
    pageSelected: {
      backgroundColor: "hsla(0, 0%, 100%, 0.1) !important",
      border: `1px solid ${theme.color.border}`,
    },
  }),
  { index: 1 }
);

export default function Notification() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [notificationState, setNotificationState] = useState();
  const [pagination, setPagination] = useState({ page: 1, page_size: 10 });
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();

  const callNotification = (params) => {
    getNotification(params)
      .then((res) => {
        if (res.status === 200) {
          setNotificationState(res.data);
          if (res.data?.results.filter((noti) => !noti.read)?.length > 0) {
            dispatch(setNotification(true));
            // let pagination = { page: 1, page_size: 10 };
            // let params = { ...pagination, is_read: 0 };
            // callNotification(params);
          } else dispatch(setNotification(false));
        }
      })
      .catch();
  };

  const handleRead = (id) => {
    let dataReq = {
      read_ids: [id],
      all: false,
    };
    updateReadNotification(dataReq)
      .then((res) => {
        if (res.status === 200) {
          let params = { ...pagination, is_read: !checked ? 1 : 0 };
          callNotification(params);
        }
      })
      .catch();
  };

  const handleReadAll = () => {
    let dataReq = {
      read_ids: [],
      all: true,
    };
    updateReadNotification(dataReq)
      .then((res) => {
        if (res.status === 200) {
          let params = { ...pagination, is_read: !checked ? 1 : 0 };
          callNotification(params);
        }
      })
      .catch();
  };

  const handleCheckedIsRead = (checked) => {
    let params = { ...pagination, page: 1, is_read: !checked ? 1 : 0 };
    callNotification(params);
    setChecked(checked);
    setPagination({ ...pagination, page: 1 });
  };

  const handleChangePage = (value) => {
    let params = { ...pagination, page: value, is_read: !checked ? 1 : 0 };
    callNotification(params);
    setPagination({ ...pagination, page: value });
  };

  useEffect(() => {
    let params = { ...pagination, is_read: !checked ? 1 : 0 };
    callNotification(params);
  }, []);

  return (
    <div className={classes.root}>
      <HeaderNotification />
      <div className={classes.body}>
        <div className={classes.coverList}>
          <div className={classes.coverAction}>
            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  color="primary"
                  classes={{
                    colorPrimary: classes.checkboxPrimary,
                    checked: classes.checkboxChecked,
                  }}
                  checked={checked}
                  onChange={(e) => {
                    handleCheckedIsRead(e.target.checked);
                  }}
                />
              }
              label={t("Hide read notifications")}
              labelPlacement="end"
            />
            <Button
              variant="outlined"
              classes={{ root: classes.button }}
              onClick={handleReadAll}
            >
              {/* <PlaylistAddCheckIcon /> */}
              <img src={CheckReadall} alt="CheckReadall" />
            </Button>
            {/* <Button variant="outlined" classes={{ root: classes.button }}>
              <MoreHorizIcon />
            </Button> */}
          </div>
          <List>
            {notificationState &&
              notificationState.results?.map((noti) => {
                return (
                  <ItemNotification
                    noti={noti}
                    key={noti.id}
                    handleRead={handleRead}
                  />
                );
              })}
          </List>
          {notificationState?.count > pagination.page_size && (
            <div className={classes.rootPagination}>
              <Pagination
                count={Math.ceil(
                  notificationState?.count / pagination.page_size || 0
                )}
                page={pagination.page}
                onChange={(_, value) => {
                  handleChangePage(value);
                }}
                variant="outlined"
                shape="rounded"
                classes={{ root: classes.pagination }}
                renderItem={(item) => (
                  <PaginationItem
                    classes={{
                      root: classes.rootPaginationItem,
                      ellipsis: classes.ellipsisPagination,
                      selected: classes.pageSelected,
                    }}
                    {...item}
                    variant="outlined"
                  />
                )}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
