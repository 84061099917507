import {
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import BodyWallet from "../../components/Body/BodyWallet/BodyWallet";
import FormDeposit from "../../components/Body/BodyWallet/FormDeposit";
import FormWithdrawal from "../../components/Body/BodyWallet/FormWithdrawal";
import Network from "../../components/Body/BodyWallet/Network";
import WalletInfo from "../../components/Body/BodyWallet/WalletInfo";
import DialogConfirmTransfer from "../../components/Dialog/DialogConfirmTransfer";
import DrawerCurrencyTransaction from "../../components/Drawer/DrawerCurrencyTransaction";
import HeaderCurrencyTransaction from "../../components/Header/HeaderCurrencyTransaction";
import TableTransactionHistory from "../../components/Table/TableTransactionHistory";
import { bch } from "../../const/currency";
import { withdrawWalletReq } from "../../redux/actions/walletAction";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: { width: "100%" },
      paper: {
        // padding: "30px 0 50px 0",
        margin: "0 0 30px 0",
        background: "#181A20",
        boxShadow: "none",
      },
      typography: {
        color: theme.text.primary,
        margin: "10px 0 15px 15px",
      },
    }),
  { index: 1 }
);

export default function CurrencyTransaction(props) {
  const classes = useStyles(props);
  const {
    transactionInfo,
    handleBackToWallet,
    openExchange,
    walletSelected,
    handleSelectWallet,
    pagination,
    handleChangePage,
    handleResetPagination,
    handleChangeTxType,
    tx_type,
  } = props;
  const { t } = useTranslation();
  const [valueTabTransaction, setValueTabTransaction] = useState("");
  const [valueTabNetwork, setValueTabNetwork] = useState("");
  const [openConfirmTransfers, setOpenConfirmTransfers] = useState(false);
  const [dataTransfer, setDataTransfer] = useState({});
  const [withdrawFee, setWithdrawFee] = useState(0);
  const [withdrawValue, setWithdrawValue] = useState(0);
  //   const [tx_type, setTx_type] = useState();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const dispatch = useDispatch();
  const walletsInfo = useSelector((state) => state.walletReducer.info);
  const walletTransaction = useSelector(
    (state) => state.walletReducer.transaction
  );
  const loading = useSelector((state) => state.walletReducer.loading);

  const handleSetTxType = (newValue) => {
    switch (newValue) {
      case t("Deposit"): {
        return "deposit";
      }
      case t("Withdraw"): {
        return "withdraw";
      }
      default:
        return;
    }
  };

  const handleChangeTabTransaction = (_, newValue) => {
    if (newValue === t("Deposit")) {
      setValueTabNetwork(t("TRC20"));
    } else setValueTabNetwork(t("Internal"));
    setValueTabTransaction(newValue);
    let tx_type = handleSetTxType(newValue);
    // setTx_type(tx_type);
    handleChangeTxType(tx_type);
    handleResetPagination();
  };

  const handleChangeTabNetwork = (_, newValue) => {
    setValueTabNetwork(newValue);
    let processFee;
    let feeFlat = walletSelected.c?.withdraw_fee_flat || 0;
    let feePct = walletSelected.c?.withdraw_fee_pct || 0;
    switch (newValue) {
      case t("Internal"):
        processFee = 0;
        break;

      default:
        processFee =
          parseFloat(withdrawValue) * parseFloat(feePct) + parseFloat(feeFlat);
        break;
    }

    setWithdrawFee(processFee);
  };

  const handleTransfer = (values) => {
    setDataTransfer({ ...dataTransfer, ...values });
    setOpenConfirmTransfers(true);
  };

  const handleCloseConfirmTransfers = () => {
    setOpenConfirmTransfers(false);
  };

  const handleConfirmTransfer = (values) => {
    let dataReq = { ...dataTransfer, ...values };
    let walletId = walletSelected.id;
    switch (valueTabNetwork) {
      case t("Internal"): {
        dataReq = { ...dataReq, network: "", is_internal: true };
        break;
      }
      case t("BEP20"): {
        dataReq = {
          ...dataReq,
          network:
            walletSelected?.c?.symbol === bch.symbol
              ? "BCH_BEP20"
              : "USDT_BEP20",
          is_internal: false,
        };
        break;
      }
      case t("TRC20"): {
        dataReq = { ...dataReq, network: "USDT_TRC20", is_internal: false };
        break;
      }
      default:
        break;
    }
    if (valueTabNetwork === t("Internal")) {
      dataReq = { ...dataReq };
    }
    let paramsTransaction = {
      page: 1,
      page_size: pagination.page_size,
      tx_type,
    };
    dispatch(withdrawWalletReq(walletId, dataReq, paramsTransaction));
    setDataTransfer(dataReq);
    handleCloseConfirmTransfers();
  };

  const handleChangeAmount = (values, networkType) => {
    let feeFlat = walletSelected.c?.withdraw_fee_flat || 0;
    let feePct = walletSelected.c?.withdraw_fee_pct || 0;
    let processFee;
    switch (networkType) {
      case t("Internal"):
        processFee = 0;
        break;

      default:
        processFee =
          parseFloat(values) * parseFloat(feePct) + parseFloat(feeFlat);
        break;
    }

    setWithdrawFee(processFee);
    setWithdrawValue(parseFloat(values));
  };

  const renderFormTransaction = () => {
    switch (valueTabTransaction) {
      case t("Deposit"): {
        return (
          <FormDeposit
            walletSelected={walletSelected}
            networkType={valueTabNetwork}
          />
        );
      }
      case t("Withdraw"): {
        return (
          <FormWithdrawal
            networkType={valueTabNetwork}
            handleTransfer={handleTransfer}
            walletSelected={walletSelected}
            handleChangeAmount={handleChangeAmount}
            withdrawFee={withdrawFee}
            withdrawValue={withdrawValue}
          />
        );
      }
      default:
        return null;
    }
  };

  const renderCurrencyTypeAndNetwork = () => {
    return (
      <>
        <Grid item xs={12} sm={4}>
          <WalletInfo
            walletSelected={walletSelected}
            walletsInfo={walletsInfo}
            handleSelectWallet={handleSelectWallet}
          />
        </Grid>
        <Grid item sm={2}></Grid>
        <Grid item xs={12} sm={5}>
          <Network
            transactionType={valueTabTransaction}
            handleChangeTab={handleChangeTabNetwork}
            valueTab={valueTabNetwork}
            walletSelected={walletSelected}
          >
            {renderFormTransaction()}
          </Network>
        </Grid>
      </>
    );
  };

  const renderHeaderCurrencyTransaction = () => {
    return (
      <HeaderCurrencyTransaction
        valueTab={valueTabTransaction}
        handleChangeTab={handleChangeTabTransaction}
        handleBackToWallet={handleBackToWallet}
        walletSelected={walletSelected}
      />
    );
  };

  //   useEffect(() => {
  //     dispatch(getMainTransactionReq({ ...pagination, tx_type }));
  //   }, [pagination, tx_type]);

  useEffect(() => {
    setValueTabTransaction(transactionInfo.action);
    if (transactionInfo.action === t("Deposit")) {
      setValueTabNetwork(t("TRC20"));
    } else setValueTabNetwork(t("Internal"));
    // let tx_type = handleSetTxType(transactionInfo.action);
    // setTx_type(tx_type);
    // dispatch(getMainTransactionReq({ ...pagination, tx_type }));
  }, [transactionInfo.action]);

  return (
    <div className={classes.root}>
      {matches ? (
        <DrawerCurrencyTransaction
          openDrawer={openExchange}
          handleCloseDrawer={handleBackToWallet}
          valueTab={valueTabTransaction}
          headerSwitchExchange={renderHeaderCurrencyTransaction()}
        >
          <BodyWallet>{renderCurrencyTypeAndNetwork()}</BodyWallet>
        </DrawerCurrencyTransaction>
      ) : (
        <>
          {openExchange && (
            <Grid container>
              {renderHeaderCurrencyTransaction()}
              <BodyWallet>
                {renderCurrencyTypeAndNetwork()}
                <Grid item sm={12}>
                  <Paper className={classes.paper}>
                    <Typography variant="body2" className={classes.typography}>
                      {valueTabTransaction === t("Deposit")
                        ? t("Deposit history")
                        : t("Withdrawal history")}
                    </Typography>
                    <TableTransactionHistory
                      loading={loading}
                      walletTransaction={walletTransaction}
                      handleChangePage={handleChangePage}
                      pagination={pagination}
                    />
                  </Paper>
                </Grid>
              </BodyWallet>
            </Grid>
          )}
        </>
      )}
      <DialogConfirmTransfer
        handleConfirmTransfer={handleConfirmTransfer}
        open={openConfirmTransfers}
        handleClose={handleCloseConfirmTransfers}
        withdrawFee={withdrawFee}
        withdrawValue={withdrawValue}
        walletSelected={walletSelected}
        network={valueTabNetwork}
        dataTransfer={dataTransfer}
        loading={loading}
      />
    </div>
  );
}

CurrencyTransaction.propTypes = {
  transactionInfo: PropTypes.object,
  handleBackToWallet: PropTypes.func,
  openExchange: PropTypes.bool,
};
