import {
  Container,
  createStyles,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { CryptocurrencyMarket } from "react-tradingview-embed";
import TradingPage from "../../../assets/img/TradingPage.png";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        width: "100vw",
        minHeight: 700,
        background: "linear-gradient(97.09deg, #000428 -2.03%, #004E92 99.57%)",
        position: "relative",
        [theme.breakpoints.down("xs")]: {
          minHeight: 520,
        },
      },
      rootBody: { width: "100%" },
      typography: {
        width: "50%",
        margin: "auto",
        color: theme.text.primary,
        fontSize: 38,
        textAlign: "center",
        marginTop: 70,
        [theme.breakpoints.down("xs")]: {
          fontSize: 24,
          width: "90%",
        },
      },
      coverImage: {
        display: "flex",
        justifyContent: "center",
      },
      image: {
        zIndex: 10,
        marginBottom: 50,
        marginTop: 50,
        width: "100%",
        maxWidth: "1000px",
        height: "400px",
        [theme.breakpoints.down("xs")]: {
          width: "90%",
          height: "200px",
          marginBottom: 10,
          marginTop: 10,
        },
      },
    }),
  { index: 1 }
);

export default function PaperFirstFloor(props) {
  const classes = useStyles();
  const { children } = props;
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      {children}
      <div className={classes.rootBody}>
        <Typography variant="h4" className={classes.typography}>
          {t("Trading system crypto market. High liquidity transparency")}
        </Typography>
        <div className={classes.coverImage}>
          <Container
            classes={{ root: classes.image }}
            className="crypto-market"
          >
            <CryptocurrencyMarket
              widgetProps={{
                colorTheme: "dark",
                width: "100%",
                height: "100%",
              }}
            />
          </Container>

          {/* <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/HHdMFdD0o08"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            className={classes.image}
          ></iframe> */}
          {/* <img
            src={`${TradingPage}`}
            alt="TradingPage"
            className={classes.image}
          ></img> */}
        </div>
      </div>
    </div>
  );
}
