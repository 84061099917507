import {
  Card,
  CardContent,
  CardHeader,
  Drawer,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import HeaderCurrencyTransaction from "../Header/HeaderCurrencyTransaction";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.body.backgroundColor,
      height: "100%",
    },
    rootCard: {
      backgroundColor: theme.body.backgroundColor,
      boxShadow: "none",
    },
    rootCardHeader: {
      background: theme.color.background,
    },
    rootCardContent: {
      maxHeight: "90%",
      overflowY: "auto",
    },
    typography: {
      color: theme.text.primary,
      width: "100%",
      hyphens: "auto",
      fontSize: 16,
      //   wordWrap: "break-word",
    },
    iconButton: {
      color: "#C4C4C4",
    },
  }),
  { index: 1 }
);

function DrawerAccountVerification(props) {
  const classes = useStyles();
  const { openDrawer, handleCloseDrawer, children } = props;
  const { t } = useTranslation();

  return (
    <Drawer
      anchor={"bottom"}
      open={openDrawer}
      onClose={handleCloseDrawer}
      classes={{ paper: classes.root }}
    >
      <Card classes={{ root: classes.rootCard }}>
        <CardHeader
          classes={{ root: classes.rootCardHeader }}
          title={
            <>
              <Typography
                classes={{ root: classes.typography }}
                variant="body1"
              >
                {t("Account verification")}
              </Typography>
            </>
          }
          action={
            <IconButton
              classes={{ root: classes.iconButton }}
              onClick={handleCloseDrawer}
            >
              <CloseIcon />
            </IconButton>
          }
        />
        <CardContent classes={{ root: classes.rootCardContent }}>
          {children}
        </CardContent>
      </Card>
    </Drawer>
  );
}

DrawerAccountVerification.propTypes = {
  openDrawer: PropTypes.bool,
  handleCloseDrawer: PropTypes.func,
  children: PropTypes.node,
};

export default DrawerAccountVerification;
