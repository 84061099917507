import { createTheme } from "@material-ui/core";

export const theme = createTheme({
  typography: {
    fontFamily: "'Lato', sans-serif",
  },
  color: {
    trendingUp: "#55ad82",
    trendingDown: "#eb6a61",
    background: "#1E272C",
    primary: "#009688",
    primaryHover: "#009699",
    default: "#263238",
    border: "#272C3A",
    light: "#B0BEC5",
    disabled: "#bdbdbd",
  },
  body: {
    backgroundColor: "#263238",
  },
  radius: {
    primary: 10,
    secondary: 5,
  },
  marginLayout: 5,
  text: {
    primary: "#B0BEC5",
    secondary: "#8B8B8B",
    link: "#009688",
    danger: "#eb6a61",
    success: "#55ad82",
    demo: "#ffcb6b",
  },
  overrides: {
    MuiTabs: {
      indicator: {
        height: 1.5,
        backgroundColor: "#009688",
      },
    },
    MuiTab: {
      textColorInherit: {
        color: "#fff",
      },
    },
  },
});
