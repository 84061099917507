import {
  Card,
  CardContent,
  CardHeader,
  Drawer,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import HeaderCurrencyTransaction from "../Header/HeaderCurrencyTransaction";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.body.backgroundColor,
      height: "100%",
    },
    rootCard: {
      backgroundColor: theme.body.backgroundColor,
      boxShadow: "none",
    },
    rootCardHeader: {
      background: theme.color.background,
      paddingBottom: 0,
    },
    rootCardContent: { padding: 0, maxHeight: "83%", overflowY: "auto" },
    typography: {
      color: theme.text.primary,
      width: "100%",
      hyphens: "auto",
      fontSize: 16,
      //   wordWrap: "break-word",
    },
    iconButton: {
      color: "#C4C4C4",
    },
  }),
  { index: 1 }
);

function DrawerCurrencyTransaction(props) {
  const classes = useStyles();
  const {
    openDrawer,
    handleCloseDrawer,
    children,
    valueTab,
    headerSwitchExchange,
  } = props;

  return (
    <Drawer
      anchor={"bottom"}
      open={openDrawer}
      onClose={handleCloseDrawer}
      classes={{ paper: classes.root }}
    >
      <Card classes={{ root: classes.rootCard }}>
        <CardHeader
          classes={{ root: classes.rootCardHeader }}
          title={
            <>
              <Typography
                classes={{ root: classes.typography }}
                variant="body1"
              >
                {valueTab || ""}
              </Typography>
              {headerSwitchExchange}
            </>
          }
          action={
            <IconButton
              classes={{ root: classes.iconButton }}
              onClick={handleCloseDrawer}
            >
              <CloseIcon />
            </IconButton>
          }
        />
        <CardContent classes={{ root: classes.rootCardContent }}>
          {children}
        </CardContent>
      </Card>
    </Drawer>
  );
}

DrawerCurrencyTransaction.propTypes = {
  openDrawer: PropTypes.bool,
  handleCloseDrawer: PropTypes.func,
  valueTab: PropTypes.string,
  children: PropTypes.node,
};

export default DrawerCurrencyTransaction;
