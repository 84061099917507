import { createStyles, makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import QRCode from "qrcode.react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ContentCopyLink from "../../../assets/img/ContentCopyLink.png";
import { callToastNotiSuccess } from "../../../redux/actions/toastNotiAction";
import { TOAST_NOTI } from "../../../const/common";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        textAlign: "center",
        marginTop: 20,
      },
      textAddressTitle: {
        padding: "10px 0",
        color: theme.text.primary,
      },
      coverCode: {
        cursor: "pointer",
      },
      textCode: {
        marginTop: 20,
        display: "block",
        color: theme.text.link,
      },
    }),
  { index: 1 }
);

function FormDeposit(props) {
  const classes = useStyles(props);
  const { walletSelected, networkType } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleCopyCode = (qrCode) => {
    navigator.clipboard.writeText(qrCode);
    dispatch(
      callToastNotiSuccess({ noti: t("Copied"), status: TOAST_NOTI.SUCCESS })
    );
  };

  return (
    <div className={classes.root}>
      <Typography variant="body2" classes={{ root: classes.textAddressTitle }}>
        {t("Address")} {walletSelected?.c?.symbol}
      </Typography>
      {walletSelected.all_address
        ?.filter((address) => {
          return address.network.includes(networkType);
        })
        ?.map((address) => {
          return (
            <>
              <QRCode
                id="qrcode"
                value={address?.address}
                size={100}
                level={"H"}
                includeMargin={true}
              />
              <div
                className={classes.coverCode}
                onClick={() => {
                  handleCopyCode(address?.address);
                }}
              >
                <Typography
                  variant="caption"
                  classes={{ root: classes.textCode }}
                >
                  {address?.address}{" "}
                  <img src={`${ContentCopyLink}`} alt="ContentCopyLink"></img>
                </Typography>
              </div>
            </>
          );
        })}
    </div>
  );
}

FormDeposit.propTypes = {};

export default FormDeposit;
