import {
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  Drawer,
  IconButton,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import FormDepositBot from "../../components/Body/BodyBotV2/FormDepositBot";
import FormWithdrawBot from "../../components/Body/BodyBotV2/FormWithdrawBot";
import DialogConfirmUpgradeBotV2 from "../../components/Dialog/DialogConfirmUpgradeBotV2";
import { depositBotV2, withdrawBotV2 } from "../../apis/botv2.api";
import { callToastNotiSuccess } from "../../redux/actions/toastNotiAction";
import { TOAST_NOTI } from "../../const/common";
import { getBotV2Req } from "../../redux/actions/botV2Action";
import { getUserWalletReq } from "../../redux/actions/walletAction";

const useStyles = makeStyles(
  (theme) => ({
    rootBody: {
      backgroundColor: theme.color.background,
      marginTop: 10,
      padding: "20px 10px 100px 10px",
    },
    root: {
      backgroundColor: theme.color.background,
      height: "100%",
    },
    rootCard: {
      backgroundColor: theme.color.background,
      boxShadow: "none",
    },
    typography: {
      color: theme.text.primary,
      width: "100%",
      hyphens: "auto",
      //   wordWrap: "break-word",
    },
    iconButton: {
      color: "#C4C4C4",
    },
    paperDialog: {
      width: 456,
      // minHeight: 573,
      // maxHeight: 700,
      backgroundColor: "#191D23",
      boxShadow: "none ",
    },
    dialogHeader: {
      color: "#fff",
      padding: "10px 20px",
    },
    closeButton: {
      position: "absolute",
      right: 2,
      top: 0,
      color: theme.text.primary,
    },
    dialogContent: {
      padding: "10px 20px 20px 20px",
    },
    dividers: { borderTopColor: "#272C3A" },
  }),
  { index: 1 }
);

export default function BotV2Upgrade(props) {
  const classes = useStyles(props);
  const { handleBackToBotStatistic, openDrawer, action } = props;
  const walletInfo = useSelector((state) => state.walletReducer.info);
  const [dataReq, setDataReq] = useState({});
  const [openComfirm, setOpenConfirm] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const botV2Info = useSelector((state) => state.botV2Reducer.info);
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const { t } = useTranslation();

  const handleUpgrade = (value) => {
    handleBackToBotStatistic();
    setDataReq({ ...value });
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleConfirmUpgrade = async () => {
    setLoadingConfirm(true);
    switch (action) {
      case "deposit":
        await depositBotV2(dataReq)
          .then((res) => {
            if (res.status === 200) {
              dispatch(
                callToastNotiSuccess({
                  noti: t("Deposit successfully"),
                  status: "success",
                })
              );
              dispatch(getBotV2Req());
              dispatch(getUserWalletReq());
            } else
              dispatch(
                callToastNotiSuccess({
                  noti: t("Deposit failed"),
                  status: "error",
                })
              );
          })
          .catch((err) => {
            let { data } = err.response;
            dispatch(
              callToastNotiSuccess({
                noti: data.message,
                status: TOAST_NOTI.ERROR,
                code: data.code,
              })
            );
          });
        break;
      case "withdraw": {
        await withdrawBotV2(dataReq)
          .then((res) => {
            if (res.status === 200) {
              dispatch(
                callToastNotiSuccess({
                  noti: t("Withdraw successfully"),
                  status: "success",
                })
              );
              dispatch(getBotV2Req());
            } else
              dispatch(
                callToastNotiSuccess({
                  noti: t("Withdraw failed"),
                  status: "error",
                })
              );
          })
          .catch((err) => {
            let { data } = err.response;
            dispatch(
              callToastNotiSuccess({
                noti: data.message,
                status: TOAST_NOTI.ERROR,
                code: data.code,
              })
            );
          });
        break;
      }
      default:
        break;
    }

    setLoadingConfirm(false);
    handleBackToBotStatistic();
  };

  const renderBody = () => {
    switch (action) {
      case "deposit": {
        return (
          <FormDepositBot
            walletCurrent={
              walletInfo.results?.filter(
                (wallet) => wallet.c.symbol === "USDT"
              )[0]
            }
            walletAla={
              walletInfo.results?.filter(
                (wallet) => wallet.c.symbol === "ALA"
              )[0]
            }
            handleDeposit={handleUpgrade}
          />
        );
      }
      case "withdraw": {
        return (
          <FormWithdrawBot
            walletCurrent={
              walletInfo.results?.filter(
                (wallet) => wallet.c.symbol === "USDT"
              )[0]
            }
            handleWithdraw={handleUpgrade}
            botV2Info={botV2Info}
          />
        );
      }
      default:
        return null;
    }
  };

  return (
    <>
      {matches ? (
        <Drawer
          anchor={"bottom"}
          open={openDrawer}
          onClose={handleBackToBotStatistic}
          classes={{ paper: classes.root }}
        >
          <Card classes={{ root: classes.rootCard }}>
            <CardHeader
              title={
                <Typography classes={{ root: classes.typography }} variant="h6">
                  {action === "deposit" ? t("Deposit") : t("Withdraw")}
                </Typography>
              }
              action={
                <IconButton
                  classes={{ root: classes.iconButton }}
                  onClick={handleBackToBotStatistic}
                >
                  <CloseIcon />
                </IconButton>
              }
            />
            <CardContent>{renderBody()}</CardContent>
          </Card>
        </Drawer>
      ) : (
        <Dialog
          open={openDrawer}
          onClose={handleBackToBotStatistic}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={false}
          classes={{
            paper: classes.paperDialog,
          }}
        >
          <DialogTitle id="alert-dialog-title" className={classes.dialogHeader}>
            <Typography variant="body1">
              {" "}
              {action === "deposit" ? t("Deposit") : t("Withdraw")}
            </Typography>
            <IconButton
              className={classes.closeButton}
              onClick={handleBackToBotStatistic}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            className={classes.dialogContent}
            dividers={true}
            classes={{ dividers: classes.dividers }}
          >
            {renderBody()}
          </DialogContent>
        </Dialog>
      )}

      {Object.keys(walletInfo).length > 0 && (
        <DialogConfirmUpgradeBotV2
          open={openComfirm}
          action={action}
          handleClose={handleCloseConfirm}
          handleConfirmUpgrade={handleConfirmUpgrade}
          amount={dataReq.amount || 0}
          loadingConfirm={loadingConfirm}
        />
      )}
    </>
  );
}

BotV2Upgrade.propTypes = {};
