export const GET_USER_WALLET_REQ = "GET_USER_WALLET_REQ";
export const GET_USER_WALLET_SUCCESS = "GET_USER_WALLET_SUCCESS";

export const GENERATE_WALLET_ADDRESS_REQ = "GENERATE_WALLET_ADDRESS_REQ";
export const GENERATE_WALLET_ADDRESS_SUCCESS =
  "GENERATE_WALLET_ADDRESS_SUCCESS";

export const GET_MAIN_TRASACTION_REQ = "GET_MAIN_TRASACTION_REQ";
export const GET_MAIN_TRASACTION_SUCCESS = "GET_MAIN_TRASACTION_SUCCESS";

export const GET_CONVERT_TRASACTION_REQ = "GET_CONVERT_TRASACTION_REQ";
export const GET_CONVERT_TRASACTION_SUCCESS = "GET_CONVERT_TRASACTION_SUCCESS";

export const WITHDRAW_WALLET_REQ = "WITHDRAW_WALLET_REQ";
export const WITHDRAW_WALLET_SUCCESS = "WITHDRAW_WALLET_SUCCESS";

export const DESTROY_WALLET = "DESTROY_WALLET";

export const SET_LOADING_WALLET = "SET_LOADING_WALLET";

export const GET_DEMO_WALLET_REQ = "GET_DEMO_WALLET_REQ";
export const GET_DEMO_WALLET_SUCCESS = "GET_DEMO_WALLET_SUCCESS";

export const SET_ACCOUNT = "SET_ACCOUNT";

export const RE_GET_USER_WALLET_REQ = "RE_GET_USER_WALLET_REQ";
export const RE_GET_USER_WALLET_SUCCESS = "RE_GET_USER_WALLET_SUCCESS";
