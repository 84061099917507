import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import convertNumberToMoney from "../../../common/convertNumberToMoney";
import CurrencySelect from "./CurrencySelect";
import { useState } from "react";
import { useEffect } from "react";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {},
      title: {
        color: theme.text.primary,
      },
      textField: {
        marginBottom: 15,
        backgroundColor: "#2A2D35",
      },
      cssOutlinedInput: {
        "&$cssFocused $notchedOutline": {
          borderColor: `${theme.text.primary} !important`,
        },
      },
      notchedOutline: {
        borderWidth: "1px",
        borderColor: "#2A2D35 !important",
        borderRadius: "2px !important",
      },
      multilineColor: {
        color: theme.text.primary,
        fontSize: 13,
      },
      input: {
        "&::placeholder": {
          opacity: 0.2,
        },
      },
      typographyLabel: {
        color: theme.text.secondary,
      },
      textPrimary: { color: theme.text.primary, padding: "10px 0" },
      coverGetAll: { cursor: "pointer", textAlign: "right" },
      endAdmornment: {
        color: theme.color.primary,
      },
      typographyValue: { paddingLeft: 20, color: theme.text.primary },
      buttonSubmit: {
        width: "100%",
        color: theme.text.primary,
        backgroundColor: theme.color.primary,
        textTransform: "none",
        borderRadius: 2,
        marginTop: 10,
      },
      gridItemLeft: {
        paddingBottom: 10,
      },
      gridItemRight: { textAlign: "right", paddingBottom: 10 },
      typographyWarning: { color: "#F0B90B" },
    }),
  { index: 1 }
);

const initialValues = {
  amount: "",
};

export default function FormConvertCurrency(props) {
  const classes = useStyles(props);
  const {
    walletCurrent,
    wallets,
    handleConvert,
    dataConvert,
    loading,
    isCanSelectCurrency,
    handleOpenConvertCurrency,
  } = props;
  const { t } = useTranslation();
  const [walletSelected, setWalletSelected] = useState();

  useEffect(() => {
    if (wallets && dataConvert) {
      const walletAla = wallets?.filter(
        (wallet) => wallet.c.symbol === dataConvert?.pair?.symbol
      )[0];
      setWalletSelected(walletAla);
    }
  }, [JSON.stringify(wallets), JSON.stringify(dataConvert)]);

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .max(
        parseFloat(dataConvert.max_amount),
        () =>
          `${t("Maximum")} ${convertNumberToMoney(
            parseFloat(dataConvert.max_amount)
          )} ${dataConvert.base?.symbol}`
      )
      .min(
        parseFloat(dataConvert.min_amount),
        () =>
          `${t("Minimum")} ${convertNumberToMoney(
            parseFloat(dataConvert.min_amount)
          )} ${dataConvert.base?.symbol}`
      )
      .required(t("Not be empty")),
  });

  const onSubmit = (values) => {
    let dataReq = {
      amount: parseFloat(values.amount) || 0,
      pair: dataConvert.id,
    };
    handleConvert(dataReq);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => onSubmit(values),
  });

  const handleGetAllAmount = () => {
    formik.setFieldValue("amount", parseFloat(walletCurrent.balance));
  };

  const renderAmountReceived = () => {
    let fee =
      parseFloat(formik.values.amount) * parseFloat(dataConvert.fee_pct) +
      parseFloat(dataConvert.fee_flat);
    let convertedAmount =
      (parseFloat(formik.values.amount) - fee) *
      parseFloat(dataConvert.current_rate);
    return parseFloat(convertedAmount || 0).toFixed(2);
  };

  const renderFee = () => {
    let fee =
      parseFloat(formik.values.amount) * parseFloat(dataConvert.fee_pct) +
      parseFloat(dataConvert.fee_flat);
    return parseFloat(fee * parseFloat(dataConvert.current_rate) || 0).toFixed(
      2
    );
  };

  console.log("walletCurrent", walletCurrent);
  console.log("dataConvert", dataConvert);
  console.log("walletSelected", walletSelected);
  //   useEffect(() => {
  //     handleChangeAmount(formik.values.amount);
  //   }, [formik.values.amount]);

  return (
    <div className={classes.root}>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <Typography
            variant="body1"
            className={classes.textPrimary}
            style={{ textAlign: "center" }}
          >
            1 USDT ={" "}
            {parseFloat(1 / parseFloat(walletSelected?.c?.usdt_rate)).toFixed(
              2
            )}{" "}
            {walletSelected?.c.symbol}
          </Typography>
        </div>
        {isCanSelectCurrency && (
          <CurrencySelect
            label={"Currency"}
            walletSelected={walletSelected}
            wallets={wallets.filter((wallet) =>
              ["USDT", "ALA"].includes(wallet?.c.symbol)
            )}
            handleSelectWallet={(wallet) => {
              setWalletSelected(wallet);
              handleOpenConvertCurrency(
                walletCurrent?.available_convert_pair?.filter(
                  (pair) => pair?.pair?.symbol === wallet?.c.symbol
                )[0],
                wallet
              );
            }}
          />
        )}
        <div>
          <Typography variant="caption" className={classes.typographyLabel}>
            {t("Amount option")} ({dataConvert.base?.symbol})
          </Typography>
          <TextField
            className={classes.textField}
            fullWidth
            variant="outlined"
            id="amount"
            name="amount"
            type="number"
            InputLabelProps={{ shrink: false }}
            InputProps={{
              endAdornment: (
                <div
                  className={classes.coverGetAll}
                  onClick={handleGetAllAmount}
                >
                  <Typography
                    variant="caption"
                    className={classes.endAdmornment}
                  >
                    {t("All")}
                  </Typography>
                </div>
              ),
              classes: {
                root: classes.cssOutlinedInput,
                notchedOutline: classes.notchedOutline,
                input: classes.input,
              },
              className: classes.multilineColor,
            }}
            size="small"
            onChange={formik.handleChange}
            //   onBlur={formik.handleBlur}
            value={formik.values.amount}
            error={formik.touched.amount && Boolean(formik.errors.amount)}
            helperText={formik.touched.amount && formik.errors.amount}
          />
        </div>
        <Grid container>
          <Grid item xs={12} className={classes.gridItemLeft}>
            <Typography variant="caption" className={classes.typographyLabel}>
              {t("Available balances")}:
            </Typography>
            <Typography variant="caption" className={classes.typographyValue}>
              {convertNumberToMoney(parseFloat(walletCurrent.balance))}{" "}
              {dataConvert.base?.symbol}
            </Typography>
          </Grid>{" "}
          <Grid item xs={12} className={classes.gridItemLeft}>
            <Typography variant="caption" className={classes.typographyLabel}>
              {t("Convert fee")}:
            </Typography>
            <Typography variant="caption" className={classes.typographyValue}>
              {renderFee()} {dataConvert.pair?.symbol}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.gridItemLeft}>
            <Typography variant="caption" className={classes.typographyLabel}>
              {t("Amount received")}:
            </Typography>
            <Typography variant="caption" className={classes.typographyValue}>
              {renderAmountReceived()} {dataConvert.pair?.symbol}
            </Typography>
          </Grid>
        </Grid>
        <div>
          <Button className={classes.buttonSubmit} type="submit">
            {loading ? (
              <div className={classes.coverLoading}>
                <CircularProgress style={{ color: "#fff" }} size={25} />
              </div>
            ) : (
              t("Convert")
            )}
          </Button>
        </div>
      </form>
    </div>
  );
}

FormConvertCurrency.propTypes = {};
