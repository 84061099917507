import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { PATH } from "../../const/paths";

const useStyles = makeStyles(
  (theme) => ({
    rootCard: {
      width: 456,
      height: 300,
      margin: "auto",
      backgroundColor: "#191D23",
      [theme.breakpoints.down("xs")]: {
        width: "100vw",
        height: "91vh",
        margin: 0,
        borderRadius: 0,
      },
    },
    rootCardHeader: {
      borderBottom: `1px solid ${theme.color.border}`,
      padding: "5px 15px",
    },
    typographyTitle: {
      textAlign: "center",
      color: theme.text.primary,
    },
    iconButton: {
      color: theme.text.primary,
    },
    typographySecondary: {
      color: theme.text.secondary,
    },
    textField: {
      marginBottom: 15,
      backgroundColor: "#2A2D35",
    },
    cssOutlinedInput: {
      "&$cssFocused $notchedOutline": {
        borderColor: `${theme.text.primary} !important`,
      },
    },
    notchedOutline: {
      borderWidth: "1px",
      borderColor: "#2A2D35 !important",
      borderRadius: "2px !important",
    },
    multilineColor: {
      color: theme.text.primary,
      fontSize: 13,
    },
    input: {
      "&::placeholder": {
        opacity: 0.2,
      },
    },
    buttonSubmit: {
      width: "100%",
      color: theme.text.primary,
      backgroundColor: theme.color.primary,
      textTransform: "none",
      borderRadius: 2,
      marginTop: 10,
    },
    coverButtonFooter: {
      marginTop: 30,
    },
    buttonRegister: {
      color: theme.text.link,
      textTransform: "none",
      float: "left",
    },
    buttonForgotPassword: {
      color: theme.text.link,
      textTransform: "none",
      float: "right",
    },
  }),
  { index: 1 }
);

const initialValues = {
  email: "",
};

function FormForgotPassword(props) {
  const classes = useStyles(props);
  const { handleSendMail } = props;
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("Email must be a valid email"))
      .required(t("Not be empty")),
  });

  const onSubmit = (values) => {
    handleSendMail(values);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => onSubmit(values),
  });

  return (
    <div>
      <Card classes={{ root: classes.rootCard }}>
        <CardHeader
          classes={{ root: classes.rootCardHeader }}
          title={
            <Typography
              variant="body1"
              classes={{ root: classes.typographyTitle }}
            >
              {t("Forgot password")}
            </Typography>
          }
          action={
            <IconButton
              aria-label="close"
              classes={{ root: classes.iconButton }}
            >
              <CloseIcon />
            </IconButton>
          }
        />
        <CardContent>
          <div>
            <Typography
              variant="body1"
              classes={{ root: classes.typographyTitle }}
            >
              {t("Please enter")}
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              <div>
                <Typography
                  variant="caption"
                  className={classes.typographySecondary}
                >
                  {t("Email")} *
                </Typography>
                <TextField
                  className={classes.textField}
                  fullWidth
                  variant="outlined"
                  name="email"
                  id="email"
                  InputLabelProps={{ shrink: false }}
                  InputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      notchedOutline: classes.notchedOutline,
                      input: classes.input,
                    },
                    className: classes.multilineColor,
                  }}
                  size="small"
                  placeholder={t("Enter your email")}
                  onChange={formik.handleChange}
                  //   onBlur={formik.handleBlur}
                  value={formik.values.email}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </div>
              <div>
                <Button
                  className={classes.buttonSubmit}
                  type="submit"
                  variant="outlined"
                >
                  {t("Send mail")}
                </Button>
              </div>
            </form>
            <div className={classes.coverButtonFooter}>
              <Link to={PATH.LOGIN}>
                <Button
                  classes={{ root: classes.buttonRegister }}
                  variant="text"
                >
                  {t("Login")}
                </Button>
              </Link>
              <Link to={PATH.REGISTER}>
                <Button
                  classes={{ root: classes.buttonForgotPassword }}
                  variant="text"
                >
                  {t("Register")}
                </Button>
              </Link>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

FormForgotPassword.propTypes = {};

export default FormForgotPassword;
