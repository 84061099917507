import {
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { PATH } from "../../const/paths";
import AgencyIcon from "../Icons/AgencyIcon";
import BotIcon from "../Icons/BotIcon";
import BotV2Icon from "../Icons/BotV2Icon";
import DashboardIcon from "../Icons/DashboardIcon";
import LotteryIcon from "../Icons/LotteryIcon";
import ProfileIcon from "../Icons/ProfileIcon";
import HotIcon from "../../assets/img/hot-icon.png";
import WalletIcon from "../Icons/WalletIcon";
import TradeIcon from "../Icons/TradeIcon";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.color.background,
      color: "#fff",
      minHeight: "92vh",
      height: "100%",
      width: 100,
      [theme.breakpoints.down("xs")]: {
        width: 220,
        position: "absolute",
        zIndex: 2,
      },
    },
    itemRoot: {
      position: "relative",
    },
    itemSelected: {
      backgroundColor: "rgba(255, 255, 255, 0.05) !important",
    },
    iconItem: { textAlign: "center" },
    iconActive: {
      "& svg path": {
        fill: theme.color.primary,
      },
    },
    badge: {
      position: "absolute",
      right: 5,
      top: 5,
      width: 25,
    },
  }),
  { index: 1 }
);

function SideBar(props) {
  const classes = useStyles(props);
  const { handleCloseDrawer } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  const sidebarItems = [
    {
      path: PATH.TRANSACTION,
      iconActive: <TradeIcon />,
      icon: <TradeIcon />,
      title: t("Trading"),
    },
    {
      path: PATH.TRADING_VIEW,
      iconActive: <DashboardIcon />,
      icon: <DashboardIcon />,
      title: t("Trading View"),
    },
    {
      path: PATH.WALLET,
      iconActive: <WalletIcon />,
      icon: <WalletIcon />,
      title: t("Wallet"),
    },
    {
      path: PATH.TRADINGBOT,
      iconActive: <BotIcon />,
      icon: <BotIcon />,
      title: t("Trading Bot"),
    },
    {
      path: PATH.TRADINGBOTV2,
      iconActive: <BotV2Icon />,
      icon: <BotV2Icon />,
      title: t("New Trading bot"),
    },
    // {
    //   path: PATH.BOTV2,
    //   iconActive: <BotV2Icon />,
    //   icon: <BotV2Icon />,
    //   title: t("Bot V2"),
    // },
    {
      path: PATH.AGENCY,
      iconActive: <AgencyIcon />,
      icon: <AgencyIcon />,
      title: t("BOT Network"),
    },
    // {
    //   path: PATH.AGENCY_TRADE,
    //   iconActive: <AgencyIcon />,
    //   icon: <AgencyIcon />,
    //   title: t("Network"),
    // },
    {
      path: PATH.LOTTERY,
      iconActive: <LotteryIcon />,
      icon: <LotteryIcon />,
      title: t("Lotteries"),
    },
    // {
    //   path: PATH.DASHBOARD,
    //   iconActive: <DashboardIcon />,
    //   icon: <DashboardIcon />,
    //   title: t("Dashboard"),
    // },
    {
      path: PATH.PROFILE,
      iconActive: <ProfileIcon />,
      icon: <ProfileIcon />,
      title: t("Profile"),
    },
  ];

  const handleChangeLink = (link) => {
    if (handleCloseDrawer) {
      handleCloseDrawer();
    }
    history.push(link);
  };

  const checkPath = (path) => {
    const { location } = history;
    if (
      location.pathname &&
      location.pathname.toLowerCase() === path.toLowerCase()
    )
      return true;
    return false;
  };

  const renderListItem = (path, iconActive, icon, title, index) => {
    return (
      <ListItem
        button
        selected={checkPath(path)}
        classes={{ root: classes.itemRoot, selected: classes.itemSelected }}
        onClick={() => {
          handleChangeLink(path);
        }}
        key={index}
      >
        <ListItemText
          className={classes.iconItem}
          primary={
            checkPath(path) ? (
              <div className={classes.iconActive}>{iconActive}</div>
            ) : (
              icon
            )
            //    <img
            //    src={`${checkPath(path) ? iconActive : icon}`}
            //    alt="Trading"
            //    width={25}
            //    style={!checkPath(path) ? { filter: "brightness(1.75)" } : {}}
            //  />
          }
          secondary={
            <p
              style={{
                fontSize: "14px",
                color: checkPath(path) ? theme.text.link : theme.text.primary,
              }}
            >
              {title}
            </p>
          }
        />
        {path === PATH.TRADINGBOTV2 && (
          <img src={HotIcon} alt="hot-icon" className={classes.badge} />
        )}
      </ListItem>
    );
  };

  return (
    <div className={classes.root}>
      <List>
        {sidebarItems.map((item, index) => {
          return renderListItem(
            item.path,
            item.iconActive,
            item.icon,
            item.title,
            index
          );
        })}
      </List>
    </div>
  );
}

SideBar.propTypes = {};

export default SideBar;
