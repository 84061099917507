import { createStyles, makeStyles, Tab, Tabs } from "@material-ui/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        marginBottom: 5,
        marginLeft: 10,
        [theme.breakpoints.down("xs")]: {
          marginLeft: 0,
        },
      },
      tab: {
        padding: 0,
        margin: "0 5px",
        textTransform: "none",
        fontWeight: "bold",
        // color: "#848E9C",
        // fontFamily: [
        //   "-apple-system",
        //   "BlinkMacSystemFont",
        //   '"Segoe UI"',
        //   "Roboto",
        //   '"Helvetica Neue"',
        //   "Arial",
        //   "sans-serif",
        //   '"Apple Color Emoji"',
        //   '"Segoe UI Emoji"',
        //   '"Segoe UI Symbol"',
        // ].join(","),
        minWidth: 70,
        "&:hover": {
          color: "#fff",
          opacity: 1,
        },
        "&:focus": {
          color: "#fff",
        },
      },
    }),
  { index: 1 }
);

function TabsWalletHistory(props) {
  const classes = useStyles(props);
  const { handleChange, value } = props;
  const { t } = useTranslation();

  return (
    <Tabs
      classes={{ root: classes.root }}
      value={value}
      onChange={handleChange}
      textColor="inherit"
    >
      <Tab
        label={t("Deposit history")}
        value={t("Deposit history")}
        className={classes.tab}
        disableRipple={true}
      />
      <Tab
        label={t("Withdrawal history")}
        value={t("Withdrawal history")}
        className={classes.tab}
        disableRipple={true}
      />
      <Tab
        label={t("Convert transaction")}
        value={"convert_transaction"}
        className={classes.tab}
        disableRipple={true}
      />
    </Tabs>
  );
}

TabsWalletHistory.propTypes = {};

export default TabsWalletHistory;
