import { useEffect } from "react";
import { BOCHART } from "../const/colorChart";
import { firebase } from "../utils/initFirebase";

function volumeBarColor(point) {
  if (point.open < point.close) return BOCHART.BUY;
  else return BOCHART.SELL;
}

export default function useCandleStick(chartRef, candleHistory) {
  const subcribeFirebase = () => {
    const bo = firebase.database().ref(process.env.REACT_APP_FIREBASE_REF);
    bo.on("value", (snapshot) => {
      const data = snapshot.val();
      let candle = chartRef?.current?.chart.series[0];
      let column = chartRef?.current?.chart.series[1];
      let scatter = chartRef?.current?.chart.series[2];
      let xAxisChart = chartRef?.current?.chart.xAxis[0];
      if (candle) {
        const lastBar = candle.data[candle.data.length - 1];
        if (data && lastBar && lastBar.x) {
          if (
            parseFloat(data?.timestamp) * 1000 === lastBar?.x &&
            data.id === lastBar.id
          ) {
            lastBar.update({
              id: data.id,
              x: parseFloat(data.timestamp) * 1000,
              date: parseFloat(data.timestamp) * 1000,
              open: data.open,
              high: data.high,
              low: data.low,
              close: data.close,
              color: volumeBarColor(data),
              lineColor: volumeBarColor(data),
            });
          } else if (data.id > lastBar.id) {
            candle.addPoint(
              {
                id: data.id,
                x: parseFloat(data.timestamp) * 1000,
                date: parseFloat(data.timestamp) * 1000,
                open: data.open,
                high: data.high,
                low: data.low,
                close: data.close,
                color: volumeBarColor(data),
                lineColor: volumeBarColor(data),
              },
              true,
              true
            );
          }
          xAxisChart.update({ min: candle.data[20].x });
        }
      }
      if (column) {
        const lastCol = column.data[column.data.length - 1];
        if (data && lastCol && lastCol.x) {
          if (
            parseFloat(data?.timestamp) * 1000 === lastCol?.x &&
            data.id === lastCol.id
          ) {
            lastCol.update({
              id: data.id,
              x: parseFloat(data.timestamp) * 1000,
              date: parseFloat(data.timestamp) * 1000,
              y: data.volume,
              color: volumeBarColor(data),
            });
          } else if (data.id > lastCol.id) {
            column.addPoint(
              {
                id: data.id,
                x: parseFloat(data.timestamp) * 1000,
                date: parseFloat(data.timestamp) * 1000,
                y: data.volume,
                color: volumeBarColor(data),
              },
              true,
              true
            );
          }
        }
      }
      if (scatter) {
        if (data) {
          scatter.data[0].update({
            x: 0,
            y: data.close,
          });
          scatter.data[1].update({
            x: parseFloat(data.timestamp) * 1000 + 15000,
            y: data.close,
          });

          // let yAxis = chartRef.current.chart.yAxis[0];
          // yAxis.options.plotLines[0].value = data.close;
          // yAxis.update(null, true);
        }
      }
    });
  };

  //   useEffect(() => {

  //   }, [data]);

  useEffect(() => {
    subcribeFirebase();
  }, []);

  return null;
}
