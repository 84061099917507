import { makeStyles, Typography } from "@material-ui/core";
import TicketTurned from "../../../assets/img/TicketTurned.png";
import TicketNotTurned from "../../../assets/img/TicketNotTurned.png";
import moment from "moment";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: 305,
      height: 118,
      position: "relative",
      backgroundRepeat: "no-repeat",
      backgroundSize: "auto",
    },
    coverNumber: {
      display: "flex",
      position: "absolute",
      top: 27,
      right: 35,
      //   justifyContent: "space-between",
    },
    coverDate: {
      position: "absolute",
      bottom: 10,
      right: 70,
    },
    textBold: {
      fontWeight: 700,
      color: theme.text.primary,
    },
  }),
  { index: 1 }
);

export default function Ticket(props) {
  const { turned, numberTicket, dateTicket } = props;
  const classes = useStyles();

  const renderNumber = () => {
    let numbers = numberTicket.split("");
    let result = null;
    result = numbers.map((item, index) => {
      return (
        <div
          style={{
            marginRight: index % 2 !== 0 && index < numbers.length - 1 ? 40 : 0,
          }}
        >
          <Typography variant="h5" classes={{ root: classes.textBold }}>
            {item}
          </Typography>
        </div>
      );
    });
    return result;
  };

  return (
    <div
      className={classes.root}
      style={{
        backgroundImage: turned
          ? `url(${TicketTurned})`
          : `url(${TicketNotTurned})`,
      }}
    >
      <div className={classes.coverNumber}>{renderNumber()}</div>
      <div className={classes.coverDate}>
        <Typography variant="h6" classes={{ root: classes.textBold }}>
          {moment(dateTicket).format("DD/MM/YYYY")}
        </Typography>
      </div>
    </div>
  );
}
