import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
    },
    typographyCount: (props) => {
      let result = {
        color: `${theme.text.link}`,
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
          fontSize: 30,
          //   paddingTop: 15,
          //   paddingBottom: 15,
        },
      };
      if (props.systemSecond >= 30) {
        result.color = `${theme.text.danger}`;
      }
      return result;
    },
    typographyLabel: {
      color: `${theme.text.secondary}`,
      textAlign: "center",
      //   [theme.breakpoints.down("sm")]: {
      //     display: "none",
      //   },
    },
    rootProgress: {
      width: "110px !important",
      height: "110px !important",
      [theme.breakpoints.down("sm")]: {
        width: "80px !important",
        height: "60px !important",
      },
    },
    colorProgress: (props) => {
      let result = {
        color: `${theme.color.primary}`,
        [theme.breakpoints.down("sm")]: {
          color: "transparent",
        },
      };
      if (props.systemSecond >= 30) {
        result.color = `${theme.color.trendingDown}`;
      }
      return result;
    },
  }),
  { index: 1 }
);

export default function CircularProgressWithLabel(props) {
  const classes = useStyles(props);
  const { systemSecond } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div className={classes.root}>
      <Box position="relative" display="inline-flex">
        <CircularProgress
          variant="determinate"
          value={
            systemSecond < 30
              ? (systemSecond / 30) * 100
              : ((systemSecond - 30) / 30) * 100
          }
          thickness={2.0}
          classes={{
            root: classes.rootProgress,
            colorPrimary: classes.colorProgress,
          }}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <div>
            <Typography
              variant="h4"
              classes={{ root: classes.typographyCount }}
            >
              {systemSecond < 30
                ? `${Math.round(30 - systemSecond)}`
                : `${Math.round(60 - systemSecond)}`}
            </Typography>

            <Typography
              variant="caption"
              classes={{ root: classes.typographyLabel }}
            >
              {systemSecond < 30 ? t("Place an order") : t("Wait result")}
            </Typography>
          </div>
        </Box>
      </Box>
      {/* {matches && (
        <Typography
          variant="caption"
          classes={{ root: classes.typographyLabel }}
        >
          {systemSecond < 30 ? t("Place an order") : t("Wait result")}
        </Typography>
      )} */}
    </div>
  );
}

CircularProgressWithLabel.propTypes = {};
