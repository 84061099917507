import dagre from "dagre";
import { cloneDeep } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import ReactFlow, {
  addEdge,
  isNode,
  ReactFlowProvider,
  removeElements,
} from "react-flow-renderer";
import { useDispatch } from "react-redux";
import { destroyTree } from "../../redux/actions/referralAction";
import PopoverNodeTree from "../PopOver/PopoverNodeTree";
import SelectorNode from "./SelectorNode";

const onNodeDragStop = (event, node) => {};
const onElementClick = (event, element) => {};

const connectionLineStyle = { stroke: "#fff" };
const nodeTypes = {
  selectorNode: SelectorNode,
};

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 172;
const nodeHeight = 100;

const getLayoutedElements = (elements, direction = "TB") => {
  const isHorizontal = direction === "LR";
  dagreGraph.setGraph({ rankdir: direction });

  elements.forEach((el) => {
    if (isNode(el)) {
      dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight });
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });

  dagre.layout(dagreGraph);

  return elements.map((el) => {
    if (isNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id);
      el.targetPosition = isHorizontal ? "left" : "top";
      el.sourcePosition = isHorizontal ? "right" : "bottom";

      el.position = {
        x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
        y: nodeWithPosition.y - nodeHeight / 2,
      };
    }

    return el;
  });
};

const TreeAgency = (props) => {
  const { referralTree, bot } = props;
  const [reactflowInstance, setReactflowInstance] = useState(null);
  const [nodeHover, setNodeHover] = useState(null);
  const [elements, setElements] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();

  const handlePopoverOpen = (event, nodeInfo) => {
    setAnchorEl(event.currentTarget);
    setNodeHover(nodeInfo);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const onElementsRemove = useCallback(
    (elementsToRemove) =>
      setElements((els) => removeElements(elementsToRemove, els)),
    []
  );
  const onConnect = useCallback(
    (params) =>
      setElements((els) =>
        addEdge({ ...params, animated: true, style: { stroke: "#fff" } }, els)
      ),
    []
  );

  const onLoad = useCallback(
    (rfi) => {
      if (!reactflowInstance) {
        setReactflowInstance(rfi);
      }
    },
    [reactflowInstance]
  );

  useEffect(() => {
    if (referralTree.length > 0) {
      const position = { x: 0, y: 0 };
      const edgeType = "smoothstep";
      let nodes = cloneDeep(referralTree);
      let edges = cloneDeep(referralTree?.filter((node) => !!node.parent));

      nodes = nodes.map((node) => ({
        id: node.id,
        type: "selectorNode",
        data: {
          info: node,
          handlePopoverClose: handlePopoverClose,
          handlePopoverOpen: handlePopoverOpen,
        },
        position,
      }));

      edges = edges.map((edge) => ({
        id: `${edge.id}-${edge.parent}`,
        source: edge.parent,
        target: edge.id,
        animated: false,
        type: edgeType,
        targetHandle: "b",
      }));

      let tree = nodes.concat(edges);

      setElements(getLayoutedElements(tree));
    }
    return () => {
      dispatch(destroyTree());
    };
  }, []);

  useEffect(() => {
    if (reactflowInstance && elements.length > 0) {
      reactflowInstance.fitView();
    }
  }, [reactflowInstance, elements.length]);

  return (
    <ReactFlowProvider>
      {/* <div style={{ width: "100%", height: 1000 }}> */}
      <ReactFlow
        elements={elements}
        onElementClick={onElementClick}
        onElementsRemove={onElementsRemove}
        onConnect={onConnect}
        onNodeDragStop={onNodeDragStop}
        // style={{ background: bgColor }}
        onLoad={onLoad}
        nodeTypes={nodeTypes}
        connectionLineStyle={connectionLineStyle}
        // snapToGrid={true}
        //   snapGrid={snapGrid}
        minZoom={0.2}
        maxZoom={1}
        nodesDraggable={false}
        style={{ width: "100%", minHeight: 500 }}
        panOnScrollMode="horizontal"

        // paneMoveable
      >
        <PopoverNodeTree
          open={open}
          anchorEl={anchorEl}
          handlePopoverClose={handlePopoverClose}
          node={nodeHover}
          bot={bot}
        />

        {/* <MiniMap /> */}
      </ReactFlow>
      {/* </div> */}
    </ReactFlowProvider>
  );
};

export default TreeAgency;
