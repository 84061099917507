import {
  Button,
  createStyles,
  Grid,
  List,
  ListItem,
  makeStyles,
  Popover,
  Typography,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import convertNumberToMoney from "../../../common/convertNumberToMoney";
import CurrencySelect from "./CurrencySelect";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: { paddingLeft: 15 },
      rootSelect: {},
      typographyLabel: {
        color: theme.text.secondary,
      },
      buttonSelect: {
        backgroundColor: "#2A2D35",
        width: 270,
        textTransform: "none",
        // minWidth: 250,
        borderRadius: 3,
        color: theme.text.primary,
        padding: "8px 8px",
        margin: "10px 0 15px 0",
        "&:hover": {
          backgroundColor: "rgba(0,0,0,.05)",
        },
      },
      labelButton: {
        display: "flex",
        justifyContent: "space-between",
      },
      typographySecondary: {
        color: theme.text.secondary,
      },
      typographyPrimary: {
        color: theme.text.primary,
      },
      gridItem: {
        padding: "5px 0",
      },
      iconArrow: {
        float: "right",
      },
      coverCurrency: {
        display: "flex",
        alignItems: "center",
      },
      typographyBtnPrimary: {
        margin: "0 5px",
      },
      typographyBtnSecondary: {
        margin: "0 5px",
        color: theme.text.secondary,
      },
      paperPopover: {
        width: 270,
      },
    }),
  { index: 1 }
);

export default function WalletInfo(props) {
  const classes = useStyles(props);
  const { walletSelected, walletsInfo, handleSelectWallet } = props;
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <CurrencySelect
        walletSelected={walletSelected}
        wallets={walletsInfo.results}
        handleSelectWallet={handleSelectWallet}
      />

      <Grid container>
        {/* <Grid item xs={4} className={classes.gridItem}>
          <Typography variant="caption" className={classes.typographySecondary}>
            {t("Total balance")}:{" "}
          </Typography>
        </Grid>
        <Grid item xs={8} className={classes.gridItem}>
          <Typography variant="caption" className={classes.typographyPrimary}>
            {convertNumberToMoney(parseFloat(walletSelected.balance))}{" "}
            {walletSelected.c?.symbol}
          </Typography>
        </Grid> */}
        <Grid item xs={5} className={classes.gridItem}>
          <Typography variant="caption" className={classes.typographySecondary}>
            {t("Available balances")}:{" "}
          </Typography>
        </Grid>
        <Grid item xs={7} className={classes.gridItem}>
          <Typography variant="caption" className={classes.typographyPrimary}>
            {convertNumberToMoney(parseFloat(walletSelected.balance))}{" "}
            {walletSelected.c?.symbol}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
