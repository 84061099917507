import * as types from "../../const/redux/bot";

export const getBotPackageReq = () => {
  return {
    type: types.GET_BOT_PACKAGE_REQ,
  };
};

export const getBotPackageSuccess = (data) => {
  return {
    type: types.GET_BOT_PACKAGE_SUCCESS,
    payload: { data },
  };
};

export const getUserPackageReq = () => {
  return {
    type: types.GET_USER_PACKAGE_REQ,
  };
};

export const getUserPackageSuccess = (data) => {
  return {
    type: types.GET_USER_PACKAGE_SUCCESS,
    payload: { data },
  };
};

export const buyBotPackageReq = (dataReq) => {
  return {
    type: types.BUY_BOT_PACKAGE_REQ,
    payload: { dataReq },
  };
};

export const buyBotPackageSuccess = (data) => {
  return {
    type: types.BUY_BOT_PACKAGE_SUCCESS,
    payload: { data },
  };
};

export const upgradeBotPackageReq = (botId, dataReq) => {
  return {
    type: types.UPGRADE_BOT_PACKAGE_REQ,
    payload: { botId, dataReq },
  };
};

export const upgradeBotPackageSuccess = (data) => {
  return {
    type: types.UPGRADE_BOT_PACKAGE_SUCCESS,
    payload: { data },
  };
};

export const destroyTradingBot = () => {
  return {
    type: types.DESTROY_TRADE_BOT,
  };
};
