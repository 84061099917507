export const GET_BOT_V2_REQ = "GET_BOT_V2_REQ";
export const GET_BOT_V2_SUCCESS = "GET_BOT_V2_SUCCESS";

export const BUY_BOT_V2_REQ = "BUY_BOT_V2_REQ";
export const BUY_BOT_V2_SUCCESS = "BUY_BOT_V2_SUCCESS";

export const DEPOSIT_BOT_V2_REQ = "DEPOSIT_BOT_V2_REQ";
export const DEPOSIT_BOT_V2_SUCCESS = "DEPOSIT_BOT_V2_SUCCESS";

export const DESTROY_BOT_V2 = "DESTROY_BOT_V2";
